import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import React, {Component} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {Button, Icon, Transition} from "semantic-ui-react";
import styled from "styled-components";

const MessageDiv = styled.div`
  margin-bottom: 1.2rem;

  .welcome {
    background-color: #BBF3BA;
    padding: 1.2rem 1rem;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2rem;

    .left-content {
      display: inline-block;
      flex-basis: 90%;

      .welcome-content {
        color: #7687FF;
      }

      .last-registration {
        font-size: 25px;
      }
    }

    .right-content {
      display: inline-block;
      flex-basis: 10%;
      text-align: right;

      .ui.compact.icon.button,
      .ui.compact.icon.buttons .button {
        padding: 0;
      }

      button {
        background: transparent;

        i {
          color: #1ED71A;
          font-size: 3em;
        }

        i:HOVER {
          color: #18a915;
        }
      }
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 767px) {
    .welcome {
      padding: 0.5rem;
    }
  }

`;

interface Props extends WithTranslation, AuthConsumerRenderProps {
  visible: boolean
}

interface State {
  visible: boolean
}

class HeaderMessageUi extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      visible: props.visible
    };
  }

  closeMessage = () => {
    this.setState({
      visible: false
    });
  };

  render() {

    const {visible} = this.state;

    return (
        <Transition.Group animation="slide down" duration={125}>
          {visible && (
              <MessageDiv>
                <div className="welcome">
                  <div className="left-content">
                    {this.props.children}
                  </div>
                  <div className="right-content">
                    <Button circular icon compact onClick={this.closeMessage}>
                      <Icon size="big" name="times circle" />
                    </Button>
                  </div>
                </div>
              </MessageDiv>
          )}
        </Transition.Group>
    );
  }
}

const HeaderMessage = withAuthContext(
    withTranslation(["mipoco"])(HeaderMessageUi));

export default HeaderMessage;