import {CancelTokenSource} from "axios";
import axios from "service/http";
import {InterventionExternalCode} from "ts-types/api.enums";
import {InterventionDto} from "ts-types/api.types";

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const apiUrl = `${baseUrl}`;


export const getAllInterventions = (cancelSourceToken: CancelTokenSource): Promise<Array<InterventionDto>> => {
  return axios
      .get(`${apiUrl}/intervention`, {cancelToken: cancelSourceToken.token})
      .then(response => response.data);
};

export const getInterventionForExteranlCode = (
    externalCode: InterventionExternalCode,
    cancelSourceToken: CancelTokenSource): Promise<InterventionDto> => {

  return axios
  .get(`${apiUrl}/intervention/external-code/${externalCode}`,
      {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};
