import ConfirmActionPopup from "component/ConfirmActionPopup";
import StyledActionButton from "component/StyledActionButton";
import React, {Component} from 'react';
import {Icon, Popup} from 'semantic-ui-react';


const PopupStyle = {
  borderRadius: 5,
  opacity: 0.7,
  padding: '7px'
};

interface Props {
  disabled?: boolean,
  popupMessage?: string,
  hoverMessage: string,
  trigger?: React.ReactNode,
  onConfirm: () => void
}

interface State {

}

class DeleteActionButton extends Component<Props, State> {

  render(): React.ReactNode {
    const {
      disabled = false, hoverMessage, popupMessage, onConfirm, trigger = (<Icon name={'trash alternate outline'}/>)
    } = this.props;

    if (popupMessage) {
      return (
          <ConfirmActionPopup
              message={popupMessage}
              renderTarget={this.renderDeleteButton()}
              onConfirmAction={onConfirm}
              disabled={disabled}
          />
      );
    }
    return (
        <StyledActionButton icon
                            disabled={disabled}
                            type='button'
                            onClick={onConfirm}
        >
          <Popup
              trigger={trigger}
              content={hoverMessage}
              size='small'
              position='top center'
              style={PopupStyle}
              inverted
          />
        </StyledActionButton>
    );

  }

  renderDeleteButton = (): React.ReactNode => {
    const {disabled = false, hoverMessage, trigger = (<Icon name={'trash alternate outline'}/>)} = this.props;

    return (
        <StyledActionButton icon
                            disabled={disabled}
                            type='button'
        >
          <Popup
              trigger={trigger}
              content={hoverMessage}
              size='small'
              position='top center'
              style={PopupStyle}
              inverted
          />
        </StyledActionButton>
    );
  };

}

export default DeleteActionButton;
