import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import Radio, {generateRadioButtonOptionsFromEnum} from "component/final-form/Radio";
import MpGrid from "component/MpGrid";
import React, {Component} from "react";
import {Field, Form as FinalForm, FormRenderProps} from "react-final-form";
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";
import {CompanyOnboardViews} from "route/company-onboard/CompanyOnboardView";
import {Button, Grid, RadioProps} from "semantic-ui-react";
import {saveCompanyOnboarding} from "service/onboardingServices";
import axios from "service/http";
import styled from "styled-components";
import {InterventionExternalCode, TestPoolingType, TestRegistrationType} from "ts-types/api.enums";
import {UpsertCompanyOnboardingDto} from "ts-types/api.types";
import {testExternalCodes} from "util/enumUtils";
import {errorUtils} from "util/errorUtils";
import {withRouterWorkaround} from "util/workaroundUtils";

const StyledMpGrid = styled(MpGrid)`

  &.ui.grid > .row {
    min-height: 30px;

    > .column:first-child {
      padding-left: 0;
    }
  }

  .header-row {
    margin-top: 2rem;
  }
`;

const cancelTokenSource = axios.CancelToken.source();

interface Props extends RouteComponentProps<any>,
    AuthConsumerRenderProps,
    WithTranslation {

  companyOnboarding: Partial<UpsertCompanyOnboardingDto>,
  setActiveView: (activeView: CompanyOnboardViews) => void,
  setCompanyOnboardingData: (data: Partial<UpsertCompanyOnboardingDto>) => void,
  errorMessages: Array<string>,
  setErrorMessage: (m: string) => void
}

interface State {
  testRegistrationTypes: Array<RadioProps>,
  testTypes: Array<RadioProps>,
  testPoolingTypes: Array<RadioProps>
}

class InstructionsView extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    const testRegTypesLabels: string[] = Object.values(TestRegistrationType);
    const testTypeLabels: string[] = testExternalCodes();
    const poolingLabels: string[] = Object.values(TestPoolingType);

    const testRegistrationTypes = generateRadioButtonOptionsFromEnum(
        testRegTypesLabels,
        "testRegistrationType.description",
        props.t
    );

    const testTypes = generateRadioButtonOptionsFromEnum(
        testTypeLabels,
        "testType.InterventionExternalCode",
        props.t
    );

    const testPoolingTypes = generateRadioButtonOptionsFromEnum(
        poolingLabels,
        "testPoolingType",
        props.t
    );

    this.state = {
      testRegistrationTypes: testRegistrationTypes,
      testTypes: testTypes,
      testPoolingTypes: testPoolingTypes
    };
  }

  handleError(error: any) {
    const {t} = this.props;
    const errorCode = error.errorCode;
    const knownErrors: Array<string> = [
      errorUtils.invalidInput,
      errorUtils.duplicateEmail
    ];

    const violations: Array<any> = error.violations;

    if (violations && violations.length > 0) {
      violations.forEach(violation => {
        if (knownErrors.includes(violation.errorCode)) {
          this.props.setErrorMessage(t(`error.${violation.errorCode}`));
        }
      });
    }

    if (!this.props.errorMessages.length) {
      if (knownErrors.includes(errorCode)) {
        this.props.setErrorMessage(t(`error.${errorCode}`));
      } else {
        this.props.setErrorMessage(t('error.general'));
      }
    }
  };

  handleSubmit = (values: any) => {

    const upsertCompanyOnboarding: Partial<UpsertCompanyOnboardingDto> = {
      //@ts-ignore
      company: {
        ...this.props.companyOnboarding.company,
        testRegistrationType: values.testRegistrationType,
        testType: values.testType,
        testPoolingType: values.testPoolingType
      },
      employee: this.props.companyOnboarding.employee
    };

    saveCompanyOnboarding(upsertCompanyOnboarding, cancelTokenSource)
    .then(response => {
      this.props.setCompanyOnboardingData(upsertCompanyOnboarding);
      this.props.setActiveView(CompanyOnboardViews.REGISTRATION_SUCCESSFUL);
    })
    .catch((e: any) => this.handleError(e.response.data));


  };

  render() {

    return this.renderFinalForm();
  }

  renderFinalForm(): React.ReactNode {

    return (
        <FinalForm
            onSubmit={(values) => this.handleSubmit(values)}
            initialValues={{
              testRegistrationType: TestRegistrationType.CENTRAL,
              testType: InterventionExternalCode.PCR_SALIVA_TEST,
              testPoolingType: TestPoolingType.NONE
            }}
            subscription={{pristine: true, submitting: true}}
            render={this.renderCooperationDetailsFormContent}
        />
    );
  }

  renderCooperationDetailsFormContent = ({form, handleSubmit, submitting}: FormRenderProps): React.ReactNode => {

    const {t} = this.props;
    const {testRegistrationTypes, testTypes, testPoolingTypes} = this.state;

    return (
        <form onSubmit={handleSubmit}>
          <StyledMpGrid>
            <Grid.Row>
              <Grid.Column width={16}>
                {t("cooperationDetails.headerText")}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="header-row">
              <Grid.Column width={8}>
                <strong>{t("testRegistrationType.title")}</strong>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16} verticalAlign="middle">
                <Field
                    name="testRegistrationType"
                    component={Radio}
                    radioDefinition={testRegistrationTypes[0]}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16} verticalAlign="middle">
                <Field
                    name="testRegistrationType"
                    component={Radio}
                    radioDefinition={testRegistrationTypes[1]}
                    disabled
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="header-row">
              <Grid.Column width={12}>
                <strong>{t("productSelection.title")}</strong>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={12} verticalAlign="middle">
                <Field
                    name="testType"
                    component={Radio}
                    radioDefinition={testTypes[0]}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={12} verticalAlign="middle">
                <Field
                    name="testType"
                    component={Radio}
                    radioDefinition={testTypes[1]}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="header-row">
              <Grid.Column width={12}>
                <strong>{t("poolingSelection.title")}</strong>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5} verticalAlign="middle">
                <Field
                    name="testPoolingType"
                    component={Radio}
                    radioDefinition={testPoolingTypes[0]}
                />
              </Grid.Column>
              <Grid.Column width={11} verticalAlign="middle">
                <Field
                    name="testPoolingType"
                    component={Radio}
                    radioDefinition={testPoolingTypes[2]}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5} verticalAlign="middle">
                <Field
                    name="testPoolingType"
                    component={Radio}
                    radioDefinition={testPoolingTypes[1]}
                />
              </Grid.Column>
              <Grid.Column width={11} verticalAlign="middle">
                <Field
                    name="testPoolingType"
                    component={Radio}
                    radioDefinition={testPoolingTypes[3]}
                    disabled
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row textAlign="right">
              <Grid.Column width={16}>
                <Button
                    type="submit"
                    className="action-button"
                    primary
                    style={{display: "inline-block", marginRight: "1.5rem"}}
                >
                  {t("companyOnboard.button.next")}
                </Button>
                <Button
                    type="button"
                    className="action-button"
                    onClick={() => this.props.setActiveView(CompanyOnboardViews.COMPANY_EMPLOYEE)}
                    secondary
                    style={{display: "inline-block"}}
                >
                  {t("action.back")}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </StyledMpGrid>
        </form>
    );
  };
}

let InstructionsViewWrapper = withRouterWorkaround(
    withAuthContext(
        withTranslation(["login"])(
            InstructionsView)));

export default InstructionsViewWrapper;