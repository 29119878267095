import {CancelTokenSource} from "axios";
import axios from "service/http";
import {InterventionExternalCode} from "ts-types/api.enums";
import {
  InterventionAvailabilitySlotSearchDto,
  InterventionBookingDto,
  QuestionSetDto,
  ResourceDisplayDto,
  TestAppointmentRequest
} from "ts-types/api.types";

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const apiUrl = `${baseUrl}`;

export const getPcrTestQuestionSet = (
    cancelSourceToken: CancelTokenSource): Promise<QuestionSetDto> => {

  return axios
  .get(`${apiUrl}/pcr-test/question-set`, {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};

export const getQuestionSetForExternalCode = (
    externalCode: InterventionExternalCode,
    cancelSourceToken: CancelTokenSource): Promise<QuestionSetDto> => {

  return axios
  .get(`${apiUrl}/test/question-set`,
      {
        params: {
          externalCode: externalCode
        },
        cancelToken: cancelSourceToken.token
      })
  .then(response => response.data);
};

export const getTestResources = (
    externalCode: InterventionExternalCode,
    cancelSourceToken: CancelTokenSource): Promise<ResourceDisplayDto[]> => {

  return axios
  .get(`${apiUrl}/test/resources`,
      {
        params: {
          externalCode: externalCode
        },
        cancelToken: cancelSourceToken.token
      })
  .then(response => response.data);
};

export const getPcrTestBooking = (
    cancelSourceToken: CancelTokenSource): Promise<InterventionBookingDto> => {

  return axios
  .get(`${apiUrl}/pcr-test/booking`, {cancelToken: cancelSourceToken.token})
  .then(response => {
    return response.data;
  });
};

export const searchTestAppointment = (
    resourceId: number,
    externalCode: InterventionExternalCode,
    cancelSourceToken: CancelTokenSource): Promise<InterventionAvailabilitySlotSearchDto[]> => {

  return axios
  .post(`${apiUrl}/test/search-appointment`, {}, {
    params: {
      resourceId: resourceId,
      externalCode: externalCode
    },
    cancelToken: cancelSourceToken.token
  })
  .then(response => response.data);
};


export const searchPcrTestAppointment = (
    resourceId: number,
    cancelSourceToken: CancelTokenSource): Promise<InterventionAvailabilitySlotSearchDto[]> => {

  return axios
  .post(`${apiUrl}/pcr-test/search-appointment`, {}, {
    params: {resourceId: resourceId},
    cancelToken: cancelSourceToken.token
  })
  .then(response => response.data);
};

export const scheduleTestAppointment = (
    employeeId: number | undefined,
    request: Partial<TestAppointmentRequest>,
    cancelSourceToken: CancelTokenSource): Promise<String> => {

  let url = `${apiUrl}/test/schedule-test`;
  if (employeeId) {
    url = `${url}/${employeeId}`;
  }

  return axios
  .post(url,
      request,
      {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};
