import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import axios from "axios";
import LoaderComponent from "component/LoaderComponent";
import React, {Component} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps, withRouter} from "react-router";
import {Icon} from "semantic-ui-react";
import {setAccessToken} from "service/browserStorageServices";
import {
  generateEmployeeCandidateAccessToken,
  getCandidateEmployees,
  getParticipantCandidateEmployees
} from "service/userServices";
import styled from "styled-components";
import {EmployeeAccountDisplayDto} from "ts-types/api.types";
import {errorUtils} from "util/errorUtils";
import LoginView from "./LoginView";

const ContainerDiv = styled.div`

  .accounts-box {
    margin-bottom: 2.2rem;

    .company-title {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      font-weight: bold;
      color: #7687ff;
      margin-bottom: 0.7rem;

      i.large.icon {
        font-size: 1.85rem;
      }
    }

    .account {
      margin-bottom: 1rem;

      border: 1px solid #7687FF;
      background: #7687FF;
      font-size: 1.2rem;
      font-weight: bold;
      color: white;
      max-width: 28rem;
      min-height: 2.4rem;
      padding: 0.875rem;
      cursor: pointer;

      &:FOCUS {
        outline: none;
        border: 1px solid #1b33d4;
      }

      &:FOCUS:HOVER {
        background: #5B70FF;
      }

      &:HOVER {
        background: #8e9bff;
      }

      &.disabled {
        background: #BAC3FF;
        border: 1.5px solid #BAC3FF;
        cursor: default;

        &:FOCUS:HOVER {
          background: #BAC3FF;
          border: 1px solid #BAC3FF;
        }
      }
    }
  }

`;

const InfoDiv = styled.div`
  max-width: 40rem;
  margin-bottom: 1.5rem;
`;

interface Props extends RouteComponentProps<any>, AuthConsumerRenderProps, WithTranslation {
}

interface State {
  initialized: boolean;
  token: string;
  accounts: EmployeeAccountDisplayDto[];
  error?: string;
}

class AccountSelection extends Component<Props, State> {

  private cancelTokenSource = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);

    const locState: any = props.location.state;
    const token = locState && locState.token;

    this.state = {
      initialized: false,
      token: token,
      accounts: []
    };

    this.fetchAccounts();
  }

  fetchAccounts = async () => {
    const {currentUser} = this.props;
    const {token} = this.state;
    try {
      let accounts: EmployeeAccountDisplayDto[] = [];
      if (token) {
        accounts = await getCandidateEmployees(token, this.cancelTokenSource);
      } else if (currentUser) {
        accounts = await getParticipantCandidateEmployees(this.cancelTokenSource);
      }
      this.setState({
        accounts
      });
    } catch (e) {
      this.setState({
        error: "error.general"
      });
    } finally {
      this.setState({
        initialized: true
      });
    }
  };

  verifyCandidateAccessToken = async (employeeId: string) => {
    const {t} = this.props;
    try {
      const accessToken = await generateEmployeeCandidateAccessToken(employeeId, this.cancelTokenSource);
      setAccessToken(accessToken);
      this.props.reloadCurrentUser();
    } catch (error) {
      if (error.response && error.response.data) {
        const err = error.response.data;
        const knownErrors: Array<string> = [
          errorUtils.employeeEmailNotRelated
        ];

        const violations: Array<any> = err.violations;

        if (violations && violations.length > 0) {
          violations.forEach(violation => {
            if (knownErrors.includes(violation.errorCode)) {
              this.setState({
                error: t(`error.${violation.errorCode}`)
              });
              this.props.logout();
            }
          });
        }
      }
    }
  };

  verifyCode = async (employeeId: string) => {

    const {token} = this.state;

    try {

      if (token) {
        await this.props.verifyToken(token, undefined, employeeId);
      } else {
        await this.verifyCandidateAccessToken(employeeId);
      }

      this.props.history.push("/", {welcome: "true"});

    } catch (e) {
      const {response} = e;
      if (response?.data?.error) {
        const {error} = response.data;

        if (["INVALID_VERIFICATION_CODE"].includes(error)) {
          this.props.history.push("/verification-code", {token: token, employeeId: employeeId});
          return;
        }
      }
    }
  };

  render(): React.ReactNode {

    const {t} = this.props;
    const {accounts, initialized} = this.state;

    const companyIds: Set<number> = new Set(accounts.map(account => account.companyId));
    const multipleCompanies = companyIds.size > 1;

    return <LoginView titleKey="login.accountSelection.title">
      <ContainerDiv>
        <InfoDiv>{t("login.accountSelection.info")}</InfoDiv>

        {!initialized
            ? <LoaderComponent message="" />
            : multipleCompanies
                ?
                Array.from(companyIds).map(companyId =>
                    <div className="accounts-box">
                      <div className="company-title">
                        <Icon name="building" size="large" />
                        <span className="company-text">
                        {accounts.find(account => account.companyId === companyId)!.companyName}
                      </span>
                      </div>

                      {
                        accounts.filter(account => account.companyId === companyId).map(account =>
                                <div
                                    key={`account-${account.id}`}
                                    className="account"
                                    onClick={() => this.verifyCode(`${account.id}`)}
                                >
                      <span
                          className="employee-name">{`${account.firstName} ${account.lastName}`}</span>
                                </div>
                        )
                      }
                    </div>
                )

                : <div className="accounts-box">
                  {
                    this.state.accounts.map(account =>
                        <div
                            key={`account-${account.id}`}
                            className="account"
                            onClick={() => this.verifyCode(`${account.id}`)}
                        >
                      <span
                          className="employee-name">{`${account.firstName} ${account.lastName}`}</span>
                        </div>
                    )
                  }
                </div>
        }

      </ContainerDiv>
    </LoginView>;
  }
}

export default withRouter(withAuthContext(withTranslation(["login"])(AccountSelection)));
