import {CancelTokenSource} from "axios";
import axios from "service/http";
import {ProductDto} from "ts-types/api.types";

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const apiUrl = `${baseUrl}`;


export const fetchAllProducts = (source: CancelTokenSource): Promise<ProductDto[]> => {
  return axios
  .get(`${apiUrl}/product/all`, {cancelToken: source.token})
  .then(response => response.data);
};
