import styled from 'styled-components';

export const DataEntry = styled.div`
  flex: 1 1 auto;
  display: grid;
  grid-gap: 5px;
  padding-bottom: 15px;
  grid-template-columns: min-content minmax(min-content, 1fr);
  font-size: 1.0877rem;

  .label {
    min-width: 2rem;
    font-weight: bold;
  }

  span.no-break {
    white-space: nowrap;
    margin-left: 0.5rem;
    color: #353535;
    font-size: 0.875em;
  }

  &.questionnaireRow {
    grid-template-columns: minmax(min-content, 3fr) max-content;
    row-gap: 1rem;
  }

  & .questions-divider {
    grid-column: 1 / span 2;
    height: 1.5rem;

    &.small {
      height: 0.75rem;
    }
  }

  & .question-num-label {
    font-weight: normal;
    color: #575757;
  }

  & .question-container {
    display: flex;
    max-width: 22rem;
    grid-column: 2 / span 1;

    .button-back,
    .button-next {
      flex: 0 0 auto;
      align-self: flex-start;
    }

    .button-back {
      margin-left: -2.5rem;
    }

    .question {
      flex: 1 1 auto;
      margin: 0.175rem 0.45rem 0.175rem 0.2rem;

      .question-wrapper.inactive {
        display: none;
      }

      .question-text {
        font-weight: normal;
        margin-bottom: 1.2rem;
        min-height: 2.3rem;
      }
    }
  }
`
export const Label = styled.div`
    min-width: 125px;
`;

export const DataEntryValue = styled.div`
  min-width: 22rem;

  .date-time-value & {
    min-width: 5rem;
  }

  &.answer-value {
    min-width: 170px;
  }
`
