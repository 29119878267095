import React from 'react';
import Dashboard from "../component/Dashboard";

function Home() {

  return (
      <div className="home">
        <Dashboard/>
      </div>
  );
}

export default Home;
