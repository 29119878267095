import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import CheckBox from "component/final-form/CheckBox";
import LoaderComponent from "component/LoaderComponent";
import MpGrid from "component/MpGrid";
import StyledErrorMessage from "component/StyledErrorMessage";
import moment from "moment";
import React, {Component} from "react";
import {Field, Form as FinalForm, FormRenderProps} from 'react-final-form';
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";
import LoginView from "route/login/LoginView";
import {Button, Grid} from "semantic-ui-react";
import {
  getContactDocuments,
  saveCompanyContractConfirmation,
  saveEmployeeContractConfirmation
} from "service/contractDocumentServices";
import axios from "service/http";
import styled from "styled-components";
import {ConfirmationStatusType, ContractDocumentType, EmployeeAccessTokenType} from "ts-types/api.enums";
import {ContractConfirmationDto, ContractDocumentsDto, UpsertContractConfirmationsDto} from "ts-types/api.types";
import {withRouterWorkaround} from "util/workaroundUtils";

const StyledMpGrid = styled(MpGrid)`

  font-size: 1.1rem;

  &.ui.checkbox .box,
  &.ui.checkbox label {
    font-size: 1.1rem;
  }

  &.ui.grid > .row > .column {
    padding-right: 0;
    padding-left: 0;

    a.contract-document-url {
      color: rgba(0, 0, 0, .87);
      text-decoration: underline;
    }

    .ui.checkbox label:before {
      border-color: #7687FF;
      border-radius: unset;
    }

    button {
      margin-right: 1.5rem;
    }
  }

  @media only screen and (max-width: 767px) {
    &.ui.grid > .row > .column {
      .button {
        margin-bottom: 1rem;
        display: block;
        width: 100%;
      }
    }
  }
`;

const cancelTokenSource = axios.CancelToken.source();

interface Props extends RouteComponentProps<any>,
    AuthConsumerRenderProps,
    WithTranslation {

}

interface State {
  contractDocuments?: ContractDocumentsDto,
  dataLoaded: boolean,
  errorMessages: Array<string>
}

class ContractDocumentView extends Component<Props, State> {

  private mounted: boolean = false;

  constructor(props: Props) {
    super(props);

    this.state = {
      dataLoaded: false,
      errorMessages: []
    };

    getContactDocuments(cancelTokenSource)
    .then(response => {
      this.setState({
        contractDocuments: response
      });
    })
    .catch((e: any) => this.handleError(e.response.data))
    .finally(() => {
      this.setState({
        dataLoaded: true
      });
    });
  }

  componentDidMount(): void {
    this.mounted = true;
  }

  handleError(error: any) {
    const {t} = this.props;

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            this.setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!this.state.errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          this.setErrorMessage(t(`error.${errorCode}`));
        } else {
          this.setErrorMessage(t('error.general'));
        }
      }
    }
  };


  setErrorMessage = (errorMessage?: string) => {

    const {errorMessages} = this.state;

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);

      this.setState({
        errorMessages: errMsgs
      });
    } else {

      this.setState({
        errorMessages: []
      });
    }
  };

  initialFormData = (): Partial<UpsertContractConfirmationsDto> => {
    const {contractDocuments} = this.state;


    const termsOfService: ContractConfirmationDto = {
      documentType: ContractDocumentType.TERMS_OF_SERVICE,
      documentVersion: contractDocuments && contractDocuments.termsOfService
          ? contractDocuments.termsOfService.documentVersion
          : "",
      selected: false
    };

    const dataProtection: ContractConfirmationDto = {
      documentType: ContractDocumentType.DATA_PROTECTION,
      documentVersion: contractDocuments && contractDocuments.dataProtection
          ? contractDocuments.dataProtection.documentVersion
          : "",
      selected: false
    };

    return {
      termsOfService: termsOfService,
      dataProtection: dataProtection,
      accepted: false,
      rejected: false
    };
  };

  handleSubmit = async (values: Partial<UpsertContractConfirmationsDto>, decision: string) => {

    this.setErrorMessage();

    let request: Partial<UpsertContractConfirmationsDto> = {
      ...values
    };
    if (decision === "accepted") {
      request.accepted = true;
      request.rejected = false;
    } else if (decision === "rejected") {
      request.accepted = false;
      request.rejected = true;
    }

    try {
      const currentUser = this.props.currentUser!;
      if (currentUser.accountType === EmployeeAccessTokenType.PERSON
          || currentUser.accountType === EmployeeAccessTokenType.PERSON_SELF_ONBOARDED) {
        await saveEmployeeContractConfirmation(request, cancelTokenSource);
      } else if (currentUser.accountType === EmployeeAccessTokenType.COMPANY) {
        await saveCompanyContractConfirmation(request, cancelTokenSource);
      }
    } catch (e) {
      this.handleError(e.response.data);
      return;
    }

    if (request.accepted) {
      await this.props.reloadCurrentUser();
      this.props.history.push("/", {welcome: true});
    } else if (request.rejected) {
      this.props.history.push("/tos-rejected");
    }
  };

  render() {

    const {currentUser} = this.props;
    const {dataLoaded} = this.state;

    if (currentUser!.registrationState === ConfirmationStatusType.ACCEPTED) {
      return this.renderAfterSubmitCompany();
    }

    return (
        <>
          {dataLoaded
              ? this.renderContractualDataForm()
              : <LoaderComponent message="Loading form" />
          }
        </>
    );
  }

  renderContractualDataForm = (): JSX.Element => {

    return (
        <FinalForm
            onSubmit={() => {
            }}
            initialValues={this.initialFormData()}
            subscription={{pristine: true, values: true, submitting: true}}
            render={this.renderContractualFormContent}
        />
    );
  };

  renderAfterSubmitCompany = (): React.ReactNode => {
    const {t, currentUser} = this.props;
    const messageKey = currentUser!.registrationState.toLowerCase();
    return (
        <LoginView titleKey={`contractDocuments.${messageKey}Title`}>
          <StyledMpGrid>
            <Grid.Row style={{marginBottom: "2rem"}}>
              <Grid.Column width={8}>
                {t(`contractDocuments.${messageKey}Text`)}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <Button
                    type="button"
                    className="action-button"
                    secondary
                    onClick={() => this.props.logout()}
                    style={{display: "inline-block"}}
                >
                  {t("action.cancel")}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </StyledMpGrid>
        </LoginView>
    );
  };

  renderContractualFormContent = ({form, handleSubmit, values}: FormRenderProps): React.ReactNode => {

    const {t} = this.props;
    const {contractDocuments, errorMessages} = this.state;

    const termsOfService = (
        (contractDocuments && contractDocuments.termsOfService) &&
        <>
          <a className="contract-document-url"
             href={
               contractDocuments.termsOfService.documentUrl
                   ? contractDocuments.termsOfService.documentUrl
                   : "#"
             }
             rel="noreferrer"
             target="_blank"
          >
            {contractDocuments.termsOfService.description}
          </a><br />
          ({t("contractDocuments.version")}&nbsp;
          {contractDocuments.termsOfService.documentVersion} {t("contractDocuments.from")}
          &nbsp;
          {moment(contractDocuments.termsOfService.validFromDate).format('DD. MMMM YYYY')})
        </>
    );

    const dataProtection = (
        (contractDocuments && contractDocuments.dataProtection) &&
        <>
          <a className="contract-document-url"
             href={
               contractDocuments.dataProtection.documentUrl
                   ? contractDocuments.dataProtection.documentUrl
                   : "#"}
             rel="noreferrer"
             target="_blank"
          >
            {contractDocuments.dataProtection.description}
          </a><br />
          ({t("contractDocuments.version")}&nbsp;
          {contractDocuments.dataProtection.documentVersion} {t("contractDocuments.from")}
          &nbsp;
          {moment(contractDocuments.dataProtection.validFromDate).format('DD. MMMM YYYY')})
        </>
    );

    const disableAcceptReject =
        !(
            values
            && values.termsOfService.selected
            && values.dataProtection.selected
        );

    const messageKey = this.props.isPersonAccessType() ? "person" : "company";

    return (
        <LoginView titleKey="contractDocuments.title">
          <form onSubmit={handleSubmit}>
            <StyledMpGrid stackable>
              {errorMessages.length > 0 &&
                <Grid.Row>
                  <Grid.Column width={8}>
                    <StyledErrorMessage onDismiss={() => this.setErrorMessage()}>
                      {errorMessages.map(err => <div key={err}>{err}</div>)}
                    </StyledErrorMessage>
                  </Grid.Column>
                </Grid.Row>
              }
              <Grid.Row style={{marginBottom: "2rem"}}>
                <Grid.Column width={8}>
                  {t(`contractDocuments.${messageKey}.text`)}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8}>
                  {termsOfService}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Field
                      name="termsOfService.selected"
                      className="checkbox"
                      component={CheckBox}
                      label={t("contractDocuments.agree")}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8}>
                  {dataProtection}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Field
                      name="dataProtection.selected"
                      className="checkbox"
                      component={CheckBox}
                      label={t("contractDocuments.agree")}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={10}>
                  <Button
                      type="button"
                      className="action-button"
                      primary
                      onClick={() => this.handleSubmit(values, "accepted")}
                      disabled={disableAcceptReject}
                  >
                    {t("contractDocuments.btn.accept")}
                  </Button>
                  <Button
                      type="button"
                      className="action-button"
                      primary
                      onClick={() => this.handleSubmit(values, "rejected")}
                  >
                    {t("contractDocuments.btn.reject")}
                  </Button>
                  <Button
                      type="button"
                      className="action-button"
                      secondary
                      onClick={() => this.props.history.push(`/login`)}
                  >
                    {t("action.cancel")}
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </StyledMpGrid>
          </form>
        </LoginView>
    );
  };

}


let ContractDocumentViewWrapper = withRouterWorkaround(
    withAuthContext(
        withTranslation(["mipoco"])(
            ContractDocumentView)));

export default ContractDocumentViewWrapper;