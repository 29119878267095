import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import StyledErrorMessage from "component/StyledErrorMessage";
import React, {Component} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";
import AddCompanyEmployee from "route/company-onboard/AddCompanyEmployee";
import CompanyOnboardContainer from "route/company-onboard/CompanyOnboardContainer";
import CooperationDetialsView from "route/company-onboard/CooperationDetialsView";
import InstructionsView from "route/company-onboard/InstructionsView";
import RegistrationSuccessfulView from "route/company-onboard/RegistrationSuccessfulView";
import ValidationView from "route/company-onboard/ValidationView";
import {CompanyType, TestPoolingType, TestRegistrationType} from "ts-types/api.enums";
import {UpsertCompanyOnboardingDto} from "ts-types/api.types";
import {withRouterWorkaround} from "util/workaroundUtils";

export enum CompanyOnboardViews {
  INSTRUCTIONS = "INSTRUCTIONS",
  VALIDATION = "VALIDATION",
  COMPANY_EMPLOYEE = "COMPANY_EMPLOYEE",
  COOPERATION_DETAILS = "COOPERATION_DETAILS",
  REGISTRATION_SUCCESSFUL = "REGISTRATION_SUCCESSFUL"
}

interface Props extends RouteComponentProps<any>,
    AuthConsumerRenderProps,
    WithTranslation {

}

interface State {
  activeView: CompanyOnboardViews,
  companyOnboarding: Partial<UpsertCompanyOnboardingDto>,
  errorMessages: Array<string>
  companyEmail: string
}

class CompanyOnboardView extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      activeView: CompanyOnboardViews.INSTRUCTIONS,
      companyOnboarding: this.initialCompanyOnboardingValues(),
      errorMessages: [],
      companyEmail: ""
    };
  }

  initialCompanyOnboardingValues = (): Partial<UpsertCompanyOnboardingDto> => {
    return {
      //@ts-ignore
      company: {
        companyType: CompanyType.COMPANY,
        testRegistrationType: TestRegistrationType.CENTRAL,
        testPoolingType: TestPoolingType.NONE,
        description: "",
        city: "",
        street: "",
        zip: "",
        tel: "",
        homepage: "",
        email: "",
        language: "de"
      },
      //@ts-ignore
      employee: {
        companyEmail: "",
        name: "",
        firstName: "",
        birthDate: "",
        sex: 2,
        telephone: "",
        language: "de"
      }
    };
  };


  setActiveView = (activeView: CompanyOnboardViews) => {
    this.setState({
      activeView: activeView
    });
  };

  setCompanyOnboardingData = (data: Partial<UpsertCompanyOnboardingDto>) => {
    this.setState({
      companyOnboarding: data
    });
  };

  setCompanyEmail = (email: string) => {
    this.setState({
      companyEmail: email
    });
  };

  setErrorMessage = (errorMessage?: string) => {

    const {errorMessages} = this.state;

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);

      this.setState({
        errorMessages: errMsgs
      });
    } else {

      this.setState({
        errorMessages: []
      });
    }
  };

  renderActiveView = () => {

    const {companyOnboarding, activeView, errorMessages, companyEmail} = this.state;

    if (companyEmail) {
      companyOnboarding.employee!.companyEmail = companyEmail;
    }

    switch (activeView) {
      case CompanyOnboardViews.VALIDATION: {
        return <ValidationView
          setActiveView={this.setActiveView}
          setCompanyEmail={this.setCompanyEmail}
        />;
      }
      case CompanyOnboardViews.COMPANY_EMPLOYEE: {
        return <AddCompanyEmployee
            companyOnboarding={companyOnboarding}
            setActiveView={this.setActiveView}
            setCompanyOnboardingData={this.setCompanyOnboardingData}
        />;
      }
      case CompanyOnboardViews.COOPERATION_DETAILS: {
        return <CooperationDetialsView
            companyOnboarding={companyOnboarding}
            setActiveView={this.setActiveView}
            setCompanyOnboardingData={this.setCompanyOnboardingData}
            errorMessages={errorMessages}
            setErrorMessage={this.setErrorMessage}
        />;
      }
      case CompanyOnboardViews.REGISTRATION_SUCCESSFUL:
        return <RegistrationSuccessfulView/>;
      default: {
        return <InstructionsView
            setActiveView={this.setActiveView}
        />;
      }
    }
  };

  render(): React.ReactNode {

    const {errorMessages, activeView} = this.state;

    return (
        <CompanyOnboardContainer titleKey={`companyOnboard.${activeView}.title`} activeView={activeView}>
          {errorMessages.length > 0 &&
          <StyledErrorMessage onDismiss={() => this.setErrorMessage()}>
            {errorMessages.map(err => <div key={err}>{err}</div>)}
          </StyledErrorMessage>
          }
          {this.renderActiveView()}
        </CompanyOnboardContainer>
    );
  }
}

let CompanyOnboardViewWrapper = withRouterWorkaround(
    withAuthContext(
        withTranslation(["login"])(
            CompanyOnboardView)));

export default CompanyOnboardViewWrapper;