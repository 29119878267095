interface Props {
    color?: string;
}

const MisantoLogo = (props: Props) => {
    // const {color = "#7A97FF"} = props;
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 86 86"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
            strokeMiterlimit="10"
        >
                    <g>
                        <rect x="0" y="0" width="85.917" height="85.917" fill="#7687ff"/>
                        <g>
                            <g>
                                <clipPath id="_clip1">
                                    <path d="M56.688,59.063c-0.48,-0 -0.73,-0.25 -0.73,-0.75l0,-21.146c0,-0.5 0.25,-0.75 0.73,-0.75l4.604,-0c0.479,-0 0.729,0.25 0.729,0.75l-0,21.166c-0,0.5 -0.25,0.75 -0.729,0.75l-4.604,0l-0,-0.02Zm2.291,-24.813c-1.083,0 -1.979,-0.354 -2.666,-1.042c-0.688,-0.687 -1.042,-1.583 -1.042,-2.666c-0,-1.084 0.354,-1.979 1.041,-2.667c0.688,-0.687 1.584,-1.042 2.667,-1.042c1.125,0 2.021,0.334 2.688,1.021c0.666,0.667 1,1.563 1,2.688c-0,1.125 -0.334,2.021 -1,2.687c-0.667,0.688 -1.563,1.021 -2.688,1.021Zm-34.917,24.813c-0.479,-0 -0.729,-0.25 -0.729,-0.75l0,-29.355c0,-0.5 0.25,-0.75 0.73,-0.75l4.604,0c0.375,0 0.666,0.167 0.854,0.48l6.229,10.312c0.063,0.083 0.125,0.125 0.188,0.125c0.062,0 0.125,-0.042 0.145,-0.125l6.313,-10.312c0.208,-0.334 0.479,-0.48 0.854,-0.48l4.604,0c0.479,0 0.729,0.25 0.729,0.75l0,29.355c0,0.5 -0.25,0.75 -0.729,0.75l-4.604,-0c-0.479,-0 -0.729,-0.25 -0.729,-0.75l-0,-19.084c-0,-0.104 -0.042,-0.187 -0.104,-0.208c-0.084,-0.021 -0.146,0.021 -0.188,0.125l-4.354,7.271c-0.208,0.333 -0.479,0.479 -0.854,0.479l-2.188,-0c-0.375,-0 -0.666,-0.167 -0.854,-0.479l-4.291,-7.271c-0.063,-0.125 -0.126,-0.167 -0.188,-0.125c-0.063,0.021 -0.104,0.104 -0.104,0.208l-0,19.084c-0,0.5 -0.25,0.75 -0.729,0.75l-4.605,-0Z"/>
                                </clipPath>
                                <g clipPath="url(#_clip1)">
                                    <rect x="22.896" y="26.438" width="40.188" height="33.063"
                                          fill="#fff" stroke="#7687ff" strokeWidth="2.08px"/>
                                </g>
                            </g>
                        </g>
                    </g>
        </svg>
    )
}

export default MisantoLogo;