import React, {Component} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import styled from "styled-components";

const ViewContainerDiv = styled.div`
  margin: 0.5rem 0.525rem;
  display: flex;
  flex-direction: column;
  font-size: 1.275rem;
  
  .view-title {
    color: #7687FF;
    font-weight: bold;
    font-size: 1.8rem;
    margin-bottom: 3rem;
  }
  
  .login-button {
    margin-bottom: 1.5rem;
  }
`;

interface Props extends WithTranslation {
  titleKey: string;
  className?: string;
}

class AppView extends Component<Props> {

  render(): React.ReactNode {

    const {titleKey, className, t, children} = this.props;

    return <ViewContainerDiv className={`${className || ""} app-view`}>
      <h2 className="view-title">
        {t(titleKey)}
      </h2>

      {children}
    </ViewContainerDiv>;
  }
}

export default withTranslation(["mipoco"])(AppView);
