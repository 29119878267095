import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import {CompanyDataConsumerRenderProps, withCompanyDataContext} from "component/CompanyDataContext";
import CompanyDataHeader from "component/CompanyDataHeader";
import CheckBox, {CheckboxSc} from "component/final-form/CheckBox";
import FinalFormInput from "component/final-form/Input";
import Radio, {generateRadioButtonOptions, generateRadioButtonOptionsFromEnum} from "component/final-form/Radio";
import Select, {
  DropdownOption,
  mapToDropdownOptionArray,
  stringArrayToDropdownOptionArray
} from "component/final-form/Select";
import UploadButton from "component/final-form/UploadButton";
import LoaderComponent from "component/LoaderComponent";
import StyledErrorMessage from "component/StyledErrorMessage";
import VirtualizedTable from "component/VirtualizedTable";
import {FormApi} from "final-form";
import moment from "moment";
import React, {Component} from "react";
import {Field, Form as FinalForm, FormRenderProps} from 'react-final-form';
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";
import {enabledTestFrequencies} from "route/employees/employeeUtils";
import {Button, Container, Icon, Input, Popup, RadioProps} from "semantic-ui-react";
import {getCompanyExternalCodeFromCompanyArticleGroups, getCompanyShippableMedicaments} from "service/companyServices";
import {importEmployees, uploadEmployeesFile} from "service/employeeServices";
import axios from "service/http";
import styled from "styled-components";
import {TestFrequency, TestRegistrationType} from "ts-types/api.enums";
import {EmployeeImportDto, EmployeeImportRequest, UpsertEmployeeImportDto} from "ts-types/api.types";
import {errorUtils} from "util/errorUtils";
import {buildMap, noop} from "util/functionUtils";
import {withRouterWorkaround} from "util/workaroundUtils";

const cancelTokenSource = axios.CancelToken.source();

const ContainerDiv = styled.div`

  margin-bottom: 2rem;
  width: 100%;

  .import-form-field {
    display: grid;
    grid-template-columns: 150px minmax(10rem, 25rem) minmax(10rem, 25rem);

    grid-row-gap: 0.25rem;

    margin-top: 1rem;

    label {
      align-self: center;
    }

    .error {
      grid-column-start: 2;
      justify-self: start;
      color: darkred;
      font-size: 1rem;
      margin: 0.3rem 0 0.5rem 0;
    }

    .ui.selection.dropdown, .ui.selection.active.dropdown .menu {
      border: 1px solid #c9d0ff;
      border-radius: unset;
      box-shadow: unset;
    }

    .action-button {
      grid-column-start: 3;
      justify-self: start;
    }
  }

  .import-form-radio {
    display: grid;
    grid-template-columns: 150px minmax(15rem, 35rem);

    grid-row-gap: 0.25rem;

    margin-top: 1.5rem;

    label {
      align-self: center;
    }

    .radio-group {
      display: inline-block;

      grid-column-start: 2;
      justify-self: start;

      .field {
        display: inline-block;

        .ui.radio.checkbox {
          min-width: 150px;
          line-height: 19px;
          margin-right: 2rem;

          input {
            width: 20px;
            height: 20px;

          }

          label:before, label:after {
            border-color: #c9d0ff;
            width: 18px;
            height: 18px;
          }
        }
      }
    }

    .error {
      grid-column-start: 2;
      justify-self: start;
      color: darkred;
      font-size: 1rem;
      margin: 0.3rem 0 0.5rem 0;
    }
  }

  .action-buttons-row {
    margin-top: 2rem;

    button {
      margin-right: 0.75rem;
    }
  }

  .accept-contractual-documents {
    display: grid;
    width: 350px;
    margin-top: 1.5rem;
  }

  .import-elements {
    display: flex;
    display-direction: row;
    flex-wrap: wrap;
  }
`;

const EmployeesTableContainer = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;

  .employee-table {
    flex: 1 1 auto;
    min-height: 500px;
    width: 100%;
  }

  .action-buttons-row {
    flex: 0 0 auto;
    margin: 2rem 0 0;
  }

  .ui.checkbox label:before {
    border-color: #7687FF !important;
    border-radius: unset;
  }
`;

const ResultsPane = styled.div`
  flex: 0 0 auto;
  margin-right: 15px;
  min-width: 15rem;
  max-width: 36rem;

  .results-header {
    font-weight: bold;
    font-size: 1.1rem;
    border-bottom: 1px solid #0f1a52;
    margin-bottom: 0.5rem;
  }

  .results-container {
    display: grid;
    grid-template-columns: minmax(min-content, 1fr);
    row-gap: 0.35rem;
    column-gap: 1.2rem;

    .import-error {
      color: #575757;
    }
  }
`;

const StyledPopup = styled(Popup)`
  &::before {
    box-shadow: -1px 1px 0 0 rgb(118 135 255) !important;
  }
`;

interface Props extends RouteComponentProps<any>,
    AuthConsumerRenderProps,
    WithTranslation,
    CompanyDataConsumerRenderProps {

}

interface State {
  foundEmployees: Array<EmployeeImportDto>,
  employeesLoaded: boolean,
  importingEmployees: boolean,
  file?: File,
  testRegistrationTypes: Array<RadioProps>,
  testFrequencies: Array<RadioProps>,
  statuses: Array<RadioProps>,
  languagesMap: Map<string, string>,
  companyShippable: Array<DropdownOption>,
  testTypes: Array<DropdownOption>,
  errorMessages: Array<string>,
  numOfImportedEmployees?: string
}

class EmployeesImport extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    const testRegTypesLabels: Array<string> = Object.values(TestRegistrationType);
    const testFrequenciesLabels: Array<string> = enabledTestFrequencies;
    const statusLabels: Array<string> = ["active", "inactive"];

    const testFrequencies = generateRadioButtonOptionsFromEnum(
        testFrequenciesLabels,
        "testFrequency",
        props.t
    );

    const testRegistrationTypes = generateRadioButtonOptionsFromEnum(
        testRegTypesLabels,
        "testRegistrationType",
        props.t
    );

    const statuses = generateRadioButtonOptions(
        statusLabels,
        "status",
        props.t,
        "employee",
        [1, 0]
    );

    const langKeys = ['de', 'en', 'fr', 'it'];
    const langValues = ["german", "english", "french", "italian"];
    const languageMap: Map<string, string> = buildMap(langKeys, langValues);

    this.state = {
      foundEmployees: [],
      employeesLoaded: true,
      importingEmployees: false,
      testRegistrationTypes: testRegistrationTypes,
      testFrequencies: testFrequencies,
      statuses: statuses,
      languagesMap: languageMap,
      companyShippable: [],
      testTypes: [],
      errorMessages: []
    };

    const companyId = this.props.currentUser ? this.props.currentUser.companyId : -1;

    getCompanyShippableMedicaments(companyId, cancelTokenSource)
    .then(response => {
      let medicaments = mapToDropdownOptionArray(response, "description", "id", "id");
      this.setState({
        companyShippable: medicaments
      });
    });
    getCompanyExternalCodeFromCompanyArticleGroups(companyId, cancelTokenSource)
    .then(response => {
      const testTypesOptions = stringArrayToDropdownOptionArray(response, props.t, "testType");
      this.setState({
        testTypes: testTypesOptions
      });
    });

  }

  setFile = (file: File) => {
    this.setState({
      file: file,
      foundEmployees: []
    });
  };

  getInitialFormValues = () => {

    const {companyData} = this.props;
    const {companyShippable, testTypes} = this.state;

    return {
      companyId: companyData.id,
      medicamentId: companyShippable.length > 0 ? companyShippable[0].value : null,
      testType: testTypes.length > 0 ? testTypes[0].value : null,
      status: 1,
      testFrequency: TestFrequency.WEEK_ONE,
      testRegistrationType: TestRegistrationType.CENTRAL,
      acceptContractualDocuments: companyData.acceptCD,
      acceptContractualDocumentsRemark: companyData.acceptCDRemark
    };
  };

  handleImportCheckClick = () => {

    const {companyData} = this.props;
    const {file} = this.state;

    this.setState({
      employeesLoaded: false
    });

    const request: Partial<UpsertEmployeeImportDto> = {
      companyId: companyData.id,
      file: file
    };

    uploadEmployeesFile(request, cancelTokenSource)
    .then(response => {
          this.setState({
            foundEmployees: response
          });
        }
    ).catch((err) => this.handleError(err.response.data))
    .finally(() => {
      this.setState({
        employeesLoaded: true
      });
    });
  };

  cancelImportCheck = (form: FormApi) => {
    form.initialize(this.getInitialFormValues());
    this.setState({
      file: undefined,
      foundEmployees: []
    });
  };

  hideMessage = (): void => {
    setTimeout(() => {
      this.setState({
        numOfImportedEmployees: undefined
      });
    }, 3000);
  };


  handleError(error: any) {
    const {t} = this.props;
    const errorCode = error.errorCode;
    const knownErrors: Array<string> = [
        errorUtils.invalidInput,
        "INVALID_DOCUMENT_INPUT"
    ];

    const violations: Array<any> = error.violations;

    if (violations && violations.length > 0) {
      violations.forEach(violation => {
        if (knownErrors.includes(violation.errorCode)) {
          this.setErrorMessage(t(`error.${violation.errorCode}`));
        }
      });
    }

    if (!this.state.errorMessages.length) {
      if (knownErrors.includes(errorCode)) {
        this.setErrorMessage(t(`error.${errorCode}`));
      } else {
        this.setErrorMessage(t('error.general'));
      }
    }
  };


  setErrorMessage = (errorMessage?: string) => {

    const {errorMessages} = this.state;

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);

      this.setState({
        errorMessages: errMsgs
      });
    } else {

      this.setState({
        errorMessages: []
      });
    }
  };

  handleImportApply = (values: Partial<EmployeeImportRequest>, form: FormApi) => {
    const {currentUser} = this.props;
    const mandateId = currentUser!.mandateId;
    const {foundEmployees} = this.state;

    this.setState({
      importingEmployees: true
    });

    let employees: Array<EmployeeImportDto> = foundEmployees.filter(employee => employee.selected);

    const request: Partial<EmployeeImportRequest> = {
      mandateId: mandateId,
      employees: employees,
      companyId: values.companyId!,
      medicamentId: values.medicamentId!,
      testType: values.testType!,
      testFrequency: values.testFrequency!,
      testRegistrationType: values.testRegistrationType!,
      acceptContractualDocuments: values.acceptContractualDocuments!,
      acceptContractualDocumentsRemark: values.acceptContractualDocumentsRemark
          ? values.acceptContractualDocumentsRemark
          : ""
    };

    importEmployees(request, cancelTokenSource)
    .then(response => {
      this.setState({
        numOfImportedEmployees: response
      });
      this.cancelImportCheck(form);
      this.hideMessage();
    })
    .catch((e) => this.handleError(e.response.data))
    .finally(() => {
      this.setState({
        importingEmployees: false
      });
    });
  };


  render() {
    return (
        <Container fluid>
          <CompanyDataHeader />

          {this.renderEmployeeImportForm()}
        </Container>
    );
  }

  renderEmployeeImportForm = (): React.ReactNode => {
    return (
        <FinalForm
            onSubmit={(values) => noop()}
            initialValues={this.getInitialFormValues()}
            subscription={{values: true, pristine: true, submitting: true}}
            render={this.renderForm}
        />
    );
  };

  renderForm = ({submitting, values, form}: FormRenderProps): React.ReactNode => {
    const {t, companyData} = this.props;
    const {
      testFrequencies,
      testRegistrationTypes,
      statuses,
      file,
      testTypes,
      foundEmployees,
      employeesLoaded,
      importingEmployees,
      numOfImportedEmployees,
      errorMessages
    } = this.state;

    const fileName = file ? file.name : "";

    const disableUpload = !(fileName.length > 0);

    const countSelectedEmployees = foundEmployees.filter(e => e.selected).length;
    const disableImport = !(countSelectedEmployees > 0);
    const visibleAcceptCD = companyData.acceptCD;
    const disableAcceptCDRemark = !values.acceptContractualDocuments;

    return (
        <div style={{height: "100%"}}>
          <div className="title-h1">{t("employee.import.header")}</div>
          {errorMessages.length > 0 &&
          <div className="error">
            <StyledErrorMessage onDismiss={() => this.setErrorMessage()}>
              {errorMessages.map(err => <div key={err}>{err}</div>)}
            </StyledErrorMessage>
          </div>
          }
          <ContainerDiv>
            <div className="import-form-field">
              <label>{t("employee.import.upload")}:</label>
              <Input
                  placeholder=""
                  value={fileName}
                  disabled={true}
              />
              <UploadButton
                  message={t("employee.import.select")}
                  setFile={(file) => this.setFile(file)}
              />
            </div>
            {/*
                    <div className="import-form-field">
                      <label>{t("employee.testType")}:</label>
                      <Field
                        name="medicamentId"
                        component={Select}
                        clearable
                        options={companyShippable}
                      />
                    </div>
*/}
            <div className="import-form-field">
              <label>{t("employee.testType")}:</label>
              <Field
                  name="testType"
                  component={Select}
                  clearable
                  options={testTypes}
              />
            </div>

            <div className="import-elements">
              <div>
                <div className="import-form-radio">
                  <label>{t("employee.testRegistrationType")}:</label>
                  <div className="radio-group">
                    <Field
                        name="testRegistrationType"
                        component={Radio}
                        radioDefinition={testRegistrationTypes[0]}
                    />
                    <Field
                        name="testRegistrationType"
                        component={Radio}
                        radioDefinition={testRegistrationTypes[1]}
                    />
                  </div>
                </div>
                <div className="import-form-radio">
                  <label>{t("employee.testFrequency")}:</label>
                  <div className="radio-group">
                    <Field
                        name="testFrequency"
                        component={Radio}
                        radioDefinition={testFrequencies[0]}
                    />
                    <Field
                        name="testFrequency"
                        component={Radio}
                        radioDefinition={testFrequencies[1]}
                    />
                  </div>
                </div>
                <div className="import-form-radio">
                  <label>{t("employee.status")}:</label>
                  <div className="radio-group">
                    <Field
                        name="status"
                        component={Radio}
                        radioDefinition={statuses[0]}
                    />
                    <Field
                        name="status"
                        component={Radio}
                        radioDefinition={statuses[1]}
                    />
                  </div>
                </div>
              </div>
              {visibleAcceptCD &&
              <div>
                <div className="accept-contractual-documents">
                  <Field
                    name="acceptContractualDocuments"
                    component={CheckBox}
                    label={`${t("employee.acceptContractDocuments")}`}
                  />
                  <Field
                    name="acceptContractualDocumentsRemark"
                    component={FinalFormInput}
                    disabled={disableAcceptCDRemark}
                  />
                </div>
              </div>
              }
            </div>
            <div className="action-buttons-row">
              <Button
                  type="button"
                  className="action-button"
                  onClick={() => this.handleImportCheckClick()}
                  primary
                  disabled={disableUpload}
              >
                {t("employee.import.check")}
              </Button>
              <Button
                  type="button"
                  className="action-button"
                  onClick={() => this.cancelImportCheck(form)}
                  secondary
              >
                {t("employee.import.cancel")}
              </Button>
              <Button
                  type="button"
                  className="action-button"
                  secondary
                  onClick={() => this.props.history.push(`/employees`)}
              >
                {t("action.back")}
              </Button>
              {
                <span>
                        {numOfImportedEmployees && !errorMessages.length &&
                        t("employee.import.success",
                            {
                              numOfEmployees: numOfImportedEmployees
                            })
                        }
                    </span>
              }
            </div>

          </ContainerDiv>
          {importingEmployees && <LoaderComponent message={t("employee.import.progress")} />}
          {!employeesLoaded
              ? <LoaderComponent message={t("employee.loading")} />
              : foundEmployees && foundEmployees.length > 0
                  ? <EmployeesTableContainer id="employeeImportTable">
                    <div className="employee-table">
                      {this.renderEmployeesTable()}
                    </div>
                    <div className="action-buttons-row">
                      <Button
                          type="button"
                          className="action-button"
                          onClick={() => this.handleImportApply(values, form)}
                          primary
                          disabled={disableImport || this.state.importingEmployees}
                      >
                        {t("employee.import.apply")}
                      </Button>
                    </div>
                  </EmployeesTableContainer>
                  : null
          }
        </div>
    );
  };

  selectEmployee = (index: number) => {
    const {foundEmployees} = this.state;
    let employee = foundEmployees[index];
    if (employee && !employee.invalid) {
      let employees = [...foundEmployees];
      employees[index].selected = !employees[index].selected;
      this.setState({
        foundEmployees: employees
      });
    }
  };

  selectHeaderColumn = () => {

    const {foundEmployees} = this.state;
    const countSelectableEmployees = foundEmployees.filter(e => !e.invalid).length;
    const countSelectedEmployees = foundEmployees.filter(e => e.selected).length;
    const indeterminate = countSelectedEmployees > 0 && countSelectedEmployees < countSelectableEmployees;
    const noSelectedEmployees = countSelectedEmployees === 0 && countSelectableEmployees > 0;
    const allEmployeesSelected = countSelectableEmployees > 0 && countSelectedEmployees === countSelectableEmployees;

    return (
        <CheckboxSc
            checked={countSelectedEmployees > 0}
            indeterminate={indeterminate}
            onChange={
              () => {
                if (noSelectedEmployees || indeterminate) {
                  let employees = [...foundEmployees];
                  const selectedEmployees = employees.map(e => {
                    if (!e.invalid) {
                      return {...e, selected: true};
                    }
                    return e;
                  });
                  this.setState({
                    foundEmployees: selectedEmployees
                  });
                }

                if (allEmployeesSelected) {
                  let employees = [...foundEmployees];
                  const selectedEmployees = employees.map(e => ({...e, selected: false}));
                  this.setState({
                    foundEmployees: selectedEmployees
                  });
                }

              }
            }
            disabled={countSelectableEmployees === 0}
        />
    );
  };

  employeeSelectCellRenderer = (data: any) => {

    const {cellData, rowData} = data;
    const {t} = this.props;
    const {foundEmployees} = this.state;

    const index = cellData - 1;
    const employee = foundEmployees[index];

    let hasErrors = employee.invalid;
    const importErrors = employee.importErrors;

    if (hasErrors && importErrors) {
      const trigger = (
          <Icon className="anamnesis-details-icon" name="clipboard outline" fitted color="grey" />
      );

      const style = {
        borderRadius: 0,
        borderColor: '#7687FF',
        boxShadow: 'unset'
      };

      const content = (
          <ResultsPane>
            <div className="results-header">{t("employee.importError")}</div>
            <div className="results-container">
              {
                importErrors.map(error => {
                  return (
                      <React.Fragment key={`error-row-${error}`}>
                        <div className="import-error">
                          {t(`importErrorType.${error}`)}
                        </div>
                      </React.Fragment>
                  );
                })
              }
            </div>
          </ResultsPane>
      );

      return (
          <div>
            <StyledPopup
                trigger={trigger}
                popperModifiers={
                  [
                    {
                      name: 'preventOverflow',
                      options: {
                        boundary: document.getElementById("employeeImportTable")
                      }
                    }
                  ]
                }
                content={content}
                flowing
                on="hover"
                size="large"
                position="right center"
                style={style}
            />
          </div>
      );
    }

    return (
        <CheckboxSc
            checked={rowData.selected}
            disabled={hasErrors}
            onChange={() => this.selectEmployee(index)}
        />
    );
  };


  birthDateCellRenderer = ({cellData}: any) => {
    if (cellData) {
      return <div>{moment(cellData).format("DD.MM.YYYY")}</div>;
    }
    return "";
  };

  languageCellRenderer = ({cellData}: any) => {

    const {t} = this.props;
    const {languagesMap} = this.state;

    if (cellData) {
      const lang = languagesMap.get(cellData);
      return t(`language.${lang}`);
    }
    return "";
  };

  divisionCellRenderer = ({cellData}: any) => {
    if (cellData && cellData.length > 0) {
      return cellData;
    }
    return "";
  };

  bagCellRenderer = ({rowData}: any) => {
    const {t} = this.props;
    const insuranceValue = rowData.insuranceName;
    if (insuranceValue) {
      return <>
        {
          rowData.unknownGuarantor &&
          <Icon name="warning circle" color="orange" title={t("error.UNKNOWN_BAG_NUMBER")} />
        }
        <span>{insuranceValue}</span>
      </>;
    }
    return "";
  };

  defaultStringValueCellRenderer = (fieldName: string) => ({rowData, cellData}: any) => {
    const {t} = this.props;
    const errors = rowData.importErrorDtoList;
    const hoverMessage = t("error.VALUE_TOO_LONG");
    let error = {};
    if (errors) {
      error = errors.find((error: { field: string; }) => error.field === fieldName);
    }

    if (cellData) {
      if (error) {
        return <>
          {
            <Popup
                trigger={<Icon name="times circle" color="red"/>}
                content={hoverMessage}
                size="large"
                position='top center'
            />
          }
          <span>{cellData}</span>
        </>
      }
      return cellData;
    }
    return "";

  };

  employeesRowGetter = ({index}: any) => {
    const {foundEmployees} = this.state;

    Object.assign(foundEmployees[index], {index: index + 1});

    return foundEmployees[index];
  };

  employeesRowRenderer = ({className, columns, index, key, style}: any) => {
    const a11yProps = {'aria-rowindex': index + 1};
    const {foundEmployees} = this.state;

    const employee = foundEmployees[index];

    let rowStyle = {...style};
    if (employee.invalid) {
      rowStyle = {...rowStyle, color: "rgb(198, 198, 203)"};
    } else if (employee.exists) {
      rowStyle = {...rowStyle, color: "rgb(118, 135, 255)"};
    }

    return (
        <div
            {...a11yProps}
            className={className}
            key={key}
            role="row"
            style={rowStyle}
            onDoubleClick={() => this.selectEmployee(index)}
        >
          {columns}
        </div>
    );
  };

  renderEmployeesTable = (): JSX.Element => {

    const {t} = this.props;
    const {foundEmployees} = this.state;
    return (
        <VirtualizedTable
            rowCount={foundEmployees.length}
            rowGetter={this.employeesRowGetter}
            rowRenderer={this.employeesRowRenderer}
            columns={[
              {
                width: 60,
                label: this.selectHeaderColumn(),
                dataKey: "index",
                cellRenderer: this.employeeSelectCellRenderer
              },
              {
                width: 250,
                label: (t("employee.name")),
                dataKey: "name"
              },
              {
                width: 250,
                label: (t("employee.firstName")),
                dataKey: "firstName"
              },
              {
                width: 150,
                label: (t("employee.birthDate")),
                dataKey: "birthDate",
                cellRenderer: this.birthDateCellRenderer
              },
              {
                width: 350,
                label: (t("employee.email")),
                dataKey: "companyEmail"
              },
              {
                width: 300,
                label: (t("employee.language")),
                dataKey: "language",
                cellRenderer: this.languageCellRenderer
              },
              {
                width: 300,
                label: (t("employee.division")),
                dataKey: "divisionName",
                cellRenderer: this.divisionCellRenderer
              },
              {
                width: 300,
                label: (t("employee.remark")),
                dataKey: "remark"
              },
              {
                width: 300,
                label: (t("employee.street")),
                dataKey: "street",
                cellRenderer: this.defaultStringValueCellRenderer("street")
              },
              {
                width: 100,
                label: (t("employee.zip")),
                dataKey: "zip",
                cellRenderer: this.defaultStringValueCellRenderer("zip")
              },
              {
                width: 300,
                label: (t("employee.city")),
                dataKey: "city"
              },
              {
                width: 300,
                label: (t("employee.passportNumber")),
                dataKey: "passportNumber",
                cellRenderer: this.defaultStringValueCellRenderer("passportNumber")
              },
              {
                width: 300,
                label: (t("employee.ahvNumber")),
                dataKey: "ahvNumber"
              },
              {
                width: 300,
                label: (t("employee.bagNumber")),
                dataKey: "insuranceName",
                cellRenderer: this.bagCellRenderer
              },
              {
                width: 300,
                label: (t("employee.telephone")),
                dataKey: "mobilePhone",
                cellRenderer: this.defaultStringValueCellRenderer("mobilePhone")
              },
              {
                width: 300,
                label: (t("employee.sex")),
                dataKey: "gender"
              }
            ]}
        />

    );
  };

}


let EmployeeImportWrapper = withRouterWorkaround(
    withAuthContext(
        withCompanyDataContext(
            withTranslation(["mipoco"])(
                EmployeesImport))));

export default EmployeeImportWrapper;