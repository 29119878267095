import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import MpGrid from "component/MpGrid";
import React, {Component} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";
import LoginView from "route/login/LoginView";
import {Button, Grid} from "semantic-ui-react";
import {getContainerForEmailAddressVerification, verifyEmailAddress} from "service/userServices";
import styled from "styled-components";
import {VerifyEmailStatus} from "ts-types/api.enums";
import {withRouterWorkaround} from "util/workaroundUtils";

const StyledMpGrid = styled(MpGrid)`

  font-size: 1.1rem;

  &.ui.checkbox .box,
  &.ui.checkbox label {
    font-size: 1.1rem;
  }

  &.ui.grid > .row > .column {
    padding-right: 0;
    padding-left: 0;

    a.contract-document-url {
      color: rgba(0, 0, 0, .87);
      text-decoration: underline;
    }

    .ui.checkbox label:before {
      border-color: #7687FF;
      border-radius: unset;
    }

    button {
      margin-right: 1.5rem;
    }
  }

  @media only screen and (max-width: 767px) {
    &.ui.grid > .row > .column {
      .button {
        margin-bottom: 1rem;
        display: block;
        width: 100%;
      }
    }
  }
`;

const InfoDiv = styled.div`
  max-width: 47rem;
  margin-bottom: 1.5rem;
`;

interface Props extends RouteComponentProps<any>, WithTranslation, AuthConsumerRenderProps {

}

export type PageStatus = "WAIT_FOR_OUTCOME" | "INVALID_CODE" | "ACCEPTED" | "DECLINED" | "ERROR";

interface State {
  pageInitialized: boolean;
  status: PageStatus;
  container?: string;
  token?: string | null;
}

class VerifyEmailView extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    const queryParams = new URLSearchParams(props.history.location.search);
    const token = queryParams.get("token");

    this.state = {
      pageInitialized: false,
      status: "WAIT_FOR_OUTCOME",
      token: token
    };

    if (!token) {
      this.state = {
        pageInitialized: true,
        status: "INVALID_CODE"
      };
    } else {
      this.fetchContainer(token);
    }
  }

  fetchContainer = async (token: string) => {
    if (!token) {
      return;
    }
    try {
      const container = await getContainerForEmailAddressVerification(token);
      this.setState({
        container: container.data
      });
    } catch (e) {
      this.setState({
        pageInitialized: true,
        status: "ERROR"
      });
    } finally {
      this.setState({
        pageInitialized: true
      });
    }
  };

  verifyEmployeeEmail = async (verifyEmailStatus: VerifyEmailStatus) => {
    const {token} = this.state;
    if (token) {
      verifyEmailAddress(token, verifyEmailStatus)
      .then(response => {
        this.setState({
          status: response.data
        });
      })
      .catch(e => {
        this.setState({
          status: "ERROR"
        });
      });
    } else {
      this.setState({
        status: "INVALID_CODE"
      });
    }
  };

  goToLogin = async () => {
    const {container} = this.state;
    if (container) {
      this.props.history.push(`/login/${container}`);
    } else {
      this.props.history.push(`/login`);
    }
  };

  render(): React.ReactNode {

    const {t} = this.props;
    const {status} = this.state;

    const title = status === "INVALID_CODE" ? "login.passwordlessLogin.title.invalidCode" : "verifyEmail.title";

    return <LoginView titleKey={title}>
      {
          status === "WAIT_FOR_OUTCOME" &&
        <StyledMpGrid stackable>
          {/*{errorMessages.length > 0 &&*/}
          {/*  <Grid.Row>*/}
          {/*    <Grid.Column width={8}>*/}
          {/*      <StyledErrorMessage onDismiss={() => this.setErrorMessage()}>*/}
          {/*        {errorMessages.map(err => <div key={err}>{err}</div>)}*/}
          {/*      </StyledErrorMessage>*/}
          {/*    </Grid.Column>*/}
          {/*  </Grid.Row>*/}
          {/*}*/}
          <Grid.Row style={{marginBottom: "2rem"}}>
            <Grid.Column width={8}>
              {t(`verifyEmail.description`)}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={10}>
              <Button
                type="button"
                className="action-button"
                primary
                onClick={() => this.verifyEmployeeEmail(VerifyEmailStatus.ACCEPTED)}
              >
                {t("verifyEmail.btn.accept")}
              </Button>
              <Button
                type="button"
                className="action-button"
                secondary
                onClick={() => this.verifyEmployeeEmail(VerifyEmailStatus.DECLINED)}
              >
                {t("verifyEmail.btn.reject")}
              </Button>
              {/*<Button*/}
              {/*  type="button"*/}
              {/*  className="action-button"*/}
              {/*  secondary*/}
              {/*  onClick={() => this.props.history.push(`/login`)}*/}
              {/*>*/}
              {/*  {t("action.cancel")}*/}
              {/*</Button>*/}
            </Grid.Column>
          </Grid.Row>
        </StyledMpGrid>
      }
      {
          (status === "ACCEPTED" || status === "DECLINED") &&
        <>
          <InfoDiv>
            {t(`verifyEmail.${status}.description`)}
          </InfoDiv>

          <div className="actions-row">
            <Button
              type="button"
              className="action-button"
              color={"grey"}
              onClick={(evt) => this.goToLogin()}
            >
              {t("verifyEmail.btn.gotoLogin")}
            </Button>
          </div>
        </>
      }

      {
          status === "INVALID_CODE" &&
        <>
          <InfoDiv>
            {t("login.passwordlessLogin.error.invalidCode")}
          </InfoDiv>

          <div className="actions-row">
            <Button
              type="button"
              className="action-button"
              color={"grey"}
              onClick={(evt) => this.goToLogin()}
            >
              {t("verifyEmail.btn.gotoLogin")}
            </Button>
          </div>
        </>
      }
      {
          status === "ERROR" &&
        <>
          <InfoDiv>
            {t("verifyEmail.error.general")}
          </InfoDiv>

          <div className="actions-row">
            <Button
              type="button"
              className="action-button"
              color={"grey"}
              onClick={(evt) => this.goToLogin()}
            >
              {t("verifyEmail.btn.gotoLogin")}
            </Button>
          </div>
        </>
      }
    </LoginView>;
  }
}

export default withRouterWorkaround(
    withAuthContext(
        withTranslation(["login"])(
            VerifyEmailView)));
