import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import React, {ChangeEvent, Component, FunctionComponent} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {withRouter} from "react-router";
import {PersonOnboardViews} from "route/person-onboard/PersonOnboardView";
import {Button, Input, InputOnChangeData} from "semantic-ui-react";
import {sendOnboardingEmailVerification, verifyOnboardingToken} from "service/onboardingServices";
import styled from "styled-components";
import {EmployeeAccessTokenType} from "ts-types/api.enums";
import {errorUtils} from "util/errorUtils";
import {isKeyCheck} from "util/keyUtils";
import {insertLink} from "util/localizationUtils";
import {emailValidator} from "util/validatorUtils";

const ContainerDiv = styled.div`

  .email-form {
    display: grid;
    grid-template-columns: 150px minmax(10rem, 25rem);

    grid-column-gap: 1rem;
    grid-row-gap: 0.25rem;

    margin-top: 3.5rem;
    margin-bottom: 2rem;

    label {
      align-self: center;
    }

    .error {
      grid-column-start: 2;
      justify-self: start;
      color: darkred;
      font-size: 1rem;
      margin: 0.3rem 0 0.5rem 0;
      
      a.text-link {
        color: darkred;
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .actions-row {
      grid-column-start: 2;
      justify-self: start;
      margin-top: 0.75rem;

      button {
        margin-right: 0.785rem;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .email-form {
      display: flex;
      flex-direction: column;

      label {
        align-self: flex-start;
      }

      .actions-row {

        display: flex;
        flex-direction: column;
        gap: 1rem;

        button {
          width: 100%;
          margin-right: 0.785rem;
        }
      }
    }
  }

`;

const InfoDiv = styled.div`
  max-width: 40rem;
  margin-bottom: 1.5rem;
`;

const TextLinkUi: FunctionComponent = (props) => {
  return <a className="text-link"
            href="#/"
            onClick={() => {(props as any).history.push("/login/individual");}}
  >
    {props.children}
  </a>;
};

const TextLink = withRouter(TextLinkUi);

interface Props extends WithTranslation, AuthConsumerRenderProps {
  setActiveView: (activeView: PersonOnboardViews) => void,
  setCompanyEmail: (email: string) => void
  setToken?: (token: string) => void
}

interface State {
  email: string;
  code: string;
  showCodeVerification: boolean;
  error?: string;
}

class ValidationView extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      email: "",
      code: "",
      showCodeVerification: false
    };
  }

  setEmail = (evt: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    this.setState({
      email: data.value
    });
  };

  setCode = (evt: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    this.setState({
      code: data.value
    });
  };

  handleError(e: any) {

    const {t} = this.props;

    const errorCode = e.errorCode;
    const knownErrors: Array<string> = [
      errorUtils.invalidInput,
      errorUtils.invalidEmail,
      errorUtils.invalidVerificationCode,
      errorUtils.emailAlreadyRegistered
    ];

    const violations: Array<any> = e.violations;

    if (violations && violations.length > 0) {
      violations.forEach(violation => {
        if (knownErrors.includes(violation.errorCode)) {
          this.setErrorMessage(t(`companyOnboard.error.${violation.errorCode}`));
        }
      });
    } else {
      if (errorCode) {
        this.setErrorMessage(t(`companyOnboard.error.${errorCode}`));
      } else {
        this.setErrorMessage(t("error.general"));
      }
    }
  };

  setErrorMessage = (errorMessage?: string) => {

    if (errorMessage) {
      this.setState({
        error: errorMessage
      });
    } else {
      this.setState({
        error: ""
      });
    }
  };

  requestEmailWithTokenAndShowCodeVerification = async (email: string) => {

    const {t, language} = this.props;

    if (!email) {
      this.setState({
        error: t("login.emailSignin.validationError.required")
      });
      return;
    } else if (emailValidator(email)) {
      this.setState({
        error: t("login.emailSignin.validationError.email")
      });
      return;
    }

    await sendOnboardingEmailVerification({
      email,
      type: EmployeeAccessTokenType.ONBOARDING_PERSON,
      language: language
    })
    .then(response => this.setShowCodeVerification(true))
    .catch((e: any) => this.handleError(e.response.data));
  };

  verifyCode = async (email: string, token: string) => {
    await verifyOnboardingToken(email, token, this.props.language)
    .then(response => {

      const {setCompanyEmail, setToken, setActiveView} = this.props;

      setCompanyEmail(response.email);

      if (setToken) {
        setToken(token);
      }

      setActiveView(PersonOnboardViews.PERSON_DETAILS);
    })
    .catch((e: any) => this.handleError(e.response.data));
  };

  setShowCodeVerification = (show: boolean) => {
    this.setState({
      showCodeVerification: show,
      error: undefined
    });
  };

  onKeyDown = (event: any) => {
    const {showCodeVerification, email, code} = this.state;
    event.persist();
    const isKey: any = isKeyCheck(event);

    if (isKey.enter) {
      if (showCodeVerification) {
        this.verifyCode(email, code);
      } else {
        this.requestEmailWithTokenAndShowCodeVerification(email);
      }
    }
  };

  render(): React.ReactNode {

    const {t} = this.props;
    const {code, email, showCodeVerification, error} = this.state;

    return <>
      <ContainerDiv>
        <InfoDiv>
          {
            showCodeVerification
                ? <>{t("companyOnboard.codeVerification.text",
                {
                  companyEmail: email
                })}<br />
                  {t("companyOnboard.codeVerification.subText")}
                </>
                : t("companyOnboard.codeVerification.email.text")
          }
        </InfoDiv>

        <div className="email-form">

          <label>
            {showCodeVerification
                ? t("companyOnboard.codeVerification.label")
                : t("companyOnboard.form.email")
            }
          </label>
          <Input
              placeholder=""
              value={showCodeVerification ? code : email}
              onChange={
                (evt: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => showCodeVerification
                    ? this.setCode(evt, data)
                    : this.setEmail(evt, data)
              }
              onKeyDown={(e: any) => this.onKeyDown(e)}
          />

          {
            error &&
            <div className="error">
              {insertLink(error, TextLink)}
            </div>
          }

          <div className="actions-row">
            <Button
                type="button"
                className="action-button"
                primary
                onClick={() => {
                  if (showCodeVerification) {
                    this.verifyCode(email, code);
                  } else {
                    this.requestEmailWithTokenAndShowCodeVerification(email);
                  }
                }}
            >
              {showCodeVerification
                  ? t("companyOnboard.button.next")
                  : t("companyOnboard.form.sendRegistrationCode")
              }
            </Button>

            {showCodeVerification &&
            <Button
              type="button"
              className="action-button"
              color={"grey"}
              onClick={(evt) => this.setShowCodeVerification(false)}
            >
              {t("action.back")}
            </Button>
            }
          </div>
        </div>

      </ContainerDiv>
    </>;
  }
}

export default withAuthContext(withTranslation(["login"])(ValidationView));
