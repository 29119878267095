import {BigActionButton} from "component/BigActionButton";
import React, {Component} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import LoginTypeView from "route/login/LoginTypeView";

interface Props extends WithTranslation {
  onCompanySignin?: () => void;
  onPersonSignin?: () => void;
  onCompanyOnboard?: () => void;
  onPersonSelfOnboard?: () => void;
  onPersonSelfOnboardSignin?: () => void;
}

class LoginTypeSelection extends Component<Props> {

  render(): React.ReactNode {

    const {
      t, onCompanySignin, onPersonSignin, onCompanyOnboard,
      onPersonSelfOnboard, onPersonSelfOnboardSignin
    } = this.props;

    return <LoginTypeView titleKey="login.typeSelection.title">
      <div className="row">
        <div className="column">

          <div className="tile">
            <div className="column-title">
              {t("login.typeSelection.repetitiveTesting.title")}
            </div>
            <div className="column-text">
              {t("login.typeSelection.imWorkingAlreadyWith.text")}
            </div>
            <BigActionButton
                icon="user"
                textDomain="login"
                textKey="login.typeSelection.individual.button"
                onClick={onPersonSignin}
            />
            <BigActionButton
                icon="building"
                textDomain="login"
                textKey="login.typeSelection.company.button"
                onClick={onCompanySignin}
            />

          </div>

          <div className="tile">
            <div className="column-text">
              {t("login.typeSelection.firstTimeHere.text")}
            </div>
            <BigActionButton
                icon="building"
                textDomain="login"
                textKey="login.typeSelection.companyOnboard.button"
                onClick={onCompanyOnboard}
            />
          </div>

        </div>

        <div className="column">

          <div className="tile">
            <div className="column-title">
              {t("login.typeSelection.healthService.title")}
            </div>
            <div className="column-text">
              {t("login.typeSelection.imWorkingAlreadyWith.text")}
            </div>
            <BigActionButton
                icon="user"
                textDomain="login"
                textKey="login.typeSelection.personSelfOnboardedLogin.button"
                onClick={onPersonSelfOnboardSignin}
            />
          </div>

          <div className="tile">
            <div className="column-text">
              {t("login.typeSelection.firstTimeHere.text")}
            </div>
            <BigActionButton
                icon="share"
                textDomain="login"
                textKey="login.typeSelection.personSelfOnboarding.button"
                onClick={onPersonSelfOnboard}
            />
          </div>
        </div>

      </div>

    </LoginTypeView>;
  }
}

export default withTranslation(["login"])(LoginTypeSelection);
