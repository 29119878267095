import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import axios from "axios";
import Input from "component/final-form/Input";
import RadioGroup from "component/final-form/RadioGroup";
import LoaderComponent from "component/LoaderComponent";
import MpGrid from "component/MpGrid";
import StyledErrorMessage from "component/StyledErrorMessage";
import {FormApi} from "final-form";
import _ from "lodash";
import React, {Fragment, useEffect, useState} from "react";
import {Field, Form as FinalForm, FormRenderProps} from "react-final-form";
import {WithTranslation, withTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";
import {DataEntry, DataEntryValue, Label} from "route/person-selfonboarded/styled";
import {Button, Grid, Icon, List, ListItem, RadioProps} from "semantic-ui-react";
import {
  addInterventionBookingForQuestionnaire,
  getInterventionIdForQuestionnaireToken,
  getQuestionSetForInterventionIdToken
} from "service/userServices";
import styled from "styled-components";
import {
  InterventionBookingDto,
  PatientQuestionnaireRequestDto,
  QuestionAnswerDto,
  QuestionDto,
  QuestionSetDto
} from "ts-types/api.types";
import {qaQuestionText, questionText} from "util/questionSetUtils";
import {withRouterWorkaround} from "util/workaroundUtils";

const CustomContainer = styled.div`

  .section-title {
    color: #7687FF;
    font-weight: bold;
    font-size: 1.5rem;
    margin: 0;
    padding-right: 0.5rem;
    display: inline-block;
  }

  .section-info {
    margin: 1rem 0 0;
  }

  .form-grid {
    label {
      display: inline-block;
      margin-bottom: 0.5rem;
      color: #454545;
    }
  }

  .ui.grid.form-grid {
    margin-left: -1rem;
    max-width: 840px;

    & > .row {
      margin-bottom: 0.578rem;
    }

    .input-field {
      max-width: 27rem;
    }

    .field > .ui.input.hidden-field {
      display: none;
    }
  }`;

const ResultsPane = styled.div`
  flex: 0 0 auto;
  margin-right: 15px;
  min-width: 15rem;
  max-width: 36rem;

  .results-header {
    font-weight: bold;
    font-size: 1.1rem;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 0.5rem;
  }

  .results-container {
    display: grid;
    grid-template-columns: minmax(min-content, 1fr) minmax(3.5rem, min-content);
    row-gap: 0.35rem;
    column-gap: 1.2rem;

    .result-question {
      color: #575757;
    }

    .result-answer.no-result {
      color: #a5a5a5;
    }
  }

`;

const ErrorInfoDiv = styled.div`
  max-width: 47rem;
  margin-bottom: 1.5rem;
  display: inline-grid;
`;

interface Props extends RouteComponentProps<any>, WithTranslation, AuthConsumerRenderProps {

}

export type PatientQuestionnairePageStatus = "INVALID_CODE";

const convertValueToList = (value: string) => value ? [value] : [];
const extractValueFromList = (value: Array<string>) => value && value.length === 1 ? value[0] : "";

const cancelTokenSource = axios.CancelToken.source();

let initialValues: Partial<InterventionBookingDto> = {
  questionAnswerPairs: []
};

const generateRadioButtonOptions = (
    ids: Array<string>,
    labels: Array<string>,
    groupName: string,
    getMessage: Function,
    values?: Array<string | number>): Array<RadioProps> => {

  return labels.map((label, index) => ({
        id: ids[index],
        label: getMessage(`${label}`),
        name: groupName,
        value: values ? values[index] : index
      }
  ));
};

const PatientQuestionnaireView = (props: Props) => {
  const {t} = props;

  const queryParams = new URLSearchParams(props.history.location.search);
  const queryToken = queryParams.get("token");
  const pathName = props.history.location.pathname;
  const interventionIdToken = pathName.split("/")[2];

  const [status, setStatus] = useState<PatientQuestionnairePageStatus>();
  const [token, setToken] = useState<string | null>(queryToken);
  const [questionSet, setQuestionSet] = useState<QuestionSetDto>();
  const [currentQuestion, setCurrentQuestion] = useState<number>(0);
  const [interventionId, setInterventionId] = useState<number>();
  const [successfullyAnsweredQuestions, setSuccessfullyAnsweredQuestions] = useState<string>();
  const [requiredDataLoaded, setRequiredDataLoaded] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  if (!queryToken) {
    setToken(null);
    setStatus("INVALID_CODE");
  }

  const goToLogin = () => {

    props.history.push(`/login`);
  };

  useEffect(() => {

    const fetchData = async () => {

      try {
        const interventionId = await getInterventionIdForQuestionnaireToken(
            interventionIdToken, cancelTokenSource);
        setInterventionId(interventionId);
      } catch (e) {
        handleError(e.response.data);
      }

      const questionSet = await getQuestionSetForInterventionIdToken(
          interventionIdToken, cancelTokenSource);

      try {
        initialValues.questionAnswerPairs = questionSet.questions.map(question => ({
          questionId: question.id,
          questionText: question.text,
          questionTextEn: question.textEn,
          questionTextFr: question.textFr,
          questionTextIt: question.textIt,
          selectedAnswers: [],
          remarks: ""
        }));

        setQuestionSet(questionSet);
        setRequiredDataLoaded(true);
      } catch (e) {
        handleError(e.response.data);
      }

      setRequiredDataLoaded(true);
    };

    fetchData();

  }, []);


  const handleError = (error: any) => {

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        "INVALID_TOKEN",
        "INVALID_QUESTIONNAIRE"
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      } else {
        setErrorMessage(t('error.general'));
      }
    }
  };

  const handleSubmit = async (values: Partial<InterventionBookingDto>) => {

    let request: Partial<PatientQuestionnaireRequestDto> = {};
    if (questionSet && token) {

      request = {
        interventionId: interventionId,
        questionSetId: questionSet.id,
        token: token,
        questionAnswerPairs: values.questionAnswerPairs
      };

      try {
        await addInterventionBookingForQuestionnaire(request, cancelTokenSource);
        setSuccessfullyAnsweredQuestions(t("patientQuestionnaire.successfullyAnsweredMessage"));
        setTimeout(() => goToLogin(), 10000);
      } catch (e) {
        handleError(e.response.data);
      }
    }
  };

  const previousQuestion = () => {

    setCurrentQuestion(Math.max(currentQuestion - 1, 0));
  };

  const nextQuestion = () => {

    setCurrentQuestion(Math.min(currentQuestion + 1, questionSet!.questions.length - 1));
  };

  const setQuestionValue = (fieldPath: string, value: boolean, form: FormApi) => {

    form.change(fieldPath, [value]);
    nextQuestion();
  };

  const renderForm = ({submitting, handleSubmit, form, values}: FormRenderProps): JSX.Element => {

    const disabled = !!(submitting || successfullyAnsweredQuestions);
    const questionAnswerPairs = form.getState().values.questionAnswerPairs!;

    return (
        <form onSubmit={handleSubmit}>
          <MpGrid stackable={true}>
            <Grid.Row>
              <Grid.Column width={16}>
                <h2 className="section-title">{t('patientQuestionnaire.title',
                    {questionnaireName: questionSet ? questionSet.name : ""})}
                </h2>
              </Grid.Column>
            </Grid.Row>

            {
                errorMessage &&
              <Grid.Row>
                <Grid.Column width={16}>
                  <StyledErrorMessage onDismiss={() => setErrorMessage(undefined)}>
                    {errorMessage}
                  </StyledErrorMessage>
                </Grid.Column>
              </Grid.Row>
            }
            <Grid.Row>
              <Grid.Column width={12}>
                <DataEntry>
                  {renderQuestions(form, values)}
                </DataEntry>
              </Grid.Column>
            </Grid.Row>

            {questionSet &&
              <>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <label>{t("patientQuestionnaire.answers")}:</label>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={12}>
                    <ResultsPane>
                      <div className="results-container">
                        {
                          questionAnswerPairs.map((questionAnswerPair: any) => {

                            let answerText = questionAnswerPair.selectedAnswers.join(", ");
                            let resultClass = "";
                            if (!answerText || answerText === "") {
                              return null;
                            }

                            if (answerText === 'true') {
                              answerText = t("anamnesis.answer.yes");
                            } else if (answerText === 'false') {
                              answerText = t("anamnesis.answer.no");
                            }

                            return (
                                <Fragment key={`answer-row-${questionAnswerPair.questionId}`}>
                                  <div className="result-question">
                                    {qaQuestionText(questionAnswerPair, props.language)}
                                  </div>
                                  <div className={`result-answer ${resultClass}`}>
                                    {answerText}
                                  </div>
                                </Fragment>
                            );
                          }).filter(Boolean)
                        }
                      </div>
                    </ResultsPane>
                  </Grid.Column>
                </Grid.Row>
              </>
            }

            <Grid.Row textAlign="right">
              <Grid.Column width={16}>
                <Button
                    type="submit"
                    className="action-button"
                    primary
                    style={{display: "inline-block", marginRight: "1.5rem"}}
                    disabled={disabled}
                >
                  {t("button.save")}
                </Button>
                <Button
                    type="button"
                    className="action-button"
                    onClick={() => goToLogin()}
                    secondary
                    style={{display: "inline-block"}}
                    disabled={disabled}
                >
                  {t("action.cancel")}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </MpGrid>
        </form>
    );
  };

  const renderQuestions = (form: FormApi<Partial<InterventionBookingDto>>, values: Partial<InterventionBookingDto>): JSX.Element => {

    if (!questionSet) {
      return <></>;
    }

    const questionMap = _.keyBy(questionSet.questions, q => q.id);

    const currentQuestionIx = currentQuestion < questionSet.questions.length ? currentQuestion : 0;

    const formValues = form.getState().values;
    const qaPairs = formValues.questionAnswerPairs!;

    return (
        <>
          {
              qaPairs.length > 1 &&
            <>
              <div className="label" />

              <div className="question-num-label">
                {t("patientQuestionnaire.questionNum")}
                {t("patientQuestionnaire.questionNum")}
                <span className="no-break">({`${currentQuestionIx + 1}/${questionSet.questions.length}`})</span>
              </div>
            </>
          }

          <div className="question-container">
            {
                qaPairs.length > 1 &&
              <Button
                className="button-back"
                type="button"
                icon size="mini"
                disabled={currentQuestionIx <= 0}
                onClick={() => previousQuestion()}
              >
                <Icon name="chevron left" />
              </Button>
            }

            <div className="question">
              {
                qaPairs.map((qaPair: any, index: number) => {
                  const question: QuestionDto = questionMap[qaPair.questionId];

                  if (!question) {
                    return <div key={`question-wrapper-unknown-q-${qaPair.questionId}`} />;
                  }

                  const wrapperClass = index !== currentQuestionIx ? "inactive" : "";
                  return <div key={`question-wrapper-${question!.id}`} className={`question-wrapper ${wrapperClass}`}>
                    {
                      renderSingleQuestion(
                          `questionAnswerPairs[${index}]`, index, question, qaPairs, form)
                    }
                  </div>;
                })
              }
            </div>

            {
                qaPairs.length > 1 &&
              <Button className="button-next"
                      type="button"
                      icon
                      size="mini"
                      disabled={currentQuestionIx >= questionSet.questions.length - 1}
                      onClick={() => nextQuestion()}
              >
                <Icon name="chevron right" />
              </Button>
            }
          </div>
        </>
    );
  };

  const renderSingleQuestion = (
      questionAnswerFieldPath: string,
      index: number,
      question: QuestionDto,
      qaPairs: QuestionAnswerDto[],
      form: FormApi) => {

    return (
        <Fragment key={`question-fragment-${index}`}>
          <Label className="question-text" key={`question_label_${question.questionKey}`}>
            {questionText(question, props.language)}
          </Label>

          <DataEntryValue className="answer-value" key={`question_value_${question.questionKey}`}>
            {renderAnswerByType(`${questionAnswerFieldPath}.selectedAnswers`, index, question, form)}
          </DataEntryValue>
        </Fragment>
    );
  };

  const renderAnswerByType = (fieldPath: string, index: number, question: QuestionDto, form: FormApi) => {

    const {questionType} = question;
    switch (questionType) {
      case 'MULTI_ANSWER':
        const answers = question.possibleAnswers.map((answer, index) => (
                <ListItem key={`${fieldPath}_${index}`}>
                  <Field
                      name={fieldPath}
                      component="input"
                      type="checkbox"
                      value={answer}
                  />
                  {' ' + t(answer)}
                </ListItem>
            )
        );
        return (
            <List>
              {answers}
            </List>
        );
      case 'SINGLE_ANSWER':
        return (
            <Field
                name={fieldPath}
                component={RadioGroup}
                parse={convertValueToList}
                format={extractValueFromList}
                radiowidth="100%"
                radioDefinitions={
                  generateRadioButtonOptions(
                      question.possibleAnswers,
                      question.possibleAnswers,
                      fieldPath,
                      t,
                      question.possibleAnswers
                  )
                }
            />
        );
      case 'FREE_TEXT':
        return (
            <Field
                name={fieldPath}
                component={Input}
                fluid
                placeholder={"..."}
                parse={convertValueToList}
                format={extractValueFromList}
            />
        );
      case 'YES_NO':
      default:
        const qa = _.get(form.getState().values, fieldPath);
        return (
            <>
              <Button
                  className="yesno-button"
                  type="button"
                  size="mini"
                  primary={answerEquals(qa, true)}
                  onClick={() => setQuestionValue(fieldPath, true, form)}
              >
                {t("anamnesis.answer.yes")}
              </Button>

              <Button
                  className="yesno-button"
                  type="button"
                  size="mini"
                  primary={answerEquals(qa, false)}
                  onClick={() => setQuestionValue(fieldPath, false, form)}
              >
                {t("anamnesis.answer.no")}
              </Button>
            </>
        );
    }
  };

  const answerEquals = (answers: string[], value: any): boolean => {

    return answers && answers.length === 1 && (answers[0] === value || answers[0] === "" + value);
  };

  if (!requiredDataLoaded) {
    return (
        <LoaderComponent message={t("patientQuestionnaire.loading")} />
    );
  }

  if (successfullyAnsweredQuestions) {
    return (
        <div style={{color: "darkgreen", fontSize: "medium"}}>
          {successfullyAnsweredQuestions}
        </div>
    );
  }

  if (status === "INVALID_CODE") {
    return (
        <>
          <ErrorInfoDiv>
            {t("login.passwordlessLogin.error.invalidCode")}
          </ErrorInfoDiv>
          <div>
            <Button
                type="button"
                className="action-button"
                color={"grey"}
                onClick={(evt) => goToLogin()}
            >
              {t("emailUnsubscribe.btn.gotoLogin")}
            </Button>
          </div>
        </>
    );
  }

  return (
      <CustomContainer>
        <FinalForm
            onSubmit={(values) => handleSubmit(values)}
            initialValues={initialValues}
            subscription={{submitting: true, pristine: true, values: true}}
            render={renderForm}
        />
      </CustomContainer>
  );

};

export default withRouterWorkaround(
    withAuthContext(
        withTranslation(["login"])(
            PatientQuestionnaireView)));