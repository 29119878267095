import { InterventionExternalCode } from 'ts-types/api.enums';

export const testExternalCodes = (): string[] => {
  return Object.values(InterventionExternalCode)
      .filter(code => InterventionExternalCode[code].endsWith("_TEST"));
}

export const certificateExternalCodes = (): string[] => {
  return Object.values(InterventionExternalCode)
  .filter(code => InterventionExternalCode[code].startsWith("COVID_CERTIFICATE_"));
}

export const testAndCertificateExternalCodes = (): string[] => {
  return Object.values(InterventionExternalCode)
      .filter(code =>
          InterventionExternalCode[code].endsWith("_TEST")
          || InterventionExternalCode[code].startsWith("COVID_CERTIFICATE_"));
}
