import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import {BigActionButton} from "component/BigActionButton";
import FootstepsIcon from "component/FootstepsIcon";
import React, {Component} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";
import {CompanyOnboardViews} from "route/company-onboard/CompanyOnboardView";
import styled from "styled-components";
import {withRouterWorkaround} from "util/workaroundUtils";

const ViewContainerDiv = styled.div`
  margin: 0.5rem 0.525rem;
  font-size: 1.275rem;

  .subtitle {
    margin-bottom: 2rem;
  }

  .instruction-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 3rem;

    .instruction-column {
      display: flex;
      flex-flow: row nowrap;
      align-items: top;
      max-width: 300px;
      
      .step-container {
        
        .step-icon {
          display: inline-block;
        }

        .step-title {
          display: inline-block;
          max-width: 200px;
          padding: 2rem 1rem 2rem 1rem;
        }

        .step-description {
          border-top: 1px solid #0f1a52;
          padding-top: 2rem;
        }
      }
    }

    .instruction-column + .instruction-column {
      margin-left: 4rem;
    }

    > .login-button > .content {
      align-items: center;
      justify-content: center;
    }

    @media (max-width: 1083px) {
      > .instruction-column {
        max-width: unset;
        min-width: 100%;
        margin-left: 0 !important;
      }

      .instruction-column + .instruction-column {
        margin-top: 2rem;
      }
    }
  }
`;

interface Props extends RouteComponentProps<any>,
    AuthConsumerRenderProps,
    WithTranslation {

  setActiveView: (activeView: CompanyOnboardViews) => void
}

interface State {

}

class InstructionsView extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render(): React.ReactNode {

    const {t} = this.props;

    return (
        <ViewContainerDiv>
          <div className="subtitle">{t(`companyOnboard.instructions.text`)}</div>
          <div className="instruction-row">
            <div className="instruction-column">
              <div className="step-container">
                <FootstepsIcon />
                <div className="step-title">
                  {t("companyOnboard.instructions.step")} 1<br />
                  <strong>{t("companyOnboard.instructions.validationEmail.title")}</strong>
                </div>
                <div className="step-description" >
                  {t("companyOnboard.instructions.validationEmail.text")}
                </div>
              </div>
            </div>
            <div className="instruction-column">
              <div className="step-container">
                <FootstepsIcon />
                <div className="step-title">
                  {t("companyOnboard.instructions.step")} 2<br />
                  <strong>{t("companyOnboard.instructions.institutionDetails.title")}</strong>
                </div>
                <div className="step-description">
                  {t("companyOnboard.instructions.institutionDetails.text")}
                </div>
              </div>
            </div>
            <div className="instruction-column">
              <div className="step-container">
                <FootstepsIcon />
                <div className="step-title">
                  {t("companyOnboard.instructions.step")} 3<br />
                  <strong>{t("companyOnboard.instructions.typeOfCooperation.title")}</strong>
                </div>
                <div className="step-description">
                  {t("companyOnboard.instructions.typeOfCooperation.text")}
                </div>
              </div>
            </div>
          </div>
          <div className="instruction-row">
            <BigActionButton
                icon="building"
                textDomain="login"
                textKey="companyOnboard.instructions.button"
                onClick={() => this.props.setActiveView(CompanyOnboardViews.VALIDATION)}
            />
          </div>
        </ViewContainerDiv>
    );
  }
}

let InstructionsViewWrapper = withRouterWorkaround(
    withAuthContext(
        withTranslation(["login"])(
            InstructionsView)));

export default InstructionsViewWrapper;