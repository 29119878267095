import i18n from 'i18next';
import XhrBackend from 'i18next-xhr-backend';
import {initReactI18next} from 'react-i18next';
import {getLanguage, setLanguage} from "service/browserStorageServices";
import {loadLocaleResources} from "service/userServices";
import {supportedLanguages} from "util/localizationUtils";

// custom prefix added to the URL of private locale resources to mark them as private
const privatePrefix = "##priv##";

// Determine the default language
let defaultLanguage = undefined;
// prio 1: get the language from the URL params
const getUrlLang = () : string | undefined => {
  const urlQuery = window.location.search;
  if (urlQuery) {
    const urlParams = new URLSearchParams(urlQuery);
    const lang = urlParams.get("lang");

    if (lang && supportedLanguages.includes(lang)) {
      return lang;
    }
  }
}
const urlLang = getUrlLang();
if (urlLang) {
  defaultLanguage = urlLang;
}

// prio 2.1: get the language from local storage, or
// prio 2.2: from the browser language
if (!defaultLanguage) {
  defaultLanguage = getLanguage();
}

// persist the language in the local storage
setLanguage(defaultLanguage);

i18n
.use(XhrBackend)
.use(initReactI18next)
.init({
  lng: defaultLanguage,

  fallbackLng:  defaultLanguage,

  backend: {
    // for all available options read the backend's repository readme file
    loadPath: function (lngs: string[], namespaces: string[]) {

      if (lngs.length > 1 || namespaces.length > 1) {
        throw new Error("Multi-loading i18 resources not supported.");
      }

      const lang = lngs[0];
      const ns = namespaces[0];

      if ("login" === ns) {
        return `${ns}-${lang}.json`;
      }

      return `${privatePrefix}${ns}-${lang}.json`;
    },

    parse: function (data: any, url: any) {
      // axios return a parsed object; no need to parse it manually
      return data;
    },

    ajax: function (urlStr: string, options: any, callback: any, data: any) {
      const privateResource = urlStr.startsWith(privatePrefix);
      const url = privateResource ? urlStr.substr(privatePrefix.length) : urlStr;
      loadLocaleResources(url, privateResource)
      .then((response: any) => {
        callback && callback(response.data, response);
      })
      .catch((error: any) => {
        callback && callback(null, error);
      });
    }

  },

  //have a common namespace used around the full app
  ns: ['default'],
  defaultNS: 'default',

  debug: true,

  interpolation: {
    escapeValue: false // not needed for react!!
  },

  react: {
    useSuspense: true
  }
});

export default i18n;