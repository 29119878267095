import React from "react";
import {useTranslation} from "react-i18next";
import {Icon, SemanticCOLORS} from "semantic-ui-react";

const icons: {[key: string] : {color: SemanticCOLORS}} = {
  "NEGATIVE" : {
    color: "green"
  },
  "POSITIVE" : {
    color: "red"
  },
  "UNCERTAIN" : {
    color: "grey"
  },
  "POOL_POSITIVE" : {
    color: "yellow"
  },
  "VACCINATED" : {
    color: "blue"
  },
  "RECOVERED" : {
    color: "blue"
  },
  "NEGATIVE_TEST_CERTIFICATE" : {
    color: "blue"
  },
  "BORDERLINE" : {
    color: "blue"
  }
}

interface Props {
  result?: string;
  title?: string;
}

const TestResultIcon: React.FunctionComponent<Props> = (props) => {

  const {t} = useTranslation('mipoco');
  const {result, title} = props;

  if (result) {
    return  <Icon title={title ? title: t(`testResult.${result}`)}
                  name="circle"
                  color={icons[result].color}
                  size="large"
    />;
  }

  return <></>;
};

export default TestResultIcon;