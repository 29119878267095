import {CancelTokenSource} from "axios";
import axios from "service/http";
import {
  QuestionSetDto,
  ResourceDisplayDto,
  UpsertInterventionDto,
  VaccinationAppointmentRequest,
  VaccinationAvailabilitiesSearchDto,
  VaccinationBookingDto,
  VaccinationBookingsDto
} from "ts-types/api.types";

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const apiUrl = `${baseUrl}`;

export const getVaccinationResources = (
    interventionExternalCode: string | undefined,
    cancelSourceToken: CancelTokenSource): Promise<ResourceDisplayDto[]> => {

  let params: {[key: string] : string} = {};
  if (interventionExternalCode) {
    params = {
      interventionExternalCode
    };
  }

  return axios
  .get(`${apiUrl}/vaccination/resources`, {params, cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};

export const getVaccinationQuestionSet = (
    cancelSourceToken: CancelTokenSource): Promise<QuestionSetDto> => {

  return axios
  .get(`${apiUrl}/vaccination/question-set`, {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};

export const getIntervention = (
    id: number,
    cancelSourceToken: CancelTokenSource): Promise<UpsertInterventionDto> => {

  return axios
  .get(`${apiUrl}/vaccination/intervention/${id}`, {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};

export const getVaccinationBooking = (
    cancelSourceToken: CancelTokenSource): Promise<VaccinationBookingsDto> => {

  return axios
  .get(`${apiUrl}/vaccination/booking`, {cancelToken: cancelSourceToken.token})
  .then(response => {
    const data = response.data;
    return typeof data === 'string' ? undefined : data;
  });
};

export const addVaccinationBooking = (
    request: Partial<VaccinationBookingDto>,
    cancelSourceToken: CancelTokenSource): Promise<String> => {

  return axios
  .post(`${apiUrl}/vaccination/booking`, request, {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};

export const searchVaccinationAppointment = (
    interventionId: number,
    cancelSourceToken: CancelTokenSource): Promise<VaccinationAvailabilitiesSearchDto> => {

  const formData = new FormData();
  formData.set("interventionId", `${interventionId}`);

  return axios
  .post(`${apiUrl}/vaccination/search-vaccination-appointment`,
      formData,
      {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};

export const scheduleVaccinationBooking = (
    interventionBookingId: number,
    request: Partial<VaccinationAppointmentRequest>,
    cancelSourceToken: CancelTokenSource): Promise<String> => {

  return axios
  .post(`${apiUrl}/vaccination/${interventionBookingId}/schedule-vaccination`,
      request,
      {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};
