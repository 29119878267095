import React, {Component} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import styled from "styled-components";

const ViewContainerDiv = styled.div`
  margin: 0.5rem 0.525rem;
  font-size: 1.275rem;

  .view-title {
    color: #7687FF;
    font-weight: bold;
    font-size: 1.8rem;
    margin-bottom: 3rem;
  }  

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    
    .column {
      display: flex;
      flex-direction: column;
      border-right: 1px solid black;
      padding-right: 2rem;

      .column-title {
        display: flex;
        font-weight: bold;
        font-size: 1.6rem;
        margin-bottom: 2rem;
      }

      .column-text {
        display: flex;
        font-weight: normal;
        font-size: 1.3rem;
        margin-bottom: 1.5rem;
      }
      
      .login-button {
        margin-bottom: 1.5rem;
      }
    }
    
    .column.no-border {
      border-right: none;
    }
    
    .column + .column {
      margin-left: 2rem;
      border-right: none;
    }
    
    .tile:first-child {
      flex: 2;
    }
    
    .tile {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    @media(max-width: 896px) {
      > .column {
        margin-left: 0 !important;
        border-right: none;
      }
    }
  }
`;

interface Props extends WithTranslation {
  titleKey: string;
}

class LoginTypeView extends Component<Props> {

  render(): React.ReactNode {

    const {titleKey, t, children} = this.props;

    return <ViewContainerDiv className="login-view">
      <h2 className="view-title">
        {t(titleKey)}
      </h2>

      {children}
    </ViewContainerDiv>;
  }
}

export default withTranslation(["login"])(LoginTypeView);
