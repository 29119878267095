/*
    GTIN-16 calculation
    155445547884400 8 - checksum
    |___digits____|

    N1  N2  N3  N4  N5  N6  N7  N8  N9  N10  N11  N12  N13  N14 N15   N16
    x   x   x   x   x   x   x   x   x   x    x    x    x    x   x
    3   1   3   1   3   1   3   1   3   1    3    1    3    1   3     Check Digit
    ________________________________________________________________________
    Sum all multiplications above
    Subtract the sum from nearest equal or higher multiple of ten = Check Digit

    Example:
    1554 4554 7884 4008

    1 x 3 = 3
    5 x 1 = 5
    5 x 3 = 15
    4 x 1 = 4

    4 x 3 = 12
    5 x 1 = 5
    5 x 3 = 15
    4 x 1 = 4

    7 x 3 = 21
    8 x 1 = 8
    8 x 3 = 24
    4 x 1 = 4

    4 x 3 = 12
    0 x 1 = 0
    0 x 3 = 0
           --- +
           132
    Nearest equal or higher multiple of ten = 140
    Checksum = 140 - 132 = 8
 */
export const calculateGTIN15Checksum = (digits: number[]): number => {
    if (!digits || digits.length !== 15) {
        return -1;
    }
    const multipliersGTIN15 = [3, 1]; // 3 - even, 1 - odd digit place.
    const sum = digits
        .reverse()
        .map((digit, index) => digit * multipliersGTIN15[index % 2])
        .reduce((a, b) => a + b, 0);

    const ceilSum = Math.ceil(sum / 10) * 10;
    return ceilSum - sum;
}

export const generateRandomMsntCode = (prefixDigits: number[]): String => {

    const digits: number[] = [];
    for (let i = 0; i < 15; i++) {
        if (i < prefixDigits.length) {
            digits.push(prefixDigits[i]);
        } else {
            digits.push(Math.floor(Math.random() * 10))
        }
    }

    const checksum = calculateGTIN15Checksum([...digits]);

    return `MSNT${digits.join("")}${checksum}`;
}

export const isValidBarcode = (code: string) => {
    if (!code) {
        return false;
    }

    const withoutDash = code.replace(/-/g, '');

    if (withoutDash.length !== 20) {
        return false;
    }

    const numericOnlyCode = withoutDash.slice(4);

    if (isNaN(Number(numericOnlyCode))) {
        return false;

    }

    const digits = [];
    for (let i = 0, len = numericOnlyCode.length; i < len; i += 1) {
        digits.push(Number(numericOnlyCode.charAt(i)));
    }
    const checksum = calculateGTIN15Checksum(digits.slice(0, 15));
    return checksum === digits[digits.length - 1];
}

export const isNumberOnlyBarcodeValid = (code: string) => {
    if (!code) {
        return false;
    }

    if (code.length !== 16) {
        return false;
    }

    if (isNaN(Number(code))) {
        return false;

    }

    const digits = [];
    for (let i = 0, len = code.length; i < len; i += 1) {
        digits.push(Number(code.charAt(i)));
    }
    const checksum = calculateGTIN15Checksum(digits.slice(0, 15));
    return checksum === digits[digits.length - 1];
}
