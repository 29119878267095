import {AuthConsumerRenderProps, withAuthContext} from 'auth/AuthContext';
import ProtectedRoute from 'auth/ProtectedRoute';
import CompanyDataProvider from "component/CompanyDataContext";
import EditCompany from "component/EditCompany";
import EmployeesView from "component/EmployeesView";
import LoaderComponent from "component/LoaderComponent";
import MisantoLogo from "component/MisantoLogo";
import StockManagementView from "component/StockManagementView";
import React from 'react';
import {useTranslation} from "react-i18next";
import {RouteComponentProps, RouterProps} from 'react-router';
import {Route, Switch} from 'react-router-dom';
import CompanyOnboardView from "route/company-onboard/CompanyOnboardView";
import ContractDocumentRejectionView from "route/contract-documents/ContractDocumentRejectionView";
import ContractDocumentView from "route/contract-documents/ContractDocumentView";
import EmployeeOverview from "route/employee-overview/EmployeeOverview";
import EmployeeOverviewTestkitOrdersList from "route/employee-overview/EmployeeOverviewTestkitOrdersList";
import DashboardEmployee from "route/employee/DashboardEmployee";
import DashboardEmployeeTestkitOrdersList from "route/employee/DashboardEmployeeTestkitOrdersList";
import EmployeeTestRegistrationManualInput from "route/employee/EmployeeTestRegistrationManualInput";
import EmployeesCommunicationView from "route/employees-communication/EmployeesCommunicationView";
import EmployeesImportWrapper from "route/EmployeesImportWrapper";
import EmployeesWrapper from "route/EmployeesWrapper";
import DashboardGuarantorCompany from "route/guarantor-company/DashboardGuarantorCompany";
import Home from 'route/Home';
import Init2fa from "route/init-2fa/Init2fa";
import ValidateCode from "route/init-2fa/ValidateCode";
import AccountNotActivated from "route/login/AccountNotActivated";
import AccountSelection from "route/login/AccountSelection";
import HeaderLogin from "route/login/HeaderLogin";
import Login from 'route/login/Login';
import EmailConsentView from "route/patient-email/EmailConsentView";
import EmailUnsubscribeView from "route/patient-email/EmailUnsubscribeView";
import PatientQuestionnaireView from "route/patient-email/PatientQuestionnaireView";
import ContainerPersonOnboardView from "route/person-onboard-container/ContainerPersonOnboardView";
import PersonOnboardView from "route/person-onboard/PersonOnboardView";
import TestKitOrderContainerPersonView from "route/person-selfonboarded-container/TestKitOrderContainerPersonView";
import TestKitOrderContainerPersonViewWithPayment
  from "route/person-selfonboarded-container/TestKitOrderContainerPersonViewWithPayment";
import DashboardPerson from "route/person-selfonboarded/DashboardPerson";
import RequestPcrTest from "route/person-selfonboarded/RequestPcrTest";
import RequestVaccination from "route/person-selfonboarded/RequestVaccination";
import RequestVaccinationAppointment from "route/person-selfonboarded/RequestVaccinationAppointment";
import EditPoolTestKit from "route/test-registration/EditPoolTestKit";
import PoolTestRegistration from "route/test-registration/PoolTestRegistration";
import TestRegistration from "route/test-registration/TestRegistration";
import TestRegistrationManualInput from "route/test-registration/TestRegistrationManualInput";
import CertificateImageUpload from "route/test-results/CertificateImageUpload";
import RapidTestResultImageUpload from "route/test-results/RapidTestResultImageUpload";
import TestResults from "route/test-results/TestResults";
import VerifyEmailView from "route/verify-email/VerifyEmailView";
import styled from 'styled-components';
import {EmployeeAccessTokenType, InterventionExternalCode} from "ts-types/api.enums";
import {withRouterWorkaround} from 'util/workaroundUtils';
import BarcodePage from "./barcode-scanner/BarcodePage";
import HeaderLoggedInUser from "./HeaderLoggedInUser";
import LoginPasswordlessLogin from "./login/LoginPasswordlessLogin";

const StyledContainer = styled.div`
  min-height: 100vh;
  overflow: hidden;

  .title-h1 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2.5rem;
  }

  .ui.button {
    border-radius: unset;
  }

  .ui.primary.button, .ui.primary.buttons .button {
    background-color: #7687FF;

    :active {
      background-color: #6877e2;
    }

    :hover {
      background-color: #5563ca;
    }

    :focus {
      background-color: #505dc8;
    }
  }

  .ui.secondary.button, .ui.secondary.buttons .button {
    background-color: #919191;

    //:active {
    //  background-color: #6877e2;
    //}
    //
    //:hover {
    //  background-color: #5563ca;
    //}
    //
    //:focus {
    //  background-color: #505dc8;
    //}
  }

  .field > .ui.input {
    width: 100%;
    height: 100%;
  }

  .ui.input > input {
    border: 1px solid #c9d0ff;
    border-radius: unset;
    padding: .5rem 0.675rem
  }

  .ui.disabled.input > input {
    opacity: .45;
  }

  .ui.input.down input,
  .ui.input > input:active {
    //border: 1px solid #7687FF;
  }

  .ui.input.focus > input,
  .ui.input > input:focus {
    border: 1px solid #7687FF;
  }

  .ui.disabled.input {
    opacity: 1;
  }

  label {
    color: #1d213c;
    font-size: 1.1589rem;
  }

  .ui.selection.dropdown, &.ui.selection.active.dropdown .menu {
    border: 1px solid #c9d0ff;
    border-radius: unset;
    box-shadow: unset;
    width: 100%;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #7687FF !important;
  }

  @media only screen and (min-width: 320px) and (max-width: 767px) {
    .title-h1 {
      font-size: 1.5rem;
      margin-bottom: 1.2rem;
    }
  }
`;

const StyledLoaderWrapper = styled.div`
  padding-top: 20px;
`;

const AppDiv = styled.div`
  &.app.mipoco {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0 1.5rem;
  }

  .app-header {
    flex: 0 0 auto;
    border-bottom: 1px solid #0f1a52;
    padding: 0.9rem 0;
    font-size: 1.4rem;
    margin: 0 0 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .misanto-logo-container {
      flex: 0 0 auto;
      width: 2.675em;
      max-width: 2.675rem;
      height: 2.675rem;
      margin-right: 1rem;
    }

    .header-title {
      flex: 0 0 auto;
      line-height: 2.775rem;
      color: #0f1a52;
    }

    .header-right {
      flex: 1 1 auto;
      align-items: end;
    }
  }

  .header-title-mobile {
    display: none;
  }

  .app-content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }

  .app-footer {
    flex: 0 0 auto;
    border-top: 1px solid #0f1a52;
    padding: 0.35rem 1rem 0.5rem;
    font-size: 1.1rem;
    margin: 1.2rem 0 0;
    color: #0f1a52;

    .left {
      float: left;
    }

    .right {
      float: right;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 767px) {
    .app-header {
      margin-bottom: 0.3rem;

      .header-title {
        display: none;
      }
    }

    .header-title-mobile {
      display: block;
      flex-basis: 100%;
      margin-bottom: 0.75rem;
      color: #0f1a52;
      font-size: 1.1rem;
      font-weight: 500;
    }

    .app-footer {
      padding: 0.35rem 0.15rem 0.5rem;
      font-size: 1rem;
    }
  }
`;

interface Props extends RouterProps, AuthConsumerRenderProps {
}

const isPersonAccessType = (accessType: EmployeeAccessTokenType) => {
  return EmployeeAccessTokenType.PERSON === accessType
      || EmployeeAccessTokenType.PERSON_SELF_ONBOARDED === accessType;
};

const Main = (props: Props) => {

  const loadingData = props.fetchingUser;
  const {t} = useTranslation("login");

  const renderLoader = (): React.ReactNode => {
    return (
        <StyledLoaderWrapper>
          <LoaderComponent active />
        </StyledLoaderWrapper>
    );
  };

  const renderApp = (): React.ReactNode => {

    const userLoggedIn = props.currentUser;
    return (
        <AppDiv className="app mipoco">
          <div className="app-header">
            <div className="misanto-logo-container" onClick={() => misantoLogoClicked(props)}>
              {userLoggedIn
                  ? <div><MisantoLogo /></div>
                  : <div style={{cursor: 'pointer'}}><MisantoLogo /></div>
              }
            </div>
            <div className="header-title">{t("app.title")}</div>
            <div className="header-right">
              <Switch>
                <Route path="/barcode-scanner/:token" component={() => <span />} />
                <Route path="/test-result-image-upload/:token" component={() => <span />} />
                <Route path="/certificate-image-upload/recovery/:token" component={() => <span />} />
                <Route path="/certificate-image-upload/vaccination/:token" component={() => <span />} />
                <Route path="/certificate-image-upload/externalTestDocument/:token" component={() => <span />} />
                <Route path="/testkit-barcode/result" component={() => <span />} />
                <Route path="/passwordless-login" component={() => <span />} />
                <Route path="/verification-code" component={() => <span />} />
                <Route path="/account-selection" component={() => <span />} />
                <Route path="/verify-email" component={() => <span />} />
                <Route path="/patient-email-consent" component={() => <span/>} />
                <Route path="/patient-email-unsubscribe" component={() => <span/>} />
                <Route path="/patient-questionnaire" component={() => <span/>} />
                <Route path="/login" component={HeaderLogin} />
                <Route path="/company-onboard" exact component={() => <span />} />
                <Route path="/person-onboard" exact component={() => <span />} />
                <Route path="/container/person-onboard" exact component={() => <span />} />
                <ProtectedRoute path="/" component={HeaderLoggedInUser} />
              </Switch>
            </div>
          </div>
          <div className="header-title-mobile">{t("app.title")}</div>

          <div className="app-content">
            <Switch>
              <Route path="/barcode-scanner/:token" component={BarcodePage} />
              <Route path="/test-result-image-upload/:token" component={RapidTestResultImageUpload} />
              <Route path="/certificate-image-upload/vaccination/:token"
                     component={() => <CertificateImageUpload certificateType="vaccination" />} />
              <Route path="/certificate-image-upload/recovery/:token"
                     component={() => <CertificateImageUpload certificateType="recovery" />} />
              <Route path="/certificate-image-upload/externalTestDocument/:token"
                     component={() => <CertificateImageUpload certificateType="externalTestDocument" />} />
              <Route path="/passwordless-login" component={LoginPasswordlessLogin} />
              <Route path="/verification-code" component={ValidateCode} />
              <Route path="/account-selection" component={AccountSelection} />
              <Route path="/verify-email" component={VerifyEmailView} />
              <Route path="/patient-email-consent" component={EmailConsentView} />
              <Route path="/patient-email-unsubscribe" component={EmailUnsubscribeView} />
              <Route path="/patient-questionnaire/" component={PatientQuestionnaireView}/>
              <Route path="/login" component={Login} />
              {/*<Route path='/reset-password' component={() => (<Login token={queryParams.get("token")}/>)}/>*/}

              <Route path="/company-onboard" exact component={CompanyOnboardView} />
              <Route path="/person-onboard" exact component={PersonOnboardView} />
              <Route path="/container/person-onboard" exact component={ContainerPersonOnboardView} />

              <ProtectedRoute path="/tos" exact component={ContractDocumentView} />
              <ProtectedRoute path="/tos-rejected" exact component={ContractDocumentRejectionView} />
              <ProtectedRoute path="/not-activated" exact component={AccountNotActivated} />

              <ProtectedRoute path="/init-2fa" exact component={Init2fa} />
            </Switch>
            {
                props.currentUser &&
                props.currentUser.accountType === EmployeeAccessTokenType.COMPANY
                && !props.currentUser.guarantorCompany &&
              <CompanyDataProvider key={props.currentUser.id}>
                <Switch>
                  <ProtectedRoute path="/" exact component={Home} />
                  {/*<ProtectedRoute path="/company-onboard" exact component={CompanyOnboardView} />*/}
                  <ProtectedRoute path="/company" exact component={EditCompany} />
                  <ProtectedRoute path="/employees-import" exact component={EmployeesImportWrapper} />
                  <ProtectedRoute path="/employee" exact component={EmployeesWrapper} />
                  <ProtectedRoute
                    path="/employees/overview/test-kit-order"
                    exact
                    render={() =>
                        <TestKitOrderContainerPersonView companyAdmin={true} />
                    }
                  />
                  <ProtectedRoute
                    path="/employees/overview/test-kit-orders-list"
                    exact
                    render={() =>
                        <EmployeeOverviewTestkitOrdersList companyAdmin={true} />
                    }
                  />
                  <ProtectedRoute
                    path="/employees/overview/request-pcr-test"
                    exact
                    render={() =>
                        <RequestPcrTest companyAdmin={true} />
                    }
                  />
                  <ProtectedRoute
                    path="/employees/overview/request-rapid-test"
                    exact
                    render={() =>
                        <RequestPcrTest testExternalCode={InterventionExternalCode.RAPID_SALIVA_TEST}
                                        companyAdmin={true}
                        />
                    }
                  />
                  <ProtectedRoute
                    path="/employees/overview/request-hpv-test"
                    exact
                    render={() =>
                        <RequestPcrTest testExternalCode={InterventionExternalCode.HPV_TEST}
                                        companyAdmin={true}
                        />
                    }
                  />
                  <ProtectedRoute
                    path="/employees/overview/request-chlamydia-test"
                    exact
                    render={() =>
                        <RequestPcrTest testExternalCode={InterventionExternalCode.CHLAMYDIA_TEST}
                                        companyAdmin={true}
                        />
                    }
                  />
                  <ProtectedRoute
                    path="/employees/overview/request-trichomoniasis-test"
                    exact
                    render={() =>
                        <RequestPcrTest testExternalCode={InterventionExternalCode.TRICHOMONIASIS_TEST}
                                        companyAdmin={true}
                        />
                    }
                  />
                  <ProtectedRoute
                    path="/employees/overview/request-rsv-test"
                    exact
                    render={() =>
                        <RequestPcrTest testExternalCode={InterventionExternalCode.RSV_TEST}
                                        companyAdmin={true}
                        />
                    }
                  />
                  <ProtectedRoute
                    path="/employees/overview/request-influenza-test"
                    exact
                    render={() =>
                        <RequestPcrTest testExternalCode={InterventionExternalCode.INFLUENZA_TEST}
                                        companyAdmin={true}
                        />
                    }
                  />
                  <ProtectedRoute path="/employees/overview" exact component={EmployeeOverview} />
                  <ProtectedRoute path="/employees/overview/testkit-registration"
                                  exact
                                  render={() => <TestRegistrationManualInput isEmployeesOverview />} />
                  <ProtectedRoute path="/employees" exact component={EmployeesView} />
                  <ProtectedRoute path="/employees-communication" exact component={EmployeesCommunicationView} />
                  <ProtectedRoute path="/test-results" exact component={TestResults} />
                  <ProtectedRoute path="/testkit-registration" exact component={TestRegistration} />
                  <ProtectedRoute path="/testkit-registration/manual" exact component={TestRegistrationManualInput} />
                  <ProtectedRoute path="/pool-testkit-registration" exact component={PoolTestRegistration} />
                  <ProtectedRoute path="/pool-testkit-registration/edit" exact component={EditPoolTestKit} />
                  <ProtectedRoute path="/pool-testkit-registration/manual"
                                  exact
                                  render={() => <TestRegistrationManualInput isPoolRegistration />} />
                  <ProtectedRoute path="/stock-management" exact component={StockManagementView} />
                </Switch>
              </CompanyDataProvider>
            }
            {
                props.currentUser &&
                props.currentUser.accountType === EmployeeAccessTokenType.COMPANY
                && props.currentUser.guarantorCompany &&
              <CompanyDataProvider>
                <Switch>
                  <ProtectedRoute path="/" exact component={DashboardGuarantorCompany} />
                </Switch>
              </CompanyDataProvider>
            }
            {
                props.currentUser
                && props.currentUser.accountType === EmployeeAccessTokenType.PERSON
                && !props.currentUser.selfOnboardedPerson
                && <CompanyDataProvider key={props.currentUser.id}>
                <Switch>
                  <ProtectedRoute path="/" exact component={DashboardEmployee} />
                  <ProtectedRoute path="/employee/manual-testkit-registration"
                                  exact component={EmployeeTestRegistrationManualInput} />
                  <ProtectedRoute path="/employee" exact component={EmployeesWrapper} />
                  <ProtectedRoute path="/person/test-kit-orders-list"
                                  exact
                                  component={DashboardEmployeeTestkitOrdersList} />
                  <ProtectedRoute path="/person/test-kit-order" exact component={TestKitOrderContainerPersonView} />
                  <ProtectedRoute path="/person/test-kit-order-selfservice"
                                  exact
                                  component={TestKitOrderContainerPersonViewWithPayment} />
                  <ProtectedRoute path="/person/test-kit-order-selfservice/:orderId"
                                  exact
                                  render={(props: RouteComponentProps<any>) => {
                                    const key = props.match.params.orderId;
                                    return <TestKitOrderContainerPersonViewWithPayment key={key} />;
                                  }} />
                  <ProtectedRoute path="/request-pcr-test" exact component={RequestPcrTest} />
                  <ProtectedRoute path="/request-rapid-test" exact render={() =>
                      <RequestPcrTest testExternalCode={InterventionExternalCode.RAPID_SALIVA_TEST} />} />
                  <ProtectedRoute path="/request-hpv-test" exact render={() =>
                      <RequestPcrTest testExternalCode={InterventionExternalCode.HPV_TEST} />} />
                  <ProtectedRoute path="/request-chlamydia-test" exact render={() =>
                      <RequestPcrTest testExternalCode={InterventionExternalCode.CHLAMYDIA_TEST} />} />
                  <ProtectedRoute path="/request-trichomoniasis-test" exact render={() =>
                      <RequestPcrTest testExternalCode={InterventionExternalCode.TRICHOMONIASIS_TEST} />} />
                  <ProtectedRoute path="/request-rsv-test" exact render={() =>
                      <RequestPcrTest testExternalCode={InterventionExternalCode.RSV_TEST} />} />
                  <ProtectedRoute path="/request-influenza-test" exact render={() =>
                      <RequestPcrTest testExternalCode={InterventionExternalCode.INFLUENZA_TEST} />} />
                </Switch>
              </CompanyDataProvider>
            }
            {
                props.currentUser
                && isPersonAccessType(EmployeeAccessTokenType[props.currentUser.accountType])
                && props.currentUser.selfOnboardedPerson
                && <Switch>
                <ProtectedRoute path="/" exact component={DashboardPerson} />
                <ProtectedRoute path="/person" exact component={EmployeesWrapper} />
                <ProtectedRoute path="/request-pcr-test" exact component={RequestPcrTest} />
                <ProtectedRoute path="/request-vaccination" exact component={RequestVaccination} />
                <ProtectedRoute path="/request-vaccination-appointment" exact
                                component={RequestVaccinationAppointment} />
              </Switch>
            }
          </div>

          <div className="app-footer">
            <div className="left">{t("app.footer.copyright")}</div>
            <div className="right">{t("app.footer.appName")}</div>
          </div>
        </AppDiv>
    );
  };

  const renderContent = (): React.ReactNode => {
    return loadingData ? renderLoader() : renderApp();
  };

  const misantoLogoClicked = (props: Props): any => {

    const userLoggedIn = props.currentUser;
    if (!userLoggedIn) {
      props.history.push("/");
    }
  };

  return (
      <StyledContainer>
        {renderContent()}
      </StyledContainer>
  );
};

export default withRouterWorkaround(withAuthContext(Main));
