import {CancelTokenSource} from "axios";
import axios from "service/http";
import {CountryDto, ZipCitySearchRequest, ZipDataDto} from "../ts-types/api.types";

const authBaseUrl = process.env.REACT_APP_AUTH_BASE_URL;
const apiUrl = `${authBaseUrl}/pub/countries-zipdata`;

export const getAllCountries = (cancelSourceToken: CancelTokenSource): Promise<Array<CountryDto>> => {
    return axios
    .get(`${apiUrl}`, {cancelToken: cancelSourceToken.token})
    .then(response => response.data);
}

export const searchZipDataForInput = (zip: string, countryCode: string, source: CancelTokenSource, limit?: number): Promise<Array<ZipDataDto>> => {

    const request: ZipCitySearchRequest = {
        zip: zip,
        countryCode: countryCode
    };

    return axios
    .post(`${apiUrl}/search`, request, { cancelToken: source.token })
    .then(response => response.data);
}