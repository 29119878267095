import ConfirmActionPopup from "component/ConfirmActionPopup";
import StyledActionButton from "component/StyledActionButton";
import React, {Component, ReactNode} from 'react';
import {Icon, Popup} from 'semantic-ui-react';


const PopupStyle = {
  borderRadius: 5,
  opacity: 0.7,
  padding: '7px'
};

interface Props {
  disabled?: boolean,
  popupMessage?: string,
  renderContent?: () => ReactNode,
  hoverMessage?: string,
  trigger?: React.ReactNode,
  poperDependencies?: any[],
  onConfirm: () => void,
  onOpen?: () => void,
  position?:
      | 'top left'
      | 'top right'
      | 'bottom right'
      | 'bottom left'
      | 'right center'
      | 'left center'
      | 'top center'
      | 'bottom center'
}

interface State {

}

class QrcodeActionButton extends Component<Props, State> {

  render(): React.ReactNode {
    const {
      disabled = false,
      hoverMessage,
      popupMessage,
      renderContent,
      poperDependencies,
      onOpen,
      onConfirm,
      trigger = (<Icon name={'mobile alternate'}/>),
      position = "top right"
    } = this.props;

    if (popupMessage || renderContent) {
      return (
          <ConfirmActionPopup
              message={popupMessage}
              renderContent={renderContent}
              renderTarget={this.renderButton()}
              poperDependencies={poperDependencies}
              onConfirmAction={onConfirm}
              onOpen={onOpen}
              disabled={disabled}
              position={position}
          />
      );
    } else if (hoverMessage) {
      return (
        <StyledActionButton icon
                            disabled={disabled}
                            type='button'
                            onClick={onConfirm}
        >
          <Popup
            trigger={trigger}
            content={hoverMessage}
            size='small'
            position='top center'
            style={PopupStyle}
            inverted
          />
        </StyledActionButton>
      );
    } else {
      return trigger;
    }
  }

  renderButton = (): React.ReactNode => {
    const {disabled = false, hoverMessage, trigger = (<Icon name={'mobile alternate'}/>)} = this.props;

    if (hoverMessage) {
      return (
        <StyledActionButton icon
                            disabled={disabled}
                            type='button'
        >
          <Popup
            trigger={trigger}
            content={hoverMessage}
            size='small'
            position='top center'
            style={PopupStyle}
            inverted
          />
        </StyledActionButton>
      );
    } else {
      return trigger;
    }
  };

}

export default QrcodeActionButton;
