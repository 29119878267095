import {CancelTokenSource} from "axios";
import axios from "service/http";
import {QuestionSetDto, SweepstakesBookingRequestDto} from "ts-types/api.types";

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const apiUrl = `${baseUrl}/sweepstakes`;

export const getQuestionSetForSweepstakes = (
    cancelSourceToken: CancelTokenSource): Promise<QuestionSetDto> => {

  return axios
  .get(`${apiUrl}/question-set`,
      {
        cancelToken: cancelSourceToken.token
      })
  .then(response => response.data);
};

export const addSweepstakesBooking = (
    request: Partial<SweepstakesBookingRequestDto>,
    cancelSourceToken: CancelTokenSource)
    : Promise<number> => {

  return axios
  .post(`${apiUrl}/booking`, request, {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};
