import {AuthConsumerRenderProps, withAuthContext} from 'auth/AuthContext';
import axios, {CancelTokenSource} from 'axios';
import FinalFormVerificationInput from "component/final-form/VerificationInput";
import {FormApi} from 'final-form';
import React, {Component} from "react";
import {Field, Form as FinalForm, FormRenderProps} from 'react-final-form';
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps, withRouter} from "react-router";
import LoginView from "route/login/LoginView";
import {Button} from 'semantic-ui-react';
import styled from 'styled-components';

const TwoFactorAuthBox = styled.div`
  max-width: 25rem;

  .form-content {
    margin-bottom: 1rem;

    .label {
      display: inline-block;
      margin: 0 0.5rem 0.5rem;
    }

    .verification-input__character {
      text-align: center;
    }

    .error {
      margin-left: 0.5rem;
      color: darkred;
    }
  }

  .success {
    color: darkgreen;
    display: inline-block;
    margin-left: 0.5rem;
  }

  .actions-row {
    margin-top: 2rem;

    button {
      margin-right: 0.75rem;
    }
  }
`;

interface Props extends RouteComponentProps<any>, WithTranslation, AuthConsumerRenderProps {
}

interface State {
  token: string;
  employeeId?: string;
  verificationCodeKey: number;
  error?: string;
  cancelTokenSource: CancelTokenSource;
}

interface TwoStepAuthValues {
  verificationCode: string;
}

class ValidateCode extends Component<Props, State> {

  initialValues: TwoStepAuthValues;

  constructor(props: Props) {
    super(props);

    const cancelTokenSource = axios.CancelToken.source();

    const state: any = this.props.location.state;
    const token = state ? state.token : "";
    const employeeId = state?.employeeId;

    this.state = {
      token: token,
      employeeId,
      verificationCodeKey: 1,
      error: undefined,
      cancelTokenSource
    };

    this.initialValues = {
      verificationCode: ""
    };
  }

  handleSubmit = async (
      values: Partial<TwoStepAuthValues>,
      form: FormApi<Partial<TwoStepAuthValues>, TwoStepAuthValues>) => {

    this.props.verifyToken(this.state.token, values.verificationCode!, this.state.employeeId)
    .then(() => {
      this.props.history.push("/", {welcome: true});
    })
    .catch(e => {
      let error = "error.general";
      const {response} = e;
      if (response?.data?.error) {

        let {error} = response.data;

        if (["INVALID_VERIFICATION_CODE"].includes(error)) {
          error = `init2fa.modal.error.${error}`;
        }
      }

      this.setState({
        verificationCodeKey: this.state.verificationCodeKey + 1,
        error: error
      });

      form.change("verificationCode", undefined);

      setTimeout(() => {
        (document.getElementsByClassName("verification-input")[0]! as any).focus();
      }, 50);
    });
  };

  render = (): React.ReactNode => {

    const {t} = this.props;

    return <LoginView titleKey={t("login.2fa.title")}>
      <FinalForm
          onSubmit={this.handleSubmit}
          subscription={{pristine: true, submitting: true, values: true}}
          render={this.renderForm}
          initialValues={this.initialValues}
      />
    </LoginView>;
  };

  renderForm = ({form, submitting, handleSubmit}: FormRenderProps): React.ReactNode => {

    const {t} = this.props;

    return (
        <form onSubmit={handleSubmit}>
          <TwoFactorAuthBox>
            <div className="form-content">
              <div>
                {/*<label htmlFor="verificationCode" className="label">
                  {t("init2fa.modal.verificationCode.label")}
                </label>*/}
                <Field
                    key={this.state.verificationCodeKey}
                    name="verificationCode"
                    component={FinalFormVerificationInput}
                    autoFocus={true}
                />
              </div>
              {
                this.state.error &&
                <div className="error">
                  {t(this.state.error)}
                </div>
              }
            </div>

            <div className="actions-row">
              <Button compact type="submit" primary disabled={submitting}>{t("login.codeVerification.title")}</Button>
              <Button
                  type="button"
                  compact
                  disabled={submitting}
                  onClick={() => {
                    this.props.history.push("/");
                  }}
              >{t("action.cancel")}</Button>
            </div>
          </TwoFactorAuthBox>
        </form>
    );
  };
}

export default withRouter(withAuthContext(withTranslation(["login"])(ValidateCode)));
