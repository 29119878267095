import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import {CancelTokenSource} from "axios";
import {BigActionButton} from "component/BigActionButton";
import {withCompanyDataContext} from "component/CompanyDataContext";
import CompanyDataHeader from "component/CompanyDataHeader";
import _ from 'lodash';
import moment from "moment";
import React, {Component} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";
import {Button, Header, Icon, Transition} from "semantic-ui-react";
import axios from "service/http";
import {getAllInterventions} from "service/interventionServices";
import {getAllResources} from "service/resourceServices";
import {getPcrTestBooking} from "service/testServices";
import {getVaccinationBooking} from "service/vaccinationServices";
import styled from "styled-components";
import {InterventionBookingStatus, InterventionExternalCode} from "ts-types/api.enums";
import {InterventionBookingDto, InterventionDto, ResourceDisplayDto, VaccinationBookingsDto} from "ts-types/api.types";
import {isoToDisplayTime, isoToLongDisplayDate} from "util/dateUtils";
import {interventionDescription} from "util/interventionUtils";
import {applyStyles} from "util/localizationUtils";
import {withRouterWorkaround} from "util/workaroundUtils";

const WelcomeDiv = styled.div`
  border-bottom: 1px solid #0f1a52;
  margin-bottom: 2rem;

  .welcome {
    background-color: #BBF3BA;
    padding: 2rem;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2rem;

    .left-content {
      display: inline-block;
      flex-basis: 90%;

      .welcome-content {
        color: #7687FF;

        .highlight-value {
          color: #1a1a1a;
        }

        span {
          color: rgba(0, 0, 0, .87);
        }
      }

      .successful-registration {
        font-size: 25px;
        margin-bottom: 1rem;
      }
    }

    .right-content {
      display: inline-block;
      flex-basis: 10%;
      text-align: right;

      .ui.compact.icon.button,
      .ui.compact.icon.buttons .button {
        padding: 0;
      }

      button {
        background: transparent;

        i {
          color: #1ED71A;
          font-size: 3em;
        }

        i:HOVER {
          color: #18a915;
        }
      }
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 767px) {

    margin-bottom: 0.95rem;

    h1.ui.header {
      font-size: 1.4rem;
      margin-bottom: 0.5rem;
    }

    .welcome {
      padding: 0.5rem;
      flex-wrap: nowrap;
      margin-bottom: 0.95rem;

      .left-content .successful-registration {
        font-size: 1.1rem;
        margin-bottom: 0.5rem;
      }

      .right-content button i {
        font-size: 2.15em;
      }
    }
  }
`;

const ButtonContentDiv = styled.div`

  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  margin-bottom: 1.5rem;

  .login-button {
    display: block;
    margin-top: 1rem;
  }

  .login-button + .login-button {
    margin-left: 1rem;
  }

  @media (max-width: 853px) {
    display: flex;
    flex-direction: column;
    text-align: center;

    .login-button + .login-button {
      margin-left: 0;
    }
  }

  @media (min-width: 1274px) {
    > span:not(:last-child) {
      margin-right: 2rem;
    }
  }

  @media (min-width: 854px) and (max-width: 1301px) {
    > span:not(:nth-child(2n)) {
      margin-right: 2rem;
    }
  }

  @media (max-width: 853px) {
    > span {
      display: block;
    }
  }

  span > button {
    @media (max-width: 1693px) {
      margin-bottom: 2rem;
    }
  }

`;

const BoldedSpan = styled.span`
  font-weight: bold;
`;

const EmployeeTestResultDiv = styled.div`
  flex: 1 1 auto;
  //display: flex; Causes rendering issues on iphone
  //flex-direction: column;

  .section-title {
    color: #7687FF;
    font-weight: bold;
    font-size: 1.5rem;
    margin-top: 2rem;
  }

  .section-title.section-border {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #c5c5c5;
  }

  .info-text {
    margin: 1rem 0 0;
    font-size: 1.2rem;
  }

  .already-vaccinated-info-text {
    font-size: 1.2rem;
    color: red;
  }

  .header-message-text {
    font-size: 1.5rem;
  }

  .dose-info {
    margin-top: 1rem;

    .appointment {
      font-weight: bold;
      margin: 0.75rem 0 0.5rem;

      .appointment-title {
        margin-right: 0.5rem;
      }
    }

    .value-row {
      display: flex;
      flex-direction: row;

      .label {
        min-width: 10rem;
        color: #545454;
      }

      .value {
        font-weight: bold;
      }
    }
  }

  .search-form {
    padding-left: 0.75rem;
    margin-bottom: 1rem;

    label {
      margin-right: 1rem;
    }

    .ui.input {
      min-width: 15rem;
    }

    button {
      margin-left: 1rem;
    }
  }

  .results-table {
    flex: 1 1 auto;
    min-height: 200px;

    .row-actions {
      i.icon,
      i.icons {
        color: #768aff;
      }
    }
  }

  .icon-button {
    padding: 2px !important;
    background: transparent !important;
  }

  .page-actions {
    justify-self: start;
  }
`;

const ParticipantButtonsDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;

  & .action-button + .action-button {
    margin-left: 1rem;
  }
`;

interface Props extends RouteComponentProps<any>, AuthConsumerRenderProps, WithTranslation {
}

interface State {
  pageDataLoaded: boolean,
  vaccinationBooking?: VaccinationBookingsDto,
  pcrTestBooking?: InterventionBookingDto,
  interventions: InterventionDto[],
  resources: ResourceDisplayDto[],
  isWelcome: boolean,
  isVaccinationAppointmentSuccess: boolean,
  scheduledInterventionBookingId?: number,
  errorMessages: Array<string>;
  cancelTokenSource: CancelTokenSource;
}


class DashboardPerson extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    const locationState: any = props.location.state;
    const isWelcome = locationState && locationState.welcome;
    const isVaccinationAppointmentSuccess = locationState && locationState.vaccinationAppointmentSuccess;
    const scheduledInterventionBookingId = (locationState && locationState.scheduledInterventionBookingId) || undefined;

    const cancelTokenSource = axios.CancelToken.source();

    this.state = {
      pageDataLoaded: false,
      interventions: [],
      resources: [],
      isWelcome: isWelcome,
      isVaccinationAppointmentSuccess,
      scheduledInterventionBookingId,
      errorMessages: [],
      cancelTokenSource
    };

    this.fetchPageData();

  }

  fetchPageData = async (): Promise<void> => {
    const {cancelTokenSource} = this.state;

    try {
      const vaccinationBooking = await getVaccinationBooking(cancelTokenSource);
      const pcrTestBooking = await getPcrTestBooking(cancelTokenSource);
      const interventions = await getAllInterventions(cancelTokenSource);

      this.setState({
        interventions,
        vaccinationBooking: vaccinationBooking,
        pcrTestBooking: pcrTestBooking
      });

      if (vaccinationBooking || pcrTestBooking) {
        const resources = await getAllResources(cancelTokenSource);
        this.setState({
          resources
        });
      }
    } catch (e) {
      this.handleError(e.response.data);
    } finally {
      this.setState({
        pageDataLoaded: true
      });
    }

  };

  handleError(error: any) {
    const {t} = this.props;

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            this.setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!this.state.errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          this.setErrorMessage(t(`error.${errorCode}`));
        } else {
          this.setErrorMessage(t('error.general'));
        }
      }
    }
  };

  setErrorMessage = (errorMessage?: string) => {

    const {errorMessages} = this.state;

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);

      this.setState({
        errorMessages: errMsgs
      });
    } else {

      this.setState({
        errorMessages: []
      });
    }
  };

  closeWelcome = () => {
    this.setState({
      isWelcome: false
    });
  };


  closeVaccinationAppointemntSuccess = () => {
    this.setState({
      isVaccinationAppointmentSuccess: false
    });
  };

  goToVaccinationAppointmentRequest = (interventionId: number) => {

    const {vaccinationBooking} = this.state;
    let interventionBooking = undefined;
    if (vaccinationBooking?.firstDoseBooking
        && vaccinationBooking.firstDoseBooking.status === "ON_WAIT_LIST") {

      interventionBooking = vaccinationBooking?.firstDoseBooking;
    }

    const boosterOnWaitList = this.getBoosterByStatus(InterventionBookingStatus.ON_WAIT_LIST);
    if (!interventionBooking && boosterOnWaitList) {
      interventionBooking = boosterOnWaitList;
    }

    if (!interventionBooking) {
      return;
    }

    this.props.history.push("/request-vaccination-appointment", {
      interventionBookingId: interventionBooking.id,
      locationId: interventionBooking.resourceId,
      interventionId: interventionId
    });
  };

  getInterventionDescription = (interventionId: number | undefined): string => {
    if (!interventionId) {
      return "";
    }

    const intervention = this.state.interventions.find(intervention => intervention.id === interventionId);
    return intervention ? interventionDescription(intervention, this.props.language) : "";
  };

  openRegisterNewParticipant = (): void => {
    const {currentUser} = this.props;
    this.props.history.push("/person", {
      companyEmail: currentUser && currentUser.username ? currentUser.username : undefined
    });
  };

  openAccountSelection = (): void => {
    this.props.history.push("/account-selection");
  };

  renderNewParticipantAndSwitchUserSection = () => {
    const {currentUser, t} = this.props;
    const multiAcc = currentUser && currentUser.multiAccount;
    return (
        <ParticipantButtonsDiv>
          <Button
              type="button"
              className="action-button"
              primary
              onClick={this.openRegisterNewParticipant}
          >
            {t("dashboard.button.registerNewParticipant")}
          </Button>
          {
              multiAcc &&
            <Button
              type="button"
              className="action-button"
              primary
              onClick={this.openAccountSelection}
            >
              {t("dashboard.button.switchParticipant")}
            </Button>
          }
        </ParticipantButtonsDiv>
    );
  };

  getAllVaccinationBookings = (): InterventionBookingDto[] => {
    const {vaccinationBooking} = this.state;

    const bookings: InterventionBookingDto[] = [];
    if (!vaccinationBooking) {
      return bookings;
    }

    if (vaccinationBooking.firstDoseBooking) {
      bookings.push(vaccinationBooking.firstDoseBooking);
    }

    if (vaccinationBooking.secondDoseBooking) {
      bookings.push(vaccinationBooking.secondDoseBooking);
    }

    if (vaccinationBooking.boosterBookings) {
      bookings.push(...vaccinationBooking.boosterBookings);
    }

    return bookings;
  };

  getBookingsByStatus = (
      bookings: InterventionBookingDto[], status: InterventionBookingStatus)
      : InterventionBookingDto[] => {

    let resultBookings = bookings.filter(booking => status === InterventionBookingStatus[booking.status]);
    resultBookings = _.sortBy(resultBookings, [
      booking => (booking.bookingData ? booking.bookingData.date : null),
      booking => (booking.bookingData ? booking.bookingData.time : null)
    ]);
    return resultBookings;
  };

  getBoosterByStatus = (status: InterventionBookingStatus): InterventionBookingDto | undefined => {
    const {vaccinationBooking} = this.state;

    if (!vaccinationBooking) {
      return undefined;
    }

    const booking = vaccinationBooking.boosterBookings.find(
        booking => booking.status && status === InterventionBookingStatus[booking.status]);
    return booking;
  };

  render(): React.ReactNode {

    const {pageDataLoaded, resources, interventions} = this.state;
    const {currentUser, t} = this.props;

    if (!pageDataLoaded) {
      return <></>;
    }

    const {pcrTestBooking} = this.state;

    const locationDescription = (locationId?: number): string => {
      const resource = resources.find(r => r.id === locationId);
      return resource ? resource.descriptionWithLocation : "";
    };

    const pcrTestPlanned = pcrTestBooking && pcrTestBooking.status === InterventionBookingStatus.PLANNED;
    const impersonate = currentUser?.skip2fa;

    const bookings = this.getAllVaccinationBookings();

    const bookingsRequested = this.getBookingsByStatus(bookings, InterventionBookingStatus.REQUESTED);
    const hasBookingsRequested = bookingsRequested.length > 0;

    const bookingsOnWaitList = this.getBookingsByStatus(bookings, InterventionBookingStatus.ON_WAIT_LIST);
    const hasBookingsOnWaitList = bookingsOnWaitList.length > 0;

    const bookingsPlanned = this.getBookingsByStatus(bookings, InterventionBookingStatus.PLANNED);
    const hasBookingsPlanned = bookingsPlanned.length > 0;

    const bookingsCompleted = this.getBookingsByStatus(bookings, InterventionBookingStatus.COMPLETED);
    const hasBookingsCompleted = bookingsCompleted.length > 0;

    return (
        <EmployeeTestResultDiv>
          {this.renderWelcomeHeader()}
          {this.renderVaccinationAppointmentSuccessHeader()}

          <CompanyDataHeader />

          {/*{this.renderNewParticipantAndSwitchUserSection()}*/}

          {this.renderButtonsContent(bookings)}

          <div className="section-title section-border">{t("personDashboard.sectionTitle.appointment")}</div>

          {
              !(hasBookingsRequested || hasBookingsOnWaitList || hasBookingsPlanned) &&
            <div className="info-text">{t("personDashboard.sectionContent.noItems")}</div>
          }

          {
              hasBookingsRequested &&
            <div className="info-text">
              {t("personDashboard.appointment.requested.info")}
            </div>
          }

          {
              hasBookingsOnWaitList &&
            <div className="info-text">
              {t("personDashboard.appointment.onWaitingList.info")}
            </div>
          }

          {
              hasBookingsPlanned &&
            <div className="info-text">
              <div>{t("personDashboard.vaccinationAppointment.planned.info")}</div>

              {
                bookingsPlanned.map(booking =>
                    <div key={`planned-${booking.id}`} className="dose-info">
                      <div className="appointment">
                          <span className="appointment-title">
                            {this.getInterventionDescription(booking.interventionId)}
                          </span>
                        <Icon name={'calendar alternate'} />
                      </div>

                      <div className="value-row">
                        <div className="label">{t("personDashboard.appointment.place")}</div>
                        <div className="value">{locationDescription(booking.resourceId)}</div>
                      </div>

                      <div className="value-row">
                        <div className="label">{t("personDashboard.appointment.dateTime")}</div>
                        <div className="value">
                          {`${isoToLongDisplayDate(booking.bookingData.date)} - ${isoToDisplayTime(booking.bookingData.time)}`}
                        </div>
                      </div>
                    </div>)
              }
            </div>
          }

          {
              (pcrTestPlanned && impersonate) &&
            <div className="info-text">
              <div>{t("personDashboard.pcrTestAppointment.planned.info")}</div>
              <div className="dose-info">
                <div className="appointment">
                  {t("personDashboard.appointment.pcrTest")}
                  <Icon name={'calendar alternate'} />
                </div>
                <div className="value-row">
                  <div className="label">{t("personDashboard.appointment.place")}</div>
                  <div className="value">{locationDescription(pcrTestBooking?.resourceId)}</div>
                </div>

                <div className="value-row">
                  <div className="label">{t("personDashboard.appointment.dateTime")}</div>
                  <div className="value">
                    {`${isoToLongDisplayDate(pcrTestBooking?.bookingData.date)} - ${isoToDisplayTime(pcrTestBooking?.bookingData.time)}`}
                  </div>
                </div>
              </div>
            </div>
          }

          <div className="section-title section-border">
            {/*t("personDashboard.sectionTitle.pastAppointments")*/}
          </div>

          {
              !(hasBookingsCompleted) &&
            <div className="info-text">{t("personDashboard.sectionContent.noItems")}</div>
          }

          {
              hasBookingsCompleted &&
            <div className="info-text">
              <div>{/*t("personDashboard.vaccinationAppointment.planned.info")*/}</div>

              {
                bookingsCompleted.map(booking =>
                    <div key={`completed-${booking.id}`} className="dose-info">
                      <div className="appointment">
                          <span className="appointment-title">
                            {this.getInterventionDescription(booking.interventionId)}
                          </span>
                        <Icon name={'calendar check'} />
                      </div>

                      <div className="value-row">
                        <div className="label">{t("personDashboard.appointment.place")}</div>
                        <div className="value">{locationDescription(booking.resourceId)}</div>
                      </div>

                      <div className="value-row">
                        <div className="label">{t("personDashboard.appointment.dateTime")}</div>
                        <div className="value">
                          {`${isoToLongDisplayDate(booking.bookingData.date)} - ${isoToDisplayTime(booking.bookingData.time)}`}
                        </div>
                      </div>
                    </div>)
              }
            </div>
          }

          <div className="section-title section-border">{t("personDashboard.sectionTitle.documents")}</div>
          <div className="info-text">{t("personDashboard.sectionContent.noItems")}</div>
        </EmployeeTestResultDiv>
    );
  }

  renderWelcomeHeader = (): React.ReactNode => {

    const {t, currentUser} = this.props;
    const {isWelcome} = this.state;

    if (!isWelcome) {
      return;
    }

    let formattedDate = "";
    if (currentUser?.lastLogin) {
      const momentDate = moment(currentUser.lastLogin);

      formattedDate = momentDate.format('DD. MMMM YYYY - HH:mm');
    }

    return (
        <Transition.Group animation="slide down" duration={125}>
          <WelcomeDiv>
            <div className="welcome">
              <div className="left-content">
                <Header as="h1">
                  <div className="welcome-content">
                    {
                      applyStyles(t(
                          "personDashboard.welcome.title",
                          {personFullName: `${currentUser?.firstName} ${currentUser?.lastName}`}))
                    }
                  </div>
                </Header>
                <div className="successful-registration">
                  {t("personDashboard.welcome.info")}
                </div>
                <div>
                  ({t("personDashboard.welcome.lastRegistrationOn")} <BoldedSpan>{formattedDate}</BoldedSpan>)
                </div>
              </div>
              <div className="right-content">
                <Button circular icon compact onClick={this.closeWelcome}>
                  <Icon size="big" name="times circle" />
                </Button>
              </div>
            </div>
          </WelcomeDiv>
        </Transition.Group>
    );
  };

  renderVaccinationAppointmentSuccessHeader = (): React.ReactNode => {

    const {t} = this.props;
    const {
      isVaccinationAppointmentSuccess,
      scheduledInterventionBookingId,
      vaccinationBooking
    } = this.state;

    if (!isVaccinationAppointmentSuccess || !scheduledInterventionBookingId) {
      return;
    }

    const scheduledBooking: InterventionBookingDto | undefined = this.getAllVaccinationBookings().find(
        booking => booking.id === scheduledInterventionBookingId);
    if (!scheduledBooking) {
      return;
    }

    return (
        <Transition.Group animation="slide down" duration={125}>
          <WelcomeDiv>
            <div className="welcome">
              <div className="left-content">
                <Header as="h1">
                  <div className="welcome-content">
                    {t("personDashboard.message.appointmentScheduledSuccess.title")}
                  </div>
                </Header>
                <div className="successful-registration">
                  {applyStyles(t("personDashboard.message.appointmentScheduledSuccess.text", {
                    location: this.state.resources.find(r => r.id === scheduledBooking.resourceId)?.description,
                    date: moment(scheduledBooking.bookingData.date).format("dddd, DD. MMMM YYYY"),
                    time: moment(scheduledBooking.bookingData.time, "HH:mm").format("HH:mm")
                  }))}
                </div>
              </div>
              <div className="right-content">
                <Button circular icon compact onClick={this.closeVaccinationAppointemntSuccess}>
                  <Icon size="big" name="times circle" />
                </Button>
              </div>
            </div>
          </WelcomeDiv>
        </Transition.Group>
    );
  };

  renderButtonsContent = (
      vaccinationBookings: InterventionBookingDto[]): React.ReactNode => {

    const {currentUser, t} = this.props;
    const {interventions, vaccinationBooking, pcrTestBooking} = this.state;

    const getIntervention = (extCode: InterventionExternalCode) => {
      return interventions.find(intervention => intervention.externalCode === extCode);
    };

    const vaccTwoDose = getIntervention(InterventionExternalCode.VACCINATION_DOSE_1);
    const vaccTwoDoseChild = getIntervention(InterventionExternalCode.VACCINATION_CHILD_DOSE_1);
    const vaccBooster = getIntervention(InterventionExternalCode.VACCINATION_BOOSTER);
    const vaccSingleDose = getIntervention(InterventionExternalCode.VACCINATION_SINGLE_DOSE);

    const firstDoseBooking = vaccinationBooking?.firstDoseBooking;
    const preventBoosterBookings = vaccinationBooking && vaccinationBooking.boosterBookings.length > 5;

    const vaccinationOnWaitingList =
        firstDoseBooking?.status === InterventionBookingStatus.ON_WAIT_LIST;
    const vaccinationBoosterOnWaitList =
        this.getBoosterByStatus(InterventionBookingStatus.ON_WAIT_LIST);

    const bookingsRequested = this.getBookingsByStatus(
        vaccinationBookings, InterventionBookingStatus.REQUESTED);
    const hasBookingsRequested = bookingsRequested.length > 0;

    const bookingsOnWaitList = this.getBookingsByStatus(
        vaccinationBookings, InterventionBookingStatus.ON_WAIT_LIST);
    const hasBookingsOnWaitList = bookingsOnWaitList.length > 0;

    const showRequestAppointment =
        !(preventBoosterBookings || hasBookingsRequested || hasBookingsOnWaitList);

    const impersonate = currentUser?.skip2fa;
    const enableTestScheduling = !pcrTestBooking && impersonate;

    const anyActionEnabled = showRequestAppointment
        || vaccinationOnWaitingList
        || vaccinationBoosterOnWaitList
        || enableTestScheduling;

    if (!anyActionEnabled) {
      return <></>;
    }

    return (
        <>
          <div className="info-text">
            {t("personDashboard.infoText")}
          </div>

          <ButtonContentDiv>
            {
                showRequestAppointment &&
              <BigActionButton
                icon="syringe"
                textDomain="mipoco"
                textKey="personDashboard.action.vaccination"
                onClick={() => this.props.history.push("/request-vaccination")}
              />
            }

            {
                vaccinationOnWaitingList &&
              <>
                <BigActionButton
                  icon="calendar alternate"
                  textDomain="mipoco"
                  textKey="personDashboard.action.vaccinationAppointmentTwoDose"
                  onClick={() => this.goToVaccinationAppointmentRequest(vaccTwoDose!.id)}
                />

                <BigActionButton
                  icon="child"
                  applyTextStyles={true}
                  textDomain="mipoco"
                  textKey="personDashboard.action.vaccinationAppointmentTwoDoseChild"
                  onClick={() => this.goToVaccinationAppointmentRequest(vaccTwoDoseChild!.id)}
                />

                <BigActionButton
                  icon="calendar alternate"
                  textDomain="mipoco"
                  textKey="personDashboard.action.vaccinationAppointmentSingleDose"
                  onClick={() => this.goToVaccinationAppointmentRequest(vaccSingleDose!.id)}
                />
              </>
            }

            {
                (vaccinationOnWaitingList || vaccinationBoosterOnWaitList) &&
              <>
                <BigActionButton
                  icon="calendar alternate"
                  textDomain="mipoco"
                  textKey="personDashboard.action.vaccinationAppointmentBooster"
                  onClick={() => this.goToVaccinationAppointmentRequest(vaccBooster!.id)}
                />
              </>
            }

            {
                (!pcrTestBooking && impersonate) &&
              <BigActionButton
                icon="flask"
                textDomain="mipoco"
                textKey="personDashboard.action.pcrTest"
                onClick={() => this.props.history.push("/request-pcr-test")}
              />
            }

            {/*<BigActionButton
              icon="flask"
              textDomain="mipoco"
              textKey="personDashboard.action.covidTesting"
              disabled
          />*/}
          </ButtonContentDiv>
        </>
    );
  };

}

export default withRouterWorkaround(withAuthContext(
    withCompanyDataContext(withTranslation(["mipoco"])(DashboardPerson))));
