/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 2.30.840 on 2023-06-20 07:37:24.

export interface Role {
}

export enum CompanySubType {
    PRIMARY_SCHOOL = "PRIMARY_SCHOOL",
    SECONDARY_SCHOOL = "SECONDARY_SCHOOL",
    HIGH_SCHOOL = "HIGH_SCHOOL",
    UNIVERSITY = "UNIVERSITY",
    OTHER = "OTHER",
}

export enum CompanyType {
    COMPANY = "COMPANY",
    SCHOOL = "SCHOOL",
    RETIREMENT_HOME = "RETIREMENT_HOME",
    ASSOCIATION = "ASSOCIATION",
    ADMINISTRATION = "ADMINISTRATION",
    HOSPITAL = "HOSPITAL",
    SOCIAL_MEDICINE = "SOCIAL_MEDICINE",
}

export enum ConfirmationStatusType {
    PENDING = "PENDING",
    ACCEPTED = "ACCEPTED",
    REJECTED = "REJECTED",
}

export enum ContractDocumentType {
    TERMS_OF_SERVICE = "TERMS_OF_SERVICE",
    DATA_PROTECTION = "DATA_PROTECTION",
}

export enum CovidTestSampleMethod {
    SALIVA = "SALIVA",
    NASAL_SWAB = "NASAL_SWAB",
    THROAT_SWAB = "THROAT_SWAB",
    VAGINAL_SWAB = "VAGINAL_SWAB",
    URINE = "URINE",
}

export enum CreatorType {
    ADMINISTRATION = "ADMINISTRATION",
    CLIENT_ADMIN = "CLIENT_ADMIN",
    CLIENT_USER = "CLIENT_USER",
    USAGE = "USAGE",
    AI = "AI",
}

export enum EmployeeAccessTokenType {
    PERSON = "PERSON",
    COMPANY = "COMPANY",
    PERSON_SELF_ONBOARDED = "PERSON_SELF_ONBOARDED",
    ONBOARDING_PERSON = "ONBOARDING_PERSON",
    ONBOARDING_COMPANY = "ONBOARDING_COMPANY",
}

export enum InterventionBookingStatus {
    REQUESTED = "REQUESTED",
    ON_WAIT_LIST = "ON_WAIT_LIST",
    PLANNED = "PLANNED",
    EXECUTED = "EXECUTED",
    AWAITING_COMPLETION = "AWAITING_COMPLETION",
    LAB_RECEIVED = "LAB_RECEIVED",
    COMPLETED = "COMPLETED",
    CANCELLED = "CANCELLED",
}

export enum InterventionExternalCode {
    PCR_SALIVA_TEST = "PCR_SALIVA_TEST",
    PCR_SALIVA_TEST_INDIVIDUAL_TEST = "PCR_SALIVA_TEST_INDIVIDUAL_TEST",
    RAPID_SALIVA_TEST = "RAPID_SALIVA_TEST",
    COVID_CERTIFICATE_OF_RECOVERY = "COVID_CERTIFICATE_OF_RECOVERY",
    COVID_CERTIFICATE_OF_VACCINATION = "COVID_CERTIFICATE_OF_VACCINATION",
    COVID_CERTIFICATE_EXTERNAL_TEST_DOCUMENT = "COVID_CERTIFICATE_EXTERNAL_TEST_DOCUMENT",
    VACCINATION_DOSE_1 = "VACCINATION_DOSE_1",
    VACCINATION_DOSE_2 = "VACCINATION_DOSE_2",
    VACCINATION_CHILD_DOSE_1 = "VACCINATION_CHILD_DOSE_1",
    VACCINATION_CHILD_DOSE_2 = "VACCINATION_CHILD_DOSE_2",
    VACCINATION_SINGLE_DOSE = "VACCINATION_SINGLE_DOSE",
    VACCINATION_BOOSTER = "VACCINATION_BOOSTER",
    HPV_TEST = "HPV_TEST",
    CLINICAL_QUESTIONS_TEST = "CLINICAL_QUESTIONS_TEST",
    CHLAMYDIA_TEST = "CHLAMYDIA_TEST",
    TRICHOMONIASIS_TEST = "TRICHOMONIASIS_TEST",
    RSV_TEST = "RSV_TEST",
    INFLUENZA_TEST = "INFLUENZA_TEST",
    HEPATITIS_B_TEST = "HEPATITIS_B_TEST",
    QUANTITY_TEST = "QUANTITY_TEST",
    ORDER_ITEM_TEST = "ORDER_ITEM_TEST",
    OTHER = "OTHER",
}

export enum LabInvoiceTo {
    GUARANTOR = "GUARANTOR",
    PROVIDER = "PROVIDER",
}

export enum OrderStatus {
    ORDER = "ORDER",
    DELIVER = "DELIVER",
    REGISTERED = "REGISTERED",
    CANCEL = "CANCEL",
}

export enum PaymentStatus {
    INITIALIZED = "INITIALIZED",
    ERROR = "ERROR",
    CANCELLED = "CANCELLED",
    EXECUTED = "EXECUTED",
}

export enum RegistrationStateType {
    INITIAL = "INITIAL",
    PENDING = "PENDING",
    ACCEPTED = "ACCEPTED",
    REJECTED = "REJECTED",
}

export enum RepetitiveTestingMode {
    DEFAULT = "DEFAULT",
    FORCE_PARENT_GUARANTOR_PAYER = "FORCE_PARENT_GUARANTOR_PAYER",
    SELF_PAYER = "SELF_PAYER",
    OUTBREAK_TESTING = "OUTBREAK_TESTING",
}

export enum SampleDateAndTimeMode {
    REGISTRATION_TIME = "REGISTRATION_TIME",
    CUSTOM_TIME = "CUSTOM_TIME",
}

export enum TestFrequency {
    WEEK_ONE = "WEEK_ONE",
    WEEK_TWO = "WEEK_TWO",
    MONTH_ONE = "MONTH_ONE",
    MONTH_TWO = "MONTH_TWO",
}

export enum TestPoolingType {
    NONE = "NONE",
    BY_MISANTO = "BY_MISANTO",
    BY_COMAPNY = "BY_COMAPNY",
    BY_LABORATORY = "BY_LABORATORY",
}

export enum TestRegistrationType {
    CENTRAL = "CENTRAL",
    EMPLOYEE = "EMPLOYEE",
}

export enum Urgency {
    LOW = "LOW",
    NORMAL = "NORMAL",
    HIGH = "HIGH",
}

export enum VerifyEmailStatus {
    PENDING = "PENDING",
    ACCEPTED = "ACCEPTED",
    DECLINED = "DECLINED",
}

export enum BillReceiver {
    PATIENT = "PATIENT",
    GUARANTOR = "GUARANTOR",
}

export enum InterventionSlotStatus {
    FREE = "FREE",
    BOOKED = "BOOKED",
    BLOCKED = "BLOCKED",
}
