import {AuthConsumerRenderProps, withAuthContext} from 'auth/AuthContext';
import React from 'react';
import {RouteProps, withRouter} from 'react-router';
import {Redirect, Route} from 'react-router-dom';
import {EmployeeAccessTokenType} from "ts-types/api.enums";

interface Props extends AuthConsumerRenderProps, RouteProps {
  component: typeof React.Component | React.FunctionComponent<any>;
  isAuthenticated: boolean;
  exact?: boolean;
}

const ProtectedRoute = ({component, isAuthenticated, exact = false, path, ...rest}: Props) => {
  const Component = component; // JSX needs capital letter, lower case in props to keep it consistent with react-router
  return (
      <Route
          exact={exact}
          render={
            props => {
              if (!isAuthenticated) {
                return <Redirect to="/login" />;
              }
              const registrationState = rest.currentUser?.registrationState;
              const termOfServicePaths = ["/tos", "/tos-rejected"];
              if (!termOfServicePaths.includes(path as string)
                  && registrationState !== "ACCEPTED" && !rest.currentUser?.skip2fa) {

                return registrationState === "REJECTED"
                    ? <Redirect to="/tos-rejected" />
                    : <Redirect to="/tos" />;
              }

              const notActivatedPaths = ["/tos", "/tos-rejected", "/not-activated"];
              const inactiveUser = rest.currentUser?.activated === false;
              if (!notActivatedPaths.includes(path as string) && inactiveUser) {
                return <Redirect to="/not-activated" />;
              }

              const set2faPaths = ["/init-2fa", "/tos", "/tos-rejected", "/not-activated"];
              const state: any = rest.location?.state;
              const show2faInit = rest.currentUser?.accountType === EmployeeAccessTokenType.COMPANY
                  && !rest.skip2faInit
                  && rest.currentUser?.use2fa === false
                  && state?.welcome;
              if (!set2faPaths.includes(path as string) && show2faInit && !rest.currentUser?.skip2fa) {
                return <Redirect to="/init-2fa" />;
              }

              return <Component {...props} />;
            }
          }
          {...rest}
      />
  );
};

export default withRouter(withAuthContext(ProtectedRoute));
