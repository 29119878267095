import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import moment from "moment";
import React, {Component} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {Button, Header, Icon, Transition} from "semantic-ui-react";
import styled from "styled-components";

const WelcomeDiv = styled.div`
  border-bottom: 1px solid #0f1a52;
  margin-bottom: 2rem;

  .welcome {
    background-color: #BBF3BA;
    padding: 2rem;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2rem;

    .left-content {
      display: inline-block;
      flex-basis: 90%;

      .welcome-content {
        color: #7687FF;
      }

      .last-registration {
        font-size: 25px;
      }
    }

    .right-content {
      display: inline-block;
      flex-basis: 10%;
      text-align: right;

      .ui.compact.icon.button,
      .ui.compact.icon.buttons .button {
        padding: 0;
      }

      button {
        background: transparent;

        i {
          color: #1ED71A;
          font-size: 3em;
        }

        i:HOVER {
          color: #18a915;
        }
      }
    }
`;

const BoldedSpan = styled.span`
  font-weight: bold;
`

interface Props extends WithTranslation, AuthConsumerRenderProps {
    isWelcome: boolean,
    companyName: string
}

interface State {
    isWelcome: boolean
}

class Welcome extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            isWelcome: props.isWelcome
        }
    }

    closeWelcome = () => {
        this.setState({
            isWelcome: false
        })
    }

    render() {

        const { isWelcome } = this.state;

        // if (!isWelcome) {
        //     return "";
        // }

        const {t, currentUser, companyName} = this.props;

        let formattedDate = "";
        if (currentUser?.adminLastLogin) {
            const momentDate = moment(currentUser.adminLastLogin);

            // TODO ftrajkov 2021-03-25: Check why moment doesn't respect the locale provided
            // momentDate.locale(language);

            formattedDate = momentDate.format("DD. MMMM YYYY - HH:mm");
        }

        return (
            <Transition.Group animation="slide down" duration={125}>
                {isWelcome && (
                    <WelcomeDiv>
                        <div className="welcome">
                            <div className="left-content">
                                <Header as="h1">
                                    <div className="welcome-content">
                                        {t("dashboard.header.welcome")}
                                    </div>
                                    <div>
                                        {currentUser?.firstName} {currentUser?.lastName}, {companyName}
                                    </div>
                                </Header>
                                <div className="last-registration">
                                    {t("dashboard.header.lastRegistrationOn")} <BoldedSpan>{formattedDate}</BoldedSpan>
                                </div>
                            </div>
                            <div className="right-content">
                                <Button circular icon compact onClick={this.closeWelcome}>
                                    <Icon size="big" name="times circle" />
                                </Button>
                            </div>
                        </div>
                    </WelcomeDiv>
                )}
            </Transition.Group>
        );
    }
}

const WrappedWelcome = withAuthContext(
    withTranslation(["mipoco"])(Welcome));

export default WrappedWelcome;