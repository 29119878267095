interface Props {
  color?: string;
  width?: string;
  height?: string;
}

const FootstepsIcon = (props: Props) => {
  const {color = "#000000", width = "43", height = "60"} = props;
  return (
      <svg width={width} height={height} viewBox="0 0 43 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M26.041 45.0137C29.1157 46.122 31.8307 46.828 35.7315 48.0898C34.2329 68.0219 16.3273 60.1063 26.041 45.0137ZM42.0279 26.7035C41.826 20.7187 40.8246 12.868 32.8478 14.0982C29.1085 15.1254 26.3456 19.4482 25.0462 26.9064C24.3325 31.0071 24.7475 36.763 25.5893 40.2134C26.358 42.6449 26.0966 42.4954 26.9227 42.9572C30.1203 43.7154 33.2858 44.5548 36.5093 45.4107C39.7841 42.9764 42.4737 30.0527 42.0279 26.7035ZM17.411 26.2442C18.2522 22.7933 18.6672 17.0374 17.9538 12.9372C16.6554 5.47865 13.8922 1.15515 10.1522 0.128609C2.17541 -1.10152 1.17399 6.74905 0.972067 12.7342C0.526314 16.0828 3.2162 29.0073 6.49129 31.4411C9.71462 30.5852 12.8797 29.7466 16.0782 28.9876C16.9034 28.5263 16.642 28.6757 17.411 26.2442ZM7.26812 34.1209C8.76617 54.0528 26.6718 46.1372 16.9584 31.0448C13.8836 32.1533 11.1689 32.8591 7.26812 34.1209Z"
            fill={color} />
      </svg>
  );
};

export default FootstepsIcon;