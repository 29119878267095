import Employees from "component/UpsertEmployee";
import React from 'react';

function EmployeesWrapper() {

  return (
      <div className="home">
        <Employees/>
      </div>
  );
}

export default EmployeesWrapper;
