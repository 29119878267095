import "@ungap/url-search-params";
import App from 'App';
import "globalthis/auto";
import "i18n";
import i18n from "i18next";
import 'index.css';
import moment from "moment";
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/it';
import React, {Suspense} from 'react';
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";
import reportWebVitals from 'reportWebVitals';
import 'semantic-ui-css/semantic.min.css';
import {supportedLanguages} from "util/localizationUtils";

/**
 * Set dummy locale namespace to prevent unnecessary calls for fetching resources.
 */
supportedLanguages.forEach(lang => {
  i18n.addResourceBundle(lang, 'default', {
    key: 'value'
  }, true, true);
});

/**
 * set default locale options to be shared for all supported locales:
 *   o use 24h time format
 *   o the first day of the week is Monday
 */
supportedLanguages.forEach(locale => {
  moment.updateLocale(locale, {
    longDateFormat: {
      LT: 'HH:mm',
      LTS: 'HH:mm:ss',
      L: 'DD/MM/YYYY',
      LL: 'D MMMM YYYY',
      LLL: 'D MMMM YYYY HH:mm',
      LLLL: 'dddd, D MMMM YYYY HH:mm'
    },
    week: {
      dow: 1, // Monday is the first day of the week.
      doy: 4  // The week that contains Jan 4th is the first week of the year.
    }
  });
});
// override the weekdaysShort values for "de" with the ones from "de_ch"
moment.updateLocale("de", {
  weekdaysShort: 'So_Mo_Di_Mi_Do_Fr_Sa'.split('_')
});

moment.updateLocale("fr", {
  weekdaysShort: 'lu_ma_me_je_ve_sa_di'.split('_')
});

moment.updateLocale("it", {
  weekdaysShort: 'lu_ma_me_gi_ve_sa_do'.split('_')
});

const defaultLang = "de";
moment.updateLocale(defaultLang, {});

const Loader = () => (
    <div style={{margin: "2rem auto", color: "#777777"}}>Loading...</div>
);

ReactDOM.render(
    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
      <Suspense fallback={<Loader />}>
        <App />
      </Suspense>
    </BrowserRouter>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
