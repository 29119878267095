import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router";
import {Button, Icon} from "semantic-ui-react";
import axios from "service/http";
import {getEmployeeTokenData, registerCertificate} from "service/testKitServices";
import styled from "styled-components";
import {InterventionExternalCode} from "ts-types/api.enums";
import {EmployeeTokenDataDto} from "ts-types/api.types";
import {isoToDisplayDate} from "util/dateUtils";

const StyledContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  max-width: 768px;

  .info-text {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }

  video {
    border: 2px solid;
  }

  .snapshot-container {
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;

    .image-input {
      display: none;
    }

    .snapshot-placeholder {
      display: flex;
      flex-direction: column;
      max-width: 80vh;
      min-width: 30vh;
      min-height: 20vh;
      align-items: center;
      border: 1px solid #e5e5e5;
      padding-top: 1rem;

      i.icon {
        color: #e5e5e5;
      }
    }

    .take-snapshot-action {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
      align-items: center;

      i.icon {
        color: #768aff;
      }

      .action-label {
        color: #293569;
        font-size: 1.1rem;
      }
    }

    .image-box {
      max-width: 90%;
      max-height: 50vh;
      margin: 10px;
      text-align: center;

      img {
        height: inherit;
        max-height: inherit;
        max-width: inherit;
      }
    }
  }

  .radio-box {
    margin: 2rem 0;

    .field-label {
      margin-right: 2rem;
    }

    .field {
      display: inline-block;
      margin-right: 1.5rem;
    }
  }

  .result {
    margin: 1rem 0 2.5rem;
    font-size: 1.2rem;
    font-weight: 500;
  }

  .success {
    background-color: #BBF3BA;
    padding: 1rem;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    width: 100%;
  }

  .error {
    background-color: #FFCCCC;
    padding: 1rem;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    width: 100%;
  }

  .info-message {
    margin: 1rem 0;
    font-size: 1.2rem;
  }
`;

interface InfoRowProps {
  backgroundColor?: string;
}

const StyledInfoRow = styled.div`
  background-color: ${(props: InfoRowProps) => props.backgroundColor || 'inherited'};
  width: 100%;
  display: flex;
  padding: 0.5rem;

  *:first-child {
    width: 150px;
  }

  *:nth-child(2) {
    flex: 1;
  }

  &.target-employee {
    margin-bottom: 1.5rem;
  }
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;

  .ui.button {
    margin-right: 0.75rem;
  }
`;

type CertificateType = "recovery" | "vaccination" | "externalTestDocument";

interface CertificateImageUploadProps {
  certificateType: CertificateType;
}

const certificateTypeToExternalCodeMap : {[key : string] : InterventionExternalCode} = {
  "vaccination" : InterventionExternalCode.COVID_CERTIFICATE_OF_VACCINATION,
  "recovery" : InterventionExternalCode.COVID_CERTIFICATE_OF_RECOVERY,
  "externalTestDocument" : InterventionExternalCode.COVID_CERTIFICATE_EXTERNAL_TEST_DOCUMENT
}

const toExternalCode = (certificateType: CertificateType) : InterventionExternalCode => {
  return certificateTypeToExternalCodeMap[certificateType];
}

interface CertificateImageUploadParams {
  token: string;
}

type PageState = "ENTRY" | "SUCCESS" | "ERROR" | "FILE_SIZE_EXCEEDED";

const CertificateImageUpload = (props: CertificateImageUploadProps) => {

  const {t} = useTranslation('login');
  const {token} = useParams<CertificateImageUploadParams>();

  const [cancelTokenSource/*, setCancelTokenSource*/] = React.useState(axios.CancelToken.source());
  const [pageState, setPageState] = React.useState("ENTRY" as PageState);
  const [tokenData, setTokenData] = React.useState({} as Partial<EmployeeTokenDataDto>);

  useEffect(() => {
    getEmployeeTokenData(token, cancelTokenSource)
    .then(response => {
      setTokenData(response);
    });
    // eslint-disable-next-line
  }, []);

  const [testResultSnapshot, setTestResultSnapshot] = useState(undefined);
  const [source, setSource] = useState("");

  const handleCapture = (target: any) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];

        if (file.size <= 10485760) {
          setTestResultSnapshot(file);
          const newUrl = URL.createObjectURL(file);
          setSource(newUrl);
        } else {
          setPageState("FILE_SIZE_EXCEEDED");
        }
      }
    }
  };

  const onUploadTestResult = async () => {
    try {
      const certificateType = toExternalCode(props.certificateType);

      await registerCertificate(
          token, certificateType, testResultSnapshot, cancelTokenSource);

      setPageState("SUCCESS");
    } catch (e) {
      if (e.message === "Network Error") {
        setPageState("FILE_SIZE_EXCEEDED");
      } else {
        setPageState("ERROR");
      }
    }
  };

  const takeSnapshotKey = source
      ? "certificateSnapshot.action.retakeSnapshot"
      : "certificateSnapshot.action.takeSnapshot";

  const titleKey = `certificateSnapshot.title.${props.certificateType}`;

  const renderTopPageData = () => <>
    <StyledInfoRow backgroundColor="#e5e5e5">
      <span>{t("testkitToken.content.institution")}</span>
      <span><b>{tokenData.companyName}, {tokenData.companyZip} {tokenData.companyCity}</b></span>
    </StyledInfoRow>
    <StyledInfoRow>
      <span>{t("testkitToken.content.administrator")}</span>
      <span>
                    <b>
                        {`${tokenData.sourceEmployeeFirstName} ${tokenData.sourceEmployeeLastName}`}, {`${tokenData.sourceEmployeeEmail}`}
                    </b>
                </span>
    </StyledInfoRow>

    <h1>{t(titleKey)}</h1>

    <StyledInfoRow className="target-employee" backgroundColor="#e5e5e5">
      <span>{t("testkitToken.content.employee")}</span>
      <span><b>{`${tokenData.targetEmployeeFirstName} ${tokenData.targetEmployeeLastName}`}, {`${isoToDisplayDate(tokenData.targetEmployeeBirthDate)}`}</b></span>
    </StyledInfoRow>
  </>;

  const renderEntryState = () => <>
    {renderTopPageData()}

    <div className="snapshot-container">
      <input
          accept="image/*"
          className="image-input"
          id="icon-button-file"
          type="file"
          capture="environment"
          onChange={(e) => handleCapture(e.target)}
      />

      <label htmlFor="icon-button-file">
        {
          source
              ?
              <div className="image-box">
                <img src={source} alt={"snap"} className="" />
              </div>
              :
              <div className="snapshot-placeholder">
                <Icon.Group size="huge">
                  <Icon name="flask" />
                  <Icon corner="top right" name="address card outline" />
                </Icon.Group>
              </div>
        }
      </label>

      <label htmlFor="icon-button-file">
        <div className="take-snapshot-action">
          <Icon name="camera" size="large" />
          <div className="action-label">
            {t(takeSnapshotKey)}
          </div>
        </div>
      </label>
    </div>

    <StyledButtonContainer>
      <Button
          type="button"
          className="action-button"
          primary
          onClick={onUploadTestResult}
          disabled={!testResultSnapshot}
      >
        {t('certificateSnapshot.action.register')}
      </Button>
    </StyledButtonContainer>
  </>;

  const renderSuccess = () => <>
    <div className="success">
      {t("certificateSnapshot.message.success")}
    </div>
    {renderTopPageData()}
    <div className="info-message">
      {t("rapidTestkitSnapshot.message.backToWeb")}
    </div>
  </>;

  const renderError = () => <>
    <div className="error">
      {t("rapidTestkitSnapshot.message.error")}
    </div>
    {renderTopPageData()}

    <div className="info-message">
      {t("rapidTestkitSnapshot.message.somethingWentWrong")}
    </div>

    <StyledButtonContainer>
      <Button
          type="button"
          className="action-button"
          color="grey"
          onClick={() => setPageState("ENTRY")}
      >
        {t('rapidTestkitSnapshot.action.registerAgain')}
      </Button>
    </StyledButtonContainer>
  </>;

  const renderFileSizeExceeded = () => <>
    <div className="error">
      {t("rapidTestkitSnapshot.message.fileSizeExceeded")}
    </div>
    {renderTopPageData()}

    <div className="info-message">
      {t("rapidTestkitSnapshot.message.smallerImage")}
    </div>

    <StyledButtonContainer>
      <Button
          type="button"
          className="action-button"
          color="grey"
          onClick={() => setPageState("ENTRY")}
      >
        {t('rapidTestkitSnapshot.action.registerAgain')}
      </Button>
    </StyledButtonContainer>
  </>;


  return <StyledContainer>
    {
      pageState === "ENTRY" &&
      renderEntryState()
    }
    {
      pageState === "SUCCESS" &&
      renderSuccess()
    }
    {
      pageState === "ERROR" &&
      renderError()
    }
    {
      pageState === "FILE_SIZE_EXCEEDED" &&
      renderFileSizeExceeded()
    }
  </StyledContainer>;
};

export default CertificateImageUpload;
