import {AuthConsumerRenderProps, withAuthContext} from 'auth/AuthContext';
import axios, {CancelTokenSource} from 'axios';
import FinalFormVerificationInput from "component/final-form/VerificationInput";
import {FormApi} from 'final-form';
import React, {Component, Fragment} from "react";
import {Field, Form as FinalForm, FormRenderProps} from 'react-final-form';
import {withTranslation, WithTranslation} from "react-i18next";
import {Button, Modal} from 'semantic-ui-react';
import {enable2fa, get2faData} from "service/userServices";
import styled from 'styled-components';

const TwoFactorAuthModalContainer = styled.div`
  padding: 0.75rem 1rem;
  
  .form-grid {
    margin-bottom: 1.2rem;

    .label {
      display: inline-block;
      margin: 0 0.5rem 0.5rem;
    }

    .verification-input__character {
      text-align: center;
    }

    .error {
      margin-top: 0.75rem;
      margin-left: 0.5rem;
      color: darkred;
    }
  }
  
  .actions-row {

    .success {
      color: darkgreen;
      display: inline-block;
      margin-left: 0.5rem;
    }
    
    button {
      margin-right: 0.75rem;
    }
  }
`;

interface Props extends WithTranslation, AuthConsumerRenderProps {
  modalOpen: boolean;
  onCloseModal: () => void;
  onInit2faSuccess: () => void;
}

interface State {
  initialized: boolean,
  verificationCodeKey: number,
  error?: string,
  showUpdateSuccess: boolean,
  cancelTokenSource: CancelTokenSource
}

interface TwoStepAuthValues {
  use2fa: boolean;
  qrcodeUrl: string;
  verificationCode: string;
}

class TwoStepAuthModal extends Component<Props, State> {

  initialValues: TwoStepAuthValues;

  constructor(props: Props) {
    super(props);

    const cancelTokenSource = axios.CancelToken.source();

    this.state = {
      initialized: false,
      verificationCodeKey: 1,
      error: undefined,
      showUpdateSuccess: false,
      cancelTokenSource
    };

    this.initialValues = {
      use2fa: false,
      qrcodeUrl: "",
      verificationCode: ""
    };

    this.get2faData();
  }

  get2faData = async () => {

    const {currentUser} = this.props;
    const {cancelTokenSource} = this.state;

    if (currentUser) {
      const qrcodeImage = await get2faData(cancelTokenSource);

      this.initialValues = {
        use2fa: true,
        qrcodeUrl: qrcodeImage,
        verificationCode: ""
      };

      this.setState({
        initialized: true
      });
    }
  };

  handleSubmit = async (
      values: Partial<TwoStepAuthValues>,
      form: FormApi<Partial<TwoStepAuthValues>, TwoStepAuthValues>) => {

    try {
      await enable2fa(values.verificationCode!, this.state.cancelTokenSource);
    } catch (ex) {
      let error = "error.general";
      const {response} = ex;
      if (!!response.data && !!response.data.error) {

        let {error} = response.data;

        if (["INVALID_VERIFICATION_CODE"].includes(error)) {
          error = `init2fa.modal.error.${error}`;
        }
      }

      this.setState({
        verificationCodeKey: this.state.verificationCodeKey + 1,
        error: error
      });

      form.change("verificationCode", undefined);

      setTimeout(() => {
        (document.getElementsByClassName("verification-input")[0]! as any).focus();
      }, 50);

      return;
    }

    this.setState({
      showUpdateSuccess: true
    });
    setTimeout(() => {
      this.props.onInit2faSuccess();
    }, 1000);

  };

  render(): React.ReactNode {

    const {initialized} = this.state;
    const {modalOpen, onCloseModal, t} = this.props;

    return (
        <Modal
            open={modalOpen}
            onClose={onCloseModal}
            style={{width: "32rem"}}
            closeOnDimmerClick={false}
        >
          <Modal.Header>{t("init2fa.modal.title")}</Modal.Header>
          <TwoFactorAuthModalContainer>
            {
              initialized
                  ?
                  <FinalForm
                      onSubmit={this.handleSubmit}
                      subscription={{pristine: true, submitting: true, values: true}}
                      render={this.renderForm}
                      initialValues={this.initialValues}
                  />
                  :
                  <></>
            }
          </TwoFactorAuthModalContainer>
        </Modal>
    );
  }

  renderForm = ({form, submitting, handleSubmit}: FormRenderProps): React.ReactNode => {

    const {t, onCloseModal} = this.props;

    const values = form.getState().values;

    return (
        <form onSubmit={handleSubmit}>
          <div>
            <div className="form-grid">
              {/*<div>
                  <Field
                      name="use2fa"
                      component={FinalFormCheckbox}
                      label={t('init2fa.modal.field.use2fa')}
                      toggle
                  />
                </div>*/}
              {
                values.use2fa &&
                <Fragment>
                  <div>
                    <img src={values.qrcodeUrl} alt="QR code" />
                  </div>

                  <div>
                    <label htmlFor="verificationCode" className="label">
                      {t("init2fa.modal.verificationCode.label")}
                    </label>
                    <Field
                      key={this.state.verificationCodeKey}
                      name="verificationCode"
                      component={FinalFormVerificationInput}
                      autoFocus={true}
                    />
                  </div>
                  {
                    this.state.error &&
                    <div className="error">
                      {t(this.state.error)}
                    </div>
                  }
                </Fragment>
              }
            </div>

            <div className="actions-row">
              <Button compact type="submit" primary disabled={submitting}>{t("button.save")}</Button>
              <Button compact type="button" disabled={submitting}
                      onClick={onCloseModal}>{t("action.cancel")}</Button>
              {
                this.state.showUpdateSuccess &&
                <div className="success">{t("init2fa.modal.success")}</div>
              }
            </div>
          </div>
        </form>
    );
  };
}

export default withAuthContext(withTranslation(["mipoco"])(TwoStepAuthModal));
