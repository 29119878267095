import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import React, {ChangeEvent, Component, FunctionComponent} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {RouterProps, withRouter} from "react-router";
import LoginView from "route/login/LoginView";
import {Button, Input, InputOnChangeData} from "semantic-ui-react";
import styled from "styled-components";
import {EmployeeAccessTokenType} from "ts-types/api.enums";
import {PasswordlessLoginRequestDto} from "ts-types/api.types";
import {isKeyCheck} from "util/keyUtils";
import {insertLink} from "util/localizationUtils";
import {emailValidator} from "util/validatorUtils";

const ContainerDiv = styled.div`

  .email-form {
    display: grid;
    grid-template-columns: 150px minmax(10rem, 25rem);

    grid-column-gap: 1rem;
    grid-row-gap: 0.25rem;

    margin-top: 2.5rem;

    label {
      align-self: center;
    }

    .error {
      grid-column-start: 2;
      justify-self: start;
      color: darkred;
      font-size: 1rem;
      margin: 0.3rem 0 0.5rem 0;

      a.text-link {
        color: darkred;
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .action-button {
      grid-column-start: 2;
      justify-self: start;
      margin-top: 0.75rem;
    }
  }

  @media only screen and (max-width: 767px) {
    .email-form {
      display: flex;
      flex-direction: column;

      label {
        align-self: flex-start;
      }

      .action-button {
        display: block;
        width: 100%;
      }
    }
  }
`;

const InfoDiv = styled.div`
  max-width: 40rem;
  margin-bottom: 1.5rem;
`;


interface TextLinkProps extends AuthConsumerRenderProps, RouterProps {

}

const TextLinkUi: FunctionComponent<TextLinkProps> = (props) => {

  const {accessType, accessContainer} = props;

  let link = accessType === EmployeeAccessTokenType.PERSON ? "/person-onboard" : "/company-onboard";
  if (accessContainer) {
    link = `/container/person-onboard`;
  }

  return <a className="text-link"
            href="#/"
            onClick={() => {
              props.history.push(link);
            }}
  >
    {props.children}
  </a>;
};

const TextLink = withRouter(withAuthContext(TextLinkUi));


interface Props extends AuthConsumerRenderProps, WithTranslation {
  accessType: EmployeeAccessTokenType;
  container?: string;
  onRequestLoginLink: (request: PasswordlessLoginRequestDto) => void;
}

interface State {
  email: string;
  error?: string;
}

class LoginEmailSignin extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      email: "",
      error: undefined
    };
  }

  setEmail = (evt: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    this.setState({
      email: data.value
    });
  };

  onEmailKeyDown = (event: any) => {
    event.persist();
    const isKey: any = isKeyCheck(event);

    if (isKey.enter) {
      this.requestLoginLink();
    }
  };

  requestLoginLink = async () => {
    const {email} = this.state;

    if (!email) {
      this.setState({
        error: "login.emailSignin.validationError.required"
      });
      return;
    } else if (emailValidator(email)) {
      this.setState({
        error: "login.emailSignin.validationError.email"
      });
      return;
    }

    // let result: string | undefined = undefined;
    try {
      /*result = */
      await this.props.onRequestLoginLink({
        email,
        type: this.props.accessType,
        container: this.props.container
      });
    } catch (ex) {
      this.setState({
        error: "login.emailSignin.error.invalidEmail"
      });
    }
  };

  render(): React.ReactNode {

    const {accessType, accessContainer, t} = this.props;
    const {error} = this.state;

    const accessTypePrefix = EmployeeAccessTokenType[accessType];
    const messageKey = accessContainer ? `container.${accessTypePrefix}` : accessTypePrefix;

    return <LoginView titleKey={`login.emailSignin.${messageKey}.title`}>
      <ContainerDiv>
        <InfoDiv>{t(`login.emailSignin.${messageKey}.intro`)}</InfoDiv>
        <InfoDiv>{t("login.emailSignin.company.emailInfo")}</InfoDiv>

        <div className="email-form">
          <label>{t("login.emailSignin.form.email")}</label>
          <Input
              placeholder=""
              value={this.state.email}
              onChange={this.setEmail}
              onKeyDown={(e: any) => this.onEmailKeyDown(e)}
          />

          {
            error &&
            <div className="error">
              {
                accessContainer
                    ? insertLink(t("login.emailSignin.error.invalidEmailWithOnboardLink"), TextLink)
                    : t(error)
              }
            </div>
          }

          <Button
              type="button"
              className="action-button"
              primary
              onClick={this.requestLoginLink}
          >
            {t("login.emailSignin.form.action.signin")}
          </Button>
        </div>
      </ContainerDiv>
    </LoginView>;
  }
}

export default withAuthContext(withTranslation(["login"])(LoginEmailSignin));
