import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import CheckBox from "component/final-form/CheckBox";
import MpGrid from "component/MpGrid";
import React, {useState} from "react";
import {Field, Form as FinalForm, FormRenderProps} from "react-final-form";
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";
import LoginView from "route/login/LoginView";
import {Button, Grid} from "semantic-ui-react";
import {setEmailConsent} from "service/userServices";
import styled from "styled-components";
import {withRouterWorkaround} from "util/workaroundUtils";

const StyledMpGrid = styled(MpGrid)`

  font-size: 1.1rem;

  &.ui.checkbox .box,
  &.ui.checkbox label {
    font-size: 1.1rem;
  }

  &.ui.grid > .row > .column {
    padding-right: 0;
    padding-left: 0;

    a.contract-document-url {
      color: rgba(0, 0, 0, .87);
      text-decoration: underline;
    }

    .ui.checkbox label:before {
      border-color: #7687FF;
      border-radius: unset;
    }

    button {
      margin-right: 1.5rem;
    }
  }

  @media only screen and (max-width: 767px) {
    &.ui.grid > .row > .column {
      .button {
        margin-bottom: 1rem;
        display: block;
        width: 100%;
      }
    }
  }
`;

const InfoDiv = styled.div`
  max-width: 47rem;
  margin-bottom: 1.5rem;
`;

interface Props extends RouteComponentProps<any>, WithTranslation, AuthConsumerRenderProps {

}

export type PageStatus =
    "WAIT_FOR_OUTCOME"
    | "INVALID_CODE"
    | "PATIENT_OPT_IN"
    | "PATIENT_OPT_OUT"
    | "ERROR";

const EmailConsentView = (props: Props) => {

  const {t} = props;

  const queryParams = new URLSearchParams(props.history.location.search);
  const queryToken = queryParams.get("token");

  const [status, setStatus] =
      useState("WAIT_FOR_OUTCOME" as PageStatus);

  const [token, setToken] = useState(queryToken as string | null)

  if (!queryToken) {
    setToken(null);
    setStatus("INVALID_CODE");
  }

  const goToLogin = async () => {

    props.history.push(`/login`);
  };


  const sendEmailConsent = (values: any) => {
    const emailConsent = values.confirmConsent;

    if (token) {
      setEmailConsent(token, emailConsent)
      .then(response => {
        setStatus(response as PageStatus);
      })
      .catch(e => {
        setStatus("ERROR");
      });
    } else {
      setStatus("INVALID_CODE");
    }
  }

  const title = status === "INVALID_CODE" ? "login.passwordlessLogin.title.invalidCode" : "emailConsent.title";

  const renderForm = ({handleSubmit, values}: FormRenderProps): React.ReactNode => {
    return (
        <LoginView titleKey={title}>
          <form onSubmit={handleSubmit}>
            {
                status === "WAIT_FOR_OUTCOME" &&
              <StyledMpGrid stackable>
                <Grid.Row style={{marginBottom: "2rem"}}>
                  <Grid.Column width={8}>
                    {t(`emailConsent.description`)}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Field
                      name="confirmConsent"
                      component={CheckBox}
                      label={t("emailConsent.confirm.checkbox")}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={10}>
                    <Button
                      type="button"
                      className="action-button"
                      primary
                        onClick={() => sendEmailConsent(values)}
                    >
                      {t("emailConsent.btn.save")}
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </StyledMpGrid>
            }
            {
                (status === "PATIENT_OPT_IN" || status === "PATIENT_OPT_OUT") &&
              <>
                <InfoDiv>
                  {t(`emailConsent.${status}.description`)}
                </InfoDiv>

                <div className="actions-row">
                  <Button
                    type="button"
                    className="action-button"
                    color={"grey"}
                    onClick={(evt) => goToLogin()}
                  >
                    {t("emailConsent.btn.gotoLogin")}
                  </Button>
                </div>
              </>
            }

            {
                status === "INVALID_CODE" &&
                <InfoDiv>
                  {t("login.passwordlessLogin.error.invalidCode")}
                </InfoDiv>
            }
            {
                status === "ERROR" &&
              <>
                <InfoDiv>
                  {t("emailConsent.error.general")}
                </InfoDiv>

                <div className="actions-row">
                  <Button
                    type="button"
                    className="action-button"
                    color={"grey"}
                    onClick={(evt) => goToLogin()}
                  >
                    {t("emailConsent.btn.gotoLogin")}
                  </Button>
                </div>
              </>
            }
          </form>
        </LoginView>
    )
  }

  return (
      <FinalForm
          onSubmit={() => {}}
          initialValues={{confirmConsent: false}}
          render={renderForm}
      />
  );
};

export default withRouterWorkaround(
    withAuthContext(
        withTranslation(["login"])(
            EmailConsentView)));

