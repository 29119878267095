import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import axios, {CancelTokenSource} from "axios";
import CompanyDataHeader from "component/CompanyDataHeader";
import ConfirmActionPopup from "component/ConfirmActionPopup";
import DeleteActionButton from "component/DeleteActionButton";
import {CheckboxSc} from "component/final-form/CheckBox";
import DatePicker from "component/final-form/DatePicker";
import Radio, {generateRadioButtonOptionsFromEnum} from "component/final-form/Radio";
import TimeInput from "component/final-form/TimeInput";
import HeaderMessage from "component/HeaderMessage";
import LoaderComponent from "component/LoaderComponent";
import MpGrid from "component/MpGrid";
import StyledErrorMessage from "component/StyledErrorMessage";
import TestResultIcon from "component/TestResultIcon";
import VirtualizedTable from "component/VirtualizedTable";
import {ValidationErrors} from "final-form";
import _ from "lodash";
import moment from "moment";
import React, {ChangeEvent, Component} from 'react';
import {Field, Form as FinalForm, FormRenderProps} from 'react-final-form';
import {withTranslation, WithTranslation} from "react-i18next";
import Lightbox from "react-image-lightbox";
import {RouteComponentProps} from "react-router";
import QrcodeActionButton from "route/test-registration/QrcodeActionButton";
import {
  Button,
  Grid,
  GridColumn,
  GridRow,
  Icon,
  Input,
  InputOnChangeData,
  Loader,
  Modal,
  RadioProps
} from "semantic-ui-react";
import {getAllInterventions} from "service/interventionServices";
import {
  createCertificateQrcodeBase64String,
  createRapidTestkitQrcodeBase64String,
  createTestkitQrcodeBase64String,
  deregisterTestkit,
  searchTestKitRegistrations,
  sendRegisteredTestkits
} from "service/testKitServices";
import {getTestResultImage} from "service/testResultServices";
import styled from "styled-components";
import {
  CompanyType,
  InterventionBookingStatus,
  InterventionExternalCode,
  SampleDateAndTimeMode
} from "ts-types/api.enums";
import {
  EmployeeTestResultImageDto,
  InterventionDto,
  SendTestkitRegistrationRequest,
  TestkitDeregistrationRequestDto,
  TestRegistrationOverviewDto,
  TestRegistrationQrCodeRequest
} from "ts-types/api.types";
import {fromIsoDateTimeString, isoToDisplayDate, toIsoDateString} from "util/dateUtils";
import {certificateExternalCodes} from "util/enumUtils";
import {errorUtils} from "util/errorUtils";
import {noop} from "util/functionUtils";
import {interventionDescription} from "util/interventionUtils";
import {isKeyCheck} from "util/keyUtils";
import {applyStyles} from "util/localizationUtils";
import {required} from "util/validatorUtils";
import {withRouterWorkaround} from "util/workaroundUtils";

const TestRegistrationDiv = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  .header-message-text {
    font-size: 1.5rem;
  }

  .search-form {
    padding-left: 0.75rem;
    margin-bottom: 1rem;

    label {
      margin-right: 1rem;
    }

    .ui.input {
      min-width: 15rem;
    }

    button {
      margin-left: 1rem;
    }
  }

  .selected-testkits {
    margin: 0 0.5rem 0.2rem;
    font-size: 0.978rem;
    color: #454545;
  }

  .results-table {
    flex: 1 1 auto;

    .row-actions {
      i.icon,
      i.icons {
        color: #768aff;
      }
    }

    .ui.checkbox label:before {
      border-color: #7687FF !important;
      border-radius: unset;
    }
  }

  .icon-button {
    padding: 2px !important;
    background: transparent !important;
  }

  .page-actions {
    justify-self: start;
    margin-top: 0.75rem;

    .actions-left {
      float: left;
    }

    .actions-right {
      float: right;

      .ui.button {
        margin-left: 0.5rem;
      }
    }
  }

  .error {
    margin-bottom: 1rem;
  }
`;

const SampleDateTimeContentDiv = styled.div`

  .ui.grid > .row.flex-grow {
    min-height: 12rem;
    min-width: 350px;
  }

  .actions-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;

    .ui.button {
      margin-left: 0.5rem;
    }
  }
`;

const QrContentDiv = styled.div`

  padding: 0.2rem;

  .employee-data {
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0 0 0.75rem 0.2rem;
    white-space: nowrap;
  }

  .qrcode-wrapper {
    display: inline-block;
    border: 1px solid #e5e5e5;
    padding: 1rem;
    margin-bottom: 0.5rem;

    .qrcode {
      background-size: contain;
      width: 150px;
      height: 150px;
    }
  }

  .actions-row {
    margin: 0.75rem 0;

    .ui.button {
      border-radius: unset;
    }
  }

  .link-info {
    color: #407A8C;
    margin: 0.75rem 0.1rem 0;
    padding-top: 0.5rem;
    border-top: 1px solid #e5e5e5;
  }
`;

interface Props extends RouteComponentProps<any> {

}

interface Props extends RouteComponentProps<any>, AuthConsumerRenderProps, WithTranslation {
}

interface State {
  query: string,
  testKitRegistrations: TestRegistrationOverviewDto[],
  qrcodeb64?: string,
  testRegistrationUrl?: string,
  qrcodeVisible: boolean,
  testRegistrationsLoaded: boolean,
  numberOfSentTestkitRegistrations?: number,
  selectedTestRegs: Array<number>,
  resultImage?: EmployeeTestResultImageDto,
  interventions: InterventionDto[],
  selectDateAndTimeModalOpen: boolean,
  sampleDateAndTimeOptions: RadioProps[],
  errorMessages: Array<string>,
  cancelTokenSource: CancelTokenSource;
}

class TestRegistration extends Component<Props, State> {

  initialSampleDateValues: any = {};

  constructor(props: Props) {
    super(props);

    const cancelTokenSource = axios.CancelToken.source();

    this.state = {
      query: "",
      testKitRegistrations: [],
      testRegistrationsLoaded: true,
      qrcodeVisible: false,
      selectedTestRegs: [],
      interventions: [],
      selectDateAndTimeModalOpen: false,
      sampleDateAndTimeOptions: generateRadioButtonOptionsFromEnum(
          Object.values(SampleDateAndTimeMode),
          "testKitCodeSampleDateAndTime",
          props.t,
          "SampleDateAndTimeMode"),
      errorMessages: [],
      cancelTokenSource
    };

    setTimeout(() => {
      this.fetchTestKitRegistrations();
    }, 5);
  }

  fetchTestKitRegistrations = async () => {
    const {currentUser} = this.props;
    const mandateId = currentUser ? currentUser.mandateId : 1;
    const {query, cancelTokenSource} = this.state;

    const onFinally = () => {
      this.setState({
        testRegistrationsLoaded: true
      });
    };

    this.setState({
      testRegistrationsLoaded: false
    });

    await getAllInterventions(cancelTokenSource)
    .then(response => {
      this.setState({
        interventions: response
      });
    })
    .catch((e: any) => this.handleError(e.response.data))
    .finally(noop);

    await searchTestKitRegistrations(query, cancelTokenSource)
    .then(response => {
      this.setState({
        testKitRegistrations: response,
        selectedTestRegs: []
      });
    })
    .catch((e: any) => this.handleError(e.response.data))
    .finally(onFinally);

  };

  handleError(error: any) {
    const {t} = this.props;

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        "SAMPLE_DATE_CANT_BE_THE_FUTURE",
        errorUtils.unableToRetrieveTestAttachment
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            this.setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!this.state.errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          this.setErrorMessage(t(`error.${errorCode}`));
        } else {
          this.setErrorMessage(t('error.general'));
        }
      }
    }
  };

  setErrorMessage = (errorMessage?: string) => {

    const {errorMessages} = this.state;

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);

      this.setState({
        errorMessages: errMsgs
      });
    } else {

      this.setState({
        errorMessages: []
      });
    }
  };

  selectTestRegistration = (index: number) => {

    const {testKitRegistrations, selectedTestRegs} = this.state;

    let testKitRegistration = testKitRegistrations[index];

    if (testKitRegistration && testKitRegistration.bookingStatus === InterventionBookingStatus.EXECUTED) {

      let selected = [...selectedTestRegs];

      if (selectedTestRegs.includes(testKitRegistration.covidTestId)) {
        selected = _.remove(selected, function (n) {
          return n !== testKitRegistration.covidTestId;
        });
      } else {
        selected.push(testKitRegistration.covidTestId);
      }

      this.setState({
        selectedTestRegs: selected
      });
    }
  };

  showSelectDateAndTimeModal = () => {
    this.initialSampleDateValues = {
      sampleDate: toIsoDateString(moment()),
      sampleTime: moment().format("HH:mm"),
      sampleDateAndTimeMode: SampleDateAndTimeMode.REGISTRATION_TIME
    };
    this.setState({
      selectDateAndTimeModalOpen: true
    });
  };

  hideSelectDateAndTimeModal = () => {
    this.setState({
      selectDateAndTimeModalOpen: false
    });
  };

  sendExecutedRegisteredTestkits = (values?: any, customSampleDateTime: boolean = false) => {

    const {selectedTestRegs, cancelTokenSource} = this.state;
    const {currentUser} = this.props;

    if (currentUser && currentUser.companyId) {

      const request: Partial<SendTestkitRegistrationRequest> = {
        covidTestIds: selectedTestRegs
      };

      const withCustomSampleDateTime = customSampleDateTime && values
          && values.sampleDateAndTimeMode === SampleDateAndTimeMode.CUSTOM_TIME;
      if (withCustomSampleDateTime) {
        request.sampleDate = values.sampleDate;
        request.sampleTime = values.sampleTime;
      }

      sendRegisteredTestkits(request, cancelTokenSource)
      .then(response => {
        this.setState({
          selectedTestRegs: [],
          numberOfSentTestkitRegistrations: response
        });
      })
      .catch((e: any) => this.handleError(e.response.data))
      .finally(() => {
        this.hideSelectDateAndTimeModal();
        this.fetchTestKitRegistrations();
      });
    }
  };

  onRegisterPCRTestKitManually = (employeeData: any) => {
    this.props.history.push("/testkit-registration/manual",
        {...employeeData, testType: InterventionExternalCode.PCR_SALIVA_TEST});
  };

  onRegisterHPVTestKitManually = (employeeData: any) => {
    this.props.history.push("/testkit-registration/manual",
        {...employeeData, testType: InterventionExternalCode.HPV_TEST});
  };

  onRegisterChlamydiaTestKitManually = (employeeData: any) => {
    this.props.history.push("/testkit-registration/manual",
        {...employeeData, testType: InterventionExternalCode.CHLAMYDIA_TEST});
  };

  onRegisterTrichomoniasisTestKitManually = (employeeData: any) => {
    this.props.history.push("/testkit-registration/manual",
        {...employeeData, testType: InterventionExternalCode.TRICHOMONIASIS_TEST});
  };

  onDeregisterTestKit = async (employeeId: number, interventionBookingId: number) => {
    const request: TestkitDeregistrationRequestDto = {
      employeeId: employeeId,
      companyId: -1,
      interventionBookingId: interventionBookingId
    };
    await deregisterTestkit(request, this.state.cancelTokenSource);
    this.fetchTestKitRegistrations();
  };

  setQuery = (evt: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    this.setState({
      query: data.value
    });
  };

  onQueryKeyDown = (event: any) => {
    event.persist();
    const isKey: any = isKeyCheck(event);

    if (isKey.enter) {
      this.fetchTestKitRegistrations();
    }
  };

  showTestResultImage = (testResultImageId: string | null) => {
    if (testResultImageId) {
      getTestResultImage(testResultImageId, this.state.cancelTokenSource)
      .then(response => {
        this.setState({
          resultImage: response
        });
      })
      .catch((e) => this.handleError(e.response.data));
    }
  };

  render(): React.ReactNode {

    const {t, currentUser} = this.props;
    const {
      query,
      errorMessages,
      testRegistrationsLoaded,
      testKitRegistrations,
      selectedTestRegs,
      numberOfSentTestkitRegistrations,
      resultImage,
      selectDateAndTimeModalOpen
    } = this.state;

    const state: any = this.props.location.state;
    const registeredTestkitCode = (state && state.registeredTestkitCode) ? state.registeredTestkitCode : undefined;

    const countSelectedTestkits = selectedTestRegs.length;
    const countSelectableTestkits =
        testKitRegistrations.filter(tr => tr.bookingStatus === InterventionBookingStatus.EXECUTED).length;

    const companyType = currentUser ? currentUser.companyType : "";

    return <TestRegistrationDiv className="test-registration">

      {
          registeredTestkitCode &&
        <HeaderMessage visible>
          <div className="header-message-text">
            {applyStyles(t("testKitRegistration.success.registrationSuccess",
                {code: registeredTestkitCode}))}
          </div>
        </HeaderMessage>
      }

      {
          numberOfSentTestkitRegistrations && numberOfSentTestkitRegistrations > 0 &&
        <HeaderMessage visible>
          <div className="header-message-text">
            {applyStyles(t("testKitRegistration.success.testRegistrationsSent",
                {numOfTestkits: numberOfSentTestkitRegistrations}))}
          </div>
        </HeaderMessage>
      }

      <CompanyDataHeader />

      <div className="title-h1">{t("testKitRegistration.title")}</div>

      {errorMessages.length > 0 &&
        <div className="error">
          <StyledErrorMessage onDismiss={() => this.setErrorMessage()}>
            {errorMessages.map(err => <div key={err}>{err}</div>)}
          </StyledErrorMessage>
        </div>
      }

      <div className="search-form">
        <label>{t("testKitRegistration.search")}</label>
        <Input
            placeholder=""
            value={query}
            onChange={this.setQuery}
            onKeyDown={(e: any) => this.onQueryKeyDown(e)}
        />

        <Button
            type="button"
            className="action-button"
            primary
            onClick={this.fetchTestKitRegistrations}
        >
          {t("testKitRegistration.searchButton")}
        </Button>
      </div>

      <div className="selected-testkits">
        {t("testKitRegistration.selectedTestkits")}:&nbsp;
        <strong>{countSelectedTestkits}/{countSelectableTestkits}</strong>
      </div>

      <div className="results-table">
        {!testRegistrationsLoaded
            ? <LoaderComponent message={t("employeeDashboard.table.loading")} />
            : this.renderTestKitRegistrationsTable()
        }
      </div>

      <div className="page-actions">
        <div className="actions-right">
          {
            companyType && companyType === CompanyType.SCHOOL.toString()
                ? <Button
                    type="button"
                    className="action-button"
                    onClick={() => this.showSelectDateAndTimeModal()}
                    primary
                    icon={{name: "shipping fast"}}
                    disabled={selectedTestRegs.length < 1}
                    content={t("testKitRegistration.button.sendTestkits")}
                />
                : <ConfirmActionPopup
                    message={t("testKitRegistration.popup.sendTestkits",
                        {
                          numberOfExecutedTestkits: selectedTestRegs.length
                        })}
                    renderContent={undefined}
                    renderTarget={<Button
                        type="button"
                        className="action-button"
                        onClick={() => noop()}
                        primary
                        icon={{name: "shipping fast"}}
                        disabled={selectedTestRegs.length < 1}
                        content={t("testKitRegistration.button.sendTestkits")}
                    />}
                    disabledYes={selectedTestRegs.length === 0}
                    onConfirmAction={() => this.sendExecutedRegisteredTestkits()}
                />
          }

          <Button
              type="button"
              className="action-button"
              color={"grey"}
              onClick={(evt) => this.props.history.push("/")}
          >
            {t("action.back")}
          </Button>
        </div>
      </div>

      {resultImage && <Lightbox
        mainSrc={`data:${resultImage.testResultFileType};base64, ${resultImage.testResultImage}`}
        onCloseRequest={() => this.setState({resultImage: undefined})}
      />}

      {selectDateAndTimeModalOpen && this.renderDateAndTimeModal()}
    </TestRegistrationDiv>;
  }

  renderDateAndTimeModal(): React.ReactNode {

    const {t} = this.props;
    const {selectDateAndTimeModalOpen} = this.state;

    return (
        <Modal
            open={selectDateAndTimeModalOpen}
            onClose={this.hideSelectDateAndTimeModal}
            style={{borderRadius: "unset"}}
            closeOnDimmerClick={false}
        >
          <Modal.Header>{t("testKitRegistration.selectSampleDateAndTimeModal.header")}</Modal.Header>
          <Modal.Content>
            <SampleDateTimeContentDiv>
              <FinalForm
                  onSubmit={(values) => this.sendExecutedRegisteredTestkits(values, true)}
                  render={this.renderSelectDateAndTimeForm}
                  initialValues={this.initialSampleDateValues}
                  validate={this.sampleDateNotInFuture}
              />
            </SampleDateTimeContentDiv>
          </Modal.Content>
        </Modal>
    );
  }

  renderSelectDateAndTimeForm = ({handleSubmit, form}: FormRenderProps): React.ReactNode => {

    const {t} = this.props;
    const {sampleDateAndTimeOptions} = this.state;

    const values = form.getState().values;
    const errors = form.getState().errors;
    const sampleDateError = errors?.sampleDate && errors?.sampleDate === "sampleDateNotInFuture";

    return (
        <form onSubmit={handleSubmit}>
          <MpGrid>
            <GridRow>
              <GridColumn width={4} verticalAlign="middle">{t("testkitRegistration.radioButtonsHeader")}:</GridColumn>
              <GridColumn width={4} className="vertical-radio" verticalAlign="middle">
                <Field
                    name="sampleDateAndTimeMode"
                    component={Radio}
                    radioDefinition={sampleDateAndTimeOptions[0]}
                />
              </GridColumn>
              <GridColumn width={4} className="vertical-radio" verticalAlign="middle">
                <Field
                    name="sampleDateAndTimeMode"
                    component={Radio}
                    radioDefinition={sampleDateAndTimeOptions[1]}
                />
              </GridColumn>
            </GridRow>
            {values.sampleDateAndTimeMode === SampleDateAndTimeMode.CUSTOM_TIME &&
              <>
                <Grid.Row>
                  <Grid.Column width={3} verticalAlign="middle">
                    <b>{t("testKitRegistration.selectSampleDateAndTimeModal.dateLabel")}:</b>
                  </Grid.Column>
                  <Grid.Column width={5} verticalAlign="middle">
                    <Field
                      fluid
                      name="sampleDate"
                      emptyDateInput
                      component={DatePicker}
                      validate={required}
                    />
                  </Grid.Column>
                  <Grid.Column width={3} verticalAlign="middle">
                    {t("testKitRegistration.selectSampleDateAndTimeModal.timeLabel")}:
                  </Grid.Column>
                  <Grid.Column width={4} verticalAlign="middle">
                    <Field
                      fluid
                      name="sampleTime"
                      component={TimeInput}
                      validate={required}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="flex-grow">
                  <Grid.Column width={16}>
                    {
                        sampleDateError &&
                      <div className="error">
                        <StyledErrorMessage>
                          {t(`testKitRegistration.selectSampleDateAndTimeModal.error.sampleDateNotInFuture`)}
                        </StyledErrorMessage>
                      </div>
                    }
                  </Grid.Column>
                </Grid.Row>
              </>
            }
            <Grid.Row>
              <div className="actions-row">
                <Button
                    compact
                    type="submit"
                    primary
                >
                  {t("testKitRegistration.button.sendTestkits")}
                </Button>
                <Button
                    compact
                    type="button"
                    onClick={this.hideSelectDateAndTimeModal}
                >
                  {t("action.back")}
                </Button>
              </div>
            </Grid.Row>
          </MpGrid>
        </form>
    );
  };

  sampleDateNotInFuture = (allValues: any): ValidationErrors => {
    if (!allValues.sampleDate || !allValues.sampleTime) {
      return;
    }

    let sampleDateTime = undefined;
    if (allValues.sampleDate && allValues.sampleTime) {
      sampleDateTime = fromIsoDateTimeString(`${allValues.sampleDate}T${allValues.sampleTime}`);
    } else if (allValues.sampleDate) {
      sampleDateTime = allValues.sampleDate;
    }

    if (sampleDateTime && sampleDateTime.isAfter(moment())) {
      return {
        sampleDate: "sampleDateNotInFuture"
      };
    }
  };

  renderTestKitRegistrationsTable = (): JSX.Element => {

    const {t} = this.props;
    const {testKitRegistrations} = this.state;

    return (
        <VirtualizedTable
            rowCount={testKitRegistrations.length}
            rowGetter={this.testKitRegistrationsRowGetter}
            rowRenderer={this.testKitRegistrationsRowRenderer}
            columns={[
              {
                width: 60,
                label: this.selectHeaderColumn(),
                dataKey: "index",
                cellRenderer: this.selectableTestRegistrationSelectCellRenderer
              },
              {
                width: 200,
                label: t("testKitRegistration.tableHeader.employee"),
                dataKey: "index",
                flexGrow: 2,
                cellRenderer: this.employeeNameCellRenderer
              },
              {
                width: 100,
                label: (t("testKitRegistration.tableHeader.birthDate")),
                dataKey: "patientBirthDate",
                cellRenderer: this.birthDateCellRenderer
              },
              {
                width: 120,
                label: (t("testKitRegistration.tableHeader.division")),
                dataKey: "divisionName",
                flexGrow: 1,
                cellRenderer: this.divisionCellRenderer
              },
              {
                width: 120,
                label: (t("testKitRegistration.tableHeader.testType")),
                dataKey: "testType",
                flexGrow: 1,
                cellRenderer: this.testTypeCellRenderer
              },
              {
                width: 120,
                label: (t("testKitRegistration.tableHeader.testFrequency")),
                dataKey: "testFrequency",
                flexGrow: 1,
                cellRenderer: this.testFrequencyCellRenderer
              },
              {
                width: 65,
                label: (t("testKitRegistration.tableHeader.numEmployees")),
                dataKey: "numberOfTests",
                cellRenderer: this.numberOfTestsCellRenderer
              },
              {
                width: 150,
                label: (t("testKitRegistration.tableHeader.registration")),
                dataKey: "covidTestCode",
                flexGrow: 1,
                cellRenderer: this.testRegistrationCodeCellRenderer
              },
              {
                width: 100,
                label: (t("testKitRegistration.tableHeader.action")),
                dataKey: "index",
                cellRenderer: this.actionCellRenderer
              }
            ]}
        />

    );
  };

  testKitRegistrationsRowGetter = ({index}: any) => {
    const {testKitRegistrations} = this.state;
    Object.assign(testKitRegistrations[index], {index: index + 1});

    return testKitRegistrations[index];
  };

  testKitRegistrationsRowRenderer = ({className, columns, index, key, style}: any) => {
    const a11yProps = {'aria-rowindex': index + 1};

    // const {testKitRegistrations} = this.state;
    // const registration = testKitRegistrations[index];

    let rowStyle = {...style};

    return (
        <div
            {...a11yProps}
            className={className}
            key={key}
            role="row"
            style={rowStyle}
        >
          {columns}
        </div>
    );
  };

  selectHeaderColumn = () => {

    const {testKitRegistrations, selectedTestRegs} = this.state;

    const bookingStatus = InterventionBookingStatus.EXECUTED;

    const countSelectableTestRegistrations =
        testKitRegistrations.filter(tr => tr.bookingStatus === bookingStatus).length;

    const countSelectedTestRegistrations = selectedTestRegs.length;
    const indeterminate = countSelectedTestRegistrations > 0 && countSelectedTestRegistrations < countSelectableTestRegistrations;
    const noSelectedTestRegistrations = countSelectedTestRegistrations === 0 && countSelectableTestRegistrations > 0;
    const allTestRegistrationsSelected = countSelectableTestRegistrations > 0 && countSelectedTestRegistrations === countSelectableTestRegistrations;

    return (
        <CheckboxSc
            checked={countSelectedTestRegistrations > 0}
            indeterminate={indeterminate}
            onChange={
              () => {
                if (noSelectedTestRegistrations || indeterminate) {
                  const covidTestIds = testKitRegistrations
                  .filter(tr => tr.bookingStatus === bookingStatus)
                  .map(tr => tr.covidTestId);
                  this.setState({
                    selectedTestRegs: covidTestIds
                  });
                }

                if (allTestRegistrationsSelected) {
                  this.setState({
                    selectedTestRegs: []
                  });
                }

              }
            }
            disabled={countSelectableTestRegistrations === 0}
        />
    );
  };

  selectableTestRegistrationSelectCellRenderer = (data: any) => {

    const {cellData} = data;
    const {testKitRegistrations, selectedTestRegs} = this.state;

    const index = cellData - 1;
    const testRegistration = testKitRegistrations[index];

    if (testRegistration.bookingStatus === InterventionBookingStatus.EXECUTED) {
      return (
          <CheckboxSc
              checked={selectedTestRegs.includes(testRegistration.covidTestId)}
              onChange={() => this.selectTestRegistration(index)}
          />
      );
    }

    return <></>;
  };

  employeeNameCellRenderer = (data: any) => {
    const {rowData} = data;
    return <div>
      {rowData.patientFirstName} {rowData.patientLastName}
    </div>;
  };

  birthDateCellRenderer = ({cellData}: any) => {
    if (cellData) {
      return <div>{moment(cellData).format("DD.MM.YYYY")}</div>;
    }
    return "";
  };

  divisionCellRenderer = ({cellData}: any) => {
    if (cellData && cellData.length > 0) {
      return cellData;
    }
    return "";
  };

  testTypeCellRenderer = ({cellData}: any) => {
    const {language} = this.props;
    const {interventions} = this.state;

    if (cellData && cellData.length > 0) {
      const intervention = interventions.find(i => i.externalCode === cellData);

      return interventionDescription(intervention, language);
    }
  };

  testFrequencyCellRenderer = ({cellData}: any) => {
    const {t} = this.props;
    if (cellData && cellData.length > 0) {
      return t(`testFrequency.${cellData}`);
    }
    return "";
  };

  numberOfTestsCellRenderer = ({rowData}: any) => {
    return rowData.numberOfTests;
  };

  testRegistrationCodeCellRenderer = ({rowData, cellData}: any) => {
    const testType = rowData.testType;
    if (testType === "RAPID_SALIVA_TEST" && cellData && cellData.length > 0) {
      return <span onClick={() => this.showTestResultImage(rowData.testResultImageId)}>
      <TestResultIcon result={rowData.covidTestResult} />
      </span>;
    }

    const testResult = rowData.covidTestResult;
    if (certificateExternalCodes().includes(testType) && testResult && testResult.length > 0) {
      return <span onClick={() => this.showTestResultImage(rowData.testResultImageId)}>
      <TestResultIcon result={rowData.covidTestResult} />
      </span>;
    }

    if (cellData && cellData.length > 0) {
      return cellData;
    }

    return "";
  };

  actionCellRenderer = ({rowData}: any) => {
    const {t} = this.props;
    const {qrcodeb64, testRegistrationUrl, qrcodeVisible} = this.state;

    const b64background = `url('data:image/png;base64,${qrcodeb64}')`;

    return <div className="row-actions">
      {
          (rowData.testType === InterventionExternalCode.PCR_SALIVA_TEST
              || rowData.testType === InterventionExternalCode.PCR_SALIVA_TEST_INDIVIDUAL_TEST)
          && rowData.covidTestCode === null &&
        <>
          <Button className="icon-button" icon
                  onClick={() => this.onRegisterPCRTestKitManually({...rowData})}
          >
            <Icon name="barcode" size="large" />
          </Button>

          <QrcodeActionButton
            poperDependencies={[qrcodeVisible]}
            renderContent={
              (toggle?: () => void) => <QrContentDiv>
                <div className="employee-data">
                  {`${rowData.patientFirstName} ${rowData.patientLastName}, ${isoToDisplayDate(rowData.patientBirthDate)}`}
                </div>
                {
                  qrcodeVisible
                      ?
                      <a target="_blank" rel="noreferrer" href={testRegistrationUrl}
                         title={t("employeeDashboard.goToSnapshot")}>
                        <div className="qrcode-wrapper">
                          <div className="qrcode" style={{
                            backgroundImage: b64background
                          }}>
                            &nbsp;
                          </div>
                        </div>
                      </a>
                      :
                      <div>
                        <Loader active inline />
                      </div>
                }
                <div className="actions-row">
                  <Button compact onClick={() => {
                    if (toggle) {
                      toggle();
                    }
                    this.fetchTestKitRegistrations();
                  }}
                  >
                    {t("action.back")}
                  </Button>
                </div>
                <div className="link-info">
                  <Icon name="info circle" />
                  {t("employeeDashboard.qrcode.linkInfo")}
                </div>
              </QrContentDiv>
            }
            hoverMessage={t("testKitRegistration.action.registerPCRTestkit")}
            onConfirm={() => {
            }}
            onOpen={() => {

              this.setState({
                qrcodeVisible: false
              });

              const request: TestRegistrationQrCodeRequest = {
                employeeId: rowData.employeeId,
                sourceEmployeeId: -1,
                companyId: -1,
                baseUrl: "",
                externalCode: InterventionExternalCode.PCR_SALIVA_TEST
              };
              createTestkitQrcodeBase64String(request, this.state.cancelTokenSource)
              .then(response => {
                this.setState({
                  qrcodeb64: response.imageB64,
                  testRegistrationUrl: response.url,
                  qrcodeVisible: true
                });
              });
            }}
            trigger={<Icon name={'mobile alternate'} size="large" />}
          />
        </>
      }
      {
          rowData.testType === InterventionExternalCode.HPV_TEST
          && rowData.covidTestCode === null &&
        <>
          <Button className="icon-button" icon
                  onClick={() => this.onRegisterHPVTestKitManually({...rowData})}
          >
            <Icon name="barcode" size="large" />
          </Button>

          <QrcodeActionButton
            poperDependencies={[qrcodeVisible]}
            renderContent={
              (toggle?: () => void) => <QrContentDiv>
                <div className="employee-data">
                  {`${rowData.patientFirstName} ${rowData.patientLastName}, ${isoToDisplayDate(rowData.patientBirthDate)}`}
                </div>
                {
                  qrcodeVisible
                      ?
                      <a target="_blank" rel="noreferrer" href={testRegistrationUrl}
                         title={t("employeeDashboard.goToSnapshot")}>
                        <div className="qrcode-wrapper">
                          <div className="qrcode" style={{
                            backgroundImage: b64background
                          }}>
                            &nbsp;
                          </div>
                        </div>
                      </a>
                      :
                      <div>
                        <Loader active inline />
                      </div>
                }
                <div className="actions-row">
                  <Button compact onClick={() => {
                    if (toggle) {
                      toggle();
                    }
                    this.fetchTestKitRegistrations();
                  }}
                  >
                    {t("action.back")}
                  </Button>
                </div>
                <div className="link-info">
                  <Icon name="info circle" />
                  {t("employeeDashboard.qrcode.linkInfo")}
                </div>
              </QrContentDiv>
            }
            hoverMessage={t("testKitRegistration.action.registerHPVTestkit")}
            onConfirm={() => {
            }}
            onOpen={() => {

              this.setState({
                qrcodeVisible: false
              });

              const request: TestRegistrationQrCodeRequest = {
                employeeId: rowData.employeeId,
                sourceEmployeeId: -1,
                companyId: -1,
                baseUrl: "",
                externalCode: InterventionExternalCode.HPV_TEST
              };
              createTestkitQrcodeBase64String(request, this.state.cancelTokenSource)
              .then(response => {
                this.setState({
                  qrcodeb64: response.imageB64,
                  testRegistrationUrl: response.url,
                  qrcodeVisible: true
                });
              });
            }}
            trigger={<Icon name={'mobile alternate'} size="large" />}
          />
        </>
      }

      {
          rowData.testType === InterventionExternalCode.CHLAMYDIA_TEST
          && rowData.covidTestCode === null &&
        <>
          <Button className="icon-button" icon
                  onClick={() => this.onRegisterChlamydiaTestKitManually({...rowData})}
          >
            <Icon name="barcode" size="large" />
          </Button>

          <QrcodeActionButton
            poperDependencies={[qrcodeVisible]}
            renderContent={
              (toggle?: () => void) => <QrContentDiv>
                <div className="employee-data">
                  {`${rowData.patientFirstName} ${rowData.patientLastName}, ${isoToDisplayDate(rowData.patientBirthDate)}`}
                </div>
                {
                  qrcodeVisible
                      ?
                      <a target="_blank" rel="noreferrer" href={testRegistrationUrl}
                         title={t("employeeDashboard.goToSnapshot")}>
                        <div className="qrcode-wrapper">
                          <div className="qrcode" style={{
                            backgroundImage: b64background
                          }}>
                            &nbsp;
                          </div>
                        </div>
                      </a>
                      :
                      <div>
                        <Loader active inline />
                      </div>
                }
                <div className="actions-row">
                  <Button compact onClick={() => {
                    if (toggle) {
                      toggle();
                    }
                    this.fetchTestKitRegistrations();
                  }}
                  >
                    {t("action.back")}
                  </Button>
                </div>
                <div className="link-info">
                  <Icon name="info circle" />
                  {t("employeeDashboard.qrcode.linkInfo")}
                </div>
              </QrContentDiv>
            }
            hoverMessage={t("testKitRegistration.action.registerChlamydiaTestkit")}
            onConfirm={() => {
            }}
            onOpen={() => {

              this.setState({
                qrcodeVisible: false
              });

              const request: TestRegistrationQrCodeRequest = {
                employeeId: rowData.employeeId,
                sourceEmployeeId: -1,
                companyId: -1,
                baseUrl: "",
                externalCode: InterventionExternalCode.CHLAMYDIA_TEST
              };
              createTestkitQrcodeBase64String(request, this.state.cancelTokenSource)
              .then(response => {
                this.setState({
                  qrcodeb64: response.imageB64,
                  testRegistrationUrl: response.url,
                  qrcodeVisible: true
                });
              });
            }}
            trigger={<Icon name={'mobile alternate'} size="large" />}
          />
        </>
      }

      {
          rowData.testType === InterventionExternalCode.TRICHOMONIASIS_TEST
          && rowData.covidTestCode === null &&
        <>
          <Button className="icon-button" icon
                  onClick={() => this.onRegisterTrichomoniasisTestKitManually({...rowData})}
          >
            <Icon name="barcode" size="large" />
          </Button>

          <QrcodeActionButton
            poperDependencies={[qrcodeVisible]}
            renderContent={
              (toggle?: () => void) => <QrContentDiv>
                <div className="employee-data">
                  {`${rowData.patientFirstName} ${rowData.patientLastName}, ${isoToDisplayDate(rowData.patientBirthDate)}`}
                </div>
                {
                  qrcodeVisible
                      ?
                      <a target="_blank" rel="noreferrer" href={testRegistrationUrl}
                         title={t("employeeDashboard.goToSnapshot")}>
                        <div className="qrcode-wrapper">
                          <div className="qrcode" style={{
                            backgroundImage: b64background
                          }}>
                            &nbsp;
                          </div>
                        </div>
                      </a>
                      :
                      <div>
                        <Loader active inline />
                      </div>
                }
                <div className="actions-row">
                  <Button compact onClick={() => {
                    if (toggle) {
                      toggle();
                    }
                    this.fetchTestKitRegistrations();
                  }}
                  >
                    {t("action.back")}
                  </Button>
                </div>
                <div className="link-info">
                  <Icon name="info circle" />
                  {t("employeeDashboard.qrcode.linkInfo")}
                </div>
              </QrContentDiv>
            }
            hoverMessage={t("testKitRegistration.action.registerTrichomoniasisTestkit")}
            onConfirm={() => {
            }}
            onOpen={() => {

              this.setState({
                qrcodeVisible: false
              });

              const request: TestRegistrationQrCodeRequest = {
                employeeId: rowData.employeeId,
                sourceEmployeeId: -1,
                companyId: -1,
                baseUrl: "",
                externalCode: InterventionExternalCode.TRICHOMONIASIS_TEST
              };
              createTestkitQrcodeBase64String(request, this.state.cancelTokenSource)
              .then(response => {
                this.setState({
                  qrcodeb64: response.imageB64,
                  testRegistrationUrl: response.url,
                  qrcodeVisible: true
                });
              });
            }}
            trigger={<Icon name={'mobile alternate'} size="large" />}
          />
        </>
      }
      {
          (rowData.testType === InterventionExternalCode.PCR_SALIVA_TEST
              || rowData.testType === InterventionExternalCode.PCR_SALIVA_TEST_INDIVIDUAL_TEST
              || rowData.testType === InterventionExternalCode.HPV_TEST
              || rowData.testType === InterventionExternalCode.CHLAMYDIA_TEST
              || rowData.testType === InterventionExternalCode.TRICHOMONIASIS_TEST
          )
          && rowData.covidTestCode !== null &&
          /*<Icon name="trash" size="large"/>*/
        <DeleteActionButton
          popupMessage={
            t("testKitRegistration.action.deleteConfirmMessage",
                {
                  code: rowData.covidTestCode,
                  employee: `${rowData.patientFirstName} ${rowData.patientLastName}`
                })
          }
          hoverMessage={t("testKitRegistration.action.deleteHoverMessage")}
          onConfirm={() => this.onDeregisterTestKit(rowData.employeeId, rowData.interventionBookingId)}
          trigger={<Icon name={'trash'} />}
        />
      }
      {
          rowData.testType === InterventionExternalCode.RAPID_SALIVA_TEST &&
        <>
          <QrcodeActionButton
            poperDependencies={[qrcodeVisible]}
            renderContent={
              (toggle?: () => void) => <QrContentDiv>
                <div className="employee-data">
                  {`${rowData.patientFirstName} ${rowData.patientLastName}, ${isoToDisplayDate(rowData.patientBirthDate)}`}
                </div>
                {
                  qrcodeVisible
                      ?
                      <a target="_blank" rel="noreferrer" href={testRegistrationUrl}
                         title={t("employeeDashboard.goToSnapshot")}>
                        <div className="qrcode-wrapper">
                          <div className="qrcode" style={{
                            backgroundImage: b64background
                          }}>
                            &nbsp;
                          </div>
                        </div>
                      </a>
                      :
                      <div>
                        <Loader active inline />
                      </div>
                }
                <div className="actions-row">
                  <Button compact onClick={() => {
                    if (toggle) {
                      toggle();
                    }
                    this.fetchTestKitRegistrations();
                  }}
                  >
                    {t("action.back")}
                  </Button>
                </div>
                <div className="link-info">
                  <Icon name="info circle" />
                  {t("employeeDashboard.qrcode.linkInfo")}
                </div>
              </QrContentDiv>
            }
            hoverMessage={t("testKitRegistration.action.registerRapidTestkit")}
            onConfirm={() => {
            }}
            onOpen={() => {

              this.setState({
                qrcodeVisible: false
              });

              const request: TestRegistrationQrCodeRequest = {
                employeeId: rowData.employeeId,
                sourceEmployeeId: -1,
                companyId: -1,
                baseUrl: "",
                externalCode: InterventionExternalCode.RAPID_SALIVA_TEST
              };
              createRapidTestkitQrcodeBase64String(request, this.state.cancelTokenSource)
              .then(response => {
                this.setState({
                  qrcodeb64: response.imageB64,
                  testRegistrationUrl: response.url,
                  qrcodeVisible: true
                });
              });
            }}
            trigger={<Icon name={'camera'} size="large" />}
          />
        </>
      }

      {
          rowData.testType === InterventionExternalCode.COVID_CERTIFICATE_OF_RECOVERY
          && !rowData.covidTestResult &&
        <>
          <QrcodeActionButton
            poperDependencies={[qrcodeVisible]}
            renderContent={
              (toggle?: () => void) => <QrContentDiv>
                <div className="employee-data">
                  {`${rowData.patientFirstName} ${rowData.patientLastName}, ${isoToDisplayDate(rowData.patientBirthDate)}`}
                </div>
                {
                  qrcodeVisible
                      ?
                      <a target="_blank" rel="noreferrer" href={testRegistrationUrl}
                         title={t("employeeDashboard.goToSnapshot")}>
                        <div className="qrcode-wrapper">
                          <div className="qrcode" style={{
                            backgroundImage: b64background
                          }}>
                            &nbsp;
                          </div>
                        </div>
                      </a>
                      :
                      <div>
                        <Loader active inline />
                      </div>
                }
                <div className="actions-row">
                  <Button compact onClick={() => {
                    if (toggle) {
                      toggle();
                    }
                    this.fetchTestKitRegistrations();
                  }}
                  >
                    {t("action.back")}
                  </Button>
                </div>
                <div className="link-info">
                  <Icon name="info circle" />
                  {t("employeeDashboard.qrcode.linkInfo")}
                </div>
              </QrContentDiv>
            }
            hoverMessage={t("testKitRegistration.action.registerCertificate")}
            onConfirm={() => {
            }}
            onOpen={() => {

              this.setState({
                qrcodeVisible: false
              });

              const request: TestRegistrationQrCodeRequest = {
                employeeId: rowData.employeeId,
                sourceEmployeeId: -1,
                companyId: -1,
                baseUrl: "",
                externalCode: InterventionExternalCode.COVID_CERTIFICATE_OF_RECOVERY
              };
              createCertificateQrcodeBase64String(request, "recovery", this.state.cancelTokenSource)
              .then(response => {
                this.setState({
                  qrcodeb64: response.imageB64,
                  testRegistrationUrl: response.url,
                  qrcodeVisible: true
                });
              });
            }}
            trigger={<Icon name={'camera'} size="large" />}
          />
        </>
      }

      {
          rowData.testType === InterventionExternalCode.COVID_CERTIFICATE_OF_VACCINATION
          && !rowData.covidTestResult &&
        <>
          <QrcodeActionButton
            poperDependencies={[qrcodeVisible]}
            renderContent={
              (toggle?: () => void) => <QrContentDiv>
                <div className="employee-data">
                  {`${rowData.patientFirstName} ${rowData.patientLastName}, ${isoToDisplayDate(rowData.patientBirthDate)}`}
                </div>
                {
                  qrcodeVisible
                      ?
                      <a target="_blank" rel="noreferrer" href={testRegistrationUrl}
                         title={t("employeeDashboard.goToSnapshot")}>
                        <div className="qrcode-wrapper">
                          <div className="qrcode" style={{
                            backgroundImage: b64background
                          }}>
                            &nbsp;
                          </div>
                        </div>
                      </a>
                      :
                      <div>
                        <Loader active inline />
                      </div>
                }
                <div className="actions-row">
                  <Button compact onClick={() => {
                    if (toggle) {
                      toggle();
                    }
                    this.fetchTestKitRegistrations();
                  }}
                  >
                    {t("action.back")}
                  </Button>
                </div>
                <div className="link-info">
                  <Icon name="info circle" />
                  {t("employeeDashboard.qrcode.linkInfo")}
                </div>
              </QrContentDiv>
            }
            hoverMessage={t("testKitRegistration.action.registerCertificate")}
            onConfirm={() => {
            }}
            onOpen={() => {

              this.setState({
                qrcodeVisible: false
              });

              const request: TestRegistrationQrCodeRequest = {
                employeeId: rowData.employeeId,
                sourceEmployeeId: -1,
                companyId: -1,
                baseUrl: "",
                externalCode: InterventionExternalCode.COVID_CERTIFICATE_OF_VACCINATION
              };
              createCertificateQrcodeBase64String(request, "vaccination", this.state.cancelTokenSource)
              .then(response => {
                this.setState({
                  qrcodeb64: response.imageB64,
                  testRegistrationUrl: response.url,
                  qrcodeVisible: true
                });
              });
            }}
            trigger={<Icon name={'camera'} size="large" />}
          />
        </>
      }

      {
          rowData.testType === InterventionExternalCode.COVID_CERTIFICATE_EXTERNAL_TEST_DOCUMENT
          && !rowData.covidTestResult &&
        <>
          <QrcodeActionButton
            poperDependencies={[qrcodeVisible]}
            renderContent={
              (toggle?: () => void) => <QrContentDiv>
                <div className="employee-data">
                  {`${rowData.patientFirstName} ${rowData.patientLastName}, ${isoToDisplayDate(rowData.patientBirthDate)}`}
                </div>
                {
                  qrcodeVisible
                      ?
                      <a target="_blank" rel="noreferrer" href={testRegistrationUrl}
                         title={t("employeeDashboard.goToSnapshot")}>
                        <div className="qrcode-wrapper">
                          <div className="qrcode" style={{
                            backgroundImage: b64background
                          }}>
                            &nbsp;
                          </div>
                        </div>
                      </a>
                      :
                      <div>
                        <Loader active inline />
                      </div>
                }
                <div className="actions-row">
                  <Button compact onClick={() => {
                    if (toggle) {
                      toggle();
                    }
                    this.fetchTestKitRegistrations();
                  }}
                  >
                    {t("action.back")}
                  </Button>
                </div>
                <div className="link-info">
                  <Icon name="info circle" />
                  {t("employeeDashboard.qrcode.linkInfo")}
                </div>
              </QrContentDiv>
            }
            hoverMessage={t("testKitRegistration.action.registerCertificate")}
            onConfirm={() => {
            }}
            onOpen={() => {

              this.setState({
                qrcodeVisible: false
              });

              const request: TestRegistrationQrCodeRequest = {
                employeeId: rowData.employeeId,
                sourceEmployeeId: -1,
                companyId: -1,
                baseUrl: "",
                externalCode: InterventionExternalCode.COVID_CERTIFICATE_EXTERNAL_TEST_DOCUMENT
              };
              createCertificateQrcodeBase64String(request, "externalTestDocument", this.state.cancelTokenSource)
              .then(response => {
                this.setState({
                  qrcodeb64: response.imageB64,
                  testRegistrationUrl: response.url,
                  qrcodeVisible: true
                });
              });
            }}
            trigger={<Icon name={'camera'} size="large" />}
          />
        </>
      }

    </div>;
  };

}

export default withRouterWorkaround(
    withAuthContext(
        withTranslation(["mipoco"])(
            TestRegistration)));
