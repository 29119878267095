import {CancelTokenSource} from "axios";
import axios from "service/http";
import {GuarantorDto, GuarantorSearchRequest} from "ts-types/api.types";

const authBaseUrl = process.env.REACT_APP_AUTH_BASE_URL;
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const apiUrl = `${baseUrl}/guarantors`;

export const getAllGuarantors = (cancelSourceToken: CancelTokenSource): Promise<Array<GuarantorDto>> => {
  return axios
  .get(`${apiUrl}`, {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};

export const searchGuarantorsForInput = (
    description: string,
    tariffType: string,
    source: CancelTokenSource, limit?: number): Promise<Array<GuarantorDto>> => {

  const request: GuarantorSearchRequest = {
    description: description,
    tariffType: tariffType
  };

  return axios
  .post(`${apiUrl}/search`, request, {cancelToken: source.token})
  .then(response => response.data);
};

export const pubSearchGuarantorsForInput = (
    description: string,
    tariffType: string,
    source: CancelTokenSource, limit?: number): Promise<Array<GuarantorDto>> => {

  const request: GuarantorSearchRequest = {
    description: description,
    tariffType: tariffType
  };

  return axios
  .post(`${authBaseUrl}/pub/guarantors/search`, request, {cancelToken: source.token})
  .then(response => response.data);
};

