import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import i18n from "i18next";
import React, {Component} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {Button, Dropdown, DropdownItemProps} from "semantic-ui-react";
import styled from "styled-components";
import {supportedLanguages} from "util/localizationUtils";

const ContainerDiv = styled.div`
  float: right;

  .ui.dropdown > .text {
    font-size: 1.2rem;
  }

  .ui.dropdown > .dropdown.icon {
    margin: 0 0 0 0.5rem;
  }

  .ui.dropdown .menu {
    border-radius: unset;
    box-shadow: unset;
    border-color: #aab3fd;

    .selected.item,
    .ui.dropdown.selected {
      background-color: rgb(236, 239, 255);
    }

    & > .item:hover {
      background-color: rgb(228, 232, 255);
    }
  }

  .top-menu-item {
    margin-left: 1rem;
  }
`;

interface Props extends WithTranslation, AuthConsumerRenderProps {
}

interface State {
}

class HeaderLoggedInUser extends Component<Props, State> {

  render(): React.ReactNode {

    const {t} = this.props;
    const currentLanguage = i18n.language;

    const langOptions: DropdownItemProps[] = supportedLanguages.map(lang => ({
      key: lang,
      value: lang,
      text: lang.toUpperCase()
    }));

    return <ContainerDiv>
      <Button
          type="button"
          className="action-button top-menu-item"
          primary
          onClick={this.props.logout}
      >
        {t("header.action.logout")}
      </Button>

      <Dropdown
          className="top-menu-item"
          text={currentLanguage?.toUpperCase()}
          direction="left"
          value={currentLanguage}
          options={langOptions}
          onChange={(evt, data) => {
            const value = data.value as string;
            this.props.updateLanguage(value);
          }}
          selectOnBlur
          selectOnNavigation={false}
      />
    </ContainerDiv>;
  }
}

export default withAuthContext(withTranslation(["mipoco"])(HeaderLoggedInUser));
