import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import axios from "axios";
import Input from "component/final-form/Input";
import RadioGroup from "component/final-form/RadioGroup";
import LoaderComponent from "component/LoaderComponent";
import MpGrid from "component/MpGrid";
import StyledErrorMessage from "component/StyledErrorMessage";
import {FormApi} from "final-form";
import _ from "lodash";
import React, {Component, Fragment} from "react";
import {Field, Form as FinalForm, FormRenderProps} from "react-final-form";
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";
import QuestionSetView from "route/person-selfonboarded/QuestionSetView";
import {DataEntry, DataEntryValue, Label} from "route/person-selfonboarded/styled";
import {Button, Grid, Icon, List, ListItem, RadioProps} from "semantic-ui-react";
import {addSweepstakesBooking, getQuestionSetForSweepstakes} from "service/sweepstakesServices";
import styled from "styled-components";
import {
  InterventionBookingDto,
  QuestionAnswerDto,
  QuestionDto,
  QuestionSetDto,
  SweepstakesBookingRequestDto
} from "ts-types/api.types";
import {questionText} from "util/questionSetUtils";
import {withRouterWorkaround} from "util/workaroundUtils";

const CustomContainer = styled.div`

  .section-title {
    color: #7687FF;
    font-weight: bold;
    font-size: 1.5rem;
    margin: 0;
    padding-right: 0.5rem;
    display: inline-block;
  }

  .section-info {
    margin: 1rem 0 0;
  }

  .success-message {
    color: darkgreen;
    display: inline-block;
    margin-right: 1rem;

    @media only screen and (max-width: 767px) {
      display: block;
      margin-bottom: 0.75rem;
    }
  }

  .form-grid {
    label {
      display: inline-block;
      margin-bottom: 0.5rem;
      color: #454545;
    }
  }

  .ui.grid.form-grid {
    margin-left: -1rem;
    max-width: 840px;

    & > .row {
      margin-bottom: 0.578rem;
    }

    .input-field {
      max-width: 27rem;
    }

    .field > .ui.input.hidden-field {
      display: none;
    }
  }`;

interface Props extends RouteComponentProps<any>,
    AuthConsumerRenderProps,
    WithTranslation {

  questionSet?: QuestionSetDto;
  onSuccess?: () => void;
}

interface State {
  questionSet?: QuestionSetDto,
  currentQuestion: number,
  dataLoaded: boolean,
  successMessage?: string,
  errorMessages: string[]
}

const generateRadioButtonOptions = (
    ids: Array<string>,
    labels: Array<string>,
    groupName: string,
    getMessage: Function,
    values?: Array<string | number>): Array<RadioProps> => {

  return labels.map((label, index) => ({
        id: ids[index],
        label: getMessage(`${label}`),
        name: groupName,
        value: values ? values[index] : index
      }
  ));
};

const convertValueToList = (value: string) => value ? [value] : [];
const extractValueFromList = (value: Array<string>) => value && value.length === 1 ? value[0] : "";


const cancelTokenSource = axios.CancelToken.source();

class SweepstakeForm extends Component<Props, State> {

  initialBookingValues: Partial<InterventionBookingDto> = {
    questionAnswerPairs: []
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      currentQuestion: 0,
      dataLoaded: false,
      errorMessages: []
    };
  }

  componentDidMount() {
    this.loadRequiredData();
  }

  loadRequiredData = async () => {

    let questionSet = this.props.questionSet;
    if (!questionSet) {
      try {
        questionSet = await getQuestionSetForSweepstakes(cancelTokenSource);
      } catch (e) {
        this.handleError(e.response.data);
        return;
      }
    }

    this.initialBookingValues.questionAnswerPairs = questionSet.questions.map(question => ({
      questionId: question.id,
      questionText: question.text,
      questionTextEn: question.textEn,
      questionTextFr: question.textFr,
      questionTextIt: question.textIt,
      selectedAnswers: [],
      remarks: ""
    }));

    this.setState({
      questionSet,
      dataLoaded: true
    });
  };

  handleError = (error: any) => {
    const {t} = this.props;

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            this.setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!this.state.errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          this.setErrorMessage(t(`error.${errorCode}`));
        } else {
          this.setErrorMessage(t('error.general'));
        }
      }
    }
  };

  setErrorMessage = (errorMessage?: string) => {

    const {errorMessages} = this.state;

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);

      this.setState({
        errorMessages: errMsgs
      });
    } else {

      this.setState({
        errorMessages: []
      });
    }
  };

  handleSubmit = async (values: Partial<InterventionBookingDto>) => {

    const {onSuccess, t, currentUser} = this.props;
    const {questionSet} = this.state;

    if (questionSet) {

      const request: Partial<SweepstakesBookingRequestDto> = {
        questionSetId: questionSet.id,
        questionAnswerPairs: values.questionAnswerPairs
      };

      try {
        await addSweepstakesBooking(request, cancelTokenSource);
        this.setState({
          successMessage: t("sweepstake.successMsg")
        });

        if (onSuccess) {
          onSuccess();
        } else {
          setTimeout(() => {
            this.props.history.push("/");
          }, 1500);
        }
      } catch (e) {
        this.handleError(e.response.data);
      }
    }
  };

  previousQuestion = () => {
    const {currentQuestion} = this.state;

    this.setState({
      currentQuestion: Math.max(currentQuestion - 1, 0)
    });
  };

  nextQuestion = () => {
    const {questionSet, currentQuestion} = this.state;

    this.setState({
      currentQuestion: Math.min(currentQuestion + 1, questionSet!.questions.length - 1)
    });
  };

  setQuestionValue = (fieldPath: string, value: boolean, form: FormApi) => {
    form.change(fieldPath, [value]);
    this.nextQuestion();
  };

  render(): React.ReactNode {

    const {dataLoaded} = this.state;
    const {t} = this.props;

    if (dataLoaded) {
      return (<CustomContainer>
        <FinalForm
            onSubmit={(values) => this.handleSubmit(values)}
            decorators={[]}
            initialValues={this.initialBookingValues}
            subscription={{values: true, pristine: true, submitting: true}}
            render={this.renderForm}
        />
      </CustomContainer>);
    } else {
      return (
          <LoaderComponent message={t("sweepstake.loading")} />
      );
    }

  }

  renderForm = ({submitting, handleSubmit, form}: FormRenderProps): React.ReactNode => {

    const {t} = this.props;
    const {questionSet, successMessage, errorMessages} = this.state;

    const disabled = !!(submitting || successMessage);

    return (
        <form onSubmit={handleSubmit}>
          <MpGrid stackable={true}>
            <Grid.Row>
              <Grid.Column width={16}>
                <h2 className="section-title">{t("sweepstake.title")}</h2>
              </Grid.Column>
            </Grid.Row>
            {errorMessages.length > 0 &&
              <Grid.Row>
                <Grid.Column width={16}>
                  <StyledErrorMessage onDismiss={() => this.setErrorMessage()}>
                    {errorMessages.map(err => <div key={err}>{err}</div>)}
                  </StyledErrorMessage>
                </Grid.Column>
              </Grid.Row>
            }

            {/*<Grid.Row>
              <Grid.Column width={4}>
                <label>{t("sweepstake.questions")}:</label>
              </Grid.Column>
            </Grid.Row>*/}
            <Grid.Row>
              <Grid.Column width={12}>
                <DataEntry>
                  {this.renderQuestions(form)}
                </DataEntry>
              </Grid.Column>
            </Grid.Row>

            {questionSet &&
              <>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <label>{t("sweepstake.answers")}:</label>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={12}>
                    <QuestionSetView
                      questionSetName={questionSet.name}
                      questionAnswerPairs={form.getState().values.questionAnswerPairs!} />
                  </Grid.Column>
                </Grid.Row>
              </>
            }

            <Grid.Row textAlign="right">
              <Grid.Column width={16}>
                {
                    successMessage &&
                  <div className="success-message">
                    {t(successMessage)}
                  </div>
                }
                <Button
                    type="submit"
                    className="action-button"
                    primary
                    style={{display: "inline-block", marginRight: "1.5rem"}}
                    disabled={disabled}
                >
                  {t("button.save")}
                </Button>
                <Button
                    type="button"
                    className="action-button"
                    onClick={() => this.props.history.push(`/`)}
                    secondary
                    style={{display: "inline-block"}}
                    disabled={disabled}
                >
                  {t("action.cancel")}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </MpGrid>
        </form>
    );
  };

  renderQuestions = (form: FormApi<Partial<InterventionBookingDto>>): JSX.Element => {
    const {t} = this.props;
    const {questionSet, currentQuestion} = this.state;

    if (!questionSet) {
      return <></>;
    }

    const questionMap = _.keyBy(questionSet.questions, q => q.id);

    const currentQuestionIx = currentQuestion < questionSet.questions.length ? currentQuestion : 0;

    const values = form.getState().values;
    const qaPairs = values.questionAnswerPairs!;

    return (
        <>

          {
              qaPairs.length > 1 &&
            <>
              <div className="label" />

              <div className="question-num-label">
                {t("sweepstake.questionNum")}
                <span className="no-break">({`${currentQuestionIx + 1}/${questionSet.questions.length}`})</span>
              </div>
            </>
          }

          <div className="question-container">
            {
                qaPairs.length > 1 &&
              <Button
                className="button-back"
                type="button"
                icon size="mini"
                disabled={currentQuestionIx <= 0}
                onClick={this.previousQuestion}
              >
                <Icon name="chevron left" />
              </Button>
            }

            <div className="question">
              {
                qaPairs.map((qaPair, index: number) => {
                  const question: QuestionDto = questionMap[qaPair.questionId];

                  if (!question) {
                    return <div key={`question-wrapper-unknown-q-${qaPair.questionId}`} />;
                  }

                  const wrapperClass = index !== currentQuestionIx ? "inactive" : "";
                  return <div key={`question-wrapper-${question!.id}`} className={`question-wrapper ${wrapperClass}`}>
                    {
                      this.renderSingleQuestion(
                          `questionAnswerPairs[${index}]`, index, question, qaPairs, form)
                    }
                  </div>;
                })
              }
            </div>

            {
                qaPairs.length > 1 &&
              <Button className="button-next"
                      type="button"
                      icon
                      size="mini"
                      disabled={currentQuestionIx >= questionSet.questions.length - 1}
                      onClick={this.nextQuestion}
              >
                <Icon name="chevron right" />
              </Button>
            }
          </div>
        </>
    );
  };

  renderSingleQuestion = (
      questionAnswerFieldPath: string,
      index: number,
      question: QuestionDto,
      qaPairs: QuestionAnswerDto[],
      form: FormApi) => {

    return (
        <Fragment key={`question-fragment-${index}`}>
          <Label className="question-text" key={`question_label_${question.questionKey}`}>
            {questionText(question, this.props.language)}
          </Label>

          <DataEntryValue className="answer-value" key={`question_value_${question.questionKey}`}>
            {this.renderAnswerByType(`${questionAnswerFieldPath}.selectedAnswers`, index, question, form)}
          </DataEntryValue>
        </Fragment>
    );
  };

  renderAnswerByType = (fieldPath: string, index: number, question: QuestionDto, form: FormApi) => {
    const {t} = this.props;
    const {questionType} = question;
    switch (questionType) {
      case 'MULTI_ANSWER':
        const answers = question.possibleAnswers.map((answer, index) => (
                <ListItem key={`${fieldPath}_${index}`}>
                  <Field
                      name={fieldPath}
                      component="input"
                      type="checkbox"
                      value={answer}
                  />
                  {' ' + t(answer)}
                </ListItem>
            )
        );
        return (
            <List>
              {answers}
            </List>
        );
      case 'SINGLE_ANSWER':
        return (
            <Field
                name={fieldPath}
                component={RadioGroup}
                parse={convertValueToList}
                format={extractValueFromList}
                radiowidth="100%"
                radioDefinitions={
                  generateRadioButtonOptions(
                      question.possibleAnswers,
                      question.possibleAnswers,
                      fieldPath,
                      t,
                      question.possibleAnswers
                  )
                }
            />
        );
      case 'FREE_TEXT':
        return (
            <Field
                name={fieldPath}
                component={Input}
                fluid
                placeholder={"..."}
                parse={convertValueToList}
                format={extractValueFromList}
            />
        );
      case 'YES_NO':
      default:
        const qa = _.get(form.getState().values, fieldPath);
        return (
            <>
              <Button
                  className="yesno-button"
                  type="button"
                  size="mini"
                  primary={this.answerEquals(qa, true)}
                  onClick={() => this.setQuestionValue(fieldPath, true, form)}
              >
                {t("anamnesis.answer.yes")}
              </Button>

              <Button
                  className="yesno-button"
                  type="button"
                  size="mini"
                  primary={this.answerEquals(qa, false)}
                  onClick={() => this.setQuestionValue(fieldPath, false, form)}
              >
                {t("anamnesis.answer.no")}
              </Button>
            </>
        );
    }
  };

  answerEquals = (answers: string[], value: any): boolean => {
    return answers && answers.length === 1 && (answers[0] === value || answers[0] === "" + value);
  };
}

export default withRouterWorkaround(
    withAuthContext(
        withTranslation(["mipoco"])(
            SweepstakeForm)));