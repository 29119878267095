import {QuestionAnswerDto, QuestionDto, QuestionSetDto} from "ts-types/api.types";

export const questionSetName = (questionSet: QuestionSetDto, language: string): string => {

  const texts: { [key: string]: string } = {
    "de": questionSet.name,
    "en": questionSet.nameEn,
    "fr": questionSet.nameFr,
    "it": questionSet.nameIt
  };
  return texts[language] || questionSet.name;
};

export const qaQuestionText = (qaPair: QuestionAnswerDto, language: string): string => {

  const texts: { [key: string]: string | undefined } = {
    "de": qaPair.questionText,
    "en": qaPair.questionTextEn,
    "fr": qaPair.questionTextFr,
    "it": qaPair.questionTextIt
  };
  return texts[language] || qaPair.questionText;
};

export const questionText = (question: QuestionDto, language: string): string => {

  const texts: { [key: string]: string } = {
    "de": question.text,
    "en": question.textEn,
    "fr": question.textFr,
    "it": question.textIt
  };
  return texts[language] || question.text;
};
