import React from 'react';
import {FieldRenderProps} from 'react-final-form';
import {Form, Input} from 'semantic-ui-react';

interface ExtendedFieldRenderProps extends FieldRenderProps<any> {
    autoComplete?: "on" | "off" | "nope"
}

const FinalFormInput = (props: ExtendedFieldRenderProps) => {

    const {
        input,
        meta: {error},
        autoComplete = "nope",
        ...restProps
    } = props;

    return (
        <Form.Field>
            <Input error={!!(error)}
                         {...input}
                         {...restProps}
                         autoComplete={autoComplete}
            />
        </Form.Field>
    );
};

export default FinalFormInput;
