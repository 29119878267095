import FootstepsIcon from "component/FootstepsIcon";
import React, {Component} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {CompanyOnboardViews} from "route/company-onboard/CompanyOnboardView";
import styled from "styled-components";

const ViewContainerDiv = styled.div`
  margin: 0.5rem 0.525rem;
  display: flex;
  flex-direction: column;
  font-size: 1.275rem;

  .header-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 3rem;

    .view-title {
      color: #7687FF;
      font-weight: bold;
      font-size: 1.8rem;
      margin-bottom: 0;
      align-self: center;
    }

    .footsteps-icon {
      margin-left: auto;

      @media(max-width: 714px) {
        margin-left: unset;
        margin-top: 2rem;
      }

      .step-text {
        vertical-align: top;
        font-weight: bold;
      }

      svg {
        margin-left: 1rem;
      }
    }
  }

  .login-button {
    margin-bottom: 1.5rem;
  }
`;

interface Props extends WithTranslation {
  titleKey: string;
  activeView: CompanyOnboardViews;
}

class CompanyOnboardContainer extends Component<Props> {

  renderActiveViewStep = (): number => {

    const {activeView} = this.props;

    switch (activeView) {
      case CompanyOnboardViews.VALIDATION: {
        return 1;
      }
      case CompanyOnboardViews.COMPANY_EMPLOYEE: {
        return 2;
      }
      default: {
        return 3;
      }
    }
  };

  render(): React.ReactNode {

    const {titleKey, activeView, t, children} = this.props;

    return <ViewContainerDiv className="login-view">
      <div className="header-content">
        <h2 className="view-title">
          {t(titleKey)}
        </h2>
        {activeView && activeView !== CompanyOnboardViews.INSTRUCTIONS
        && activeView !== CompanyOnboardViews.REGISTRATION_SUCCESSFUL
        && <div className="footsteps-icon">
          <span className="step-text">
            {t("companyOnboard.instructions.step")} {this.renderActiveViewStep()}
          </span>
          <FootstepsIcon />
        </div>
        }
      </div>

      {children}
    </ViewContainerDiv>;
  }
}

export default withTranslation(["login"])(CompanyOnboardContainer);
