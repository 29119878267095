import React, {ChangeEvent, Component} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import LoginView from "route/login/LoginView";
import {Button, Input, InputOnChangeData} from "semantic-ui-react";
import styled from "styled-components";
import {isKeyCheck} from "util/keyUtils";

const ContainerDiv = styled.div`

  .email-form {
    display: grid;
    grid-template-columns: 150px minmax(10rem, 25rem);

    grid-column-gap: 1rem;
    grid-row-gap: 0.25rem;

    margin-top: 3.5rem;
    margin-bottom: 2rem;

    label {
      align-self: center;
    }

    .error {
      grid-column-start: 2;
      justify-self: start;
      color: darkred;
      font-size: 1rem;
      margin: 0.3rem 0 0.5rem 0;
    }

    .actions-row {
      grid-column-start: 2;
      justify-self: start;
      margin-top: 0.75rem;

      button {
        margin-right: 0.785rem;
      }
    }
  }
  
  @media only screen and (max-width: 767px) {
     .email-form {
       display: flex;
       flex-direction: column;
       
        label {
          align-self: flex-start;
        }
        
        .actions-row {
          
          display: flex;
          flex-direction: column;
          gap: 1rem;
                      
          button {
            width: 100%;
            margin-right: 0.785rem;
          }
        }
     }
  }

`;

const InfoDiv = styled.div`
  max-width: 40rem;
  margin-bottom: 1.5rem;
`;

interface Props extends WithTranslation {
  onVerifyCode: (code: string) => Promise<void>;
  onBackToLogin: () => void;
}

interface State {
  code: string;
  error?: string;
}

class LoginCodeVerificationSelection extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      code: ""
    };
  }

  setCode = (evt: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    this.setState({
      code: data.value
    });
  };

  onCodeKeyDown = (event: any) => {
    event.persist();
    const isKey: any = isKeyCheck(event);

    if (isKey.enter) {
      this.verifyCode();
    }
  };

  verifyCode = async () => {
    const {code} = this.state;

    // let result: string | undefined = undefined;
    try {
      /*result = */
      await this.props.onVerifyCode(code);
    } catch (ex) {
      this.setState({
        error: "login.codeVerification.error.invalidCode"
      });
    }
  };

  render(): React.ReactNode {

    const {t} = this.props;
    const {code} = this.state;

    return <LoginView titleKey="login.codeVerification.title">
      <ContainerDiv>
        <InfoDiv>{t("login.codeVerification.intro")}</InfoDiv>

        <div className="email-form">

          <label>{t("login.codeVerification.form.code")}</label>
          <Input
              placeholder=""
              value={code}
              onChange={this.setCode}
              onKeyDown={(e: any) => this.onCodeKeyDown(e)}
          />

          {
            this.state.error &&
            <div className="error">
              {t(this.state.error)}
            </div>
          }

          <div className="actions-row">
            <Button
                type="button"
                className="action-button"
                primary
                onClick={this.verifyCode}
            >
              {t("login.codeVerification.action.signin")}
            </Button>

            <Button
                type="button"
                className="action-button"
                color={"grey"}
                onClick={(evt) => this.props.onBackToLogin()}
            >
              {t("action.back")}
            </Button>
          </div>
        </div>

        <InfoDiv>{t("login.codeVerification.alternativeInfo")}</InfoDiv>
      </ContainerDiv>
    </LoginView>;
  }
}

export default withTranslation(["login"])(LoginCodeVerificationSelection);
