import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import React, {Component} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";
import {Button, Loader} from "semantic-ui-react";
import styled from "styled-components";
import {EmployeeAccessTokenType} from "ts-types/api.enums";
import {withRouterWorkaround} from "util/workaroundUtils";
import LoginView from "./LoginView";

const InfoDiv = styled.div`
  max-width: 47rem;
  margin-bottom: 1.5rem;
`;

interface Props extends RouteComponentProps<any>, WithTranslation, AuthConsumerRenderProps {

}

interface State {
  status: "CHECKING" | "INVALID_CODE";
  type?: EmployeeAccessTokenType;
}

class LoginPasswordlessLogin extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    const queryParams = new URLSearchParams(props.history.location.search);
    const token = queryParams.get("upn");

    const typeString = queryParams.get("type");
    const type = typeString ? EmployeeAccessTokenType[typeString as keyof typeof EmployeeAccessTokenType] : undefined;

    this.state = {
      status: "CHECKING",
      type: type
    };

    if (token) {
      this.verifyCode(token);
    } else {
      this.state = {
        status: "INVALID_CODE"
      };
    }
  }

  verifyCode = async (code: string) => {

    this.props.verifyToken(code)
    .then(() => {
      this.props.history.push("/", {welcome: "true"});
    })
    .catch(e => {
      const {response} = e;
      if (response?.data?.error) {
        const {error} = response.data;

        if (["INVALID_VERIFICATION_CODE"].includes(error)) {
          this.props.history.push("/verification-code", {token: code});
          return;
        } else if (["MULTIPLE_CANDIDATE_ACCOUNTS"].includes(error)) {
          this.props.history.push("/account-selection", {token: code});
          return;
        }
      }

      this.setState({
        status: "INVALID_CODE"
      });
    });
  };

  goToLogin = async () => {
    if (EmployeeAccessTokenType.PERSON_SELF_ONBOARDED === this.state.type) {
      this.props.history.push("/login/individual");
      return;
    }
    this.props.history.push("/login");
  };

  render(): React.ReactNode {

    const {t} = this.props;
    const {status} = this.state;

    const title = status === "INVALID_CODE" ? "login.passwordlessLogin.title.invalidCode" : "";

    return <LoginView titleKey={title}>
      {
        status === "CHECKING" &&
        <Loader active inline />
      }
      {
        status === "INVALID_CODE" &&
        <>
          <InfoDiv>
            {t("login.passwordlessLogin.error.invalidCode")}
          </InfoDiv>

          <div className="actions-row">
            <Button
              type="button"
              className="action-button"
              color={"grey"}
              onClick={(evt) => this.goToLogin()}
            >
              {t("login.codeVerification.actions.gotoLogin")}
            </Button>
          </div>
        </>
      }
    </LoginView>;
  }
}

export default withRouterWorkaround(
    withAuthContext(
        withTranslation(["login"])(
            LoginPasswordlessLogin)));
