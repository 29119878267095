import {AxiosPromise, AxiosRequestConfig, AxiosResponse, CancelTokenSource} from "axios";
import {UnsubscribePageStatus} from "route/patient-email/EmailUnsubscribeView";
import {PageStatus} from "route/verify-email/VerifyEmailView";
import {getAuthorizationHeaderValue} from "service/browserStorageServices";
import axios from "service/http";
import {VerifyEmailStatus} from "ts-types/api.enums";
import {
  EmployeeAccountDisplayDto,
  EmployeeInviteRequest,
  PasswordlessLoginRequestDto,
  PatientQuestionnaireRequestDto,
  QuestionSetDto,
  UserDto
} from "ts-types/api.types";

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const baseAuthUrl = process.env.REACT_APP_AUTH_BASE_URL;

const localeResUrl = process.env.REACT_APP_LOCALE_BASE_URL;
const localePublicResUrl = process.env.REACT_APP_PUBLIC_LOCALE_BASE_URL;

export const sendMagicLink = (
    request: PasswordlessLoginRequestDto): Promise<AxiosResponse> => {

  return axios({
    url: `${baseAuthUrl}/pub/passwordless`,
    method: 'post',
    withCredentials: true,
    data: {
      ...request
    }
  });
};

export const getCandidateEmployees = (
    token: string, cancelSourceToken: CancelTokenSource): Promise<EmployeeAccountDisplayDto[]> => {

  return axios.get(`${baseAuthUrl}/pub/passwordless/employees`, {
    params: {
      token
    }
  }).then(response => {
    return response.data;
  });
};

export const getParticipantCandidateEmployees = (
    cancelSourceToken: CancelTokenSource): Promise<EmployeeAccountDisplayDto[]> => {

  return axios
  .post(`${baseUrl}/current-user/employees`, undefined, {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};

export const generateEmployeeCandidateAccessToken = (
    employeeId: string,
    cancelSourceToken: CancelTokenSource): Promise<string> => {

  return axios
  .post(`${baseUrl}/current-user/employees/${employeeId}`, {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};

export const sendInviteToEmployee = (
    employeeId: number): Promise<AxiosResponse> => {

  return axios.post(`${baseUrl}/invite-employee/${employeeId}`);
};

export const sendInviteToAllSelectedInitialEmployees = (
    request: EmployeeInviteRequest,
    source: CancelTokenSource): Promise<AxiosResponse> => {

  return axios
  .post(`${baseUrl}/invite-all-selected-initial-employees`, request, {cancelToken: source.token});
};

export const retrieveAccessToken = (
    username: string, password: string, verificationToken?: string) => {

  return axios({
    url: `${baseAuthUrl}/auth`,
    method: 'post',
    withCredentials: true,
    data: {
      username: username,
      password: password,
      verificationToken
    }
  });
};

export const getCurrentUser = (token?: string): Promise<UserDto> => {

  const config: AxiosRequestConfig = {};
  if (token) {
    config.params = {
      upn: token
    };
  }

  return axios.get(`${baseUrl}/current-user`, config).then(response => response.data);
};

export const verifyToken = (
    token: string, code?:
        string, employeeId?: string): AxiosPromise<string> => {

  const formData = new FormData();
  formData.set("token", token);
  if (code) {
    formData.set("code", code);
  }
  if (employeeId) {
    formData.set("employeeId", employeeId);
  }

  return axios.post(`${baseAuthUrl}/auth`, formData, {});
};

export const get2faData = (
    cancelSourceToken: CancelTokenSource): Promise<string> => {

  return axios.get(
      `${baseUrl}/current-user/mfa`,
      {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};

export const enable2fa = (
    code: string,
    cancelSourceToken: CancelTokenSource): Promise<string> => {

  const formData = new FormData();
  formData.set("code", code);
  return axios.post(
      `${baseUrl}/current-user/mfa/enable`,
      formData,
      {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};

export const loadLocaleResources = (
    url: string, isPrivateResource: boolean) => {

  const config: any = {
    url: `${localePublicResUrl}/${url}`,
    method: "get"
  };

  if (isPrivateResource) {
    config.url = `${localeResUrl}/${url}`;
    config.headers = {
      "Authorization": getAuthorizationHeaderValue()
    };
  }

  return axios(config);
};

export const getContainerForEmailAddressVerification = (
    token: string): AxiosPromise<string> => {

  return axios.get(`${baseAuthUrl}/pub/verify-email/container`, {params: {token}});
};

export const verifyEmailAddress = (
    token: string,
    verifyEmailStatus: VerifyEmailStatus): AxiosPromise<PageStatus> => {

  const formData = new FormData();
  formData.set("token", token);
  formData.set("verifyEmailStatus", verifyEmailStatus);

  return axios.post(`${baseAuthUrl}/pub/verify-email`, formData, {});
};

export const setEmailConsent = (
    token: string,
    emailConsent: boolean): Promise<PageStatus> => {

  const formData = new FormData();

  formData.set("token", token);
  formData.set("emailConsent", emailConsent.toString());

  return axios
  .post(`${baseAuthUrl}/pub/patient-email-consent`, formData, {})
  .then(response => response.data);
};

export const unsubscribePatientEmail = (
    token: string): Promise<UnsubscribePageStatus> => {

  return axios
  .post(`${baseAuthUrl}/pub/patient-email-unsubscribe`, {}, {params: {token}})
  .then(response => response.data);
};

export const getQuestionSetForInterventionIdToken = (
    interventionIdToken: string, cancelSourceToken: CancelTokenSource): Promise<QuestionSetDto> => {

  return axios
  .get(`${baseAuthUrl}/pub/patient-intervention-questionnaire/${interventionIdToken}`,
      {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};

export const getInterventionIdForQuestionnaireToken = (
    questionnaireToken: string, cancelSourceToken: CancelTokenSource): Promise<number> => {

  return axios
  .get(`${baseAuthUrl}/pub/patient-intervention-questionnaire/intervention/${questionnaireToken}`,
      {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};

export const addInterventionBookingForQuestionnaire = (
    request: Partial<PatientQuestionnaireRequestDto>,
    cancelSourceToken: CancelTokenSource): Promise<PatientQuestionnaireRequestDto> => {

  return axios
  .post(`${baseAuthUrl}/pub/add-patient-questionnaire-intervention-booking`,
      request,
      {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};
