import moment, {Moment} from 'moment';

export const ISO_DATE_FORMAT = 'YYYY-MM-DD';

export const isoToDisplayDate = (isoDate?: string): string => {
  if (!isoDate) {
    return "";
  }

  return moment(isoDate).format("DD.MM.YYYY");
};

export const isoToLongDisplayDate = (isoDate?: string): string => {
  if (!isoDate) {
    return "";
  }

  return moment(isoDate).format("dddd, DD. MMMM YYYY");
};

export const toLongDisplayDate = (date?: Moment): string => {
  if (!date) {
    return "";
  }

  return date.format("dddd, DD. MMMM YYYY");
};

export const isoToLongDisplayDateTime = (isoDate?: string, isoTime?: string): string => {
  const values = [];
  if (isoDate) {
    values.push(isoToLongDisplayDate(isoDate));
  }
  if (isoTime) {
    values.push(isoToDisplayTime(isoTime));
  }

  return values ? values.join(" — ") : "";
};

export const isoToDisplayTime = (isoTime?: string): string => {
  if (!isoTime) {
    return "";
  }

  return moment(isoTime, "HH:mm").format("HH:mm");
};

export const fromIsoDateString = (isoDate?: string): Moment | undefined => {
  if (!isoDate) {
    return undefined;
  }

  return moment(isoDate, "YYYY-MM-DD");
};

export const fromIsoDateTimeString = (isoDateTime?: string): Moment | undefined => {
  if (!isoDateTime) {
    return undefined;
  }

  return moment(isoDateTime, "YYYY-MM-DDTHH:mm");
};

export const toIsoDateString = (date?: moment.Moment): string => {
  if (!date) {
    return "";
  }

  return date.format("YYYY-MM-DD");
};
