import React from 'react';
import {FieldRenderProps} from 'react-final-form';
import TimeField from 'react-simple-timefield';
import {Form, Input} from 'semantic-ui-react';
import styled from 'styled-components';

export const StyledTimeInput = styled(Input)`

  &.ui.input > input {
    padding: 5px 5px;
  }

  &.ui.mini.input {
    width: 50px;
  }

  &.ui.mini.input > input {
    font-size: 1rem;
  }

  &.ui.small.input > input {
    font-size: 1rem;
  }
`;

interface ExtendedFieldRenderProps extends FieldRenderProps<any> {
  fieldClassName?: string;
  disabled?: boolean;
  autoComplete?: "on" | "off";
  size?: 'mini' | 'small' | 'large' | 'big' | 'huge' | 'massive';
}

const onTimeChange = (value: any) => {
  const newTime = value.replace(/-/g, ':');
  return newTime.substr(0, 5);
}

const FinalFormTimeInput = (props: ExtendedFieldRenderProps) => {

  const {
    input,
    disabled = false,
    meta: {touched, error},
    autoComplete = "off",
    size = "mini",
    ...restProps
  } = props;

  return (
      <Form.Field className={props.fieldClassName}>
        <TimeField
            value={input.value}
            onChange={(event: any, value: any) => {
              input.onChange(onTimeChange(value))
            }}
            input={
              <StyledTimeInput
                  disabled={disabled}
                  error={!!(touched && error)}
                  {...restProps}
                  autoComplete={autoComplete}
                  size={size}
              />
            }
        />
      </Form.Field>
  );
};

export default FinalFormTimeInput;
