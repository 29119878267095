import {BigActionButton} from "component/BigActionButton";
import React, {Component} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";
import LoginTypeView from "route/login/LoginTypeView";
import {SelfServiceContainerDto} from "ts-types/api.types";
import {withRouterWorkaround} from "util/workaroundUtils";

interface Props extends RouteComponentProps<any>, WithTranslation {
  containerData?: SelfServiceContainerDto,
  onCompanySignin?: () => void;
  onPersonSignin?: () => void;
  onPersonSelfOnboardContainer?: () => void;
}

class LoginTypeSelectionContainer extends Component<Props> {

  containerTextDefault = (textKey: string): string => {
    const {t} = this.props;

    let text = undefined;

    const {containerData} = this.props;
    if (containerData) {
      const suffix = containerData.textResourcesSuffix
          ? containerData.textResourcesSuffix : containerData.type;
      const key = `login.typeSelectionContainer_${suffix}.${textKey}`;
      text = t(key, "NO_VALUE");
    }

    if (text === "NO_VALUE") {
      text = t(`login.typeSelectionContainer.${textKey}`);
    }
    return text || textKey;
  };

  render(): React.ReactNode {

    const {
      t, location, containerData, onCompanySignin, onPersonSignin, onPersonSelfOnboardContainer
    } = this.props;

    const locationState: any = this.props.location.state;
    if (locationState && locationState.reload && locationState.reload === "yes") {
      return <></>;
    }

    if (!containerData) {
      return <></>;
    }

    if (containerData.type === "SWS") {
      return this.renderSweepstakeContainer();
    }

    const individualLogin = containerData.enableParticipantLogin;
    const institutionLogin = containerData.enableInstitutionLogin;

    return <LoginTypeView titleKey="login.typeSelectionContainer.title">
      <div className="row">
        <div className="column no-border">

          <div className="tile">
            <div className="column-title">
              {this.containerTextDefault("subTitle")}
            </div>
            <div className="column-text">
              {t("login.typeSelectionContainer.imWorkingAlreadyWith.text")}
            </div>

            {
                individualLogin &&
              <BigActionButton
                icon="user"
                textDomain="login"
                textKey={this.containerTextDefault("individual.button")}
                onClick={onPersonSignin}
              />
            }

            {
                institutionLogin &&
              <BigActionButton
                icon="building"
                textDomain="login"
                textKey={this.containerTextDefault("company.button")}
                onClick={onCompanySignin}
              />
            }

          </div>

          <div className="tile">
            <div className="column-text">
              {t("login.typeSelectionContainer.firstTimeHere.text")}
            </div>
            <BigActionButton
                icon="user"
                textDomain="login"
                textKey={this.containerTextDefault("personSelfOnboard.button")}
                onClick={onPersonSelfOnboardContainer}
            />
          </div>

        </div>

      </div>

    </LoginTypeView>;
  }

  renderSweepstakeContainer(): React.ReactNode {

    const {
      t, containerData, onPersonSignin, onPersonSelfOnboardContainer, history
    } = this.props;

    return <LoginTypeView titleKey="login.typeSelectionContainer.sweepstake.title">
      <div className="row">
        <div className="column no-border">

          <div className="tile">

            <BigActionButton
                icon="gift"
                textDomain="login"
                textKey={this.containerTextDefault("personSelfOnboard.button")}
                onClick={onPersonSelfOnboardContainer}
            />

            <BigActionButton
                icon="flask"
                textDomain="login"
                textKey={t("login.typeSelectionContainer_SWS.goToTesting.button")}
                onClick={() => {
                  history.push("/login/msntselfservice", {reload: "true"});
                  window.location && window.location.reload();
                }}
            />

          </div>

        </div>

      </div>

    </LoginTypeView>;
  }

}

export default withRouterWorkaround(withTranslation(["login"])(LoginTypeSelectionContainer));
