import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import React, {Fragment} from 'react';
import {withTranslation, WithTranslation} from "react-i18next";
import styled from 'styled-components';
import {QuestionAnswerDto} from 'ts-types/api.types';
import {qaQuestionText} from "util/questionSetUtils";

const ResultsPane = styled.div`
  flex: 0 0 auto;
  margin-right: 15px;
  min-width: 15rem;
  max-width: 36rem;

  .results-header {
    font-weight: bold;
    font-size: 1.1rem;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 0.5rem;
  }

  .results-container {
    display: grid;
    grid-template-columns: minmax(min-content, 1fr) minmax(3.5rem, min-content);
    row-gap: 0.35rem;
    column-gap: 1.2rem;

    .result-question {
      color: #575757;
    }

    .result-answer.no-result {
      color: #a5a5a5;
    }
  }

`;

export interface QuestionSetViewProps extends AuthConsumerRenderProps, WithTranslation {
  questionSetName: string,
  questionAnswerPairs: Array<QuestionAnswerDto>
}


const QuestionSetView = (props: QuestionSetViewProps) => {

  const {
    questionAnswerPairs,
    t
  } = props;

  return (
      <ResultsPane>
        {/*<div className="results-header">{questionSetName}</div>*/}
        {/*<div className="results-header">{t("vaccinationBooking.anamnesis.answers.title")}</div>*/}
        <div className="results-container">
          {
            questionAnswerPairs.map(questionAnswerPair => {

              let answerText = questionAnswerPair.selectedAnswers.join(", ");
              let resultClass = "";
              if (!answerText || answerText === "") {
                return null;
              }

              if (answerText === 'true') {
                answerText = t("anamnesis.answer.yes");
              } else if (answerText === 'false') {
                answerText = t("anamnesis.answer.no");
              }

              return (
                  <Fragment key={`answer-row-${questionAnswerPair.questionId}`}>
                    <div className="result-question">
                      {qaQuestionText(questionAnswerPair, props.language)}
                    </div>
                    <div className={`result-answer ${resultClass}`}>
                      {answerText}
                    </div>
                  </Fragment>
              );
            }).filter(Boolean)
          }
        </div>
      </ResultsPane>
  );
};

export default withAuthContext(withTranslation("mipoco")(QuestionSetView));
