import {AuthProvider} from "auth/AuthContext";
import GlobalStyle from "globalStyle";
import React from 'react';
import 'react-image-lightbox/style.css';
import './App.css';
import Main from "./route/Main";

function App() {

  return (
      <AuthProvider>
        <GlobalStyle/>
        <Main />
      </AuthProvider>
  );
}

export default App;
