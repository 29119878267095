import {AuthConsumerRenderProps, withAuthContext} from 'auth/AuthContext';
import axios, {AxiosError, CancelTokenSource} from 'axios';
import {FormApi} from 'final-form';
import React, {Component} from 'react';
import {WithTranslation, withTranslation} from "react-i18next";
import {RouteComponentProps} from 'react-router';
import LoginCodeVerification from "route/login/LoginCodeVerification";
import LoginEmailSignin from "route/login/LoginEmailSignin";
import LoginTypeSelection from "route/login/LoginTypeSelection";
import LoginTypeSelectionContainer from "route/login/LoginTypeSelectionContainer";
import {Message, MessageProps} from 'semantic-ui-react';
import {sendMagicLink} from "service/userServices";
import {EmployeeAccessTokenType} from "ts-types/api.enums";
import {PasswordlessLoginRequestDto} from "ts-types/api.types";
import {required} from 'util/validatorUtils';
import {withRouterWorkaround} from 'util/workaroundUtils';


enum Error {
  UNKNOWN_ACCOUNT_EMAIL = "UNKNOWN_ACCOUNT_EMAIL",
  PASSWORD_RESET_TOKEN_INVALID = "PASSWORD_RESET_TOKEN_INVALID",
  PASSWORD_RESET_TOKEN_EXPIRED = "PASSWORD_RESET_TOKEN_EXPIRED",
  INVALID_EMAIL_ADDRESS = "INVALID_EMAIL_ADDRESS"
}

interface FormValues {
  // username: string;
  // password: string;
  // repeatPassword: string;
  // verificationCode: string;
  email: string;
}

type Segments = "selectLoginType"
    | "emailSignin"
    | "codeVerification"
/*| "forgotPasswordVisible"
| "validatingTokenVisible"
| "resetPasswordVisible"*/;

interface Props extends RouteComponentProps<any>, WithTranslation, AuthConsumerRenderProps {
  token?: string;
}

interface State {
  success: boolean;
  error: boolean;
  errorMessage: string;
  submitting: boolean;
  currentSegment: Segments;
  // forgotPasswordVisible: boolean;
  // validatingTokenVisible: boolean;
  // resetPasswordVisible: boolean;
  cancelTokenSource: CancelTokenSource;
}

class Login extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    // const {history: {location}} = this.props;
    const cancelTokenSource = axios.CancelToken.source();

    // const isResetPassword = location.pathname.includes("/reset-password");
    // if (isResetPassword) {
    //   this.validateToken(cancelTokenSource);
    // }

    this.state = {
      success: false,
      error: false,
      errorMessage: "",
      submitting: false,
      currentSegment: "selectLoginType" /*&& !isResetPassword*/,
      // forgotPasswordVisible: false,
      // validatingTokenVisible: isResetPassword,
      // resetPasswordVisible: false,
      cancelTokenSource
    };
  }

  componentDidMount = () => {
    const pathname = this.props.location.pathname;
    if (pathname === "/login/company") {
      this.requestSignIn(EmployeeAccessTokenType.COMPANY);
      return;
    } else if (pathname === "/login/person") {
      this.requestSignIn(EmployeeAccessTokenType.PERSON);
      return;
    } else if (pathname === "/login/individual") {
      this.requestSignIn(EmployeeAccessTokenType.PERSON_SELF_ONBOARDED);
      return;
    }

    const companyContainerMatch = pathname.match(/\/login\/company\/(\w+)$/);
    if (companyContainerMatch) {
      this.requestSignIn(EmployeeAccessTokenType.COMPANY, companyContainerMatch[1]);
      return;
    }

    const personContainerMatch = pathname.match(/\/login\/person\/(\w+)$/);
    if (personContainerMatch) {
      this.requestSignIn(EmployeeAccessTokenType.PERSON, personContainerMatch[1]);
      return;
    }

    const containerLoginMatch = pathname.match(/\/login\/(\w+)$/);
    if (containerLoginMatch) {
      this.props.setContainer(containerLoginMatch[1]);
      return;
    }
  };

  validatorImpl = (value: string, allValues: Partial<FormValues>): string | undefined => {

    if (!required(value)) {
      // if (allValues.password != allValues.repeatPassword) {
      //   this.setState({
      //     error: true,
      //     errorMessage: t('resetPassword.passwordsDontMatch')
      //   });
      //
      //   return "Required";
      // } else if (allValues.password!.length < 8) {
      //   this.setState({
      //     error: true,
      //     errorMessage: t('resetPassword.passwordFieldLength')
      //   });
      //
      //   return "Required";
      // }

      this.setState({
        error: false,
        errorMessage: ""
      });
    } else {
      return "Required";
    }
  };

  resetErrors = () => {
    this.setState({
      error: false,
      errorMessage: ""
    });
  };

  startTransitioningTo = (segmentFrom: Segments | undefined, segmentTo: Segments, form?: FormApi) => {

    if (form) {
      form.reset();
    }

    this.resetErrors();
    this.setState({
      success: false,
      currentSegment: segmentTo
    });

    // this.transitionTo(segmentTo);
  };

  // transitionTo = debounce((segment: Segments) => {
  //   this.setState({
  //     [segment]: true
  //   })
  // }, 500);

  // removeVerificationCodeInput = () => {
  //   this.setState({
  //     verificationCodeKey: this.state.verificationCodeKey + 1,
  //     verificationCodeInput: false,
  //     verificationCodeError: false,
  //   });
  // }

  goToLogin = () => {

    const {history} = this.props;

    history.push("/login");
  };

  handleSuccess = () => {
    this.resetErrors();
    this.setState({
      success: true,
      submitting: false
    });
  };

  setErrorMessage = (message: string | undefined) => {
    const {t} = this.props;
    this.setState({
      error: true,
      errorMessage: message ? t(message) : "",
      submitting: false
    });
  };

  handleError = (error: AxiosError, form?: FormApi) => {

    const messageKey = error.response && error.response!.status === 401 ? undefined : "general.unknownError";

    if (error.response) {

      const {violations/*, message*/} = error.response!.data;

      // if (message && message.includes("Invalid verification code")) {
      //   this.setState((prevState) => {
      //     return {
      //       verificationCodeKey: prevState.verificationCodeKey + 1,
      //       verificationCodeInput: true,
      //       verificationCodeError: prevState.verificationCodeInput,
      //       submitting: false
      //     }
      //   });
      //
      //   if (form) {
      //     form.change("verificationCode", undefined);
      //   }
      //   setTimeout(() => {
      //     (document.getElementsByClassName("verification-input")[0]! as any).focus();
      //   }, 50);
      //
      //   return;
      // }

      if (violations) {
        const violation = violations[0];
        switch (violation.errorCode) {
          case Error.INVALID_EMAIL_ADDRESS: {
            this.setErrorMessage("resetPassword.validEmail");
            return;
          }
          case Error.UNKNOWN_ACCOUNT_EMAIL: {
            this.setErrorMessage("resetPassword.unknownEmail");
            return;
          }
          case Error.PASSWORD_RESET_TOKEN_EXPIRED:
          case Error.PASSWORD_RESET_TOKEN_INVALID: {
            this.setErrorMessage("resetPassword.tokenExpired");
            return;
          }
        }
      }
    }
    this.setErrorMessage(messageKey);
  };

  // validateToken = (cancelTokenSource: CancelTokenSource) => {
  //
  //   const {token, t} = this.props;
  //
  //   const transitionToResetPassword = () =>
  //       this.startTransitioningTo("validatingTokenVisible", "resetPasswordVisible");
  //
  //   if (token) {
  //     validateToken(token, cancelTokenSource)
  //         .then(transitionToResetPassword)
  //         .catch(this.handleError);
  //   } else {
  //     this.setState({
  //       error: true,
  //       errorMessage: t("resetPassword.tokenExpired")
  //     })
  //   }
  // };

  // login = (values: Partial<FormValues>, form: FormApi) => {
  //
  //   const {t} = this.props;
  //   const {login, history, location} = this.props;
  //   const {cancelTokenSource} = this.state;
  //
  //   // const {from} = location.state || {from: {pathname: '/'}};
  //
  //   if (login) {
  //     login(values.email!)
  //         .then((user: UserDto) => {
  //
  //           const {userConfig: {mandateId}, accountMandates} = user;
  //
  //           if (mandateId || accountMandates.length == 1) {
  //             const mandate = mandateId ?
  //                 accountMandates.find(mandate => mandate.id == mandateId) : accountMandates[0];
  //             const messageKey = mandateId ? "login.lastMandate" : "login.loggedMandate";
  //
  //             // @ts-ignore
  //             const notification = notificationSystem.current;
  //             if (notification) {
  //               notification.addNotification({
  //                 title: getMessage("login.loggedIn"),
  //                 message: `${getMessageWithNamedParams(messageKey, {mandate: mandate!.name})}`,
  //                 level: 'info'
  //               });
  //             }
  //
  //             if (!mandateId) {
  //               const upsertUserConfig: Partial<UpsertUserConfigDto> = {
  //                 mandateId: accountMandates[0].id
  //               };
  //
  //               saveUserConfig(upsertUserConfig, cancelTokenSource).then(response => {
  //                 updateUserConfig(response);
  //                 history.push(from.pathname);
  //               }).catch(noop);
  //             } else {
  //               history.push(from.pathname);
  //             }
  //           } else {
  //             this.startTransitioningTo("enterCredentialsVisible", "chooseMandateVisible");
  //           }
  //         })
  //         .catch(error => {
  //           this.handleError(error, form);
  //         });
  //   }
  // };

  // handleSubmit = (values: Partial<FormValues>, form: FormApi) => {
  //   const {currentUser} = this.props;
  //   const {enterCredentialsVisible, resetPasswordVisible} = this.state;
  //
  //   this.setState({
  //     submitting: true
  //   });
  //
  //   if (enterCredentialsVisible && !currentUser) {
  //     this.login(values, form);
  //   } else if (resetPasswordVisible) {
  //     this.resetPassword(values);
  //   } else {
  //     this.createResetPasswordToken(values.email!);
  //   }
  // };

  requestCompanySelfOnboard = () => {
    this.props.history.push("/company-onboard");
  };

  requestPersonSelfOnboard = () => {
    this.props.history.push("/person-onboard");
  };

  requestPersonSelfOnboardContainer = () => {
    this.props.history.push("/container/person-onboard");
  };

  requestSignIn = (accessType: EmployeeAccessTokenType, container?: string) => {
    this.props.setAccessType(accessType, container);
    this.startTransitioningTo(undefined, "emailSignin");
  };

  requestLoginLink = async (request: PasswordlessLoginRequestDto) => {
    await sendMagicLink(request);
    this.startTransitioningTo(undefined, "codeVerification");
  };

  verifyCode = async (token: string) => {
    try {

      await this.props.verifyToken(token);

      this.props.history.push("/", {welcome: "true"});

    } catch (e) {
      const {response} = e;
      if (response?.data?.error) {
        const {error} = response.data;

        if (["INVALID_VERIFICATION_CODE"].includes(error)) {
          this.props.history.push("/verification-code", {token: token});
          return;
        } else if (["MULTIPLE_CANDIDATE_ACCOUNTS"].includes(error)) {
          this.props.history.push("/account-selection", {token: token});
          return;
        }
      }
    }
  };

  render(): React.ReactNode {

    // const {t} = this.props;
    const {accessType, accessContainer, accessContainerData} = this.props;
    const {currentSegment} = this.state;

    return (
        <>
          {
            currentSegment === "selectLoginType" && !accessContainer &&
            <LoginTypeSelection
              onCompanySignin={() => this.requestSignIn(EmployeeAccessTokenType.COMPANY, accessContainer)}
              onPersonSignin={() => this.requestSignIn(EmployeeAccessTokenType.PERSON, accessContainer)}
              onCompanyOnboard={this.requestCompanySelfOnboard}
              onPersonSelfOnboard={this.requestPersonSelfOnboard}
              onPersonSelfOnboardSignin={() => this.requestSignIn(EmployeeAccessTokenType.PERSON_SELF_ONBOARDED)}
            />
          }
          {
            currentSegment === "selectLoginType" && accessContainer &&
            <LoginTypeSelectionContainer
              containerData={accessContainerData}
              onCompanySignin={() => this.requestSignIn(EmployeeAccessTokenType.COMPANY, accessContainer)}
              onPersonSignin={() => this.requestSignIn(EmployeeAccessTokenType.PERSON, accessContainer)}
              onPersonSelfOnboardContainer={this.requestPersonSelfOnboardContainer}
            />
          }
          {
            currentSegment === "emailSignin" &&
            <LoginEmailSignin
              accessType={accessType!}
              container={accessContainer}
              onRequestLoginLink={this.requestLoginLink}
            />
          }
          {
            currentSegment === "codeVerification" &&
            <LoginCodeVerification
              onVerifyCode={this.verifyCode}
              onBackToLogin={() => this.startTransitioningTo(undefined, "emailSignin")}
            />
          }
        </>
    );
  }

  renderMessage = (header: string, text: string, messageProps: MessageProps): React.ReactNode => {
    return (
        <Message {...messageProps}>
          <Message.Header>{header}</Message.Header>
          <p>{text}</p>
        </Message>
    );
  };
}

export default withRouterWorkaround(withAuthContext(withTranslation(["login"])(Login)));
