import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import AppView from "component/AppView";
import React, {Component} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";
import TwoStepAuthModal from "route/init-2fa/TwoStepAuthModal";
import {Button} from "semantic-ui-react";
import styled from "styled-components";
import {applyStyles} from "util/localizationUtils";
import {withRouterWorkaround} from "util/workaroundUtils";

const ContainerDiv = styled.div`

  .info-box {
    max-width: 40rem;
    margin-bottom: 1.5rem;
  }

  .actions-row {
    margin-top: 2rem;
  }
`;

interface Props extends RouteComponentProps<any>, AuthConsumerRenderProps, WithTranslation {
}

interface State {
  modalOpen: boolean;
}

class Init2fa extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      modalOpen: false
    };
  }

  onCloseModal = () => {
    this.setState({modalOpen: false});
  };

  onInit2faSuccess = () => {
    this.setState({modalOpen: false});
    this.proceedToHomePage();
  };

  proceedToHomePage = () => {
    this.props.setSkip2FaInit(true);
    this.props.history.push("/", {welcome: true});
  };

  render() {

    const {t} = this.props;

    return <AppView titleKey={t("init2fa.title")}>
      <ContainerDiv>
        <div key="info-box" className="info-box">{applyStyles(t("init2fa.info"))}</div>

        <div key="actions-box" className="actions-row">
          <Button
              type="button"
              className="action-button"
              primary
              onClick={(evt) => {
                this.setState({
                  modalOpen: true
                });
              }}
          >
            {t("init2fa.action.setup2fa")}
          </Button>

          <Button
              type="button"
              className="action-button"
              color="grey"
              onClick={this.proceedToHomePage}
          >
            {t("init2fa.action.continueWout2fa")}
          </Button>
        </div>

        <TwoStepAuthModal
            key="init-2fa-modal"
            modalOpen={this.state.modalOpen}
            onCloseModal={() => this.onCloseModal()}
            onInit2faSuccess={() => this.onInit2faSuccess()}
        />
      </ContainerDiv>
    </AppView>;
  }
}

export default withRouterWorkaround(withAuthContext(withTranslation(["mipoco"])(Init2fa)));
