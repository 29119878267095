import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import StyledErrorMessage from "component/StyledErrorMessage";
import React, {Component} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";
import InstructionsView from "route/person-onboard/InstructionsView";
import PersonDetails from "route/person-onboard/PersonDetails";
import PersonOnboardContainer from "route/person-onboard/PersonOnboardContainer";
import ValidationView from "route/person-onboard/ValidationView";
import {convertverifyOnboardingToken} from "service/onboardingServices";
import {UpsertEmployeeDto} from "ts-types/api.types";
import {withRouterWorkaround} from "util/workaroundUtils";

export enum PersonOnboardViews {
  INSTRUCTIONS = "INSTRUCTIONS",
  VALIDATION = "VALIDATION",
  PERSON_DETAILS = "PERSON_DETAILS"
}

interface Props extends RouteComponentProps<any>,
    AuthConsumerRenderProps,
    WithTranslation {

}

interface State {
  activeView: PersonOnboardViews,
  personOnboarding: Partial<UpsertEmployeeDto>,
  token?: string,
  errorMessages: Array<string>
  personEmail: string
}

class PerosnOnboardView extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      activeView: PersonOnboardViews.INSTRUCTIONS,
      personOnboarding: this.initialPersonOnboardingValues(),
      errorMessages: [],
      personEmail: ""
    };
  }

  initialPersonOnboardingValues = (): Partial<UpsertEmployeeDto> => {
    return {
      companyEmail: "",
      name: "",
      firstName: "",
      birthDate: "",
      sex: 2,
      telephone: "",
      language: "de"
    };
  };

  setActiveView = (activeView: PersonOnboardViews) => {
    this.setState({
      activeView: activeView
    });
  };

  setPersonOnboardingData = (data: Partial<UpsertEmployeeDto>) => {
    this.setState({
      personOnboarding: data
    });
  };

  setPersonEmail = (email: string) => {
    this.setState({
      personEmail: email
    });
  };

  setToken = (token: string) => {
    this.setState({
      token: token
    });
  };

  verifyCode = async () => {

    const token = this.state.token;
    if (!token) {
      return;
    }

    try {

      const accessToken = await convertverifyOnboardingToken(token);

      await this.props.verifyToken(accessToken);

      this.props.history.push("/", {welcome: "true"});

    } catch (e) {
      const {response} = e;
      if (response?.data?.error) {
        const {error} = response.data;

        if (["INVALID_VERIFICATION_CODE"].includes(error)) {
          this.props.history.push("/verification-code", {token: token});
          return;
        } else if (["MULTIPLE_CANDIDATE_ACCOUNTS"].includes(error)) {
          this.props.history.push("/account-selection", {token: token});
          return;
        }
      }
    }
  };

  setErrorMessage = (errorMessage?: string) => {

    const {errorMessages} = this.state;

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);

      this.setState({
        errorMessages: errMsgs
      });
    } else {

      this.setState({
        errorMessages: []
      });
    }
  };

  renderActiveView = () => {

    const {personOnboarding, activeView, personEmail} = this.state;

    if (personEmail) {
      personOnboarding.companyEmail = personEmail;
    }

    switch (activeView) {
      case PersonOnboardViews.VALIDATION: {
        return <ValidationView
            setActiveView={this.setActiveView}
            setCompanyEmail={this.setPersonEmail}
            setToken={this.setToken}
        />;
      }

      case PersonOnboardViews.PERSON_DETAILS: {
        return <PersonDetails
            personOnboarding={personOnboarding}
            setPersonOnboardingData={this.setPersonOnboardingData}
            setActiveView={this.setActiveView}
            onRegistrationSuccess={this.verifyCode}
            setErrorMessage={this.setErrorMessage}
        />;
      }

      default: {
        return <InstructionsView
            setActiveView={this.setActiveView}
        />;
      }
    }
  };

  render(): React.ReactNode {

    const {errorMessages, activeView} = this.state;

    return (
        <PersonOnboardContainer titleKey={`personOnboard.${activeView}.title`} activeView={activeView}>
          {errorMessages.length > 0 &&
          <StyledErrorMessage onDismiss={() => this.setErrorMessage()}>
            {errorMessages.map(err => <div key={err}>{err}</div>)}
          </StyledErrorMessage>
          }
          {this.renderActiveView()}
        </PersonOnboardContainer>
    );
  }
}

export default withRouterWorkaround(
    withAuthContext(
        withTranslation(["login"])(
            PerosnOnboardView)));