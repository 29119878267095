import React, {FunctionComponent} from "react";
import {useTranslation, withTranslation} from "react-i18next";
import {Icon} from 'semantic-ui-react';
import {SemanticICONS} from "semantic-ui-react/dist/commonjs/generic";
import styled from "styled-components";
import {applyStyles} from "util/localizationUtils";

export const ButtonSc = styled.button`
  border: 1.5px solid #7687FF;
  background: #7687FF;
  font-size: 1.3rem;
  font-weight: bold;
  color: white;
  max-width: 28rem;
  min-height: 5.8rem;
  padding: 1rem;
  cursor: pointer;

  &:FOCUS {
    outline: none;
    border: 1.5px solid #1b33d4;
  }

  &:FOCUS:HOVER {
    background: #5B70FF;
  }

  &:HOVER {
    background: #8e9bff;
  }

  &.disabled {
    background: #BAC3FF;
    border: 1.5px solid #BAC3FF;
    cursor: default;

    &:FOCUS:HOVER {
      background: #BAC3FF;
      border: 1.5px solid #BAC3FF;
    }
  }

  .content {
    display: flex;
    flex-direction: row;

    .icon {
      align-self: center;
      flex: 0 0 auto;
    }

    .text {
      flex: 1 1 auto;
      text-align: left;
      margin-left: 1rem;
    }
  }
`;

interface BigActionButtonProps {
  icon: SemanticICONS;
  textKey: string;
  textDomain: "login" | "mipoco";
  applyTextStyles?: boolean;
  disabled?: boolean;
  onClick?: (evt: any) => void;
}

export const BigActionButton: FunctionComponent<BigActionButtonProps> = (
    {icon, textKey, textDomain, disabled = false, ...props}) => {

  const {t} = useTranslation(textDomain);
  const {onClick} = props;

  return <ButtonSc
      className={`${disabled ? 'disabled' : ''} login-button`}
      type="button"
      onClick={(evt) => {
        if (disabled) {
          evt.preventDefault();
          return;
        }
        if (onClick) {
          onClick(evt);
        }
      }}
  >
    <div className="content">
      <Icon className="icon" name={icon} size="big" />
      {
        props.applyTextStyles
            ? <span className="text">{applyStyles(t(textKey))}</span>
            : <span className="text">{t(textKey)}</span>
      }
    </div>
  </ButtonSc>;
};

export default withTranslation(["login"])(BigActionButton);
