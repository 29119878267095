import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    .ui.button {
      border-radius: unset;
    }

    .ui.primary.button, .ui.primary.buttons .button {
      background-color: #7687FF;

      :active {
        background-color: #6877e2;
      }

      :hover {
        background-color: #5563ca;
      }

      :focus {
        background-color: #505dc8;
      }
    }

    .ui.secondary.button, .ui.secondary.buttons .button {
      background-color: #919191;

      //:active {
      //  background-color: #6877e2;
      //}
      //
      //:hover {
      //  background-color: #5563ca;
      //}
      //
      //:focus {
      //  background-color: #505dc8;
      //}
    }

    .field > .ui.input {
      width: 100%;
      height: 100%;
    }

    .ui.input > input {
      border: 1px solid #c9d0ff;
      border-radius: unset;
      padding: .5rem 0.675rem
    }

    .ui.disabled.input > input {
      opacity: .45;
    }

    .ui.input.down input,
    .ui.input > input:active {
      //border: 1px solid #7687FF;
    }

    .ui.input.focus > input,
    .ui.input > input:focus {
      border: 1px solid #7687FF;
    }

    .ui.disabled.input {
      opacity: 1;
    }

    label {
      color: #1d213c;
      font-size: 1.1589rem;
    }

    .ui.selection.dropdown, &.ui.selection.active.dropdown .menu {
      border: 1px solid #c9d0ff;
      border-radius: unset;
      box-shadow: unset;
      width: 100%;
    }

    .ui.toggle.checkbox input:checked ~ .box:before,
    .ui.toggle.checkbox input:checked ~ label:before {
      background-color: #7687FF !important;
    }
  }
`;

export default GlobalStyle;