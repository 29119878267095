import React from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {EmployeeTestResultDto} from "ts-types/api.types";
import {isoToLongDisplayDate} from "util/dateUtils";

const StyledContainer = styled.div`
  .info-text {
    font-size: 1.1rem;
  }

  .dose-info {
    display: flex;

    .appointment {
      font-weight: bold;
      margin: 0 4rem 0 0.5rem;
    }

    .value-row {
      display: flex;
      flex-direction: row;

      .label {
        min-width: 10rem;
        color: #545454;
      }

      .value {
        font-weight: bold;
      }
    }
  }
`;

interface Props {
  testResult: EmployeeTestResultDto,
  locationDescription: string,
  translationKey: string
}

const TestInfo = (props: Props) => {

  const {t} = useTranslation(props.translationKey);
  const {testResult, locationDescription} = props;

  return <StyledContainer>
    <div className="info-text">
      <div className="dose-info">
        <div>
          <div className="value-row">
            <div className="label">{t("personDashboard.appointment.place")}</div>
            <div className="value">{locationDescription}</div>
          </div>

          <div className="value-row">
            <div className="label">{t("personDashboard.appointment.dateTime")}</div>
            <div className="value">
              {`${isoToLongDisplayDate(testResult.appointmentDate)} - ${testResult.appointmentTime.substr(0, 5)}`}
            </div>
          </div>
        </div>
      </div>
    </div>
  </StyledContainer>;
};

export default TestInfo;