import React, {useRef} from 'react';
import {Button} from 'semantic-ui-react';

interface UploadButtonProps {
    message?: string
    setFile: (file: File) => void
}

const UploadButton = (props: UploadButtonProps) => {

    const inputFile = useRef(null);

    const onButtonClick = () => {
        // `current` points to the mounted file input element
        // @ts-ignore
        inputFile.current.click();
    };

    const handleChange = (event: any) => {
        const fileUploaded = event.target.files[0];
        props.setFile(fileUploaded);
    };

    const {
        message
    } = props;

    return (
        <React.Fragment>
            <input type='file' id='file' ref={inputFile} style={{display: 'none'}} onChange={handleChange} />
            <Button
                type="button"
                className="action-button"
                onClick={onButtonClick}
                primary
            >
                {message}
            </Button>
        </React.Fragment>

    );
};

export default UploadButton;
