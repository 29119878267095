import React, {Component, ReactNode} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {Button, Grid, Header, Popup} from 'semantic-ui-react';
import styled from "styled-components";

const PopupGrid = styled(Grid)`

  .ui.button {
    border-radius: unset;
  }
  
  .ui.primary.button, .ui.primary.buttons .button {
    background-color: #7687FF;

    :active {
      background-color: #6877e2;
    }

    :hover {
      background-color: #5563ca;
    }

    :focus {
      background-color: #505dc8;
    }
  }

  .ui.secondary.button, .ui.secondary.buttons .button {
    background-color: #919191;

    //:active {
    //  background-color: #6877e2;
    //}
    //
    //:hover {
    //  background-color: #5563ca;
    //}
    //
    //:focus {
    //  background-color: #505dc8;
    //}
  }
  
  .actions-row {
    button {
      margin-right: 0.75rem;
    }
  }
`;

interface Props extends WithTranslation {
  message?: string,
  onOpen?: () => void,
  poperDependencies?: any[],
  onConfirmAction: () => void,
  renderTarget: React.ReactNode,
  position?:
      | 'top left'
      | 'top right'
      | 'bottom right'
      | 'bottom left'
      | 'right center'
      | 'left center'
      | 'top center'
      | 'bottom center',
  disabled?: boolean,
  disabledYes?: boolean,
  renderContent?: (toggle?: () => void) => ReactNode,
  style?: Object
}

interface State {
  popupOpen: boolean
}

class ConfirmActionPopup extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      popupOpen: false
    }
  }

  togglePopup = () => {
    this.setState(({popupOpen}: State) => ({
      popupOpen: !popupOpen
    }));
  };

  onConfirm = () => {
    this.togglePopup();
    this.props.onConfirmAction();
  };

  render(): React.ReactNode {

    const {
      renderTarget,
      position,
      disabled,
      poperDependencies,
      onOpen,
      renderContent = this.renderContent,
      style
    } = this.props;

    const {popupOpen} = this.state;

    return (
        <Popup
            trigger={(<span>{renderTarget}</span>)}
            content={renderContent(this.togglePopup)}
            open={popupOpen}
            popperDependencies={poperDependencies}
            onClose={() => !disabled && this.togglePopup()}
            onOpen={() => {
              if (!disabled) {
                this.togglePopup();

                if (onOpen) {
                  onOpen();
                }
              }
            }}
            on='click'
            position={position || 'top center'}
            hideOnScroll
            style={style}
        />
    );
  };

  renderContent = (toggle?: () => void): React.ReactNode => {

    const {t, message, disabledYes} = this.props;
    return (
        <PopupGrid>
          <Grid.Row>
            <Grid.Column>
              <Header size="small">{message}</Header>
            </Grid.Column>
          </Grid.Row>
            <Grid.Row>
              <Grid.Column className="actions-row">
                <Button compact primary onClick={this.onConfirm} disabled={disabledYes}>{t("button.yes")}</Button>
                <Button compact onClick={this.togglePopup}>{t("button.no")}</Button>
              </Grid.Column>
            </Grid.Row>
        </PopupGrid>
    );
  }

}

export default withTranslation(["mipoco"])(ConfirmActionPopup);
