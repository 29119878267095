import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import {CompanyDataConsumerRenderProps, withCompanyDataContext} from "component/CompanyDataContext";
import CompanyDataHeader from "component/CompanyDataHeader";
import Input from "component/final-form/Input";
import RadioGroup from "component/final-form/RadioGroup";
import Select, {DropdownOption, mapToDropdownOptionArray} from "component/final-form/Select";
import LoaderComponent from "component/LoaderComponent";
import MpGrid from "component/MpGrid";
import StyledErrorMessage from "component/StyledErrorMessage";
import {FormApi} from "final-form";
import _ from 'lodash';
import React, {Component, Fragment} from "react";
import {Field, Form as FinalForm, FormRenderProps} from 'react-final-form';
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";
import QuestionSetView from "route/person-selfonboarded/QuestionSetView";
import {DataEntry, DataEntryValue, Label} from "route/person-selfonboarded/styled";
import {Button, Grid, Icon, List, ListItem, RadioProps} from "semantic-ui-react";
import axios from "service/http";
import {addVaccinationBooking, getVaccinationQuestionSet, getVaccinationResources} from "service/vaccinationServices";
import styled from "styled-components";
import {
  QuestionAnswerDto,
  QuestionDto,
  QuestionSetDto,
  ResourceDisplayDto,
  VaccinationBookingDto
} from "ts-types/api.types";
import {errorUtils} from "util/errorUtils";
import {questionText} from "util/questionSetUtils";
import {required} from "util/validatorUtils";
import {withRouterWorkaround} from "util/workaroundUtils";

const UpsertContainer = styled.div`

  .section-title {
    color: #7687FF;
    font-weight: bold;
    font-size: 1.5rem;
    margin: 0;
  }

  .section-info {
    margin: 1rem 0 0;
  }

  .success-message {
    color: darkgreen;
    display: inline-block;
    margin-right: 1rem;

    @media only screen and (max-width: 767px) {
      display: block;
      margin-bottom: 0.75rem;
    }
  }

  .form-grid {
    label {
      display: inline-block;
      margin-bottom: 0.5rem;
      color: #454545;
    }
  }

  .ui.grid.form-grid {
    margin-left: -1rem;
    max-width: 760px;

    & > .row {
      margin-bottom: 0.578rem;
    }

    .input-field {
      max-width: 27rem;
    }
  }

  .form-section-start {
    margin-top: 1rem;
  }

  .anamnesis-section {
    font-size: 1.1rem;
  }

  .no-available-locations {
    margin-bottom: 4rem;
    color: darkred;
  }
`;

const cancelTokenSource = axios.CancelToken.source();

interface Props extends RouteComponentProps<any>,
                        AuthConsumerRenderProps,
                        WithTranslation,
                        CompanyDataConsumerRenderProps {

  onSave: () => void,
  onCancel: () => void
}

interface State {
  employeeId?: number,
  resources: DropdownOption[],
  questionSet?: QuestionSetDto,
  currentQuestion: number,

  formDataLoaded: boolean,
  pageActionInProgress: boolean,
  successMessage?: string,
  errorMessages: Array<string>
}

const generateRadioButtonOptions = (
    ids: Array<string>,
    labels: Array<string>,
    groupName: string,
    getMessage: Function,
    values?: Array<string | number>): Array<RadioProps> => {

  return labels.map((label, index) => ({
        id: ids[index],
        label: getMessage(`${label}`),
        name: groupName,
        value: values ? values[index] : index
      }
  ));
};

const convertValueToList = (value: string) => value ? [value] : [];
const extractValueFromList = (value: Array<string>) => value && value.length === 1 ? value[0] : "";

class RequestVaccination extends Component<Props, State> {

  initialBookingValues: Partial<VaccinationBookingDto> = {
    questionAnswerPairs: []
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      resources: [],
      currentQuestion: 0,

      formDataLoaded: false,
      pageActionInProgress: false,
      errorMessages: []
    };

    const methods: Promise<any>[] = [
      getVaccinationResources(undefined, cancelTokenSource),
      getVaccinationQuestionSet(cancelTokenSource)
    ];

    Promise.all(methods)
    .then(responses => {

      const resources = responses[0].filter((res: ResourceDisplayDto) => res.active);
      const questionSet: QuestionSetDto = responses[1];

      this.setState({
        resources: mapToDropdownOptionArray(resources, "descriptionWithLocation"),
        questionSet
      });

      const qaPairs: QuestionAnswerDto[] = questionSet.questions.map(question => ({
        questionId: question.id,
        questionText: question.text,
        questionTextEn: question.textEn,
        questionTextFr: question.textFr,
        questionTextIt: question.textIt,
        selectedAnswers: [],
        remarks: ""
      }));
      this.initialBookingValues.questionAnswerPairs = qaPairs;
      this.initialBookingValues.locationId = resources && resources.length > 0 ? resources[0].id : undefined;

    }).finally(() => {
      this.setState({
        formDataLoaded: true
      });
    });
  }


  setErrorMessage = (errorMessage?: string) => {

    const {errorMessages} = this.state;

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);

      this.setState({
        errorMessages: errMsgs
      });
    } else {

      this.setState({
        errorMessages: []
      });
    }
  };

  handleError(error: any) {
    const {t} = this.props;
    const errorCode = error.errorCode;
    const knownErrors: Array<string> = [
      errorUtils.invalidInput,
      errorUtils.questionAnswersAreMandatory
    ];

    const violations: Array<any> = error.violations;

    if (violations && violations.length > 0) {
      violations.forEach(violation => {
        if (knownErrors.includes(violation.errorCode)) {
          this.setErrorMessage(t(`error.${violation.errorCode}`));
        }
      });
    }

    if (!this.state.errorMessages.length) {
      if (knownErrors.includes(errorCode)) {
        this.setErrorMessage(t(`error.${errorCode}`));
      } else {
        this.setErrorMessage(t('error.general'));
      }
    }
  };

  handleSubmit = async (values: Partial<VaccinationBookingDto>) => {

    this.setErrorMessage();
    this.setPageActionInProgress();

    const upsertVaccinationBookingDto: Partial<VaccinationBookingDto> = {
      ...values
    };

    try {
      await addVaccinationBooking(upsertVaccinationBookingDto, cancelTokenSource);

      this.setState({
        successMessage: "employee.saveSuccess"
      });

      setTimeout(() => {
        this.props.history.push("/");
      }, 1200);

    } catch (e) {
      this.clearPageActionInProgress();
      this.handleError(e.response.data);
    }
  };

  setPageActionInProgress = () => {
    this.setState({
      pageActionInProgress: true
    });
  };

  clearPageActionInProgress = () => {
    this.setState({
      pageActionInProgress: false
    });
  };

  render() {

    const {formDataLoaded} = this.state;
    const {t} = this.props;

    return (
        <UpsertContainer>
          <CompanyDataHeader />

          {formDataLoaded
              ? <React.Fragment>
                {this.renderFinalForm()}
              </React.Fragment>
              : <LoaderComponent message={t("employee.loadFormData")} />
          }
        </UpsertContainer>
    );
  }

  renderFinalForm(): React.ReactNode {

    return (
        <FinalForm
            onSubmit={(values) => this.handleSubmit(values)}
            decorators={[]}
            initialValues={this.initialBookingValues}
            subscription={{pristine: true, submitting: true}}
            render={this.renderVaccinationFormContent}
        />
    );
  }

  renderVaccinationFormContent = (
      {form, handleSubmit, submitting}: FormRenderProps<Partial<VaccinationBookingDto>>): React.ReactNode => {

    const {t} = this.props;
    const {
      pageActionInProgress,
      resources,
      errorMessages
    } = this.state;

    return (
        <form onSubmit={handleSubmit}>
          <MpGrid stackable={true} className="form-grid">
            <Grid.Row>
              <Grid.Column width={16}>
                {/*<div className="title-h1">{t("vaccinationBooking.title")}</div>*/}
                <div className="section-title">{t("vaccinationBooking.title")}</div>
                <div className="section-info">{t("vaccinationBooking.info")}</div>
              </Grid.Column>
            </Grid.Row>
            {errorMessages.length > 0 &&
              <Grid.Row>
                <Grid.Column width={16}>
                  <StyledErrorMessage onDismiss={() => this.setErrorMessage()}>
                    {errorMessages.map(err => <div key={err}>{err}</div>)}
                  </StyledErrorMessage>
                </Grid.Column>
              </Grid.Row>
            }
            <Grid.Row className="form-section-start">
              <Grid.Column width={4}>
                <label htmlFor={"locationId"}>{t("vaccinationBooking.location")}:</label>
              </Grid.Column>
              <Grid.Column width={12}>
                <Field
                    id="locationId"
                    name="locationId"
                    className="input-field"
                    component={Select}
                    fluid
                    options={resources}
                    validate={required}
                    clearable
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={4}>
                <label>{t("vaccinationBooking.anamnesis.questions")}:</label>
              </Grid.Column>
              <Grid.Column width={12}>
                <DataEntry>
                  {this.renderQuestions(form)}
                </DataEntry>
              </Grid.Column>
            </Grid.Row>

            {this.state.questionSet &&
              <Grid.Row>
                <Grid.Column width={4}>
                  <label>{t("vaccinationBooking.anamnesis.answers")}:</label>
                </Grid.Column>
                <Grid.Column width={12}>
                  <QuestionSetView
                    questionSetName={this.state.questionSet.name}
                    questionAnswerPairs={form.getState().values.questionAnswerPairs!} />
                </Grid.Column>
              </Grid.Row>
            }

            <Grid.Row textAlign="right">
              <Grid.Column width={16}>
                {
                    this.state.successMessage &&
                  <div className="success-message">
                    {t(this.state.successMessage)}
                  </div>
                }
                <Button
                    type="submit"
                    className="action-button"
                    primary
                    style={{display: "inline-block", marginRight: "1.5rem"}}
                    disabled={pageActionInProgress}
                >
                  {t("employee.save")}
                </Button>
                <Button
                    type="button"
                    className="action-button"
                    onClick={() => this.props.history.push(`/`)}
                    secondary
                    style={{display: "inline-block"}}
                >
                  {t("action.cancel")}
                </Button>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                {
                  resources.length === 0
                      ? <div className="no-available-locations">
                        {t("vaccinationAppointmentBooking.noAvailableLocations.message")}
                      </div>
                      : <></>
                }
              </Grid.Column>
            </Grid.Row>
          </MpGrid>
        </form>
    );
  };

  previousQuestion = () => {
    const {currentQuestion} = this.state;

    this.setState({
      currentQuestion: Math.max(currentQuestion - 1, 0)
    });
  };

  nextQuestion = () => {
    const {questionSet, currentQuestion} = this.state;

    this.setState({
      currentQuestion: Math.min(currentQuestion + 1, questionSet!.questions.length - 1)
    });
  };

  setQuestionValue = (fieldPath: string, value: boolean, form: FormApi) => {
    form.change(fieldPath, [value]);
    this.nextQuestion();
  };

  renderQuestions = (form: FormApi<Partial<VaccinationBookingDto>>): JSX.Element => {
    const {t} = this.props;
    const {questionSet, currentQuestion} = this.state;

    if (!questionSet) {
      return <></>;
    }

    const questionMap = _.keyBy(questionSet.questions, q => q.id);

    const currentQuestionIx = currentQuestion < questionSet.questions.length ? currentQuestion : 0;

    const values = form.getState().values;
    const qaPairs = values.questionAnswerPairs!;

    return (
        <>
          <div className="label" />
          <div className="question-num-label">
            {t("vaccinationBooking.anamnesis.questionNum")}
            <span className="no-break">({`${currentQuestionIx + 1}/${questionSet.questions.length}`})</span>
          </div>

          <div className="question-container">
            <Button
                className="button-back"
                type="button"
                icon size="mini"
                disabled={currentQuestionIx <= 0}
                onClick={this.previousQuestion}
            >
              <Icon name="chevron left" />
            </Button>

            <div className="question">
              {
                qaPairs.map((qaPair, index: number) => {
                  const question: QuestionDto = questionMap[qaPair.questionId];

                  if (!question) {
                    return <div key={`question-wrapper-unknown-q-${qaPair.questionId}`} />;
                  }

                  const wrapperClass = index !== currentQuestionIx ? "inactive" : "";
                  return <div key={`question-wrapper-${question!.id}`} className={`question-wrapper ${wrapperClass}`}>
                    {this.renderSingleQuestion(`questionAnswerPairs[${index}]`, index, question, form)}
                  </div>;
                })
              }
            </div>

            <Button className="button-next"
                    type="button"
                    icon
                    size="mini"
                    disabled={currentQuestionIx >= questionSet.questions.length - 1}
                    onClick={this.nextQuestion}
            >
              <Icon name="chevron right" />
            </Button>
          </div>
        </>
    );
  };

  renderSingleQuestion = (questionAnswerFieldPath: string, index: number, question: QuestionDto, form: FormApi) => {
    return (
        <Fragment key={`question-fragment-${index}`}>
          <Label className="question-text" key={`question_label_${question.questionKey}`}>
            {questionText(question, this.props.language)}
          </Label>
          <DataEntryValue className="answer-value" key={`question_value_${question.questionKey}`}>
            {this.renderAnswerByType(`${questionAnswerFieldPath}.selectedAnswers`, index, question, form)}
          </DataEntryValue>
        </Fragment>
    );
  };

  renderAnswerByType = (fieldPath: string, index: number, question: QuestionDto, form: FormApi) => {
    const {t} = this.props;
    const {questionType} = question;
    switch (questionType) {
      case 'MULTI_ANSWER':
        const answers = question.possibleAnswers.map((answer, index) => (
                <ListItem key={`${fieldPath}_${index}`}>
                  <Field
                      name={fieldPath}
                      component="input"
                      type="checkbox"
                      value={answer}
                  />
                  {' ' + t(answer)}
                </ListItem>
            )
        );
        return (
            <List>
              {answers}
            </List>
        );
      case 'SINGLE_ANSWER':
        return (
            <Field
                name={fieldPath}
                component={RadioGroup}
                parse={convertValueToList}
                format={extractValueFromList}
                radiowidth="100%"
                radioDefinitions={
                  generateRadioButtonOptions(
                      question.possibleAnswers,
                      question.possibleAnswers,
                      fieldPath,
                      t,
                      question.possibleAnswers
                  )
                }
            />
        );
      case 'FREE_TEXT':
        return (
            <Field
                name={fieldPath}
                component={Input}
                fluid
                placeholder={t('insert.answer')}
                parse={convertValueToList}
                format={extractValueFromList}
            />
        );
      case 'YES_NO':
      default:
        const qa = _.get(form.getState().values, fieldPath);
        return (
            <>
              <Button
                  className="yesno-button"
                  type="button"
                  size="mini"
                  primary={this.answerEquals(qa, true)}
                  onClick={() => this.setQuestionValue(fieldPath, true, form)}
              >
                {t("anamnesis.answer.yes")}
              </Button>

              <Button
                  className="yesno-button"
                  type="button"
                  size="mini"
                  primary={this.answerEquals(qa, false)}
                  onClick={() => this.setQuestionValue(fieldPath, false, form)}
              >
                {t("anamnesis.answer.no")}
              </Button>
            </>
        );
    }
  };

  answerEquals = (answers: string[], value: any): boolean => {
    return answers && answers.length === 1 && (answers[0] === value || answers[0] === "" + value);
  };

  mapFieldRows = (fields: Array<any>, messageKey?: string) => {

    return fields.map((fieldProps, index) => {
      return this.renderFieldRow(fieldProps, index, messageKey);
    });
  };

  renderFieldRow = (
      fieldProps: any,
      index: number,
      messageKey: string | undefined) => {

    const {t} = this.props;

    const labelKey = messageKey ? `${messageKey}.${fieldProps.name}` : fieldProps.name;

    const className = fieldProps.className;
    const labelClassName = className ? `${className}-label` : "";
    const valueClassName = className ? `${className}-value` : "";

    return (
        <Fragment key={`treatment_input_row_${index}`}>
          <Label className={`${labelClassName} label`} key={`treatment_label_${index}`}>{t(labelKey)}:</Label>
          <DataEntryValue key={`treatment_value_${index}`} className={`${valueClassName}`}>
            <Field fluid {...fieldProps} />
          </DataEntryValue>
        </Fragment>
    );
  };

}

export default withRouterWorkaround(
    withAuthContext(
        withCompanyDataContext(
            withTranslation(["mipoco"])(
                RequestVaccination))));