import {CancelTokenSource} from "axios";
import axios from "service/http";
import {
  EmployeeTestResultDto,
  EmployeeTestResultImageDto,
  TestResultOverviewDto,
  TestResultSearchRequestDto
} from "ts-types/api.types";

//const authBaseUrl = process.env.REACT_APP_AUTH_BASE_URL;
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const apiUrl = `${baseUrl}/test-result`;

export const searchTestResults = (
    searchValues: Partial<TestResultSearchRequestDto>,
    cancelSourceToken: CancelTokenSource): Promise<TestResultOverviewDto[]> => {

    return axios
    .post(`${apiUrl}/search`, searchValues,
        {cancelToken: cancelSourceToken.token})
    .then(response => response.data);
}

export const getTestResultsForEmployee = (
    cancelSourceToken: CancelTokenSource): Promise<Array<EmployeeTestResultDto>> => {

    return axios
    .get(`${apiUrl}/employee`, {cancelToken: cancelSourceToken.token})
    .then(response => response.data);
}

export const getTestResultsForEmployeeOverview = (id: number,
    cancelSourceToken: CancelTokenSource): Promise<Array<EmployeeTestResultDto>> => {

  return axios
  .get(`${apiUrl}/employee-overview`, {params: {id}, cancelToken: cancelSourceToken.token})
  .then(response => response.data);
}

export const getTestResultImage = (
    testAttachmentId: string,
    cancelSourceToken: CancelTokenSource): Promise<EmployeeTestResultImageDto> => {

  return axios
  .get(`${apiUrl}/image/${testAttachmentId}`, {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
}
