import {CancelTokenSource} from "axios";
import axios from "service/http";
import {SelfServiceContainerDto} from "ts-types/api.types";

const baseAuthUrl = process.env.REACT_APP_AUTH_BASE_URL;
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const apiUrl = `${baseUrl}/self-service-container`;

export const getContainerForCompany = (
    cancelSourceToken: CancelTokenSource): Promise<SelfServiceContainerDto> => {

  return axios
  .get(`${apiUrl}/company`, {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};

export const getContainerData = (
    container: string, cancelSourceToken: CancelTokenSource): Promise<SelfServiceContainerDto> => {

  return axios
  .get(`${baseAuthUrl}/pub/self-service-container/${container}`, {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};
