import React from 'react';
import {FieldRenderProps} from 'react-final-form';
import {Form, Radio, RadioProps} from 'semantic-ui-react';
import styled from 'styled-components';


export const PxRadioSc = styled(Radio)`

  //&.ui.radio.checkbox .box,
  //&.ui.radio.checkbox label {
  //  padding-left: 1.45714em;
  //}

  .field.error &.ui.radio.checkbox {
    label:before, label:after {
      border-color: rgb(224, 180, 180);
      background: rgb(255, 246, 246);
    }
  }

  &.ui.radio.checkbox {
    min-width: 150px;
    line-height: 19px;
    margin-right: 2rem;

    input {
      width: 20px;
      height: 20px;

    }

    label:before, label:after {
      border-color: #c9d0ff;
      width: 18px;
      height: 18px;
    }
  }
`;

export const generateRadioButtonOptions = (
    labels: Array<string>,
    groupName: string,
    getMessage: Function,
    messageKey: string = groupName,
    values?: Array<string | number>): Array<RadioProps> => {

  return labels.map((label, index) => ({
        id: label,
        label: getMessage(`${messageKey}.${label}`),
        name: groupName,
        value: values ? values[index] : index
      }
  ));
};

export const generateRadioButtonOptionsFromEnum = (
    labels: Array<string>,
    groupName: string,
    getMessage: Function,
    messageKey: string = groupName): Array<RadioProps> => {

  return labels.map(label => ({
        id: label,
        label: getMessage(`${messageKey}.${label}`),
        name: groupName,
        value: label
      }
  ));
};

interface FinalFormRadioProps extends FieldRenderProps<any> {
  radioDefinition?: RadioProps,
  disabled?: boolean
}

const FinalFormRadio = (
    {
      input,
      disabled,
      meta: {error},
      radioDefinition,
      ...restProps
    }: FinalFormRadioProps) => {

  return (
      <Form.Field className={`${error ? 'error' : ''}`}>
        <PxRadioSc
            onClick={(param: React.FormEvent<HTMLInputElement>, data: RadioProps) => input.onChange(data.value)}
            checked={radioDefinition && input.value === radioDefinition.value}
            disabled={disabled}
            {...radioDefinition}
            {...restProps}
        />
      </Form.Field>
  );
};

export default FinalFormRadio;
