import {supportedLanguages} from "util/localizationUtils";

export const PROP_NAME_ACCESS_TOKEN = "mipoco-access-token";
export const PROP_NAME_LANGUAGE = "mipoco-langugage";

// access token
export const getAccessToken = (): string | null => {
  return sessionStorage.getItem(PROP_NAME_ACCESS_TOKEN);
};

export const setAccessToken = (accessToken: string) => {
  sessionStorage.setItem(PROP_NAME_ACCESS_TOKEN, accessToken);
};

export const clearAccessToken = () => {
  sessionStorage.removeItem(PROP_NAME_ACCESS_TOKEN);
};

export const getLanguage = () => {

  const sessionStorageLang: string | null = sessionStorage.getItem(PROP_NAME_LANGUAGE);

  if (sessionStorageLang && supportedLanguages.includes(sessionStorageLang)) {
    return sessionStorageLang;
  }

  const browserLang = window.navigator.language.slice(0, 2);
  if (supportedLanguages.includes(browserLang)) {
    return browserLang;
  }

  return "de";
};

export const setLanguage = (language: string) => {
  if (supportedLanguages.includes(language)) {
    sessionStorage.setItem(PROP_NAME_LANGUAGE, language);
  } else {
    sessionStorage.setItem(PROP_NAME_LANGUAGE, "de");
  }
};

export const getAuthorizationHeaderValue = (authToken?: string): string => {
  const tokenValue = (!!authToken) ? authToken : getAccessToken();
  return `Bearer ${tokenValue}`;
};
