import {PxRadioSc} from 'component/final-form/Radio';
import React from 'react';
import {FieldRenderProps} from 'react-final-form';
import {Form, RadioProps} from 'semantic-ui-react';
import styled from 'styled-components';

interface FinalFormRadioProps extends FieldRenderProps<any> {
  radioDefinitions?: Array<RadioProps>,
  radiowidth?: string,
  disabled?: boolean
}

const StyledFormField = styled(Form.Field)`
  width: ${(props: FinalFormRadioProps) => props.radiowidth ? props.radiowidth : "30%"};
  margin-bottom: ${(props: FinalFormRadioProps) => props.radiowidth && props.radiowidth === "100%" ? "5px" : "0px"};
  display: inline-block;
`;

const renderRadios = (radioDefinitions: Array<RadioProps>, {
  radiowidth,
  input,
  disabled
}: FinalFormRadioProps): Array<JSX.Element> => {
  return radioDefinitions.map(options => {
    return (
        <StyledFormField key={options.id} radiowidth={radiowidth}>
          <PxRadioSc
              onClick={(param: React.FormEvent<HTMLInputElement>, data: RadioProps) => input.onChange(data.value)}
              checked={input.value === options.value}
              disabled={disabled}
              {...options}
          />
        </StyledFormField>
    );
  });
};

const FinalFormRadioGroup = (
    {
      radioDefinitions,
      ...restProps
    }: FinalFormRadioProps
) => (

    <Form.Group>
      {radioDefinitions && renderRadios(radioDefinitions, restProps)}
    </Form.Group>
);

export default FinalFormRadioGroup;
