import React from 'react';
import {AutoSizer, Column, Table} from 'react-virtualized';
import 'react-virtualized/styles.css';
import styled from 'styled-components';
import 'styles/VirtualizedTable.css';

const StyledTable = styled(Table)`

  .ReactVirtualized__Table__Grid {
    outline: none;
    border-radius: unset;
  }

  .ReactVirtualized__Table__headerRow {
    font-weight: unset;
  }

  & .ReactVirtualized__Table__row {
    cursor: pointer;
  }

`;

function rowClassName({index}: any) {
  if (index < 0) {
    return "headerRow";
  } else {
    return index % 2 === 0 ? "evenRow" : "oddRow";
  }
}

const VirtualizedTable = ({columns, ...tableProps}: any) => {
  return (
      <AutoSizer>
        {({width, height}) => (
            <StyledTable
                width={width}
                height={height}
                headerHeight={46}
                headerClassName='headerColumn'
                rowClassName={rowClassName}
                rowHeight={tableProps.rowHeight || 42}
                {...tableProps}
            >
              {
                columns.map(({className, dataKey, customClass, ...other }: any) => {
                  const custom = customClass ? customClass : "";
                  return (
                      <Column
                          key={dataKey}
                          className={`bodyColumn ${custom}`}
                          dataKey={dataKey}
                          width={width}
                          {...other}
                      />
                  );
                })
              }
            </StyledTable>
        )}
      </AutoSizer>
  );
};

export default VirtualizedTable;
