import moment from 'moment';
import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import 'react-day-picker/lib/style.css';
import {FieldRenderProps} from 'react-final-form';
import {Form} from 'semantic-ui-react';
import styled from 'styled-components';

const DayPickerInputStyled = styled(Form.Field)`
  
  & .DayPickerInput {
    width: 100%;
  }
  & .DayPickerInput-OverlayWrapper .DayPickerInput-Overlay {
    z-index: 99999;
  }
  
  & .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: #7687FF;
    color: #ffffff;
    
    &:hover {
      background-color: #5563ca;
    }
  }
`;

interface DatePickerFieldProps extends FieldRenderProps<any> {

  disabledBefore?: Date,
  disabledAfter?: Date,
  disabled?: boolean,
  emptyDateInput?: boolean

}

const semanticUiInput = (hasError?: boolean, disabled?: boolean): object => {

  let errorStyle: object = {};
  if (hasError) {
    errorStyle = {
      backgroundColor: "#fff6f6",
      borderColor: "#e0b4b4",
      color: "#9f3a38"
    };
  }

  return {
    style: {
      minHeight: "30px",
      margin: 0,
      maxWidth: '100%',
      flex: '1 0 auto',
      outline: 0,
      WebkitTapHighlightColor: 'rgba(255,255,255,0)',
      textAlign: 'left',
      lineHeight: '1.21428571em',
      fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
      padding: '.5rem 0.675rem',
      background: '#fff',
      border: '1px solid #c9d0ff',
      color: disabled ? 'rgba(0,0,0,.39)' : 'rgba(0,0,0,.87)',
      WebkitTransition: 'border-color .1s ease,-webkit-box-shadow .1s ease',
      transition: 'box-shadow .1s ease,border-color .1s ease',
      boxShadow: 'none',
      width: '100%',
      ...errorStyle
    }
  }
};

const FORMATS = ['DD.MM.YYYY', 'D.M.YYYY', 'DDMMYYYY'];
const DEFAULT_FORMAT = FORMATS[0];

interface DateArgs {
  formats: string,
  locale: string
}

function getDateArgs(format?: string, locale?: string): DateArgs {
  return {
    formats: format !== undefined ? format : 'L',
    locale: locale !== undefined ? locale : 'de'
  }
}

function formatDate(date: Date, format?: string, locale?: string) {
  const dateArgs = getDateArgs(format, locale);
  const dateString = moment(date).locale(dateArgs.locale).format(dateArgs.formats[0]);
  return dateString;
}

function parseDate(str: string, format?: string, locale?: string) {

  const dateArgs = getDateArgs(format, locale);

  for (let format of dateArgs.formats){
    const m = moment(str, format, dateArgs.locale, true);

    if (m.isValid()) {
      const date = m.toDate();
      return date;
    }
  }
  return undefined;
}

const FinalFormDatePicker = (props: DatePickerFieldProps) => {

  const {
    input,
    meta: {
      error
    },
    disabled,
    disabledBefore,
    disabledAfter,
    emptyDateInput,
    ...restProps
  } = props;

  let modifiers: any = {
    selected: input.value ? new Date(input.value) : null
  };

  if (disabledBefore || disabledAfter) {
    modifiers.disabled = [
      {
        before: disabledBefore,
        after: disabledAfter
      }
    ]
  }

  const modifiersStyles = {
    birthday: {
      color: 'white',
      backgroundColor: '#7687FF !important'
    }
  };

  const {value, onChange, ...inputRest} = input;

  return (

      <DayPickerInputStyled>
        <DayPickerInput
            placeholder={`e.x. ${moment("1985-06-25").format(DEFAULT_FORMAT)}`}
            formatDate={formatDate}
            format={FORMATS}
            {...inputRest}
            parseDate={parseDate}
            value={input.value ? moment(input.value, "YYYY-MM-DD").toDate() : ""}
            dayPickerProps={{
              modifiers: modifiers,
              modifiersStyles: modifiersStyles,
              firstDayOfWeek: 1
            }}
            onDayChange={day => {
              input.onChange(day
                  ? moment(formatDate(day), DEFAULT_FORMAT).format("YYYY-MM-DD")
                  : emptyDateInput ? "" : input.value)
            }}
            {...restProps}
            inputProps={{
              ...semanticUiInput(!!(error), disabled),
              disabled: disabled,
              autoComplete: "off",
              ...inputRest
            }}
        />
      </DayPickerInputStyled>
  )
};

export default FinalFormDatePicker;
