import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import {CompanyDataConsumerRenderProps, withCompanyDataContext} from "component/CompanyDataContext";
import CompanyDataHeader from "component/CompanyDataHeader";
import Input from "component/final-form/Input";
import RemoteSelect from "component/final-form/RemoteSelect";
import Select, {
    DropdownOption,
    mapToDropdownOptionArray,
    stringArrayToDropdownOptionArray
} from "component/final-form/Select";
import LoaderComponent from "component/LoaderComponent";
import MpGrid from "component/MpGrid";
import StyledErrorMessage from "component/StyledErrorMessage";
import createDecorator from 'final-form-calculate';
import React, {Component} from "react";
import {Field, Form as FinalForm, FormRenderProps} from 'react-final-form';
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";
import {Button, Container, Grid} from "semantic-ui-react";
import {editCompany, getCompany} from "service/companyServices";
import {getAllCountries, searchZipDataForInput} from "service/countryAndZipCityServices";
import axios from "service/http";
import styled from "styled-components";
import {CompanySubType, CompanyType} from "ts-types/api.enums";
import {UpsertCompanyDto, UpsertEmployeeDto, ZipDataDto} from "ts-types/api.types";
import {IndexSignature} from "util/commonTypes";
import {errorUtils} from "util/errorUtils";
import {required} from "util/validatorUtils";
import {withRouterWorkaround} from "util/workaroundUtils";

const UpsertContainer = styled(Container)`
  .success-message {
    color: darkgreen;
    display: inline-block;
    margin-right: 1rem;
  }
`;

const cancelTokenSource = axios.CancelToken.source();

enum ZipDataOrderBy {
    ZIP = "ZIP",
    CITY = "CITY",
};

export interface IndexedZipData extends IndexSignature, ZipDataDto {
}

interface Props extends RouteComponentProps<any>,
    AuthConsumerRenderProps,
    WithTranslation,
    CompanyDataConsumerRenderProps {
}

interface State {
    companyId?: number,
    company?: UpsertCompanyDto,
    countries?: Array<DropdownOption>,
    cities: Array<DropdownOption>,
    zip: Array<DropdownOption>,
    languages: Array<DropdownOption>,
    companyTypes: Array<DropdownOption>,
    companySubTypes: Array<DropdownOption>,
    submitInProgress: boolean,
    formDataLoaded?: boolean,
    successMessage?: string,
    errorMessages: Array<string>
}

class EditCompany extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        const companyId = props.companyData.id;

        const initialCities: Array<DropdownOption> = [];
        const initialZip: Array<DropdownOption> = [];

        const methods: Array<Promise<any>> =
            [getCompany(cancelTokenSource), getAllCountries(cancelTokenSource)];

        Promise.all(methods)
        .then(responses => {
            let company: UpsertCompanyDto = responses[0];
            const countries = responses[1];

            if (company.city) {
                initialCities.push({
                    key: company.city,
                    text: company.city,
                    value: company.city,
                    predefinedOption: true
                })
            }

            if (company.zip) {
                initialZip.push({
                    key: company.zip,
                    text: company.zip,
                    value: company.zip,
                    predefinedOption: true
                })
            }

            this.setState({
                company: company,
                companyId: companyId,
                countries: mapToDropdownOptionArray(countries, "name", "shortName")
            })
        }).finally(() => {
            this.setState({
                formDataLoaded: true
            })
        })

        const languageLabels: Array<string> = ["german", "french", "italian", "english"];
        const languageKeys: Array<string> = ["de", "fr", "it", "en"];

        this.state = {
            cities: initialCities,
            zip: initialZip,
            languages: stringArrayToDropdownOptionArray(languageLabels, props.t, "language", languageKeys),
            companyTypes: stringArrayToDropdownOptionArray(Object.values(CompanyType), props.t, "companyType"),
            companySubTypes: stringArrayToDropdownOptionArray(Object.values(CompanySubType), props.t, "companySubType"),
            errorMessages: [],
            submitInProgress: false
        }
    }

    initialComapnyValues = (): Partial<UpsertCompanyDto> => {

        const {countries} = this.state;

        return {
            description: "",
            street: "",
            zip: "",
            city: "",
            email: "",
            homepage: "",
            tel: "",
            countryId: countries ? countries[0].value : undefined,
            language: "de",
            active: true
        };
    };

    sortZipData = (zipData: Array<IndexedZipData>, orderBy: ZipDataOrderBy) => {
        if (zipData) {
            if (orderBy === ZipDataOrderBy.ZIP) {
                return zipData.sort((a, b) => (a.zip > b.zip) ? 1 : ((b.zip > a.zip) ? -1 : 0));
            } else {
                return zipData.sort((a, b) => (a.city > b.city) ? 1 : ((b.city > a.city) ? -1 : 0));
            }
        }

        return [];
    }

    mapZipData = (field: string, zipData: Array<IndexedZipData>, orderBy: ZipDataOrderBy): Array<DropdownOption> => {

        const sortedZipData = this.sortZipData(zipData, orderBy);

        return sortedZipData.map(data => ({
            key: data.id,
            text: data[field],
            value: `${data.zip}, ${data.city}, ${data.countryId}`,
            content: `${data.zip}, ${data.city}, ${data.canton}`,
            predefinedOption: true
        }))
    };

    searchZipData = (zip: string, countryId: number) => {

        const {cities, zip: zips, countries} = this.state;

        if (countries && countries.length > 0) {

            const country = countries.find(c => c.value === countryId);
            if (country) {

                const countryCode = country.key.toString();

                searchZipDataForInput(zip, countryCode, cancelTokenSource, 75)
                .then(response => {

                    const allInitialAndNewCityAdditions = cities.filter(city => city.value === city.key);
                    const allInitialAndNewZipAdditions = zips.filter(zip => zip.value === zip.key);

                    this.setState({
                        cities: [...allInitialAndNewCityAdditions, ...this.mapZipData("city", response, ZipDataOrderBy.CITY)],
                        zip: [...allInitialAndNewZipAdditions, ...this.mapZipData("zip", response, ZipDataOrderBy.ZIP)]
                    })
                })
                .catch(() => {
                    this.setState({
                        cities: [],
                        zip: []
                    })
                })
            }


        }
    };

    onNewAddition = (options: "zip" | "cities") => (newAddition: DropdownOption) => {

        const {cities, zip: zips} = this.state;

        if (options === "cities") {

            const foundCity = cities.find(city => city.key === newAddition.key);

            if (!foundCity) {
                newAddition.predefinedOption = false;
                const newCities = cities.filter(city => city.predefinedOption === true);
                this.setState({
                    cities: [newAddition, ...newCities]
                });
            }
        } else if (options === "zip") {

            const foundZip = zips.find(zip => zip.key === newAddition.key);

            if (!foundZip) {
                newAddition.predefinedOption = false;
                const newZips = zips.filter(city => city.predefinedOption === true);
                this.setState({
                    zip: [newAddition, ...newZips]
                });
            }
        }
    };

    updateZipDataFieldDecorator = (field: string) =>
        (zipData?: string, allValues?: Partial<UpsertEmployeeDto & IndexSignature>) => {

            if (zipData) {
                const splitZipData = zipData.split(", ");

                if (splitZipData.length === 3 && allValues && allValues[field] !== zipData) {
                    return zipData;
                }
            }

            return allValues ? allValues[field] : undefined;
        };

    zipDataDecorator = createDecorator(
        {
            field: "city",
            updates: {
                zip: this.updateZipDataFieldDecorator("zip")
            }
        },
        {
            field: "zip",
            updates: {
                city: this.updateZipDataFieldDecorator("city")
            }
        }
    );

    setErrorMessage = (errorMessage?: string) => {

        const {errorMessages} = this.state;

        if (errorMessage) {

            const errMsgs = [...errorMessages];
            errMsgs.push(errorMessage);

            this.setState({
                errorMessages: errMsgs
            });
        } else {

            this.setState({
                errorMessages: []
            });
        }
    };

    handleError(error: any) {
        const {t} = this.props;
        const errorCode = error.errorCode;
        const knownErrors: Array<string> = [
            errorUtils.invalidInput,
            errorUtils.invalidEmail
        ];

        const violations: Array<any> = error.violations;

        if (violations && violations.length > 0) {
            violations.forEach(violation => {
                if (knownErrors.includes(violation.errorCode)) {
                    this.setErrorMessage(t(`error.${violation.errorCode}`))
                }
            });
        }

        if (!this.state.errorMessages.length) {
            if (knownErrors.includes(errorCode)) {
                this.setErrorMessage(t(`error.${errorCode}`))
            } else {
                this.setErrorMessage(t('error.general'));
            }
        }
    };

    handleSubmit = async (values: Partial<UpsertCompanyDto>) => {
        const {companyId} = this.state;

        await this.setState({
            submitInProgress: true
        });

        let zip = "";
        let city = "";

        if (values.zip) {
            if (values.zip.includes(', ')) {
                zip = values.zip.split(", ")[0];
            } else {
                zip = values.zip;
            }
        }

        if (values.city) {
            if (values.city.includes(', ')) {
                city = values.city.split(", ")[1];
            } else {
                city = values.city;
            }
        }

        const upsertCompany: Partial<UpsertCompanyDto> = {
            ...values,
            zip: zip,
            city: city,
        };

        const onSave = () => {
            this.setState({
                successMessage: "company.saveSuccess"
            })
            this.props.setCompanyData(upsertCompany);
            setTimeout(() => {
                this.props.history.push("/");
            }, 1200);
        };

        if (companyId) {
            await editCompany(upsertCompany, cancelTokenSource)
            .then(onSave)
            .catch((e: any) => {
                this.handleError(e.response.data)
                this.setState({
                    submitInProgress: false
                })
            })
        }
    };

    resetCompanySubType = createDecorator({
        field: 'companyType',
        updates: {
            companySubType: (fieldValue, allValues?: Partial<UpsertCompanyDto>) => {
                return !fieldValue || !allValues || (fieldValue && fieldValue !== CompanyType.SCHOOL)
                ? undefined
                : allValues.companySubType;
            }
        }
    });

    render() {

        const {formDataLoaded} = this.state;
        const {t} = this.props;

        return (
            <UpsertContainer fluid>
                <CompanyDataHeader />

                {formDataLoaded
                    ? <React.Fragment>
                        {this.renderFinalForm()}
                    </React.Fragment>
                    : <LoaderComponent message={t("employee.loadFormData")} />
                }
            </UpsertContainer>
        );
    }

    renderFinalForm(): React.ReactNode {

        const {company = this.initialComapnyValues()} = this.state;

        return (
            <FinalForm
                onSubmit={(values) => this.handleSubmit(values)}
                decorators={[this.zipDataDecorator, this.resetCompanySubType]}
                initialValues={company}
                subscription={{pristine: true, submitting: true}}
                render={this.renderEmployeeFormContent}
            />
        );
    }

    renderEmployeeFormContent = ({form, handleSubmit, submitting}: FormRenderProps): React.ReactNode => {

        const {t} = this.props;
        const {
            errorMessages,
            zip,
            cities,
            countries,
            languages,
            companyTypes,
            companySubTypes,
            submitInProgress
        } = this.state;

        const countryId = form.getState().values.countryId;
        let disabledCompanySubTyp = true;
        const values = form.getState().values;

        if(values.companyType && values.companyType === CompanyType.SCHOOL) {
             disabledCompanySubTyp = false;
        }

        return (
            <form onSubmit={handleSubmit}>
                <MpGrid>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <div className="title-h1">{t("company.edit")}</div>
                        </Grid.Column>
                    </Grid.Row>
                    {errorMessages.length > 0 &&
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <StyledErrorMessage onDismiss={() => this.setErrorMessage()}>
                            {errorMessages.map(err => <div key={err}>{err}</div>)}
                        </StyledErrorMessage>
                      </Grid.Column>
                    </Grid.Row>
                    }
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <MpGrid>
                                {/*<Grid.Row>*/}
                                {/*    <Grid.Column width={16} textAlign="right">*/}
                                {/*        <Field*/}
                                {/*            name="active"*/}
                                {/*            component={FinalFormCheckbox}*/}
                                {/*            label={t("company.active")}*/}
                                {/*            toggle*/}
                                {/*        />*/}
                                {/*    </Grid.Column>*/}
                                {/*</Grid.Row>*/}
                                <Grid.Row>
                                    <Grid.Column width={3} verticalAlign="middle">
                                        {t("company.description")}:
                                    </Grid.Column>
                                    <Grid.Column width={13} verticalAlign="middle">
                                        <Field
                                            fluid
                                            name="description"
                                            component={Input}
                                            //validate={required}
                                            disabled={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={3} verticalAlign="middle">
                                        {t("company.street")}:
                                    </Grid.Column>
                                    <Grid.Column width={13} verticalAlign="middle">
                                        <Field
                                            fluid
                                            name="street"
                                            component={Input}
                                            //validate={required}
                                            disabled={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={3} verticalAlign="middle">
                                        {t("company.zip")}:
                                    </Grid.Column>
                                    <Grid.Column width={4} verticalAlign="middle">
                                        <Field
                                            fluid
                                            name="zip"
                                            component={RemoteSelect}
                                            allowAdditions
                                            remoteSearch
                                            customRemoteSearchMethod={(searchQuery: string) => this.searchZipData(searchQuery, countryId)}
                                            onNewAddition={this.onNewAddition("zip")}
                                            options={zip}
                                            //validate={required}
                                            disabled={true}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={1} verticalAlign="middle">
                                        {t("company.city")}:
                                    </Grid.Column>
                                    <Grid.Column width={8} verticalAlign="middle">
                                        <Field
                                            fluid
                                            name="city"
                                            component={RemoteSelect}
                                            allowAdditions
                                            remoteSearch
                                            customRemoteSearchMethod={(searchQuery: string) => this.searchZipData(searchQuery, countryId)}
                                            onNewAddition={this.onNewAddition("cities")}
                                            options={cities}
                                            //validate={required}
                                            disabled={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={3} verticalAlign="middle">
                                        {t("company.countryId")}:
                                    </Grid.Column>
                                    <Grid.Column width={13} verticalAlign="middle">
                                        <Field
                                            name="countryId"
                                            component={RemoteSelect}
                                            options={countries}
                                            //validate={required}
                                            disabled={true}
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={3} verticalAlign="middle">
                                        {t("company.tel")}:
                                    </Grid.Column>
                                    <Grid.Column width={13} verticalAlign="middle">
                                        <Field
                                            fluid
                                            name="tel"
                                            component={Input}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={3} verticalAlign="middle">
                                        {t("company.email")}:
                                    </Grid.Column>
                                    <Grid.Column width={13} verticalAlign="middle">
                                        <Field
                                            fluid
                                            name="email"
                                            component={Input}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={3} verticalAlign="middle">
                                        {t("company.homepage")}:
                                    </Grid.Column>
                                    <Grid.Column width={13} verticalAlign="middle">
                                        <Field
                                            fluid
                                            name="homepage"
                                            component={Input}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={3} verticalAlign="middle">
                                        {t("company.language")}:
                                    </Grid.Column>
                                    <Grid.Column width={13} verticalAlign="middle">
                                        <Field
                                            name="language"
                                            component={Select}
                                            options={languages}
                                            validate={required}
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={3} verticalAlign="middle">
                                        {t("company.type")}:
                                    </Grid.Column>
                                    <Grid.Column width={13} verticalAlign="middle">
                                        <Field
                                            name="companyType"
                                            component={Select}
                                            options={companyTypes}
                                            disabled={true}
                                            clearable
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={3} verticalAlign="middle">
                                        {t("company.subType")}:
                                    </Grid.Column>
                                    <Grid.Column width={13} verticalAlign="middle">
                                        <Field
                                            name="companySubType"
                                            component={Select}
                                            options={companySubTypes}
                                            disabled={disabledCompanySubTyp}
                                            clearable
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row textAlign="right">
                                    <Grid.Column width={16}>
                                        {
                                            this.state.successMessage &&
                                            <div className="success-message">
                                                {t(this.state.successMessage)}
                                            </div>
                                        }
                                        <Button
                                            type="submit"
                                            className="action-button"
                                            primary
                                            disabled={submitInProgress}
                                            style={{display: "inline-block", marginRight: "1.5rem"}}
                                        >
                                            {t("button.save")}
                                        </Button>
                                        <Button
                                            type="button"
                                            className="action-button"
                                            onClick={() => this.props.history.push(`/`)}
                                            secondary
                                            style={{display: "inline-block"}}
                                        >
                                            {t("action.cancel")}
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </MpGrid>
                        </Grid.Column>
                    </Grid.Row>
                </MpGrid>
            </form>
        );
    }

}


let EditCompanyWrapper = withRouterWorkaround(
    withAuthContext(
        withCompanyDataContext(
            withTranslation(["mipoco"])(
                EditCompany))));

export default EditCompanyWrapper;