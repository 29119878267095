import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import {BigActionButton} from "component/BigActionButton";
import FootstepsIcon from "component/FootstepsIcon";
import React, {Component} from "react";
import {useTranslation, withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";
import {ContainerPersonOnboardViews} from "route/person-onboard-container/ContainerPersonOnboardView";
import styled from "styled-components";
import {applyStyles} from "util/localizationUtils";
import {withRouterWorkaround} from "util/workaroundUtils";

const ViewContainerDiv = styled.div`
  margin: 0.5rem 0.525rem;
  font-size: 1.2rem;

  .subtitle {
    margin-bottom: 2rem;

    @media (max-width: 768px) {
      margin-bottom: 1rem;
    }
  }

  @media (max-width: 768px) {
    margin: 0.5rem 0;
  }

  .instruction-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 3rem;

    .instruction-column {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      max-width: 210px;
      padding: 1.2rem 0.5rem;

      .step-container {

        svg {
          display: none;
          float: left;
        }

        .step-title {
          min-height: 4.2rem;
          padding: 0 0.5rem;
        }

        .step-description {
          border-top: 1px solid #0f1a52;
          padding-top: 1rem;
          font-size: 1.075rem;
        }
      }
    }

    .instruction-column.disabled {
      color: #aaaaaa;

      .step-container {
        .step-description {
          border-top: 1px solid #aaa;
        }
      }
    }

    > .login-button > .content {
      align-items: center;
      justify-content: center;
    }

    @media (max-width: 768px) {
      flex-direction: column;

      > .instruction-column {
        display: block;
        max-width: unset;
        min-width: 100%;
        margin-left: 0 !important;
      }

      .instruction-column + .instruction-column {
        margin-top: 0;
      }

      .instruction-column {

        padding-left: 0;
        padding-right: 0;

        .step-container {
          .step-title {
            min-height: unset;
            margin-bottom: 0.5rem;
            padding: 0;
          }
        }
      }

    }
  }
`;

interface RegistrationStepProps {
  name: string;
  prefix: string;
  disabled?: boolean;
}

const RegistrationStep: React.FunctionComponent<RegistrationStepProps> =
    (props) => {

      const {name, prefix, disabled = false} = props;

      const {t} = useTranslation("login");

      return <div className={`instruction-column ${disabled ? "disabled" : ""}`}>
        <div className="step-container">
          <FootstepsIcon width="3rem" height="3rem" color={!disabled ? "#000000" : "#aaaaaa"} />
          <div className="step-title">
            <strong>{applyStyles(t(`${prefix}.${name}.title`))}</strong>
          </div>
          <div className="step-description">
            {applyStyles(t(`${prefix}.${name}.text`))}
          </div>
        </div>
      </div>;

    };

interface Props extends RouteComponentProps<any>,
    AuthConsumerRenderProps,
    WithTranslation {

  setActiveView: (activeView: ContainerPersonOnboardViews) => void;
}

interface State {

}

class ContainerInstructionsView extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  containerTextDefault = (textKey: string): string => {
    const {t} = this.props;

    let text = undefined;

    const {accessContainerData} = this.props;
    if (accessContainerData) {
      const key = `personOnboardContainer_${accessContainerData.type}.instructions.${textKey}`;
      text = t(key, "NO_VALUE");
    }

    if (text === "NO_VALUE") {
      text = t(`personOnboardContainer.instructions.${textKey}`);
    }
    return text || textKey;
  };

  render(): React.ReactNode {

    const {t, accessContainerData} = this.props;

    const numSteps = Number(this.containerTextDefault("numSteps") || 0);
    // create array [1, 2, ..., num_steps]
    const stepsArray = Array.from({ length: numSteps }, (v, i) => i + 1);

    const customPrefix = "yes" === this.containerTextDefault("customPrefix");
    const prefix = customPrefix
        ? `personOnboardContainer_${accessContainerData!.type}.instructions`
        : "personOnboardContainer.instructions";

    return (
        <ViewContainerDiv>
          <div className="subtitle">{t(`personOnboardContainer.instructions.text`)}</div>
          <div className="instruction-row">
            {
              stepsArray.map(stepNum => (
                  <RegistrationStep key={`step-num-${stepNum}`} name={`step_${stepNum}`} prefix={prefix} />
              ))
            }
          </div>
          <div className="instruction-row">
            <BigActionButton
                icon="building"
                textDomain="login"
                textKey="companyOnboard.instructions.button"
                onClick={() => this.props.setActiveView(ContainerPersonOnboardViews.VALIDATION)}
            />
          </div>
        </ViewContainerDiv>
    );
  }
}

let ContainerInstructionsViewWrapper = withRouterWorkaround(
    withAuthContext(
        withTranslation(["login"])(
            ContainerInstructionsView)));

export default ContainerInstructionsViewWrapper;