import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import {CompanyDataConsumerRenderProps, withCompanyDataContext} from "component/CompanyDataContext";
import CompanyDataHeader from "component/CompanyDataHeader";
import ConfirmActionPopup from "component/ConfirmActionPopup";
import EditActionButton from "component/EditActionButton";
import CheckBox, {CheckboxSc} from "component/final-form/CheckBox";
import Input from "component/final-form/Input";
import StyledErrorMessage from "component/StyledErrorMessage";
import VirtualizedTable from "component/VirtualizedTable";
import {FormState} from "final-form";
import _ from "lodash";
import moment from "moment";
import React, {Component} from "react";
import {Field, Form as FinalForm, FormRenderProps, FormSpy} from 'react-final-form';
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";
import {Button, Icon, Loader, Popup} from "semantic-ui-react";
import {getCompanyDivisions} from "service/companyServices";
import {deleteEmployee, getEmployeeExportUrl, searchEmployees} from "service/employeeServices";
import axios from "service/http";
import {getAllInterventions} from "service/interventionServices";
import {sendInviteToAllSelectedInitialEmployees, sendInviteToEmployee} from "service/userServices";
import styled from "styled-components";
import {debounce} from "ts-debounce";
import {RegistrationStateType} from "ts-types/api.enums";
import {
  DivisionDto,
  EmployeeDto,
  EmployeeExportRequest,
  EmployeeInviteRequest,
  EmployeeSearchRequest,
  InterventionDto,
  UpsertEmployeeDto
} from "ts-types/api.types";
import {errorUtils} from "util/errorUtils";
import {noop} from "util/functionUtils";
import {interventionDescription} from "util/interventionUtils";
import {isKeyCheck} from "util/keyUtils";
import {withRouterWorkaround} from "util/workaroundUtils";

const EmployeesContainer = styled.div`
  flex: 1 1 auto;

  display: flex;
  flex-direction: column;

  .search-form {
    padding-left: 0.75rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    label {
      margin-right: 1rem;
    }

    .ui.input {
      min-width: 15rem;
    }

    button {
      margin-left: 1rem;
    }

    .checkbox {
      margin-left: 1rem;
    }
  }

  .error {
    margin-bottom: 1rem;
  }

  .results-table {
    flex: 1 1 auto;
    min-height: 250px;

    .row-actions {
      i.icon,
      i.icons {
        color: #768aff;
      }
    }

    .table-loader.ui.inline.loader.active,
    .table-loader.ui.inline.loader.visible {
      display: block;
      top: 5rem;
    }
  }

  .page-actions {
    align-self: flex-end;
    display: inline-block;
    margin-top: 1rem;
  }

  .action-message {
    display: inline-block;
    font-size: 1.2rem;
    padding-right: 1rem;

    &.email-send-success {
      color: darkgreen;
    }

    &.email-send-error {
      color: darkred;
    }
  }
`;

const cancelTokenSource = axios.CancelToken.source();

interface InviteEmployee extends EmployeeDto {
  selected: boolean;
}

interface EmployeeFieldProps extends FormState<any> {
  values: Partial<EmployeeSearchRequest>;
}

interface Props extends RouteComponentProps<any>,
    AuthConsumerRenderProps,
    WithTranslation,
    CompanyDataConsumerRenderProps {

}

interface State {
  foundEmployees: Array<InviteEmployee>;
  employeesLoaded: boolean;
  employee?: UpsertEmployeeDto;
  employeeId?: number;
  searchValues: Partial<EmployeeSearchRequest>;
  sendingEmailsInProgress: boolean;
  showEmailSendSuccess: boolean;
  emailSendFailure?: string;
  errorMessages: Array<string>;
  divisions: Array<DivisionDto>;
  divisionLoaded: boolean;
  interventions: InterventionDto[];
}

class EmployeesView extends Component<Props, State> {

  private mounted: boolean = false;

  constructor(props: Props) {
    super(props);

    const currentUser = this.props.currentUser!;

    const companyId = currentUser.companyId;

    this.state = {
      foundEmployees: [],
      employeesLoaded: true,
      searchValues: {
        searchKey: "",
        includesInactive: false
      },
      sendingEmailsInProgress: false,
      showEmailSendSuccess: false,
      errorMessages: [],
      divisions: [],
      divisionLoaded: false,
      interventions: []
    };

    const methods: Array<Promise<any>> = [
      getCompanyDivisions(companyId, cancelTokenSource)
    ];

    Promise.all(methods)
    .then(responses => {

      this.setState({
        divisions: responses[0]
      });

    }).finally(() => {
      this.setState({
        divisionLoaded: true
      });
    });

    setTimeout(() => {
      this.fetchEmployees();
    }, 10);


  }

  componentDidMount(): void {
    this.mounted = true;
  }

  handleError(error: any) {
    const {t} = this.props;

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        errorUtils.invalidInput,
        errorUtils.invalidEmail,
        errorUtils.duplicateEmail,
        errorUtils.employeeEmailAlreadyExists
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            this.setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!this.state.errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          this.setErrorMessage(t(`error.${errorCode}`));
        } else {
          this.setErrorMessage(t('error.general'));
        }
      }
    }
  };

  setErrorMessage = (errorMessage?: string) => {

    const {errorMessages} = this.state;

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);

      this.setState({
        errorMessages: errMsgs
      });
    } else {

      this.setState({
        errorMessages: []
      });
    }
  };

  onQueryKeyDown = (event: any) => {
    event.persist();
    const isKey: any = isKeyCheck(event);

    if (isKey.enter) {
      this.fetchEmployees();
    }
  };

  handleChange = ({values}: EmployeeFieldProps): void => {

    if (!this.mounted) {
      return;
    }

    this.setState({
      searchValues: values
    });
  };

  fetchEmployees = debounce((): void => {

    const onFinally = () => {
      this.setState({
        employeesLoaded: true
      });
    };

    this.setState({
      employeesLoaded: false,
      foundEmployees: []
    });

    let searchValues = {...this.state.searchValues};
    if (_.isEmpty(searchValues)) {
      searchValues = {
        searchKey: "",
        includesInactive: false
      };
    }

    getAllInterventions(cancelTokenSource)
    .then(response => {
      this.setState({
        interventions: response
      });
    })
    .catch((e: any) => this.handleError(e.response.data))
    .finally(noop);

    searchEmployees(searchValues, cancelTokenSource)
    .then(response => {

          const employees: Array<InviteEmployee> = response.map((employee) => ({
            ...employee,
            selected: false
          }));

          this.setState({
            foundEmployees: employees
          });
        }
    )
    .catch((e: any) => this.handleError(e.response.data))
    .finally(onFinally);

  }, 300);

  selectEmployee = (index: number) => {
    const {foundEmployees} = this.state;
    let employee = foundEmployees[index];
    if (employee && employee.registrationState === RegistrationStateType.INITIAL && employee.companyEmail !== null) {
      let employees = [...foundEmployees];
      employees[index].selected = !employees[index].selected;
      this.setState({
        foundEmployees: employees
      });
    }
  };

  deleteEmployee = (id: number): void => {
    deleteEmployee(id, cancelTokenSource).then(response => {
      this.setState(({foundEmployees}: State) => ({
        foundEmployees: foundEmployees.filter((employee: EmployeeDto) => employee.id !== response.id)
      }));
    }).catch(noop);
  };

  openEditEmployee = (id: number): void => {
    this.props.history.push("/employee/", {
      id: id
    });
  };

  sendInviteToEmployee = async (employeeId: number) => {
    await sendInviteToEmployee(employeeId);
    this.fetchEmployees();
  };

  sendInviteToAllInitialEmployees = async () => {
    const {foundEmployees} = this.state;

    const employeeIds = foundEmployees.filter(emp => emp.selected).map(emp => emp.id);

    this.setState({
      sendingEmailsInProgress: true,
      showEmailSendSuccess: false,
      emailSendFailure: undefined
    });

    const request: EmployeeInviteRequest = {
      employeeIds: employeeIds
    };

    try {
      await sendInviteToAllSelectedInitialEmployees(request, cancelTokenSource);

      this.setState({
        showEmailSendSuccess: true
      });

      setTimeout(() => {
        this.setState({
          showEmailSendSuccess: false
        });
      }, 2000);
    } catch (e) {
      this.setState({
        emailSendFailure: "employee.emailSendFailure"
      });

      setTimeout(() => {
        this.setState({
          emailSendFailure: undefined
        });
      }, 2000);
    } finally {
      this.setState({
        sendingEmailsInProgress: false
      });

      this.fetchEmployees();
    }
  };

  sendInviteToAllSelectedInitialEmployees = async () => {
    const {foundEmployees} = this.state;

    const employeeIds = foundEmployees.filter(emp => emp.selected).map(emp => emp.id);

    this.setState({
      sendingEmailsInProgress: true,
      showEmailSendSuccess: false,
      emailSendFailure: undefined
    });

    const request: EmployeeInviteRequest = {
      employeeIds: employeeIds
    };

    try {

      await sendInviteToAllSelectedInitialEmployees(request, cancelTokenSource);

      this.setState({
        showEmailSendSuccess: true
      });

      setTimeout(() => {
        this.setState({
          showEmailSendSuccess: false
        });
      }, 2000);
    } catch (e) {
      this.setState({
        emailSendFailure: "employee.emailSendFailure"
      });

      setTimeout(() => {
        this.setState({
          emailSendFailure: undefined
        });
      }, 2000);
    } finally {
      this.setState({
        sendingEmailsInProgress: false
      });

      this.fetchEmployees();
    }
  };

  goToEmployeeOverview = (employeeId: number) => {
    this.props.history.push("/employees/overview", {employeeId: employeeId});
  };

  exportEmployees = (language: string) => {

    const {companyData} = this.props;

    this.setState({
      employeesLoaded: false
    });

    setTimeout(() => {
      try {
        const request: Partial<EmployeeExportRequest> = {
          language: language ? language : "de",
          includesInactive: this.state.searchValues.includesInactive,
          searchKey: this.state.searchValues.searchKey
        };
        const exportUrl = getEmployeeExportUrl(request);

        const link = document.createElement('a');
        link.href = window.location.origin + exportUrl;

        const defaultFileName = "temp_response.xlsx";

        link.setAttribute('download', defaultFileName);
        document.body.appendChild(link);
        link.click();
      } catch (e) {
        this.handleError(e.response.data);
        return;
      } finally {
        this.setState({
          employeesLoaded: true
        });
      }
    }, 500);
  };

  render() {
    return (
        <EmployeesContainer>
          <CompanyDataHeader />

          {this.renderSearchEmployeeForm()}
        </EmployeesContainer>
    );
  }

  renderSearchEmployeeForm = (): JSX.Element => {
    return (
        <FinalForm
            onSubmit={() => {
            }}
            initialValues={{
              searchKey: "",
              includesInactive: false
            }}
            subscription={{pristine: true}}
            render={this.renderSearchFormContent}
        />
    );
  };

  renderSearchFormContent = ({form}: FormRenderProps): React.ReactNode => {

    const {t, language} = this.props;
    const {employeesLoaded, errorMessages, divisionLoaded, foundEmployees} = this.state;
    const currentUser = this.props.currentUser!;
    const countSelectedEmployees = foundEmployees.filter(emp => emp.selected).length;
    const countSelectableEmployees = foundEmployees.filter(emp =>
        emp.registrationState === RegistrationStateType.INITIAL && emp.companyEmail !== null).length;
    const disableSendInvitation = !(countSelectedEmployees > 0);

    const message = t("employee.confirmInviteAll") + " "
        + "(" + countSelectedEmployees + "/" + countSelectableEmployees + ")";

    return (
        <>
          <div className="title-h1">{t("employee.viewTitle")}</div>

          {errorMessages.length > 0 &&
            <div className="error">
              <StyledErrorMessage onDismiss={() => this.setErrorMessage()}>
                {errorMessages.map(err => <div key={err}>{err}</div>)}
              </StyledErrorMessage>
            </div>
          }

          <div className="search-form">
            <label htmlFor="searchKey">{t("employee.search")}:</label>

            <Field
                id="searchKey"
                name="searchKey"
                component={Input}
                onKeyDown={(e: any) => this.onQueryKeyDown(e)}
                autoFocus
                fluid
            />

            <Button
                type="button"
                className="action-button"
                primary
                onClick={() => this.fetchEmployees()}
            >
              {t("employee.searchButton")}
            </Button>

            <Field
                name="includesInactive"
                className="checkbox"
                component={CheckBox}
                label={t("employee.includingInactive")}
                toggle={true}
            />
          </div>

          <div className="results-table">
            {(employeesLoaded && divisionLoaded) && this.renderEmployeesTable()}
            {(!employeesLoaded || !divisionLoaded) &&
              <Loader className="table-loader" active inline content={t("employee.loading")} />}
          </div>

          <div className="page-actions">
            {
                this.state.showEmailSendSuccess &&
              <div className="action-message email-send-success">
                {t("employee.emailSendSuccess")}
              </div>
            }
            {
                this.state.emailSendFailure &&
              <div className="action-message email-send-error">
                {t("employee.emailSendFailure")}
              </div>
            }
            <ConfirmActionPopup
                message={message}
                renderContent={undefined}
                renderTarget={<Button
                    type="button"
                    className="action-button"
                    onClick={() => noop()}
                    primary
                    content={t("employee.inviteAllInitialEmployees")}
                    disabled={disableSendInvitation}
                />}
                disabled={disableSendInvitation}
                onConfirmAction={() => this.sendInviteToAllSelectedInitialEmployees()}
            />

            <Button
                type="button"
                className="action-button"
                onClick={() => this.props.history.push(`/employee`)}
                primary
                style={{display: "inline-block", marginRight: "1.5rem"}}

            >
              {t("employee.addNew")}
            </Button>

            <Button
                type="button"
                className="action-button"
                onClick={() => this.exportEmployees(language)}
                primary
                style={{display: "inline-block", marginRight: "1.5rem"}}
            >
              {t("employee.export.header")}
            </Button>


            <Button
                type="button"
                className="action-button"
                onClick={() => this.props.history.push(`/employees-import`)}
                primary
                style={{display: "inline-block", marginRight: "1.5rem"}}
                disabled={currentUser.adminDivisionId > 0}
            >
              {t("employee.import.header")}
            </Button>

            <Button
                type="button"
                className="action-button"
                secondary
                onClick={() => this.props.history.push(`/`)}
                style={{display: "inline-block"}}
            >
              {t("action.back")}
            </Button>
          </div>

          <FormSpy subscription={{values: true}} onChange={this.handleChange} />
        </>
    );
  };

  renderEmployeesTable = (): JSX.Element => {

    const {t} = this.props;
    const {foundEmployees} = this.state;
    return (
        <VirtualizedTable
            rowCount={foundEmployees.length}
            rowGetter={this.employeesRowGetter}
            rowRenderer={this.employeesRowRenderer}
            columns={[
              {
                width: 60,
                label: this.selectHeaderColumn(),
                dataKey: "index",
                cellRenderer: this.employeeSelectCellRenderer
              },
              {
                width: 100,
                label: (t("employee.registrationState")),
                dataKey: "registrationState",
                cellRenderer: this.registrationStateCellRenderer
              },
              {
                width: 100,
                label: (t("employee.status")),
                dataKey: "active",
                cellRenderer: this.statusCellRenderer
              },
              {
                width: 150,
                label: (t("employee.admin")),
                dataKey: "companyAdmin",
                cellRenderer: this.adminCellRenderer
              },
              {
                width: 600,
                label: (t("employee.title")),
                dataKey: "firstName",
                cellRenderer: this.employeeCellRenderer
              },
              {
                width: 200,
                label: (t("employee.birthDate")),
                dataKey: "birthDate",
                cellRenderer: this.birthDateCellRenderer
              },
              {
                width: 500,
                label: (t("employee.companyEmail")),
                dataKey: "companyEmail"
              },
              {
                width: 300,
                label: (t("employee.division")),
                dataKey: "divisionName",
                cellRenderer: this.divisionCellRenderer
              },
              {
                width: 300,
                label: (t("employee.testType")),
                dataKey: "testType",
                cellRenderer: this.testTypeCellRenderer
              },
              {
                width: 200,
                label: (t("employee.testFrequency")),
                dataKey: "testFrequency",
                cellRenderer: this.testFrequencyCellRenderer
              },
              {
                width: 200,
                label: (t("employee.actions")),
                dataKey: "id",
                cellRenderer: (this.actionsCellRenderer)
              }
            ]}
        />

    );
  };

  selectHeaderColumn = () => {

    const {foundEmployees} = this.state;
    const countSelectedEmployees = foundEmployees.filter(emp => emp.selected).length;
    const countSelectableEmployees = foundEmployees.filter(emp =>
        emp.registrationState === RegistrationStateType.INITIAL && emp.companyEmail !== null).length;
    const indeterminate = countSelectedEmployees > 0 && countSelectedEmployees < countSelectableEmployees;
    const noSelectedEmployees = countSelectedEmployees === 0 && countSelectableEmployees > 0;
    const allEmployeesSelected = countSelectableEmployees > 0 && countSelectedEmployees === countSelectableEmployees;

    return (
        <CheckboxSc
            checked={countSelectedEmployees > 0}
            indeterminate={indeterminate}
            onChange={
              () => {
                if (noSelectedEmployees || indeterminate) {
                  let employees = [...foundEmployees];
                  const selectedEmployees = employees.map(e => {
                    if (e.registrationState === RegistrationStateType.INITIAL && e.companyEmail !== null) {
                      return {...e, selected: true};
                    }
                    return e;
                  });
                  this.setState({
                    foundEmployees: selectedEmployees
                  });
                }

                if (allEmployeesSelected) {
                  let employees = [...foundEmployees];
                  const selectedEmployees = employees.map(e => ({...e, selected: false}));
                  this.setState({
                    foundEmployees: selectedEmployees
                  });
                }

              }
            }
            disabled={countSelectableEmployees === 0}
        />
    );
  };

  employeeSelectCellRenderer = (data: any) => {

    const {cellData, rowData} = data;

    const index = cellData - 1;

    const disableSelectionCheckbox = rowData.registrationState !== RegistrationStateType.INITIAL
        || rowData.companyEmail === null;

    return (
        !disableSelectionCheckbox &&
        <CheckboxSc
          checked={rowData.selected}
          onChange={() => this.selectEmployee(index)}
          disabled={disableSelectionCheckbox}
        />
    );
  };

  employeesRowGetter = ({index}: any) => {
    const {foundEmployees} = this.state;

    Object.assign(foundEmployees[index], {index: index + 1});

    return foundEmployees[index];
  };

  employeesRowRenderer = ({className, columns, index, key, style}: any) => {
    const a11yProps = {'aria-rowindex': index + 1};

    const {foundEmployees} = this.state;

    const employeeId = foundEmployees[index].id;

    return (
        <div
            {...a11yProps}
            className={className}
            key={key}
            role="row"
            style={style}
            onDoubleClick={() => this.openEditEmployee(employeeId)}
        >
          {columns}
        </div>
    );
  };

  registrationStateCellRenderer = ({cellData}: any) => {
    const {t} = this.props;

    const tooltipTitle = t(`registrationState.${cellData}`);

    if (cellData === RegistrationStateType.REJECTED) {
      return <Icon title={tooltipTitle} name="minus circle" color="red" size="large" />;
    } else if (cellData === RegistrationStateType.ACCEPTED) {
      return <Icon title={tooltipTitle} name="check circle" color="green" size="large" />;
    } else if (cellData === RegistrationStateType.PENDING) {
      return <Icon title={tooltipTitle} name="question circle" color="blue" size="large" />;
    } else if (cellData === RegistrationStateType.INITIAL) {
      return <Icon title={tooltipTitle} name="circle outline" color="grey" size="large" />;
    }
    return "";
  };

  employeeCellRenderer = ({rowData}: any) => {

    const trigger = (
        <div>{rowData.firstName} {rowData.name}</div>
    );

    const content = rowData.remark;

    if (content) {
      return (
          <div title={""}>
            <Popup
                trigger={trigger}
                popperModifiers={[{
                  name: 'preventOverflow',
                  options: {
                    boundary: document.getElementById("employees-table")
                  }
                }]}
                content={content}
                flowing
                on="hover"
                onClose={noop}
                size="large"
                position="top center"
            />
          </div>
      );
    }

    if (rowData.firstName || rowData.name) {
      return trigger;
    }

    if (rowData.companyEmail) {
      return rowData.companyEmail;
    }

    return "";
  };

  birthDateCellRenderer = ({cellData}: any) => <div>{moment(cellData).format("DD.MM.YYYY")}</div>;

  divisionCellRenderer = ({cellData}: any) => {
    if (cellData && cellData.length > 0) {
      return cellData;
    }
    return "";
  };

  testFrequencyCellRenderer = ({cellData}: any) => {
    const {t} = this.props;
    if (cellData && cellData.length > 0) {
      return t(`testFrequency.${cellData}`);
    }
    return "";
  };

  testTypeCellRenderer = ({cellData}: any) => {
    const {language} = this.props;
    const {interventions} = this.state;

    if (cellData && cellData.length > 0) {
      const intervention = interventions.find(i => i.externalCode === cellData);

      return interventionDescription(intervention, language);
    }
  };

  statusCellRenderer = ({cellData}: any) => {
    const {t} = this.props;
    if (cellData) {
      return t("employee.active");
    }
    return t("employee.inactive");
  };


  adminCellRenderer = ({cellData, rowData}: any) => {
    const {t} = this.props;
    const adminDivisionId = rowData.adminDivisionId;

    const {divisions} = this.state;

    if (!(cellData && cellData === true)) {
      return "";
    } else if (adminDivisionId == null || !adminDivisionId) {
      return <div>{t("employee.admin")}</div>;
    }

    const trigger = (
        <div>{t("employee.admin")}</div>
    );

    const foundDivision = divisions.filter((division: DivisionDto) => division.id === adminDivisionId);

    if (foundDivision && foundDivision.length > 0) {
      const content = (
          <div>{t("employee.adminFor")} {foundDivision[0].name}</div>
      );

      return (
          <div title={""}>
            <Popup
                trigger={trigger}
                popperModifiers={[{
                  name: 'preventOverflow',
                  options: {
                    boundary: document.getElementById("employees-table")
                  }
                }]}
                content={content}
                flowing
                on="hover"
                onClose={noop}
                size="large"
                position="top center"
            />
          </div>
      );
    }
    return trigger;
  };


  actionsCellRenderer = ({rowData, cellData}: any) => {
    const {t} = this.props;

    return (
        <div className="row-actions">
          <EditActionButton
              hoverMessage={t('employee.edit')}
              onConfirm={() => this.openEditEmployee(cellData)}
          />

          {

              (rowData.registrationState === RegistrationStateType.INITIAL
                  || rowData.registrationState === RegistrationStateType.PENDING) &&
            <EditActionButton
              trigger={<Icon name="paper plane" />}
              hoverMessage={t(`${rowData.registrationState === RegistrationStateType.INITIAL
                  ? "employee.invite" : "employee.resendInvite"}`)}
              onConfirm={() => this.sendInviteToEmployee(rowData.id)}
              disabled={rowData.companyEmail === null}
            />
          }

          {
              rowData.registrationState === RegistrationStateType.ACCEPTED &&
            <EditActionButton
              trigger={<Icon name="user circle outline" />}
              hoverMessage={t("employeeOverview.title")}
              onConfirm={() => this.goToEmployeeOverview(rowData.id)}
            />
          }

          {/*<DeleteActionButton
                    popupMessage={
                        t("employee.deleteConfirmMessage",
                            {
                                employee: `${rowData.firstName} ${rowData.name}`
                            })
                    }
                    hoverMessage={t("employee.action.deleteHoverMessage")}
                    onConfirm={() => this.deleteEmployee(cellData)}
                    trigger={<Icon name={'trash alternate outline'} />}
                />*/}

        </div>
    );
  };

}


let EmployeesViewWrapper = withRouterWorkaround(
    withAuthContext(
        withCompanyDataContext(
            withTranslation(["mipoco"])(
                EmployeesView))));

export default EmployeesViewWrapper;