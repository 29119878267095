import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import i18n from "i18next";
import React, {Component} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {Dropdown, DropdownItemProps, Icon, SemanticICONS} from "semantic-ui-react";
import styled from "styled-components";
import {EmployeeAccessTokenType} from "ts-types/api.enums";
import {supportedLanguages} from "util/localizationUtils";

const ContainerDiv = styled.div`
  float: right;
  margin-top: 0.5rem;

  .ui.dropdown > .text {
    font-size: 1.2rem;
  }

  .ui.dropdown > .dropdown.icon {
    margin: 0 0 0 0.5rem;
  }

  .ui.dropdown .menu {
    border-radius: unset;
    box-shadow: unset;
    border-color: #aab3fd;

    .selected.item,
    .ui.dropdown.selected {
      background-color: rgb(236, 239, 255);
    }

    &>.item:hover {
      background-color: rgb(228, 232, 255);
    }
  }

  .top-menu-item {
    margin-left: 1rem;
  }
`;

interface Props extends WithTranslation, AuthConsumerRenderProps {
}

interface State {
}

const PERSON_ACCESS_TYPES = [
    EmployeeAccessTokenType.PERSON,
    EmployeeAccessTokenType.PERSON_SELF_ONBOARDED
];

class HeaderLogin extends Component<Props, State> {

  render(): React.ReactNode {

    const {accessType} = this.props;

    let icon: SemanticICONS = accessType && PERSON_ACCESS_TYPES.includes(accessType) ? "user" : "building";

    const currentLanguage = i18n.language;

    const langOptions: DropdownItemProps[] = supportedLanguages.map(lang => ({
      key: lang,
      value: lang,
      text: lang.toUpperCase()
    }));

    return <ContainerDiv>
      {
        accessType &&
        <>
          <Icon className="top-menu-item" name={icon} color="grey" size="large" />
        </>
      }

      <Dropdown
          className="top-menu-item"
          text={currentLanguage?.toUpperCase()}
          direction="left"
          value={currentLanguage}
          options={langOptions}
          onChange={(evt, data) => {
            const value = data.value as string;
            this.props.updateLanguage(value);
          }}
          selectOnBlur
          selectOnNavigation={false}
      />
    </ContainerDiv>;
  }
}

export default withAuthContext(withTranslation(["login"])(HeaderLogin));
