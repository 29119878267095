import React, { Component } from 'react';
import {Dimmer, DimmerProps, Loader, LoaderProps} from "semantic-ui-react";

interface Props extends LoaderProps {
  message?: string,
  dimmerProps?: DimmerProps
}

interface State {

}

class LoaderComponent extends Component<Props, State> {

  static defaultProps = {
    inline: 'centered',
    size: 'medium',
    dimmerProps: {
      active: true,
      inverted: true
    }
  };

  render(): React.ReactNode {
    let {message, dimmerProps, ...loaderProps}: Partial<Props> = this.props;

    return (
        <Dimmer {...dimmerProps}>
          <Loader {...loaderProps}>{message ? message : ""}</Loader>
        </Dimmer>
    );
  }
}


export default LoaderComponent;
