import axios, {AxiosError, AxiosResponse} from 'axios';
import i18n from "i18next";
import moment from "moment";
import React, {useContext} from 'react';
import {RouteComponentProps, withRouter} from "react-router";
import {
  clearAccessToken,
  getAccessToken,
  getLanguage,
  setAccessToken,
  setLanguage
} from 'service/browserStorageServices';
import {getContainerData} from "service/selfServiceContainerServices";
import {getCurrentUser, sendMagicLink, verifyToken} from "service/userServices";
import {EmployeeAccessTokenType} from "ts-types/api.enums";
import {SelfServiceContainerDto, UserDto} from 'ts-types/api.types';
import {getContainerFromPathname} from "util/pathUtils";

type LoginFunction = (email: string, accessType: EmployeeAccessTokenType) => Promise<any>;

type VerifyTokenFunction = (token: string, code?: string, employeeId?: string) => Promise<any>;

interface State {
  isAuthenticated: boolean;
  skip2faInit: boolean;
  setSkip2FaInit: (skip: boolean) => void;
  accessType?: EmployeeAccessTokenType;
  accessContainer?: string;
  accessContainerData?: SelfServiceContainerDto;
  setContainer: (container?: string) => void;
  setAccessType: (accessType: EmployeeAccessTokenType, container?: string) => void;
  isPersonAccessType: () => boolean;
  currentUser?: UserDto;
  login: LoginFunction;
  verifyToken: VerifyTokenFunction;
  logout: () => void;
  reloadCurrentUser: () => Promise<void | UserDto>;
  fetchingUser: boolean;
  fetchingMessages: boolean;
  setFetchingMessages: (fetchingMessages: boolean) => void;
  sessionExpiredModalOpen: boolean;
  onSessionExpiredModalClose: () => void;
  language: string;
  updateLanguage: (lang: string) => void;
}

const AuthContext = React.createContext({
  isAuthenticated: false,
  skip2faInit: false,
  setSkip2FaInit: (skip: boolean) => {},
  setContainer: (container?: string) => {},
  setAccessType: (accessType: EmployeeAccessTokenType, container?: string) => {},
  isPersonAccessType: (): boolean => false,
  login: (email: string, accessType: EmployeeAccessTokenType) => {return {} as Promise<any>;},
  verifyToken: (token: string, code?: string, employeeId?: string) => {return {} as Promise<any>;},
  logout: () => {},
  reloadCurrentUser: () => {return {} as Promise<void | UserDto>;},
  fetchingUser: true,
  fetchingMessages: true,
  setFetchingMessages: (fetchingMessages: boolean) => {},
  sessionExpiredModalOpen: false,
  onSessionExpiredModalClose: () => {},
  language: getLanguage(),
  updateLanguage: (lang: string) => {}
});

interface Props extends JSX.ElementChildrenAttribute, RouteComponentProps<any> {}

class AuthProviderComp extends React.Component<Props, State> {

  cancelTokenSource = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);

    const accessToken = getAccessToken();

    const pathname = props.location.pathname;
    const skipInitialLoginAttempt = pathname.endsWith("/passwordless-login");
    const attemptInitialLogin = !skipInitialLoginAttempt && !!accessToken;

    const accessContainer = getContainerFromPathname(pathname);

    this.state = {
      isAuthenticated: attemptInitialLogin,
      skip2faInit: false,
      setSkip2FaInit: this.setSkip2faInit,
      accessContainer,
      setContainer: this.setContainer,
      setAccessType: this.setAccessType,
      isPersonAccessType: this.isPersonAccessType,
      login: this.login,
      verifyToken: this.verifyToken,
      logout: this.logout,
      reloadCurrentUser: this.fetchCurrentUser,
      fetchingUser: attemptInitialLogin,
      fetchingMessages: true,
      setFetchingMessages: this.setFetchingMessages,
      sessionExpiredModalOpen: false,
      onSessionExpiredModalClose: this.closeSessionExpiredModal,
      language: getLanguage(),
      updateLanguage: this.updateLanguage
    };

    axios.interceptors.response.use(response => response, this.sessionExpiredHandler);

    if (attemptInitialLogin) {
      this.fetchCurrentUser();
    }
  }

  setSkip2faInit = (skip: boolean) => {
    this.setState({
      skip2faInit: skip
    });
  };

  setAccessType = (accessType: EmployeeAccessTokenType, container?: string) => {
    this.setState({
      accessType,
      accessContainer: container
    });
  };

  setContainer = async (container?: string) => {

    this.setState({
      accessContainer: container,
    });

    let containerData = undefined;
    if (container) {
      containerData = await getContainerData(container, this.cancelTokenSource);
    }
    this.setState({
      accessContainerData: containerData
    });
  };

  isPersonAccessType = (): boolean => {
    const currentUser = this.state.currentUser;
    if (!currentUser || !currentUser.accountType) {
      return false;
    }
    const accountType = currentUser.accountType;
    return EmployeeAccessTokenType.PERSON === accountType
        || EmployeeAccessTokenType.PERSON_SELF_ONBOARDED === accountType;
  };

  sessionExpiredHandler = (error: AxiosError) => {

    const {response} = error;
    const {sessionExpiredModalOpen} = this.state;

    if (response && response.status === 401) {
      if (!sessionExpiredModalOpen && !this.isPathExcludedFromSessionModal(response.data.path)) {
        this.showSessionExpiredModal();
      }
    }

    return Promise.reject(error);
  };

  isPathExcludedFromSessionModal = (path: string): boolean => {

    const excludedPaths = ["auth", "password-reset", "public"];
    let excluded = false;

    for (const excludedPath of excludedPaths) {
      if (path.includes(excludedPath)) {
        excluded = true;
        break;
      }
    }

    return excluded;
  };

  login: LoginFunction = (email, accessType) => {
    return sendMagicLink({
      email: "test",
      type: EmployeeAccessTokenType[accessType]
    }).then((response: AxiosResponse) => {
      const accessToken = response.headers['authorization'].replace('Bearer ', '');
      setAccessToken(accessToken);
      return this.fetchCurrentUser();
    });
  };

  verifyToken: VerifyTokenFunction = (token, code?: string, employeeId?: string) => {
    return verifyToken(token, code, employeeId)
    .then((response) => {
      const accessToken = response.headers['authorization'].replace('Bearer ', '');
      setAccessToken(accessToken);
      return this.fetchCurrentUser();
    });
  };

  fetchCurrentUser = () => {

    return getCurrentUser()
    .then(user => {

      const language = user.language ? user.language : getLanguage();

      this.setState({
        isAuthenticated: user.id !== null,
        sessionExpiredModalOpen: false,
        currentUser: user,
        language: language
      });

      const container = user.container;
      if (container) {
        this.setContainer(container);
      }

      this.updateLanguage(language);

      return user;
    })
    .catch(this.logout)
    .finally(() => {
      this.setState({
        fetchingUser: false
      });
    });
  };

  logout = () => {
    clearAccessToken();

    const container = this.state.accessContainer;

    return this.setState(
        {
          isAuthenticated: false,
          currentUser: undefined,
          sessionExpiredModalOpen: false
        },
        () => {
          this.props.history.push(`/login${container ? "/" + container : ""}`);
          window.location && window.location.reload();
        });
  };

  updateLanguage = (lang: string) => {
    this.setState({
      language: lang
    });
    setLanguage(lang);
    i18n.changeLanguage(lang);
    moment.locale(lang);
  };

  setFetchingMessages = (fetchingMessages: boolean) => {
    this.setState({
      fetchingMessages: fetchingMessages
    });
  };

  showSessionExpiredModal = () => {
    this.setState(({sessionExpiredModalOpen}: State) => ({
      sessionExpiredModalOpen: true
    }));
  };

  closeSessionExpiredModal = () => {
    this.setState(({sessionExpiredModalOpen}: State) => ({
      sessionExpiredModalOpen: false
    }));
  };

  render() {
    return (
        <AuthContext.Provider value={this.state}>
          {this.props.children}
        </AuthContext.Provider>
    );
  }
}

export const AuthProvider = withRouter(AuthProviderComp);


export const AuthConsumer = AuthContext.Consumer;

export interface AuthConsumerRenderProps extends State {}

export const withAuthContext = (Component: typeof React.Component | React.FunctionComponent<any>) => {
  return (props: any) => (
      <AuthConsumer>
        {({...authProps}: AuthConsumerRenderProps) => {
          return <Component {...props} {...authProps} />;
        }}
      </AuthConsumer>
  );
};

export const useCurrentUser = () => {
  const context = useContext<State>(AuthContext);

  if (!context) {
    throw new Error("useCurrentUser must be used inside AuthProvider.");
  }

  return context.currentUser;
};
