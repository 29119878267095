import {CancelTokenSource} from "axios";
import axios from "service/http";
import {ResourceDisplayDto} from "ts-types/api.types";

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const apiUrl = `${baseUrl}`;


export const getAllResources = (
    cancelSourceToken: CancelTokenSource): Promise<ResourceDisplayDto[]> => {

  return axios
  .get(`${apiUrl}/resources`, {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};
