import React, {ComponentClass, Fragment} from "react";

export const supportedLanguages = ["de", "fr", "it", "en"];

export const applyStyles = (text: string) => {

  const regex = /\*\*\$\*(.+?)\*\$\*\*|\*\*\*(.+?)\*\*\*|\[\[nl]]/g;
  const matches = text.matchAll(regex);

  let prevMatchEnd = 0;
  let textNodes = [];
  let ix = 0;
  for (const match of Array.from(matches)) {
    if (match[0].startsWith("**$*")) {
      textNodes.push(<Fragment key={ix++}>{text.substring(prevMatchEnd, match.index)}</Fragment>);
      textNodes.push(<span key={ix++} className="highlight-value">{match[1]}</span>);
      prevMatchEnd = match.index! + match[0].length;
    } else if (match[0].startsWith("***")) {
      textNodes.push(<Fragment key={ix++}>{text.substring(prevMatchEnd, match.index)}</Fragment>);
      textNodes.push(<b key={ix++}>{match[2]}</b>);
      prevMatchEnd = match.index! + match[0].length;
    } else if (match[0] === "[[nl]]") {
      textNodes.push(<Fragment key={ix++}>{text.substring(prevMatchEnd, match.index)}</Fragment>);
      textNodes.push(<br key={ix++} />);
      prevMatchEnd = match.index! + match[0].length;
    }
  }

  if (prevMatchEnd !== text.length) {
    textNodes.push(<Fragment key={ix++}>{text.substring(prevMatchEnd, text.length)}</Fragment>);
  }

  return <>{textNodes}</>;
};

export const insertLink = (text: string, textLink: ComponentClass) => {

  const regex = /%%%(.+?)%%%/g;
  const matches = text.matchAll(regex);

  let prevMatchEnd = 0;
  let textNodes = [];
  let ix = 0;
  for (const match of Array.from(matches)) {
    if (match[0].startsWith("%%%")) {
      textNodes.push(<Fragment key={ix++}>{text.substring(prevMatchEnd, match.index)}</Fragment>);
      textNodes.push(React.createElement(textLink, undefined, [<>{match[1]}</>]));
      prevMatchEnd = match.index! + match[0].length;
    }
  }

  if (prevMatchEnd !== text.length) {
    textNodes.push(<Fragment key={ix++}>{text.substring(prevMatchEnd, text.length)}</Fragment>);
  }

  return <>{textNodes}</>;
};