import {AxiosResponse, CancelTokenSource} from "axios";
import axios from "service/http";
import {
  CompanyDto,
  OnboardingEmailVerificationDto,
  UpsertCompanyOnboardingDto,
  UpsertEmployeeDto
} from "ts-types/api.types";

const baseAuthUrl = process.env.REACT_APP_AUTH_BASE_URL;

export const sendOnboardingEmailVerification = (
    request: OnboardingEmailVerificationDto): Promise<AxiosResponse> => {

  return axios({
    url: `${baseAuthUrl}/pub/onboarding-email-confirmation`,
    method: 'post',
    withCredentials: true,
    data: {
      ...request
    }
  });
};

export const verifyOnboardingToken = (
    email: string, token: string, language: string): Promise<OnboardingEmailVerificationDto> => {

  const formData = new FormData();
  formData.set("token", token);
  formData.set("email", email);
  formData.set("language", language);

  return axios.post(`${baseAuthUrl}/pub/onboarding-token-verification`, formData, {})
  .then(response => response.data);
};

export const convertverifyOnboardingToken = (
    token: string): Promise<string> => {

  const formData = new FormData();
  formData.set("token", token);

  return axios.post(`${baseAuthUrl}/pub/onboarding-to-access-token`, formData, {})
  .then(response => response.data);
};

export const saveCompanyOnboarding = (
    request: Partial<UpsertCompanyOnboardingDto>,
    cancelSourceToken: CancelTokenSource): Promise<CompanyDto> => {

  return axios
  .post(`${baseAuthUrl}/pub/onboarding/company`, request, {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};

export const savePersonOnboarding = (
    request: Partial<UpsertEmployeeDto>,
    container: string | undefined,
    cancelSourceToken: CancelTokenSource): Promise<CompanyDto> => {

  const params = container ? {container} : {};

  return axios
  .post(`${baseAuthUrl}/pub/onboarding/person`, request, {
    params,
    cancelToken: cancelSourceToken.token
  })
  .then(response => response.data);
};