import {DropdownOption} from "component/final-form/Select";
import LoaderComponent from "component/LoaderComponent";
import _ from 'lodash';
import React, {Component} from 'react';
import {FieldRenderProps} from 'react-final-form';
import {Dropdown, DropdownItemProps, DropdownProps, Form} from 'semantic-ui-react';
import {DropdownOnSearchChangeData} from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import styled from 'styled-components';
import {debounce} from 'ts-debounce';
import {isValueEmpty} from "util/validatorUtils";

export const PxDropdownSc = styled(Dropdown)`
  & .visible.menu.transition {
    border-radius: unset;
  }
`;

export interface FinalFormRemoteSelectProps extends FieldRenderProps<any>, DropdownProps {
  remoteSearch?: boolean,
  customRemoteSearchMethod?: (search: string) => void,
  remoteSearchMethod?: (search: string) => Promise<Array<any>>,
  searchResultToOptionMapper?: (options: Array<any>) => Array<DropdownOption>,
  onNewAddition?: (addition: DropdownOption) => void
}

interface State {
  options: Array<DropdownItemProps>
}

class FinalFormRemoteSelectUI extends Component<FinalFormRemoteSelectProps, State> {

  constructor(props: FinalFormRemoteSelectProps) {
    super(props);

    const {options = []} = props;

    this.state = {
      options: options
    }
  }

  componentDidUpdate(
      prevProps: Readonly<FinalFormRemoteSelectProps>,
      prevState: Readonly<State>, snapshot?: any
  ): void {

    const {options: optionsProps = []} = this.props;
    const {options: prevOptions = []} = prevProps;

    if ((prevOptions !== optionsProps && optionsProps.length !== 0 && prevOptions.length !== 0)
        || prevOptions.length !== optionsProps.length) {
      this.setState({
        options: optionsProps
      });
    }
  }

  onSearchChange = debounce((e: React.SyntheticEvent<HTMLElement>, {searchQuery}: DropdownOnSearchChangeData) => {
    const {remoteSearchMethod, customRemoteSearchMethod, searchResultToOptionMapper, allowAdditions} = this.props;

    if (!customRemoteSearchMethod) {
      if (remoteSearchMethod) {
        if (!isValueEmpty(searchQuery)) {

          this.setState({
            options: [{
              key: "dummy",
              text: "dummy",
              value: "dummy",
              content: (
                  <div style={{height: allowAdditions ? "163px" : "200px"}}>
                    <LoaderComponent message=""/>
                  </div>
              ),
              disabled: true
            }]
          });

          remoteSearchMethod(searchQuery)
              .then(results => {
                this.setState({
                  options: searchResultToOptionMapper ? searchResultToOptionMapper(results) : results
                })
              })
        } else {
          this.setState({
            options: []
          })
        }
      }
    } else {

      this.setState({
        options: [{
          key: "dummy",
          text: "dummy",
          value: "dummy",
          content: (
              <div style={{height: allowAdditions ? "163px" : "200px"}}>
                <LoaderComponent message=""/>
              </div>
          ),
          disabled: true
        }]
      });

      customRemoteSearchMethod(searchQuery);
    }
  }, 200);

  onValueChange = (event: React.SyntheticEvent<HTMLElement>, {value}: DropdownProps) => {

    const {input, remoteSearch, clearable} = this.props;

    if (remoteSearch && clearable && !value) {
      this.setState({
        options: []
      })
    }

    input.onChange(value);
  };

  removeAutoCompleteOnFocus = (e: any) => {
    e.target.setAttribute("autocomplete", "nope");
  };

  customSearch = (options: Array<DropdownItemProps>): Array<DropdownItemProps> => {

    const {allowAdditions} = this.props;

    if (allowAdditions) {
      return options.filter(option => {
        return option.key !== "addition";
      });
    }

    return options;
  };

  onNewAddition = (e: any, {value}: any): void => {

    const {onNewAddition} = this.props;

    const newAddition = {
      key: value,
      text: value.toString(),
      value: value
    };

    if (onNewAddition) {
      onNewAddition(newAddition);
    }

    this.setState({
      options: [newAddition, ...this.state.options]
    });
  };

  render(): React.ReactNode {

    const {
      meta,
      input,
      allowAdditions,
      search,
      remoteSearch,
      remoteSearchMethod,
      customRemoteSearchMethod,
      searchResultToOptionMapper,
      automaticAddingOfValue,
      automaticAdditionMapper,
      multiple,
      onNewAddition,
      ...restProps
    } = this.props;

    const {options} = this.state;
    const value = multiple && input.value === "" ? [] : input.value;

    const restPropsCleaned = _.omit(restProps, ["children", "className"]);
    const optionsCleaned = options.map(option => _.omit(option, ["predefinedOption"]));

    return (
        <Form.Field className={`${this.props.className}`}>
          <>
            <PxDropdownSc
                error={!!(meta.error)}
                selection
                value={value}
                onChange={this.onValueChange}
                onFocus={this.removeAutoCompleteOnFocus}
                multiple={!!multiple}
                {...restPropsCleaned}
                search={remoteSearch ? this.customSearch : true}
                allowAdditions={allowAdditions}
                onAddItem={allowAdditions ? this.onNewAddition : undefined}
                onSearchChange={remoteSearch ? this.onSearchChange : undefined}
                options={optionsCleaned}
            />
            {this.props.children}
          </>
        </Form.Field>
    )
  }

}

const FinalFormRemoteSelect = FinalFormRemoteSelectUI;
export default FinalFormRemoteSelect;
