import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import LoaderComponent from "component/LoaderComponent";
import React, {useEffect, useState} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";
import LoginView from "route/login/LoginView";
import {Button} from "semantic-ui-react";
import {unsubscribePatientEmail} from "service/userServices";
import styled from "styled-components";
import {withRouterWorkaround} from "util/workaroundUtils";

const InfoDiv = styled.div`
  max-width: 47rem;
  margin-bottom: 1.5rem;
  display: inline-grid;
  border-bottom: 1px solid #7687FF;
`;

const ErrorInfoDiv = styled.div`
  max-width: 47rem;
  margin-bottom: 1.5rem;
  display: inline-grid;
`;

interface Props extends RouteComponentProps<any>, WithTranslation, AuthConsumerRenderProps {

}

export type UnsubscribePageStatus = "WAIT_FOR_OUTCOME" | "PATIENT_OPT_OUT" | "INVALID_CODE" | "ERROR";

const EmailUnsubscribeView = (props: Props) => {

  const {t} = props;

  const queryParams = new URLSearchParams(props.history.location.search);
  const queryToken = queryParams.get("token");

  const [status, setStatus] = useState("WAIT_FOR_OUTCOME" as UnsubscribePageStatus);
  const [token, setToken] = useState(queryToken as string | null);

  useEffect(() => {
    if (token) {
      unsubscribePatientEmail(token)
      .then(response => {
        setStatus(response);
      })
      .catch(e => {
        setStatus("ERROR");
      });
    }
  }, []);

  if (!queryToken) {
    setToken(null);
    setStatus("INVALID_CODE");
  }

  const goToLogin = () => {

    props.history.push(`/login`);
  };

  const goToEmailConsent = () => {

    props.history.push(`/patient-email-consent?token=${token}`);
  };

  const title = status === "INVALID_CODE" ? "login.passwordlessLogin.title.invalidCode" : "emailUnsubscribe.title";

  return (
      <LoginView titleKey={title}>
        {
            status === "WAIT_FOR_OUTCOME" &&
          <LoaderComponent message={t("emailUnsubscribe.loader.message")} />
        }
        {
            status === "PATIENT_OPT_OUT" &&
          <>
            <InfoDiv>
                  <span style={{marginBottom: "10px"}}>
                    {t("emailUnsubscribe.message1")}
                  </span>
              <span style={{marginBottom: "15px"}}>
                    {t("emailUnsubscribe.message2")}
                  </span>
            </InfoDiv>
            <div>
              <Button
                type="button"
                className="action-button"
                primary
                onClick={(evt) => goToEmailConsent()}
              >
                {t("emailUnsubscribe.btn.setEmailPreferences")}
              </Button>
            </div>
          </>
        }

        {
            status === "INVALID_CODE" &&
          <>
            <ErrorInfoDiv>
              {t("login.passwordlessLogin.error.invalidCode")}
            </ErrorInfoDiv>
            <div>
              <Button
                type="button"
                className="action-button"
                color={"grey"}
                onClick={(evt) => goToLogin()}
              >
                {t("emailUnsubscribe.btn.gotoLogin")}
              </Button>
            </div>
          </>
        }

        {
            status === "ERROR" &&
          <>
            <InfoDiv>
              {t("emailUnsubscribe.error.general")}
            </InfoDiv>

            <div>
              <Button
                type="button"
                className="action-button"
                color={"grey"}
                onClick={(evt) => goToLogin()}
              >
                {t("emailUnsubscribe.btn.gotoLogin")}
              </Button>
            </div>
          </>
        }
      </LoginView>
  );

};

export default withRouterWorkaround(
    withAuthContext(
        withTranslation(["login"])(
            EmailUnsubscribeView)));
