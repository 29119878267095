import React, {Component} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import styled from "styled-components";

const ContainerDiv = styled.div`

  .email-form {
    display: grid;
    grid-template-columns: 150px minmax(10rem, 25rem);

    grid-column-gap: 1rem;
    grid-row-gap: 0.25rem;

    margin-top: 3.5rem;
    margin-bottom: 2rem;

    label {
      align-self: center;
    }

    .error {
      grid-column-start: 2;
      justify-self: start;
      color: darkred;
      font-size: 1rem;
      margin: 0.3rem 0 0.5rem 0;
    }

    .actions-row {
      grid-column-start: 2;
      justify-self: start;
      margin-top: 0.75rem;

      button {
        margin-right: 0.785rem;
      }
    }
  }

`;

const InfoDiv = styled.div`
  max-width: 40rem;
  margin-bottom: 1.5rem;
`;

interface Props extends WithTranslation {
}


class RegistrationSuccessfulView extends Component<Props> {

  render(): React.ReactNode {

    const {t} = this.props;

    return <>
      <ContainerDiv>
        <InfoDiv>{t("companyOnboard.registrationSuccessful.first")}</InfoDiv>
        <InfoDiv>{t("companyOnboard.registrationSuccessful.second")}</InfoDiv>
        <InfoDiv>{t("companyOnboard.registrationSuccessful.third")}</InfoDiv>
        <InfoDiv><strong>{t("companyOnboard.registrationSuccessful.fourth")}</strong></InfoDiv>
      </ContainerDiv>
    </>;
  }
}

export default withTranslation(["login"])(RegistrationSuccessfulView);
