import {int} from "@zxing/library/es2015/customTypings";
import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import {CancelTokenSource} from "axios";
import {CompanyDataConsumerRenderProps, withCompanyDataContext} from "component/CompanyDataContext";
import CompanyDataHeader from "component/CompanyDataHeader";
import Input from "component/final-form/Input";
import RemoteSelect from "component/final-form/RemoteSelect";
import {DropdownOption, mapToDropdownOptionArray} from "component/final-form/Select";
import MpGrid from "component/MpGrid";
import StyledErrorMessage from "component/StyledErrorMessage";
import VirtualizedTable from "component/VirtualizedTable";
import {FormApi, FormState} from "final-form";
import moment from "moment/moment";
import React, {Component} from "react";
import {Field, Form as FinalForm, FormRenderProps, FormSpy} from 'react-final-form';
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";
import {Button, GridColumn, GridRow, Loader} from "semantic-ui-react";
import {
  getAllArticleGroupsByCompanyId,
  getAllStockOrdersByCompanyId,
  getCompanyDivisions,
  getStockManagementOverviewData
} from "service/companyServices";
import {searchEmployees} from "service/employeeServices";
import axios from "service/http";
import {saveStockOrder} from "service/stockerOrderServices";
import styled from "styled-components";
import {debounce} from "ts-debounce";
import {InterventionExternalCode, OrderStatus} from "ts-types/api.enums";
import {
  ArticleGroupDto,
  DivisionDto,
  EmployeeDto,
  EmployeeSearchRequest,
  StockManagementOverviewDataDto,
  StockOrderDto,
  UpsertLightStockOrderDto
} from "ts-types/api.types";
import {isValueValidNumber, required} from "util/validatorUtils";
import {withRouterWorkaround} from "util/workaroundUtils";


const StockManagementContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  
  .orders-table {
    flex: 1 1 auto;
    min-height: 250px;
    margin-bottom: 1rem;

    .row-actions {
      i.icon,
      i.icons {
        color: #768aff;
      }
    }

    .table-loader.ui.inline.loader.active,
    .table-loader.ui.inline.loader.visible {
      display: block;
      top: 5rem;
    }
  }
  
  .action-message {
    display: inline-block;
    font-size: 1.2rem;
    padding-right: 1rem;
    margin: 1rem;

    &.stock-ordered-success {
      color: darkgreen;
    }

  }
  
  .error {
    margin-bottom: 1rem;
  }
  
  .page-actions {
    display: flex;
    margin-top: 1rem;
  }
  
 .table-form-container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
 }
 
 .buttons {
    display: inline-block;
    margin-top: 1rem;
 }
 
 .final-form {
    display: flex;
    flex-direction: column;
 }
 
 .quantity-info {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    
 }
  
`;

interface Props extends RouteComponentProps<any>,
    AuthConsumerRenderProps,
    WithTranslation,
    CompanyDataConsumerRenderProps {

}

interface State {
  errorMessages: Array<string>,
  foundStockOrders: Array<StockOrderDto>,
  stockOrdersLoaded: boolean,
  articleGroups: Array<ArticleGroupDto>,
  articleGroupOptions: Array<DropdownOption>,
  articleGroupId?: int,
  divisions: Array<DivisionDto>,
  divisionOptions: Array<DropdownOption>,
  loading: boolean,
  cancelTokenSource: CancelTokenSource,
  divisionLoaded: boolean,
  articleGroupsLoaded: boolean,
  packageQuantity: number,
  totalQuantity: number,
  showStockOrderedSuccess: boolean,
  testKitsInStock: StockManagementOverviewDataDto,
  foundEmployees: Array<EmployeeDto>,
  employeesOptions: Array<DropdownOption>,
  pcrIndividualTestProductSelected: boolean,
  submitInProgress: boolean
}

const cancelTokenSource = axios.CancelToken.source();

class StockManagementView extends Component<Props, State> {

  private mounted: boolean = false;

  constructor(props: Props) {
    super(props);

    const { t } = props;
    const currentUser = this.props.currentUser!;
    const companyId = currentUser.companyId;

    this.state = {
      errorMessages: [],
      foundStockOrders: [],
      stockOrdersLoaded: true,
      articleGroups: [],
      articleGroupOptions: [],
      divisions: [],
      divisionOptions: [],
      loading: false,
      divisionLoaded: false,
      articleGroupsLoaded: false,
      packageQuantity: 0,
      totalQuantity: 0,
      showStockOrderedSuccess: false,
      testKitsInStock: {
        totalTestsInStock: 0
      },
      foundEmployees: [],
      employeesOptions: [],
      pcrIndividualTestProductSelected: false,
      submitInProgress: false,
      cancelTokenSource
    };

    const searchEmployeeRequest: Partial<EmployeeSearchRequest> = {
              includesInactive: false
    }

    const methods: Array<Promise<any>> = [
      getCompanyDivisions(companyId, cancelTokenSource),
      getAllArticleGroupsByCompanyId(cancelTokenSource),
      getAllStockOrdersByCompanyId(cancelTokenSource),
      getStockManagementOverviewData(cancelTokenSource),
      searchEmployees(searchEmployeeRequest, cancelTokenSource)
    ];

    Promise.all(methods)
        .then(responses => {
          const articleGroups: Array<ArticleGroupDto> = responses[1];
          const articleGroupOptions: Array<DropdownOption> = articleGroups.map((articleGroup: ArticleGroupDto) => ({
            key: articleGroup.id,
            text: t("enum.InterventionExternalCode." + articleGroup.externalCode) as string,
            value: articleGroup.id
          }));

          this.setState({
            divisionOptions: mapToDropdownOptionArray(responses[0], "name", "id"),
            articleGroupOptions: articleGroupOptions,
            articleGroups: articleGroups,
            foundStockOrders: responses[2],
            testKitsInStock: responses[3],
            foundEmployees: responses[4],
            employeesOptions: mapToDropdownOptionArray(responses[4], ["firstName", "name"], "id")
          });
        }).finally(() => {
          this.setState({
            stockOrdersLoaded: true,
            divisionLoaded: true,
            articleGroupsLoaded: true
          });
    });
  }

  componentDidMount(): void {
    this.mounted = true;
  }

  handleChange = debounce((formState: FormState<Partial<UpsertLightStockOrderDto>>) => {
    const {values} = formState;
    const {articleGroupId, quantity} = values;
    const {articleGroups} = this.state;

    let packageQuantity = 0;
    if (articleGroupId) {
      const articleGroup = articleGroups.find(ag => ag.id === articleGroupId);
      if (articleGroup) {
        packageQuantity = articleGroup.packageQuantity;

        if (InterventionExternalCode.PCR_SALIVA_TEST_INDIVIDUAL_TEST === articleGroup.externalCode) {
          this.setState({
            pcrIndividualTestProductSelected: true
          });
        } else {
          this.setState({
            pcrIndividualTestProductSelected: false
          });
        }
      }
    }

    let totalQuantity = 0;
    if (quantity && isValueValidNumber(quantity) && !(+quantity < 0)) {
      totalQuantity = quantity * packageQuantity;
    }
    this.setState({
      stockOrdersLoaded: true,
      packageQuantity: packageQuantity,
      totalQuantity: totalQuantity
    });
  }, 300);

  handleError = (error: any) => {
    const {t} = this.props;

    this.setState({
      submitInProgress: false
    });

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            this.setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!this.state.errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          this.setErrorMessage(t(`error.${errorCode}`));
        } else {
          this.setErrorMessage(t('error.general'));
        }
      }
    }
  };

  setErrorMessage = (errorMessage?: string) => {

    const {errorMessages} = this.state;

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);

      this.setState({
        errorMessages: errMsgs
      });
    } else {

      this.setState({
        errorMessages: []
      });
    }
  };

  handleSubmit = async (values: Partial<UpsertLightStockOrderDto>, form: FormApi) => {

    const {packageQuantity, cancelTokenSource, foundStockOrders, pcrIndividualTestProductSelected} = this.state;

    await this.setState({
      submitInProgress: true
    });

    let upsertLightStockOrderDto = {};

    if(pcrIndividualTestProductSelected) {
       upsertLightStockOrderDto = {
        ...values,
        packageQuantity: packageQuantity
      };
    } else {
      upsertLightStockOrderDto = {
        quantity: values.quantity,
        divisionId: values.divisionId,
        articleGroupId: values.articleGroupId,
        mipocoPaidStockOrder: false,
        packageQuantity: packageQuantity
      }
    }

    saveStockOrder(upsertLightStockOrderDto, cancelTokenSource)
      .then(response => {
        this.setState({
          foundStockOrders: [
            ...foundStockOrders,
            response
          ],
          showStockOrderedSuccess: true
        });
        setTimeout(() => {
          this.setState({
            showStockOrderedSuccess: false
          });
        }, 4000);
        form.reset();
      })
      .catch(this.handleError);
  };

  render() {
    return (
        <StockManagementContainer>
          <CompanyDataHeader />

          {this.renderSearchFormContent()}
        </StockManagementContainer>
    );
  }

  renderSearchFormContent = (): JSX.Element => {

    const {t} = this.props;
    const {errorMessages, stockOrdersLoaded} = this.state;
    return (
        <div className="table-form-container">
          <div className="title-h1">{t("stockManagement.title")}</div>

          {errorMessages.length > 0 &&
          <div className="error">
            <StyledErrorMessage onDismiss={() => this.setErrorMessage()}>
              {errorMessages.map(err => <div key={err}>{err}</div>)}
            </StyledErrorMessage>
          </div>
          }
          <div className="table-form-container">
            <div className="table-form-container">
              {stockOrdersLoaded && <div className="orders-table">
                {this.renderOrdersTable()}
              </div>}
              {!stockOrdersLoaded &&
              <Loader className="table-loader" active inline content={t("stockManagement.stockOrder.loading")} />}
            </div>
            <div className="page-actions">
              <div className="final-form">
                {this.renderFinalForm()}
              </div>
              <div className="quantity-info">
                {this.renderQuantityInfo()}
              </div>
            </div>
          </div>
        </div>
    );
  };

  renderFinalForm(): React.ReactNode {
    return (
        <FinalForm
            onSubmit={(values, form) => this.handleSubmit(values, form)}
            render={this.renderOrdersFrom}
            subscription={{pristine: true, submitting: true}}
        />
    );
  }

  ordersRowGetter = ({index}: any) => {
    const {foundStockOrders} = this.state;

    Object.assign(foundStockOrders[index], {index: index + 1});

    return foundStockOrders[index];
  };

  ordersRowRenderer = ({className, columns, index, key, style}: any) => {
    const a11yProps = {'aria-rowindex': index + 1};

    return (
        <div
            {...a11yProps}
            className={className}
            key={key}
            role="row"
            style={style}
        >
          {columns}
        </div>
    );
  };

  renderOrdersTable = (): JSX.Element => {
    const {t} = this.props;
    const {foundStockOrders} = this.state;
    return (
        <VirtualizedTable
            rowCount={foundStockOrders.length}
            rowGetter={this.ordersRowGetter}
            rowRender={this.ordersRowRenderer}
            rowHeight={42}
            columns={[
              {
                width: 150,
                label: "#",
                dataKey: "index"
              },
              {
                width: 400,
                label: (t("stockManagement.orderedProduct.title")),
                dataKey: "articleGroupId",
                cellRenderer: this.productTypeRenderer
              },
              {
                width: 300,
                label: (t("stockManagement.participant.title")),
                dataKey: "employeeId",
                cellRenderer: this.employeeCellRenderer
              },
              {
                width: 300,
                label: (t("stockManagement.stockOrder.orderDate")),
                dataKey: "orderDate",
                cellRenderer: this.dateCellRenderer
              },
              {
                width: 300,
                label: (t("stockManagement.stockOrder.deliverDate")),
                dataKey: "deliverDate",
                cellRenderer: this.dateCellRenderer
              },
              {
                width: 300,
                label: (t("stockManagement.stockOrder.quantity")),
                dataKey: "quantity",
                cellRenderer: this.quantityCellRenderer
              },
              {
                width: 300,
                label: (t("stockManagement.stockOrder.packageQuantity")),
                dataKey: "articleGroupId",
                cellRenderer: this.packageQuantityCellRenderer
              },
              {
                width: 300,
                label: (t("stockManagement.stockOrder.totalQuantity")),
                dataKey: "articleGroupId",
                cellRenderer: this.totalQuantityCellRenderer
              },
              {
                width: 300,
                label: (t("stockManagement.stockOrder.status")),
                dataKey: "status",
                cellRenderer: this.stockOrderStatusCellRenderer
              }
            ]}
        />
    );
  };

  productTypeRenderer = ({cellData}: any) => {
    const {articleGroups} = this.state;
    const {t} = this.props;

    if (cellData && cellData > 0) {
      const foundAG = articleGroups.filter((ag: ArticleGroupDto) => ag.id === cellData);
      if (foundAG && foundAG.length > 0) {
        let productType = foundAG[0].externalCode;

        return t(`enum.InterventionExternalCode.${productType}`);
      }
    }

    return "";
  };

  packageQuantityCellRenderer = ({cellData}: any) => {
    const {articleGroups} = this.state;

    if (cellData && cellData > 0) {
      const foundAG = articleGroups.find((ag: ArticleGroupDto) => ag.id === cellData);
      if (foundAG) {
        return foundAG.packageQuantity;
      }
    }

    return 0;
  };

  totalQuantityCellRenderer = ({cellData, rowData}: any) => {
    const {articleGroups} = this.state;

    if (cellData && rowData) {

      const foundAG = articleGroups.find((ag: ArticleGroupDto) => ag.id === rowData.articleGroupId);

      if (foundAG) {
        const packageQuantity = foundAG.packageQuantity;
        const quantity = rowData.quantity;

        return quantity * packageQuantity;
      }
    }

    return 0;
  };

  dateCellRenderer = ({cellData}: any) => {

    if (cellData) {
      return moment(cellData).format("DD.MM.YY");
    }
    return "";
  };

  quantityCellRenderer = ({cellData}: any) => {
    if (cellData) {
      return cellData;
    }
    return (
        <div></div>
    );
  };

  stockOrderStatusCellRenderer = ({cellData}: any) => {
    const {t} = this.props;
    if (cellData) {
      return t(`enum.status.${cellData}`);
    }
  };

  employeeCellRenderer = ({rowData}: any) => {
    const {foundEmployees} = this.state;
    if (rowData.employeeId > 0) {
      const employee = foundEmployees.find(e => e.id === rowData.employeeId);
      if(employee) {
        const desc = employee.firstName + " " + employee.name;
        return desc;
      }
    } else {
      return "";
    }
  }

  renderQuantityInfo = (): JSX.Element => {
    const {foundStockOrders, testKitsInStock} = this.state;
    const {t} = this.props;

    const totalOrderQuantity = foundStockOrders
      .filter(so => so.status === OrderStatus.ORDER)
      .map(so => so.quantity)
        .reduce((stockQuantity1, stockQuantity2) => stockQuantity1 + stockQuantity2, 0);

    const totalDeliverQuantity = foundStockOrders
      .filter(so => so.status === OrderStatus.DELIVER)
      .map(so => so.quantity)
      .reduce((stockQuantity1, stockQuantity2) => stockQuantity1 + stockQuantity2, 0);

    return (
        <MpGrid>
          <GridRow>
            <GridColumn width={10}>
              <strong>{t("stockManagement.stockOrder.label.totalOrderQuantity")}</strong>
            </GridColumn>
            <GridColumn width={6} textAlign="right">
              &nbsp;<strong>{totalOrderQuantity}</strong>
            </GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn width={10}>
              <strong>{t("stockManagement.stockOrder.label.totalDeliverQuantity")}</strong>
            </GridColumn>
            <GridColumn width={6} textAlign="right">
              &nbsp;<strong>{totalDeliverQuantity}</strong>
            </GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn width={10}>
              <strong>{t("stockManagement.stockOrder.label.totalTestsInStock")}</strong>
            </GridColumn>
            <GridColumn width={6} textAlign="right">
              &nbsp;<strong>{testKitsInStock.totalTestsInStock}</strong>
            </GridColumn>
          </GridRow>
        </MpGrid>
    );
  }

  renderOrdersFrom = ({ handleSubmit, submitting }: FormRenderProps): React.ReactNode => {
    const {t} = this.props;
    const {divisionOptions, articleGroupOptions, totalQuantity, packageQuantity,
      employeesOptions, pcrIndividualTestProductSelected, showStockOrderedSuccess, submitInProgress} = this.state;

    return (
        <form onSubmit={handleSubmit}>
          <MpGrid>
            <GridRow>
              <GridColumn width={3}>
                <strong>{t("stockManagement.productSelection.dropdown.title")}</strong>
              </GridColumn>
              <GridColumn width={5}>
                <Field
                    name="articleGroupId"
                    component={RemoteSelect}
                    options={articleGroupOptions}
                    validate={required}
                    fluid
                />
              </GridColumn>
              <GridColumn width={2}>
                <strong>{t("stockManagement.package.quantity.title")}</strong>
              </GridColumn>
              <GridColumn width={1} textAlign="right">
                &nbsp;<strong>{packageQuantity}</strong>
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn width={3}>
                <strong>{t("stockManagement.quantity.title")}</strong>
              </GridColumn>
              <GridColumn width={5}>
                <Field
                    fluid
                    name="quantity"
                    component={Input}
                    validate={required}
                />
              </GridColumn>
              <GridColumn width={2}>
                <strong>{t("stockManagement.total.quantity.title")}</strong>
              </GridColumn>
              <GridColumn width={1} textAlign="right">
                &nbsp;<strong>{totalQuantity}</strong>
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn width={3}>
                <strong>{t("stockManagement.divisions.dropdown.title")}</strong>
              </GridColumn>
              <GridColumn width={5}>
                <Field
                    name="divisionId"
                    component={RemoteSelect}
                    options={divisionOptions}
                    clearable
                />
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn width={3}>
                <strong>{t("stockManagement.employees.dropdown.title")}</strong>
              </GridColumn>
              <GridColumn width={5}>
                <Field
                    name="employeeId"
                    component={RemoteSelect}
                    options={employeesOptions}
                    clearable
                    disabled={!pcrIndividualTestProductSelected}
                />
              </GridColumn>
            </GridRow>
          </MpGrid>
          <div className="buttons">
            <Button
                type="submit"
                className="action-button"
                primary
                disabled={submitInProgress}
                style={{display: "inline-block", marginRight: "1.5rem"}}
            >
              {t("stockManagement.stockOrder.orderButton")}
            </Button>

            <Button
                type="button"
                className="action-button"
                secondary
                disabled={submitting}
                onClick={() => this.props.history.push(`/`)}
                style={{display: "inline-block"}}
            >
              {t("action.back")}
            </Button>
          </div>
          {showStockOrderedSuccess &&
          <div className="action-message stock-ordered-success">
            {t("stockManagement.stockOrder.orderSendSuccess")}
          </div>
          }
          <FormSpy subscription={{values: true}} onChange={formState => this.handleChange(formState)} />
        </form>
    );
  };

}

let StockManagementViewWrapper = withRouterWorkaround(
    withAuthContext(
        withCompanyDataContext(
            withTranslation(["mipoco"])(
                StockManagementView
            )
        )
    )
);

export default StockManagementViewWrapper;