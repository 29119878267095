import EmployeeImport from "component/EmployeesImport";
import React from 'react';

function EmployeesImportWrapper() {

  return (
      <div className="home">
        <EmployeeImport/>
      </div>
  );
}

export default EmployeesImportWrapper;
