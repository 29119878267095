import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import React, {Component} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps, withRouter} from "react-router";
import LoginView from "route/login/LoginView";
import {Button} from "semantic-ui-react";
import styled from "styled-components";

const ContainerDiv = styled.div`
  
  .info-box {
    max-width: 40rem;
    margin-bottom: 1.5rem;
  }
  
  .actions-row {
    button {
      margin-right: 0.75rem;
    }
  }
`;

interface Props extends RouteComponentProps<any>, AuthConsumerRenderProps, WithTranslation {
}

class ContractDocumentRejectionView extends Component<Props> {

  gotoTos = () => {
    this.props.history.push("/tos");
  }

  render(): React.ReactNode {

    const {t} = this.props;

    return <LoginView titleKey="contractDocumentsRejection.title">
      <ContainerDiv>
        <div className="info-box">{t("contractDocumentsRejection.info")}</div>

        <div className="actions-row">
          <Button
              type="button"
              className="action-button"
              primary
              onClick={this.gotoTos}
          >
            {t("contractDocumentsRejection.actions.reconsider")}
          </Button>

          <Button
              type="button"
              className="action-button"
              color="grey"
              onClick={this.props.logout}
          >
            {t("action.back")}
          </Button>
        </div>
      </ContainerDiv>
    </LoginView>;
  }
}

export default withRouter(withAuthContext(withTranslation(["login"])(ContractDocumentRejectionView)));
