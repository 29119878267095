import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import {CancelTokenSource} from "axios";
import {BigActionButton} from "component/BigActionButton";
import {CompanyDataConsumerRenderProps, withCompanyDataContext} from "component/CompanyDataContext";
import CompanyDataHeader from "component/CompanyDataHeader";
import DeleteActionButton from "component/DeleteActionButton";
import EditActionButton from "component/EditActionButton";
import HeaderMessage from "component/HeaderMessage";
import LoaderComponent from "component/LoaderComponent";
import StyledErrorMessage from "component/StyledErrorMessage";
import StyledWarningMessage from "component/StyledWarningMessage";
import TestResultIcon from "component/TestResultIcon";
import VirtualizedTable from "component/VirtualizedTable";
import _ from "lodash";
import moment from "moment";
import React, {Component, Fragment} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import Lightbox from 'react-image-lightbox';
import {RouteComponentProps} from "react-router";
import RegisterTestWithQrCodeButton from "route/employee/RegisterTestWithQrCodeButton";
import TestInfo from "route/employee/TestInfo";
import SweepstakeForm from "route/sweepstake/SweepstakeForm";
import {Button, Header, Icon, Modal, Popup, Transition} from "semantic-ui-react";
import {SemanticICONS} from "semantic-ui-react/dist/commonjs/generic";
import {getAllArticleGroupsByCompanyId} from "service/companyServices";
import {getStockOrdersByEmployeeId} from "service/employeeServices";
import axios from "service/http";
import {getAllResources} from "service/resourceServices";
import {getQuestionSetForSweepstakes} from "service/sweepstakesServices";
import {
  createCertificateQrcodeBase64String,
  createRapidTestkitQrcodeBase64String,
  createTestkitQrcodeBase64String,
  deleteInterventionBooking
} from "service/testKitServices";
import {getTestResultImage, getTestResultsForEmployee} from "service/testResultServices";
import styled from "styled-components";
import {debounce} from "ts-debounce";
import {InterventionBookingStatus, InterventionExternalCode, OrderStatus} from "ts-types/api.enums";
import {
  ArticleGroupDto,
  EmployeeTestResultDto,
  EmployeeTestResultImageDto,
  QuestionSetDto,
  ResourceDisplayDto,
  StockOrderDto,
  TestRegistrationQrCodeRequest
} from "ts-types/api.types";
import {errorUtils} from "util/errorUtils";
import {applyStyles} from "util/localizationUtils";
import {withRouterWorkaround} from "util/workaroundUtils";

const SweepstakeDiv = styled.div`
  & .title-sws {
    color: #7687FF;
    font-size: 1.4rem;
  }
`;

const WelcomeDiv = styled.div`
  border-bottom: 1px solid #0f1a52;
  margin-bottom: 2rem;

  .welcome {
    background-color: #BBF3BA;
    padding: 2rem;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2rem;

    .left-content {
      display: inline-block;
      flex-basis: 90%;

      .welcome-content {
        color: #7687FF;

        span {
          color: rgba(0, 0, 0, .87);
        }
      }

      .successful-registration {
        font-size: 25px;
        margin-bottom: 1rem;
      }
    }

    .right-content {
      display: inline-block;
      flex-basis: 10%;
      text-align: right;

      .ui.compact.icon.button,
      .ui.compact.icon.buttons .button {
        padding: 0;
      }

      button {
        background: transparent;

        i {
          color: #1ED71A;
          font-size: 3em;
        }

        i:HOVER {
          color: #18a915;
        }
      }
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 767px) {

    margin-bottom: 0.95rem;

    h1.ui.header {
      font-size: 1.4rem;
      margin-bottom: 0.5rem;
    }

    .welcome {
      padding: 0.5rem;
      flex-wrap: nowrap;
      margin-bottom: 0.95rem;

      .left-content .successful-registration {
        font-size: 1.1rem;
        margin-bottom: 0.5rem;
      }

      .right-content button i {
        font-size: 2.15em;
      }
    }
  }
`;

const ParticipantButtonsDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;

  & .action-button + .action-button {
    margin-left: 1rem;
  }
`;

const ButtonContentDiv = styled.div`

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;

  margin-top: 1.5rem;

  > span {
    margin-bottom: 2rem;
  }

  @media (max-width: 768px) {
    text-align: center;

    > span {
      display: block;
      width: 100%;

      button.login-button {
        width: 100%;
        max-width: unset;
      }
    }
  }

  @media (min-width: 768px) {
    span > button {
      height: 100%;
    }

    > span:not(:last-child) {
      margin-right: 2rem;
    }
  }

  .login-button {
    font-size: 1.15rem;
    min-height: 4.5rem;
    padding: 0.5rem 0.75rem;
    max-width: 25rem;
  }

`;

const BoldedSpan = styled.span`
  font-weight: bold;
`;

const EmployeeTestResultDiv = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  .header-message-text {
    font-size: 1.5rem;
  }

  .search-form {
    padding-left: 0.75rem;
    margin-bottom: 1rem;

    label {
      margin-right: 1rem;
    }

    .ui.input {
      min-width: 15rem;
    }

    button {
      margin-left: 1rem;
    }
  }

  .results-table {
    flex: 1 1 auto;
    min-height: 200px;

    .row-actions {
      i.icon,
      i.icons {
        color: #768aff;
      }
    }
  }

  .test-kits-table {
    flex: 1 1 auto;
    min-height: 200px;
    margin-bottom: 1rem;

    .row-actions {
      i.icon,
      i.icons {
        color: #768aff;
      }
    }

    .no-border.ui.basic.button {
      border: none;
      box-shadow: none;
      padding: 0.2rem 0.5rem;
      color: rgba(0, 0, 0, .8) !important;
    }
  }

  .icon-button {
    padding: 2px !important;
    background: transparent !important;
  }

  .page-actions {
    justify-self: start;
  }
`;

const StyledButton = styled(Button)`
  padding: 2px !important;
  margin-bottom: 2px !important;
  background: transparent !important;
`;

const PopupStyle = {
  borderRadius: 5,
  opacity: 0.7,
  padding: '7px'
};

interface Props extends RouteComponentProps<any>,
    AuthConsumerRenderProps,
    CompanyDataConsumerRenderProps,
    WithTranslation {
}

interface State {
  pageDataLoaded: boolean;
  swsShowQuestion: boolean;
  swsQuestionSet?: QuestionSetDto;
  testResults: Array<EmployeeTestResultDto>;
  testResultsLoaded: boolean;
  orderedTestKits: Array<StockOrderDto>;
  orderedTestKitsLoaded: boolean;
  articleGroups: Array<ArticleGroupDto>;
  resources: ResourceDisplayDto[];
  resourceMap: { [id: number]: ResourceDisplayDto };
  isWelcome: boolean;
  resultImage?: EmployeeTestResultImageDto;
  resultImageLoading: boolean;
  pcrTestType: InterventionExternalCode;
  orderTestsDisabled: boolean;
  orderPcrTestsDisabled: boolean;
  orderRapidTestsDisabled: boolean;
  orderHpvTestsDisabled: boolean;
  orderChlamydiaTestsDisabled: boolean;
  orderTrichomoniasisTestsDisabled: boolean;
  orderRsvTestsDisabled: boolean;
  orderInfluenzaTestsDisabled: boolean;
  schedulePcrTestDisabled: boolean;
  scheduleRapidTestDisabled: boolean;
  scheduleHpvTestDisabled: boolean;
  scheduleChlamydiaTestDisabled: boolean;
  scheduleTrichomoniasisTestDisabled: boolean;
  scheduleRsvTestDisabled: boolean;
  scheduleInfluenzaTestDisabled: boolean;
  testResultIx?: number;
  testResultModalOpen: boolean;
  personDataComplete: boolean;
  errorMessages: Array<string>;
  cancelTokenSource: CancelTokenSource;
}


class DashboardEmployee extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    const locationState: any = props.location.state;
    const isWelcome = locationState && locationState.welcome;

    const cancelTokenSource = axios.CancelToken.source();

    const currentUser = props.currentUser;
    const personDataComplete =
        !!currentUser && (!_.isNil(currentUser.container) || currentUser.personalDataComplete);

    this.state = {
      pageDataLoaded: false,
      swsShowQuestion: false,
      testResults: [],
      testResultsLoaded: true,
      orderedTestKits: [],
      orderedTestKitsLoaded: true,
      articleGroups: [],
      resources: [],
      resourceMap: [],
      isWelcome: isWelcome,
      resultImageLoading: false,
      pcrTestType: InterventionExternalCode.PCR_SALIVA_TEST,
      orderTestsDisabled: true,
      orderPcrTestsDisabled: true,
      orderRapidTestsDisabled: true,
      orderHpvTestsDisabled: true,
      orderChlamydiaTestsDisabled: true,
      orderTrichomoniasisTestsDisabled: true,
      orderRsvTestsDisabled: true,
      orderInfluenzaTestsDisabled: true,
      schedulePcrTestDisabled: true,
      scheduleRapidTestDisabled: true,
      scheduleHpvTestDisabled: true,
      scheduleChlamydiaTestDisabled: true,
      scheduleTrichomoniasisTestDisabled: true,
      scheduleRsvTestDisabled: true,
      scheduleInfluenzaTestDisabled: true,
      errorMessages: [],
      testResultModalOpen: false,
      personDataComplete,
      cancelTokenSource
    };

  }

  componentDidMount() {
    const currentUser = this.props.currentUser!;

    if (currentUser.container && this.props.containerData?.type === "SWS") {
      this.fetchSweepstakesPageData();
      return;
    }

    this.fetchPageData();
  }

  fetchPageData = async () => {
    const {currentUser} = this.props;

    setTimeout(() => {
      this.fetchTestResults();
    }, 50);

    setTimeout(() => {
      this.fetchTestBookings();
    }, 300);

    setTimeout(() => {
      this.fetchOrderedTestKits();
    }, 300);

    try {

      if (currentUser && currentUser.container) {

        const companyArticleGroups = await getAllArticleGroupsByCompanyId(this.state.cancelTokenSource);
        const pcrSingularTest = companyArticleGroups.filter(
            articleGroup => articleGroup.externalCode === InterventionExternalCode.PCR_SALIVA_TEST_INDIVIDUAL_TEST);
        const pcrSalivaTest = companyArticleGroups.filter(
            articleGroup => articleGroup.externalCode === InterventionExternalCode.PCR_SALIVA_TEST);
        const rapidTestTest = companyArticleGroups.filter(
            articleGroup => articleGroup.externalCode === InterventionExternalCode.RAPID_SALIVA_TEST);
        const hpvTest = companyArticleGroups.filter(
            articleGroup => articleGroup.externalCode === InterventionExternalCode.HPV_TEST);
        const chlamydiaTest = companyArticleGroups.filter(
            articleGroup => articleGroup.externalCode === InterventionExternalCode.CHLAMYDIA_TEST);
        const trichomoniasisTest = companyArticleGroups.filter(
            articleGroup => articleGroup.externalCode === InterventionExternalCode.TRICHOMONIASIS_TEST);
        const rsvTest = companyArticleGroups.filter(
            articleGroup => articleGroup.externalCode === InterventionExternalCode.RSV_TEST);
        const influenzaTest = companyArticleGroups.filter(
            articleGroup => articleGroup.externalCode === InterventionExternalCode.INFLUENZA_TEST);

        const filterLogistics = (articleGroups: ArticleGroupDto[]) => articleGroups.filter(ag => ag.logistics);
        const logistics = filterLogistics(companyArticleGroups);

        let pcrTestType = InterventionExternalCode.PCR_SALIVA_TEST;
        if (filterLogistics(pcrSalivaTest).length <= 0 && filterLogistics(pcrSingularTest).length > 0) {
          pcrTestType = InterventionExternalCode.PCR_SALIVA_TEST_INDIVIDUAL_TEST;
        }

        this.setState({
          pcrTestType,
          orderTestsDisabled: logistics.length <= 0,
          orderPcrTestsDisabled: filterLogistics([...pcrSingularTest, ...pcrSalivaTest]).length <= 0,
          orderRapidTestsDisabled: filterLogistics(rapidTestTest).length <= 0,
          orderHpvTestsDisabled: filterLogistics(hpvTest).length <= 0,
          orderChlamydiaTestsDisabled: filterLogistics(chlamydiaTest).length <= 0,
          orderTrichomoniasisTestsDisabled: filterLogistics(trichomoniasisTest).length <= 0,
          orderRsvTestsDisabled: filterLogistics(rsvTest).length <= 0,
          orderInfluenzaTestsDisabled: filterLogistics(influenzaTest).length <= 0,
          schedulePcrTestDisabled: pcrSalivaTest.length <= 0,
          scheduleRapidTestDisabled: rapidTestTest.length <= 0,
          scheduleHpvTestDisabled: hpvTest.length <= 0,
          scheduleChlamydiaTestDisabled: chlamydiaTest.length <= 0,
          scheduleTrichomoniasisTestDisabled: trichomoniasisTest.length <= 0,
          scheduleRsvTestDisabled: rsvTest.length <= 0,
          scheduleInfluenzaTestDisabled: influenzaTest.length <= 0
        });
      }

    } finally {
      this.setState({
        pageDataLoaded: true
      });
    }
  };

  fetchSweepstakesPageData = async () => {

    const {currentUser} = this.props;

    const {cancelTokenSource} = this.state;

    const questionSetDto = await getQuestionSetForSweepstakes(cancelTokenSource);
    if (questionSetDto) {
      this.setState({
        swsShowQuestion: true,
        swsQuestionSet: questionSetDto
      });
    }
    this.setState({
      pageDataLoaded: true
    });
  };

  fetchTestResults = debounce((): void => {
    const {personDataComplete, cancelTokenSource} = this.state;
    const {containerData} = this.props;

    if (!personDataComplete || containerData?.type === "SWS") {
      return;
    }

    const onFinally = () => {
      this.setState({
        testResultsLoaded: true
      });
    };

    this.setState({
      testResultsLoaded: false,
      testResults: []
    });

    getTestResultsForEmployee(cancelTokenSource)
    .then(response => {
          let plannedTestResults =
              response.filter(tr => InterventionBookingStatus.PLANNED === tr.interventionBookingStatus);
          plannedTestResults =
              _.orderBy(plannedTestResults, ['appointmentDate', 'appointmentTime'], ['asc', 'desc']);
          const otherTestResults =
              response.filter(tr => InterventionBookingStatus.PLANNED !== tr.interventionBookingStatus);
          this.setState({
            testResults: [...plannedTestResults, ...otherTestResults]
          });
        }
    )
    .catch((e: any) => this.handleError(e.response.data))
    .finally(onFinally);

  }, 300);

  showTestResultModal = (testResultIx: number) => {
    this.setState({
      testResultModalOpen: true,
      testResultIx
    });
  };

  hideTestResultModal = () => {
    this.setState({
      testResultModalOpen: false,
      testResultIx: undefined
    });
  };

  fetchTestBookings = async () => {
    if (this.isTestSchedulingEnabled()) {
      const {personDataComplete, cancelTokenSource} = this.state;

      if (!personDataComplete) {
        return;
      }

      const resources = await getAllResources(cancelTokenSource);
      this.setState({
        resources,
        resourceMap: _.keyBy(resources, "id")
      });
    }
  };

  fetchOrderedTestKits = async () => {
    const currentUser = this.props.currentUser!;
    const {cancelTokenSource} = this.state;

    const employeeId = parseInt(currentUser.id);

    const onFinally = () => {
      this.setState({
        orderedTestKitsLoaded: true
      });
    };

    this.setState({
      orderedTestKitsLoaded: false,
      orderedTestKits: []
    });

    const methods: Array<Promise<any>> = [
      getStockOrdersByEmployeeId(employeeId, cancelTokenSource),
      getAllArticleGroupsByCompanyId(cancelTokenSource)
    ];

    Promise.all(methods)
    .then(responses => {
      const stockOrders: Array<StockOrderDto> = responses[0];
      const articleGroups: Array<ArticleGroupDto> = responses[1];

      this.setState({
        orderedTestKits: stockOrders.filter(order =>
            OrderStatus.REGISTERED !== order.status
            && OrderStatus.CANCEL !== order.status),
        articleGroups: articleGroups
      });
    })
    .catch((e: any) => this.handleError(e.response.data))
    .finally(onFinally);
  };

  isTestSchedulingEnabled = () => {
    const currentUser = this.props.currentUser!;
    const container = currentUser.container;
    return container && this.props.containerData && this.props.containerData.testSchedulingEnabled;
  };

  isTestPending = (testResults: EmployeeTestResultDto[], testExternalCode: InterventionExternalCode) => {

    return testResults.filter(testRes => (
        testRes.interventionBookingStatus !== InterventionBookingStatus.COMPLETED
        && testRes.testType === testExternalCode.toString()
    ));
  };


  deleteAppointment = async (interventionBookingId: number) => {
    const {cancelTokenSource} = this.state;

    await deleteInterventionBooking(interventionBookingId, cancelTokenSource)
    .then(() => {
      this.fetchTestResults();
    })
    .catch(e => this.handleError(e.response.data));
  };

  handleError(error: any) {
    const {t} = this.props;

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        "UNKNOWN_TEST_ATTACHMENT_ID",
        "EMPTY_BYTE_ARRAY_TEST_ATTACHMENT_BLOB",
        "BLOB_CONTAINER_NOT_INITIALIZED",
        "AZURE_IS_DOWN",
        "DELETING_REFERENCED_BOOKING_NOT_ALLOWED",
        errorUtils.unableToRetrieveTestAttachment
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            this.setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!this.state.errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          this.setErrorMessage(t(`error.${errorCode}`));
        } else {
          this.setErrorMessage(t('error.general'));
        }
      }
    }
  };

  setErrorMessage = (errorMessage?: string) => {

    const {errorMessages} = this.state;

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);

      this.setState({
        errorMessages: errMsgs
      });
    } else {

      this.setState({
        errorMessages: []
      });
    }
  };

  closeWelcome = () => {
    this.setState({
      isWelcome: false
    });
  };

  onRegisterTestkitManually = (testType: any, testkitOrderId: number) => {
    this.props.history.push("/employee/manual-testkit-registration",
        {testType: testType, testkitOrderId: testkitOrderId});
  };

  onTestOrderButtonClick = () => {
    this.props.history.push("/person/test-kit-orders-list");
  };

  openRegisterNewParticipant = (): void => {
    const {currentUser} = this.props;
    this.props.history.push("/employee/", {
      companyEmail: currentUser && currentUser.username ? currentUser.username : undefined
    });
  };

  openAccountSelection = (): void => {
    this.props.history.push("/account-selection");
  };

  renderNewParticipantAndSwitchUserSection = () => {
    const {currentUser, t} = this.props;
    const multiAcc = currentUser && currentUser.multiAccount;
    return (
        <ParticipantButtonsDiv>
          <Button
              type="button"
              className="action-button"
              primary
              onClick={this.openRegisterNewParticipant}
          >
            {t("dashboard.button.registerNewParticipant")}
          </Button>
          {
              multiAcc &&
            <Button
              type="button"
              className="action-button"
              primary
              onClick={this.openAccountSelection}
            >
              {t("dashboard.button.switchParticipant")}
            </Button>
          }
        </ParticipantButtonsDiv>
    );
  };

  render(): React.ReactNode {

    const {
      pageDataLoaded,
      testResultsLoaded,
      orderedTestKitsLoaded,
      resultImage,
      testResultModalOpen,
      errorMessages,
      resultImageLoading,
      personDataComplete
    } = this.state;

    const {t} = this.props;

    if (!pageDataLoaded) {
      return <></>;
    }

    if (this.props.containerData?.type === "SWS") {
      return <SweepstakeDiv>
        <CompanyDataHeader />
        {this.renderWelcomeHeader()}
        {
          this.state.swsQuestionSet
              ? <SweepstakeForm
                  questionSet={this.state.swsQuestionSet}
                  onSuccess={() => {
                    this.setState({
                      swsQuestionSet: undefined
                    });
                  }}
              />
              : <div className="title-sws">{t("dashboard.title.sweepstakeGreeting")}</div>
        }
      </SweepstakeDiv>;
    }

    const state: any = this.props.location.state;
    const registeredTestkitCode = (state && state.registeredTestkitCode) ? state.registeredTestkitCode : undefined;

    const paymentContainer = this.props.containerData && this.props.containerData.paymentsSupported;

    return (
        <>
          {
              resultImageLoading && <LoaderComponent message={t("employeeDashboard.testResultImage.loading")} />
          }
          <EmployeeTestResultDiv>
            {this.renderWelcomeHeader()}

            {
                registeredTestkitCode &&
              <HeaderMessage visible>
                <div className="header-message-text">
                  {applyStyles(t("testKitRegistration.success.registrationSuccess",
                      {code: registeredTestkitCode}))}
                </div>
              </HeaderMessage>
            }

            <CompanyDataHeader />

            {/*{this.renderNewParticipantAndSwitchUserSection()}*/}

            <div className="title-h1">{t("dashboard.title.overallOveriew")}</div>

            {
                !personDataComplete &&
              <div className="error">
                <StyledWarningMessage>
                  {t("dashboard.warning.incompletePersonalData")}
                </StyledWarningMessage>
              </div>
            }

            {
                errorMessages.length > 0 &&
              <div className="error">
                <StyledErrorMessage onDismiss={() => this.setErrorMessage()}>
                  {errorMessages.map(err => <div key={err}>{err}</div>)}
                </StyledErrorMessage>
              </div>
            }

            {
                personDataComplete && paymentContainer &&
              <div className="test-kits-table">
                {!orderedTestKitsLoaded
                    ? <LoaderComponent message={t("testKitRegistration.loading")} />
                    : this.renderOrderedTestKitsTable()
                }
              </div>
            }

            <div className="results-table">
              {
                  personDataComplete &&
                <>
                  {!testResultsLoaded
                      ? <LoaderComponent message={t("testKitRegistration.loading")} />
                      : this.renderTestResultsTable()
                  }
                </>
              }
            </div>

            {this.renderButtonsContent()}

            {
                resultImage &&
              <Lightbox
                mainSrc={`data:${resultImage.testResultFileType};base64, ${resultImage.testResultImage}`}
                onCloseRequest={() => this.setState({resultImage: undefined})}
              />
            }
            {testResultModalOpen && this.renderTestResultModal()}
          </EmployeeTestResultDiv>
        </>
    );
  }

  renderWelcomeHeader = (): React.ReactNode => {

    const {t, currentUser} = this.props;
    const {isWelcome} = this.state;

    let formattedDate = "";
    if (currentUser?.lastLogin) {
      const momentDate = moment(currentUser.lastLogin);

      formattedDate = momentDate.format('DD. MMMM YYYY - HH:mm');
    }

    return (
        <Transition.Group animation="slide down" duration={125}>
          {isWelcome && (
              <WelcomeDiv>
                <div className="welcome">
                  <div className="left-content">
                    <Header as="h1">
                      <div className="welcome-content">
                        {t("dashboard.header.welcome")},&nbsp;
                        <span>{currentUser?.firstName} {currentUser?.lastName}</span>
                      </div>
                    </Header>
                    <div className="successful-registration">
                      {t("employeeDashboard.home.successfullyRegistered")}
                    </div>
                    <div>
                      ({t("employeeDashboard.header.lastRegistrationOn")} <BoldedSpan>{formattedDate}</BoldedSpan>)
                    </div>
                  </div>
                  <div className="right-content">
                    <Button circular icon compact onClick={this.closeWelcome}>
                      <Icon size="big" name="times circle" />
                    </Button>
                  </div>
                </div>
              </WelcomeDiv>
          )}
        </Transition.Group>
    );
  };

  createRequestForCreateQrCodeCall = (
      testType: InterventionExternalCode,
      testkitOrderId?: number): TestRegistrationQrCodeRequest => {

    const {currentUser} = this.props;

    const request: TestRegistrationQrCodeRequest = {
      employeeId: Number(currentUser?.id),
      sourceEmployeeId: -1,
      companyId: -1,
      baseUrl: "",
      externalCode: testType
    };

    if (testkitOrderId) {
      request.testkitOrderId = testkitOrderId;
    }

    return request;
  };

  renderButtonsContent = (): React.ReactNode => {
    const {
      testResults,
      pcrTestType,
      orderTestsDisabled,
      orderPcrTestsDisabled,
      orderRapidTestsDisabled,
      orderHpvTestsDisabled,
      orderChlamydiaTestsDisabled,
      orderTrichomoniasisTestsDisabled,
      orderRsvTestsDisabled,
      orderInfluenzaTestsDisabled,
      schedulePcrTestDisabled,
      scheduleRapidTestDisabled,
      scheduleHpvTestDisabled,
      scheduleChlamydiaTestDisabled,
      scheduleTrichomoniasisTestDisabled,
      scheduleRsvTestDisabled,
      scheduleInfluenzaTestDisabled
    } = this.state;

    const {currentUser} = this.props;

    let employeeId = 0;
    if (currentUser) {
      employeeId = parseInt(currentUser.id);
    }

    const pendingTest = testResults.find(testResult =>
        testResult.interventionBookingStatus !== InterventionBookingStatus.COMPLETED);

    const pendingPcrTest = this.isTestPending(testResults, InterventionExternalCode.PCR_SALIVA_TEST);

    const pendingRapidTest = this.isTestPending(testResults, InterventionExternalCode.RAPID_SALIVA_TEST);

    const pendingHpvTest = this.isTestPending(testResults, InterventionExternalCode.HPV_TEST);

    const pendingChlamydiaTest = this.isTestPending(testResults, InterventionExternalCode.CHLAMYDIA_TEST);

    const pendingTrichomoniasisTest = this.isTestPending(testResults, InterventionExternalCode.TRICHOMONIASIS_TEST);

    const pendingRsvTest = this.isTestPending(testResults, InterventionExternalCode.RSV_TEST);

    const pendingInfluenzaTest = this.isTestPending(testResults, InterventionExternalCode.INFLUENZA_TEST);

    const testInterventionType = currentUser?.testInterventionType;

    const hasPcrTest = testInterventionType === InterventionExternalCode.PCR_SALIVA_TEST
        || testInterventionType === InterventionExternalCode.PCR_SALIVA_TEST_INDIVIDUAL_TEST;
    const hasHpvTest = testInterventionType === InterventionExternalCode.HPV_TEST;
    const hasRapidTest = testInterventionType === InterventionExternalCode.RAPID_SALIVA_TEST;
    const hasChlamydiaTest = testInterventionType === InterventionExternalCode.CHLAMYDIA_TEST;
    const hasTrichomoniasisTest = testInterventionType === InterventionExternalCode.TRICHOMONIASIS_TEST;
    const hasRsvTest = testInterventionType === InterventionExternalCode.RSV_TEST;
    const hasInfluenzaTest = testInterventionType === InterventionExternalCode.INFLUENZA_TEST;

    const hasRecoveryCertificate =
        testInterventionType === InterventionExternalCode.COVID_CERTIFICATE_OF_RECOVERY;
    const hasVaccinationCertificate =
        testInterventionType === InterventionExternalCode.COVID_CERTIFICATE_OF_VACCINATION;
    const hasExternalTestDocument =
        testInterventionType === InterventionExternalCode.COVID_CERTIFICATE_EXTERNAL_TEST_DOCUMENT;
    const hasCertificateTest = hasRecoveryCertificate || hasVaccinationCertificate || hasExternalTestDocument;

    let disableTestKitRegistration = !!pendingTest || !this.state.personDataComplete;
    let disablePcrTestKitRegistration = pendingPcrTest.length >= 1 || !this.state.personDataComplete;
    let disableRapidTestKitRegistration = pendingRapidTest.length >= 1 || !this.state.personDataComplete;
    let disableHpvTestKitRegistration = pendingHpvTest.length >= 1 || !this.state.personDataComplete;
    let disableChlamydiaTestKitRegistration = pendingChlamydiaTest.length >= 1 || !this.state.personDataComplete;
    let disableTrichomoniasisTestKitRegistration =
        pendingTrichomoniasisTest.length >= 1 || !this.state.personDataComplete;
    let disableRsvTestKitRegistration = pendingRsvTest.length >= 1 || !this.state.personDataComplete;
    let disableInfluenzaTestKitRegistration = pendingInfluenzaTest.length >= 1 || !this.state.personDataComplete;


    if (hasCertificateTest) {
      const recoveryCertificate = hasRecoveryCertificate && testResults.find(testResult =>
          testResult.testType === InterventionExternalCode.COVID_CERTIFICATE_OF_RECOVERY) !== undefined;

      const vaccinationCertificate = hasVaccinationCertificate && testResults.find(testResult =>
          testResult.testType === InterventionExternalCode.COVID_CERTIFICATE_OF_VACCINATION) !== undefined;

      const externalTestCertificate = hasExternalTestDocument && testResults.find(testResult =>
          testResult.testType === InterventionExternalCode.COVID_CERTIFICATE_EXTERNAL_TEST_DOCUMENT) !== undefined;

      disablePcrTestKitRegistration = recoveryCertificate || vaccinationCertificate || externalTestCertificate;
      disableRapidTestKitRegistration = recoveryCertificate || vaccinationCertificate || externalTestCertificate;
      disableHpvTestKitRegistration = recoveryCertificate || vaccinationCertificate || externalTestCertificate;
      disableChlamydiaTestKitRegistration = recoveryCertificate || vaccinationCertificate || externalTestCertificate;
      disableTrichomoniasisTestKitRegistration =
          recoveryCertificate || vaccinationCertificate || externalTestCertificate;
      disableRsvTestKitRegistration = recoveryCertificate || vaccinationCertificate || externalTestCertificate;
      disableInfluenzaTestKitRegistration = recoveryCertificate || vaccinationCertificate || externalTestCertificate;

      disableTestKitRegistration =
          disablePcrTestKitRegistration || disableRapidTestKitRegistration || disableHpvTestKitRegistration
          || disableChlamydiaTestKitRegistration || disableTrichomoniasisTestKitRegistration
          || disableRsvTestKitRegistration || disableInfluenzaTestKitRegistration;
    }

    const inContainer = this.props.companyData.container;
    const nonPaymentContainer = this.props.containerData && !this.props.containerData.paymentsSupported;
    const testSchedulingEnabled = this.isTestSchedulingEnabled();

    return (
        <ButtonContentDiv>
          {
              inContainer && !orderTestsDisabled &&
            <span>
              <BigActionButton
                icon="shipping"
                textDomain="mipoco"
                textKey="employeeDashboard.button.orderTests"
                onClick={this.onTestOrderButtonClick}
              />
            </span>
          }

          {
              testSchedulingEnabled && !schedulePcrTestDisabled &&
              this.renderTestScheduleButton(
                  "personDashboard.action.pcrTest", "dna", "/request-pcr-test")
          }

          {
              testSchedulingEnabled && !scheduleRapidTestDisabled &&
              this.renderTestScheduleButton(
                  "personDashboard.action.rapidTest", "flask", "/request-rapid-test")
          }

          {
              testSchedulingEnabled && !scheduleHpvTestDisabled &&
              this.renderTestScheduleButton(
                  "personDashboard.action.hpvTest", "eye dropper", "/request-hpv-test")
          }

          {
              testSchedulingEnabled && !scheduleChlamydiaTestDisabled &&
              this.renderTestScheduleButton(
                  "personDashboard.action.chlamydiaTest", "eye dropper", "/request-chlamydia-test")
          }

          {
              testSchedulingEnabled && !scheduleTrichomoniasisTestDisabled &&
              this.renderTestScheduleButton(
                  "personDashboard.action.trichomoniasisTest", "eye dropper",
                  "/request-trichomoniasis-test")
          }

          {
              testSchedulingEnabled && !scheduleRsvTestDisabled &&
              this.renderTestScheduleButton(
                  "personDashboard.action.rsvTest", "dna", "/request-rsv-test")
          }

          {
              testSchedulingEnabled && !scheduleInfluenzaTestDisabled &&
              this.renderTestScheduleButton(
                  "personDashboard.action.influenzaTest", "dna", "/request-influenza-test")
          }

          {
              ((!inContainer && hasPcrTest) || (nonPaymentContainer && !orderPcrTestsDisabled)) &&
              this.renderTestRegisterButton(
                  disablePcrTestKitRegistration, pcrTestType,
                  "employeeDashboard.button.testKitsRegistrationSmartphone",
                  "employeeDashboard.button.testKitsRegistrationWebBrowser")
          }

          {
              ((!inContainer && hasHpvTest) || (nonPaymentContainer && !orderHpvTestsDisabled)) &&
              this.renderTestRegisterButton(
                  disableHpvTestKitRegistration, InterventionExternalCode.HPV_TEST,
                  "employeeDashboard.button.hpvTestKitRegistrationSmartphone",
                  "employeeDashboard.button.hpvTestKitsRegistrationWebBrowser")
          }

          {
              ((!inContainer && hasChlamydiaTest) || (nonPaymentContainer && !orderChlamydiaTestsDisabled)) &&
              this.renderTestRegisterButton(
                  disableChlamydiaTestKitRegistration, InterventionExternalCode.CHLAMYDIA_TEST,
                  "employeeDashboard.button.chlamydiaTestKitRegistrationSmartphone",
                  "employeeDashboard.button.chlamydiaTestKitsRegistrationWebBrowser")
          }

          {
              ((!inContainer && hasTrichomoniasisTest) || (nonPaymentContainer && !orderTrichomoniasisTestsDisabled)) &&
              this.renderTestRegisterButton(
                  disableTrichomoniasisTestKitRegistration, InterventionExternalCode.TRICHOMONIASIS_TEST,
                  "employeeDashboard.button.trichomoniasisTestKitRegistrationSmartphone",
                  "employeeDashboard.button.trichomoniasisTestKitsRegistrationWebBrowser")
          }

          {
              ((!inContainer && hasRsvTest) || (nonPaymentContainer && !orderRsvTestsDisabled)) &&
              this.renderTestRegisterButton(
                  disableRsvTestKitRegistration, InterventionExternalCode.RSV_TEST,
                  "employeeDashboard.button.rsvTestKitRegistrationSmartphone",
                  "employeeDashboard.button.rsvTestKitsRegistrationWebBrowser")
          }

          {
              ((!inContainer && hasInfluenzaTest) || (nonPaymentContainer && !orderInfluenzaTestsDisabled)) &&
              this.renderTestRegisterButton(
                  disableInfluenzaTestKitRegistration, InterventionExternalCode.INFLUENZA_TEST,
                  "employeeDashboard.button.influenzaTestKitRegistrationSmartphone",
                  "employeeDashboard.button.influenzaTestKitsRegistrationWebBrowser")
          }


          {
              ((!inContainer && hasRapidTest)
                  || (nonPaymentContainer && !orderRapidTestsDisabled)) &&
            <>
              <RegisterTestWithQrCodeButton
                disabled={disableRapidTestKitRegistration}
                user={currentUser!}
                buttonText="employeeDashboard.button.registerRapidTestkit"
                onClose={this.fetchTestResults}
                fetchQrCodeLink={() => {

                  const request = this.createRequestForCreateQrCodeCall(InterventionExternalCode.RAPID_SALIVA_TEST);

                  return createRapidTestkitQrcodeBase64String(request, this.state.cancelTokenSource)
                  .then(response => {
                    return {
                      url: response.url,
                      b64Image: response.imageB64
                    };
                  });
                }}
              />
            </>
          }

          {
              hasVaccinationCertificate &&
              this.registerCertificateOrDocumentButton(disableTestKitRegistration,
                  "employeeDashboard.button.registerCertificateOfVaccination",
                  InterventionExternalCode.COVID_CERTIFICATE_OF_VACCINATION,
                  "vaccination")
          }

          {
              hasRecoveryCertificate &&
              this.registerCertificateOrDocumentButton(disableTestKitRegistration,
                  "employeeDashboard.button.registerCertificateOfRecovery",
                  InterventionExternalCode.COVID_CERTIFICATE_OF_RECOVERY,
                  "recovery")
          }

          {
              hasExternalTestDocument &&
              this.registerCertificateOrDocumentButton(disableTestKitRegistration,
                  "employeeDashboard.button.registerCertificateExternalTestDocument",
                  InterventionExternalCode.COVID_CERTIFICATE_EXTERNAL_TEST_DOCUMENT,
                  "externalTestDocument")
          }

          <span>
            <BigActionButton
                icon="circle notch"
                textDomain="mipoco"
                textKey="employeeDashboard.button.manageAccount"
                onClick={() => this.openEditEmployee(employeeId)}
            />
          </span>
        </ButtonContentDiv>
    );
  };

  renderTestScheduleButton = (textKey: string, icon: SemanticICONS, path: string): React.ReactNode => {

    return (
        <span>
          <BigActionButton
              icon={icon}
              textDomain="mipoco"
              textKey={textKey}
              onClick={() => this.props.history.push(path)}
          />
        </span>
    );
  };

  renderTestRegisterButton = (
      disableTestRegistration: boolean,
      testExternalCode: InterventionExternalCode,
      buttonText: string,
      textKey: string
  ): React.ReactNode => {

    const {currentUser} = this.props;
    return (
        <>
          <span>
            <RegisterTestWithQrCodeButton
                disabled={disableTestRegistration}
                user={currentUser!}
                buttonText={buttonText}
                onClose={this.fetchTestResults}
                fetchQrCodeLink={() => {
                  const request = this.createRequestForCreateQrCodeCall(testExternalCode);
                  return createTestkitQrcodeBase64String(request, this.state.cancelTokenSource)
                  .then(response => {
                    return {
                      url: response.url,
                      b64Image: response.imageB64
                    };
                  });
                }}
            />
          </span>

          <span>
                <BigActionButton
                    icon="window maximize outline"
                    textDomain="mipoco"
                    textKey={textKey}
                    onClick={() =>
                        this.props.history.push("/employee/manual-testkit-registration",
                            {testType: testExternalCode})}
                    disabled={disableTestRegistration}
                />
              </span>
        </>
    );
  };

  registerCertificateOrDocumentButton = (
      disableTestKitRegistration: boolean,
      buttonText: string,
      externalCode: InterventionExternalCode,
      certificateType: string): React.ReactNode => {

    const {currentUser} = this.props;

    return (
        <>
          <RegisterTestWithQrCodeButton
              disabled={disableTestKitRegistration}
              user={currentUser!}
              buttonText={buttonText}
              onClose={this.fetchTestResults}
              fetchQrCodeLink={() => {

                const request = this.createRequestForCreateQrCodeCall(externalCode);

                return createCertificateQrcodeBase64String(
                    request, certificateType, this.state.cancelTokenSource)
                .then(response => {
                  return {
                    url: response.url,
                    b64Image: response.imageB64
                  };
                });
              }}
          />
        </>
    );
  };

  testResultsRowGetter = ({index}: any) => {
    const {testResults} = this.state;
    Object.assign(testResults[index], {index: index + 1});

    return testResults[index];
  };

  testResultsRowRenderer = ({rowData, className, columns, index, key, style}: any) => {

    const {t} = this.props;

    const a11yProps = {'aria-rowindex': index + 1};

    let rowStyle = {...style};

    return (
        <div
            {...a11yProps}
            className={className}
            key={key}
            role="row"
            style={rowStyle}
            onDoubleClick={() => {
              if (rowData.interventionBookingStatus === InterventionBookingStatus.PLANNED) {
                this.showTestResultModal(index);
              }
            }}
        >
          {columns.map((column: any) => {
            let col = {...column};

            if (column.props["aria-colindex"] === 4 && column.props.children === ""
                && rowData.interventionBookingStatus !== InterventionBookingStatus.COMPLETED) {

              let statusText = `${t("employeeDashboard.table.status")}: `;
              let messageKey = t(`enum.interventionBookingStatus.${rowData.interventionBookingStatus}`);

              if (rowData.interventionBookingStatus === InterventionBookingStatus.PLANNED) {
                messageKey = "";
                statusText = "";
              }

              col = {
                ...col,
                props: {
                  ...col.props,
                  children: `${statusText} ${messageKey}`
                }
              };

            }

            return col;
          })}
        </div>
    );
  };

  ordersRowGetter = ({index}: any) => {
    const {orderedTestKits} = this.state;

    Object.assign(orderedTestKits[index], {index: index + 1});

    return orderedTestKits[index];
  };

  ordersRowRenderer = ({className, columns, index, key, style}: any) => {
    const a11yProps = {'aria-rowindex': index + 1};

    return (
        <div
            {...a11yProps}
            className={className}
            key={key}
            role="row"
            style={style}
        >
          {columns}
        </div>
    );
  };

  openEditEmployee = (id: number): void => {
    this.props.history.push("/employee/", {
      id: id
    });
  };

  dateCellRenderer = ({cellData}: any) => {
    if (cellData) {
      return moment(cellData).format('DD.MM.YYYY / HH:mm');
    }
    return "";
  };

  covidTestRegistrationTimestampCellRenderer = ({rowData, cellData}: any) => {
    let appointmentDateTime = "";
    if (rowData.interventionBookingStatus === InterventionBookingStatus.PLANNED) {
      if (rowData.appointmentDate) {
        appointmentDateTime = moment(rowData.appointmentDate).format("DD.MM.YYYY");
      }
      if (rowData.appointmentTime) {
        appointmentDateTime = `${appointmentDateTime} / ${rowData.appointmentTime.substr(0, 5)}`;
      }

      return <Fragment>
        <Icon name={'calendar alternate'} />
        {appointmentDateTime}
      </Fragment>;
    }

    if (cellData) {
      return moment(cellData).format('DD.MM.YYYY / HH:mm');
    }

    return "";
  };

  testTypeCellRenderer = ({rowData}: any) => {
    return this.testDescription(rowData);
  };

  testDescription = (testResult: EmployeeTestResultDto): string => {
    if (!testResult) {
      return "";
    }

    const texts: { [key: string]: string } = {
      "de": testResult.testDescription,
      "en": testResult.testDescriptionEn,
      "fr": testResult.testDescriptionFr,
      "it": testResult.testDescriptionIt
    };

    return texts[this.props.language] || testResult.testDescription;
  };

  testRegistrationCodeCellRenderer = ({rowData, cellData}: any) => {
    if (rowData.interventionBookingStatus === InterventionBookingStatus.PLANNED && rowData.testLocationId) {
      return this.getLocationDescription(rowData.testLocationId);
    }
    if (cellData && cellData.length > 0) {
      return cellData;
    }
    return "";
  };

  getLocationDescription = (locationId?: number): string => {
    const {resourceMap} = this.state;
    let locationDesc = "";
    if (Object.keys(resourceMap).length && locationId) {
      locationDesc = resourceMap[locationId]
          ? resourceMap[locationId].descriptionWithLocation
          : "";
    }

    return locationDesc;
  };

  testResultImageRenderer = (testResultImageId: string) => {

    const {t} = this.props;
    return (
        <EditActionButton
            hoverMessage={t("employeeDashboard.button.testResultImage")}
            trigger={<Icon name={'camera'} style={{verticalAlign: "middle"}} />}
            onConfirm={() => {

              this.setState({
                resultImageLoading: true
              });

              getTestResultImage(testResultImageId, this.state.cancelTokenSource)
              .then(response => {
                this.setState({
                  resultImage: response,
                  resultImageLoading: false
                });
              })
              .catch((e) => {
                this.setState({
                  resultImageLoading: false
                });
                this.handleError(e.response.data);
              });
            }}
        />
    );
  };

  testResultsCellRenderer = ({rowData, cellData, rowIndex}: any) => {
    const {t} = this.props;

    if (rowData.interventionBookingStatus === InterventionBookingStatus.PLANNED) {
      return <>
        <StyledButton icon
                      type="button"
                      onClick={() => this.showTestResultModal(rowIndex)}
        >
          <Popup
              trigger={<Icon color="blue" name="info circle" />}
              content={t('personDashboard.appointment.seeDetails')}
              size="small"
              position="top center"
              style={PopupStyle}
              inverted
          />
        </StyledButton>
        <DeleteActionButton
            hoverMessage={t("personDashboard.appointment.deleteAppointment")}
            popupMessage={t("personDashboard.appointment.confirmDelete")}
            onConfirm={() => this.deleteAppointment(rowData.interventionBookingId)}
        />
      </>;
    }

    let result = cellData;

    if (result) {
      return <>
        <TestResultIcon result={result} />
        {t(`testResult.${result}`)}
        {
            rowData.testResultImageId &&
            this.testResultImageRenderer(rowData.testResultImageId)
        }
      </>;
    }

    return <></>;
  };

  productTypeRenderer = ({cellData}: any) => {
    const {articleGroups} = this.state;
    const {t} = this.props;

    if (cellData && cellData > 0) {
      const foundAG = articleGroups.filter((ag: ArticleGroupDto) => ag.id === cellData);
      if (foundAG && foundAG.length > 0) {
        let productType = foundAG[0].externalCode;

        return t(`enum.InterventionExternalCode.${productType}`);
      }
    }

    return "";
  };

  quantityCellRenderer = ({cellData}: any) => {
    if (cellData) {
      return cellData;
    }
    return (
        <div></div>
    );
  };

  stockOrderStatusCellRenderer = ({cellData}: any) => {
    const {t} = this.props;
    if (cellData) {
      return t(`enum.status.${cellData}`);
    }
  };

  stockOrderActionsCellRenderer = ({rowData}: any) => {
    const {t, currentUser} = this.props;
    const {
      articleGroups
    } = this.state;

    if (_.isEmpty(articleGroups)) {
      return <></>;
    }

    const foundAG = articleGroups.find((ag: ArticleGroupDto) => ag.id === rowData.articleGroupId);

    if (_.isNil(foundAG)) {
      return <></>;
    }

    let testType = foundAG!.externalCode;

    let disableTestKitRegistration = !this.state.personDataComplete;
    const testkitOrderId = rowData.id;

    return <>
      <div>
        <Button className="no-border"
                compact
                basic
                icon="barcode"
                disabled={disableTestKitRegistration}
                content={t("testKitRegistrationManual.form.action.register")}
                title={t("employeeDashboard.button.testKitsRegistrationWebBrowser")}
                onClick={() => this.onRegisterTestkitManually(testType, testkitOrderId)}
        />
      </div>

      <div>
        <RegisterTestWithQrCodeButton
            disabled={false}
            user={currentUser!}
            buttonText=""
            trigger={
              <Button className="no-border"
                      compact
                      basic
                      icon="mobile alternate"
                      disabled={disableTestKitRegistration}
                      content={t("testKitRegistrationManual.form.action.register")}
                      title={t("employeeDashboard.button.testKitsRegistrationSmartphone")}
              />
            }
            onClose={() => {
              this.fetchOrderedTestKits();
              this.fetchTestResults();
            }}
            fetchQrCodeLink={() => {
              const request = this.createRequestForCreateQrCodeCall(
                  InterventionExternalCode[testType], testkitOrderId);
              return createTestkitQrcodeBase64String(request, this.state.cancelTokenSource)
              .then(response => {
                return {
                  url: response.url,
                  b64Image: response.imageB64
                };
              });
            }}
        />
      </div>
    </>;
  };

  renderOrderedTestKitsTable = (): JSX.Element => {
    const {orderedTestKits} = this.state;
    const {t} = this.props;

    return (
        <VirtualizedTable
            rowCount={orderedTestKits.length}
            rowGetter={this.ordersRowGetter}
            rowRender={this.ordersRowRenderer}
            rowHeight={42}
            columns={[
              {
                width: 400,
                flexGrow: 2,
                flexShrink: 1,
                label: (t("stockManagement.orderedProduct.title")),
                dataKey: "articleGroupId",
                cellRenderer: this.productTypeRenderer
              },
              {
                width: 200,
                flexGrow: 0,
                flexShrink: 1,
                label: (t("stockManagement.stockOrder.orderDate")),
                dataKey: "orderDate",
                cellRenderer: this.dateCellRenderer
              },
              {
                width: 200,
                flexGrow: 0,
                flexShrink: 1,
                label: (t("stockManagement.stockOrder.deliverDate")),
                dataKey: "deliverDate",
                cellRenderer: this.dateCellRenderer
              },
              {
                width: 170,
                flexGrow: 0,
                flexShrink: 1,
                label: (t("stockManagement.stockOrder.quantity")),
                dataKey: "quantity",
                cellRenderer: this.quantityCellRenderer
              },
              {
                width: 200,
                flexGrow: 0,
                flexShrink: 1,
                label: (t("stockManagement.stockOrder.status")),
                dataKey: "status",
                cellRenderer: this.stockOrderStatusCellRenderer
              },
              {
                width: 350,
                flexGrow: 1,
                flexShrink: 1,
                label: (t("stockManagement.stockOrder.actions")),
                dataKey: "articleGroupId",
                cellRenderer: this.stockOrderActionsCellRenderer
              }
            ]}
        />
    );
  };


  renderTestResultsTable = (): JSX.Element => {

    const {testResults} = this.state;
    const {t} = this.props;

    return (
        <VirtualizedTable
            rowCount={testResults.length}
            rowGetter={this.testResultsRowGetter}
            rowRenderer={this.testResultsRowRenderer}
            columns={[
              {
                width: 150,
                label: (t("employeeDashboard.tableHeader.registrationDate")),
                dataKey: "covidTestRegistrationTimestamp",
                flexGrow: 1,
                cellRenderer: this.covidTestRegistrationTimestampCellRenderer
              },
              {
                width: 200,
                label: (t("employeeDashboard.tableHeader.test")),
                dataKey: "testDescription",
                flexGrow: 1,
                cellRenderer: this.testTypeCellRenderer
              },
              {
                width: 200,
                label: (t("employeeDashboard.tableHeader.registration")),
                dataKey: "testKitCode",
                flexGrow: 1,
                cellRenderer: this.testRegistrationCodeCellRenderer
              },
              {
                width: 150,
                label: (t("employeeDashboard.tableHeader.resultDate")),
                dataKey: "covidTestResultTimestamp",
                flexGrow: 1,
                cellRenderer: this.dateCellRenderer
              },
              {
                width: 200,
                label: (t("employeeDashboard.tableHeader.finding")),
                dataKey: "covidTestResult",
                flexGrow: 1,
                cellRenderer: this.testResultsCellRenderer
              }
            ]}
        />

    );
  };

  renderTestResultModal = () => {
    const {t} = this.props;
    const {testResults, testResultIx, testResultModalOpen} = this.state;

    const testResult = testResults.find((tr, ix) => ix === testResultIx);

    if (testResult && InterventionBookingStatus.PLANNED === testResult.interventionBookingStatus) {
      return (
          <Modal
              open={testResultModalOpen}
              onClose={this.hideTestResultModal}
              style={{width: "32rem", borderRadius: "unset"}}
              closeOnDimmerClick={false}
          >
            <Modal.Header>{this.testDescription(testResult)}</Modal.Header>
            <Modal.Content>
              <TestInfo testResult={testResult}
                        locationDescription={this.getLocationDescription(testResult.testLocationId)}
                        translationKey="mipoco"
              />
            </Modal.Content>
            <Modal.Actions>
              <Button compact type="button"
                      onClick={this.hideTestResultModal}>{t("button.close")}</Button>
            </Modal.Actions>
          </Modal>
      );
    }

    return null;
  };

}

export default withRouterWorkaround(
    withAuthContext(
        withCompanyDataContext(
            withTranslation(["mipoco"])(DashboardEmployee))));
