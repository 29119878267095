import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import React, {Component} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import LoginView from "route/login/LoginView";
import {Button} from "semantic-ui-react";
import styled from "styled-components";

const ContainerDiv = styled.div`
  
  .info-box {
    max-width: 40rem;
    margin-bottom: 1.5rem;
  }

  .actions-row {
    margin-top: 2rem;
  }
`;

interface Props extends AuthConsumerRenderProps, WithTranslation {
}

class ContractDocumentRejectionView extends Component<Props> {

  render(): React.ReactNode {

    const {t} = this.props;

    return <LoginView titleKey="accountNotActivated.title">
      <ContainerDiv>
        <div className="info-box">{t("accountNotActivated.info")}</div>

        <div className="actions-row">
          <Button
              type="button"
              className="action-button"
              color={"grey"}
              onClick={(evt) => this.props.logout()}
          >
            {t("action.back")}
          </Button>
        </div>
      </ContainerDiv>
    </LoginView>;
  }
}

export default withAuthContext(withTranslation(["login"])(ContractDocumentRejectionView));
