import {InterventionDto} from "ts-types/api.types";

export const interventionDescription = (intervention: InterventionDto | undefined, language: string): string => {

  if (!intervention) {
    return "";
  }

  const texts: { [key: string]: string } = {
    "de": intervention.description,
    "en": intervention.descriptionEn,
    "fr": intervention.descriptionFr,
    "it": intervention.descriptionIt
  };
  return texts[language] || intervention.description;
};

export const interventionInfoText = (intervention: InterventionDto | undefined, language: string): string => {

  if (!intervention) {
    return "";
  }

  const texts: { [key: string]: string } = {
    "de": intervention.infoText,
    "en": intervention.infoTextEn,
    "fr": intervention.infoTextFr,
    "it": intervention.infoTextIt
  };
  return texts[language] || intervention.infoText;
};
