import {BigActionButton} from "component/BigActionButton";
import React, {Component} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import QrcodeActionButton from "route/test-registration/QrcodeActionButton";
import {Button, Icon, Loader} from "semantic-ui-react";
import styled from "styled-components";
import {InterventionExternalCode} from "ts-types/api.enums";
import {TestRegistrationQrCodeRequest, UserDto} from "ts-types/api.types";
import {isoToDisplayDate} from "util/dateUtils";

const QrContentDiv = styled.div`

  padding: 0.2rem;

  .employee-data {
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0 0 0.75rem 0.2rem;
    white-space: nowrap;
  }

  .qrcode-wrapper {
    display: inline-block;
    border: 1px solid #e5e5e5;
    padding: 1rem;
    margin-bottom: 0.5rem;

    .qrcode {
      background-size: contain;
      width: 150px;
      height: 150px;
    }
  }

  .actions-row {
    margin: 0.75rem 0;

    .ui.button {
      border-radius: unset;
    }
  }

  .link-info {
    color: #407A8C;
    margin: 0.75rem 0.1rem 0;
    padding-top: 0.5rem;
    border-top: 1px solid #e5e5e5;
  }
`;

export interface QrCodeLinkData {
  url: string;
  b64Image: string;
}

interface Props extends WithTranslation {

  disabled: boolean;
  user: UserDto;
  buttonText: string;
  trigger?: React.ReactNode,

  onClose: () => void;
  fetchQrCodeLink: () => Promise<QrCodeLinkData>;
}

interface State {
  qrcodeVisible: boolean;
  testRegistrationUrl?: string;
  b64Image?: string;
}

class RegisterTestWithQrCodeButtonComponent extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      qrcodeVisible: false
    };
  }

  render(): React.ReactNode {

    const {disabled, user, buttonText, trigger, fetchQrCodeLink, onClose, t} = this.props;

    const {qrcodeVisible, testRegistrationUrl, b64Image} = this.state;

    const b64background = `url('data:image/png;base64,${b64Image}')`;

    const triggerComponent = trigger
        ? trigger
        : <BigActionButton
            icon="mobile alternate"
            textDomain="mipoco"
            textKey={buttonText}
            disabled={disabled}
        />;

    return <QrcodeActionButton
        disabled={disabled}
        poperDependencies={[qrcodeVisible]}
        renderContent={
          (toggle?: () => void) => <QrContentDiv>
            <div className="employee-data">
              {`${user?.firstName} ${user?.lastName}, ${isoToDisplayDate(user?.birthDate)}`}
            </div>
            {
              qrcodeVisible
                  ?
                  <a target="_blank"
                     rel="noreferrer"
                     href={testRegistrationUrl}
                     title={t("employeeDashboard.goToSnapshot")}>
                    <div className="qrcode-wrapper">
                      <div className="qrcode" style={{
                        backgroundImage: b64background
                      }}>
                        &nbsp;
                      </div>
                    </div>
                  </a>
                  :
                  <div>
                    <Loader active inline />
                  </div>
            }
            <div className="actions-row">
              <Button compact onClick={() => {
                if (toggle) {
                  toggle();
                }

                if (onClose) {
                  onClose();
                }
              }}
              >
                {t("action.back")}
              </Button>
            </div>
            <div className="link-info">
              <Icon name="info circle" />
              {t("employeeDashboard.qrcode.linkInfo")}
            </div>
          </QrContentDiv>
        }
        onConfirm={() => {
        }}
        onOpen={() => {

          this.setState({
            qrcodeVisible: false
          });

          const request: TestRegistrationQrCodeRequest = {
            employeeId: Number(user?.id),
            sourceEmployeeId: -1,
            companyId: -1,
            baseUrl: "",
            externalCode: InterventionExternalCode.PCR_SALIVA_TEST
          };
          fetchQrCodeLink().then(response => {
            this.setState({
              testRegistrationUrl: response.url,
              b64Image: response.b64Image,
              qrcodeVisible: true
            });
          });
        }}
        trigger={triggerComponent}
    />;
  }

}

export default withTranslation(["mipoco"])(RegisterTestWithQrCodeButtonComponent);
