import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import {CompanyDataConsumerRenderProps, withCompanyDataContext} from "component/CompanyDataContext";
import CompanyDataHeader from "component/CompanyDataHeader";
import RemoteSelect from "component/final-form/RemoteSelect";
import Select, {DropdownOption} from "component/final-form/Select";
import MpGrid from "component/MpGrid";
import StyledErrorMessage from "component/StyledErrorMessage";
import {FormApi, FormState} from "final-form";
import moment from "moment";
import React, {Component} from "react";
import Lightbox from "react-datatrans-light-box";
import {Field, Form as FinalForm, FormRenderProps, FormSpy} from 'react-final-form';
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";
import {Button, GridColumn, GridRow, Icon, Message} from "semantic-ui-react";
import {getAllArticleGroupsByCompanyId} from "service/companyServices";
import axios from "service/http";
import {getInterventionPrice} from "service/interventionPriceServices";
import {getStockOrderForEmployee, saveStockOrderForEmployee} from "service/stockerOrderServices";
import styled from "styled-components";
import {debounce} from "ts-debounce";
import {PaymentStatus} from "ts-types/api.enums";
import {ArticleGroupDto, StockOrderDto, UpsertLightStockOrderDto} from "ts-types/api.types";
import {toIsoDateString} from "util/dateUtils";
import {isDevMode} from "util/functionUtils";
import {required} from "util/validatorUtils";
import {withRouterWorkaround} from "util/workaroundUtils";

const TestOrderContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  .orders-table {
    flex: 1 1 auto;
    min-height: 250px;
    margin-bottom: 1rem;

    .row-actions {
      i.icon,
      i.icons {
        color: #768aff;
      }
    }

    .table-loader.ui.inline.loader.active,
    .table-loader.ui.inline.loader.visible {
      display: block;
      top: 5rem;
    }
  }

  .action-message {
    display: inline-block;
    font-size: 1.2rem;
    padding-right: 1rem;
    margin: 1rem;

    &.stock-ordered-success {
      color: darkgreen;
    }

    &.stock-order-processed {
      color: grey;
    }

  }

  .error {
    margin-bottom: 1rem;
  }

  .page-actions {
    display: flex;
    margin-top: 1rem;
  }

  .table-form-container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .buttons {
    display: inline-block;
    margin-top: 2.5rem;
  }

  .final-form {
    display: flex;
    flex-direction: column;
  }

  .quantity-info {
    display: flex;
    flex-direction: column;
    max-width: 300px;

  }

  .message-container {
    align-self: flex-start;
    margin-bottom: 1.5rem;
    font-size: 1.1rem;
  }

  .message {
    padding-left: 0.5rem;
    color: #978606;
  }

`;

interface Props extends RouteComponentProps<any>,
                        AuthConsumerRenderProps,
                        WithTranslation,
                        CompanyDataConsumerRenderProps {

  companyAdmin?: boolean;
}

interface State {
  pageDataLoaded: boolean;
  errorMessages: string[];

  employeeId?: number,

  articleGroups: ArticleGroupDto[];
  articleGroupOptions: DropdownOption[];
  quantityOptions: DropdownOption[];

  loadingPrice: boolean;
  price: number;

  stockOrderId?: number;
  stockOrder?: StockOrderDto;

  paymentTransactionId?: string;
  paymentStockOrderId?: number;
  showDatatransLightbox: boolean;

  showStockOrderedSuccess: boolean;
}

class TestKitOrderContainerPersonViewWithPaymentComponent extends Component<Props, State> {

  private mounted: boolean = false;

  private cancelTokenSource = axios.CancelToken.source();

  private initialValues: Partial<UpsertLightStockOrderDto> = {
    quantity: 1,
    mipocoPaidStockOrder: true
  };

  constructor(props: Props) {
    super(props);

    let employeeId: number | undefined = undefined;
    if (props.companyAdmin) {
      const state: any = this.props.location.state;
      let employeeIdString = state?.employeeId;
      employeeId = employeeIdString ? Number(employeeIdString) : undefined;
    }

    const pathParams: any = props.match.params;
    const stockOrderId = pathParams && pathParams.orderId;

    let quantityOptions: DropdownOption[] = [1].map(quantity => ({
      key: quantity,
      text: `${quantity}`,
      value: quantity
    }));

    if (props.currentUser
        && props.currentUser.container
        && ["adctp", "swica"].includes(props.currentUser.container)) {

      quantityOptions = [1, 5, 10].map(quantity => ({
        key: quantity,
        text: `${quantity}`,
        value: quantity
      }));
    }

    this.state = {
      pageDataLoaded: false,
      errorMessages: [],

      employeeId,

      articleGroups: [],
      articleGroupOptions: [],
      quantityOptions,

      loadingPrice: false,
      price: -1,

      stockOrderId: stockOrderId,

      showDatatransLightbox: false,

      showStockOrderedSuccess: false
    };
  }

  componentDidMount(): void {
    this.mounted = true;
    this.loadPageData();
  }

  loadPageData = () => {

    const {t} = this.props;
    const {stockOrderId} = this.state;

    const methods: Array<Promise<any>> = [
      getAllArticleGroupsByCompanyId(this.cancelTokenSource)
    ];

    if (stockOrderId) {
      methods.push(getStockOrderForEmployee(stockOrderId, undefined, this.cancelTokenSource));
    }

    Promise.all(methods)
    .then(responses => {

      const availableArticleGroups = responses[0].filter((articleGroup: ArticleGroupDto) => articleGroup.logistics);

      let initialArticleGroup = availableArticleGroups ? availableArticleGroups[0] : undefined;

      const defaultArticleGroup: ArticleGroupDto | undefined = responses[0].find(
          (ag: ArticleGroupDto) => ag.externalCode === this.props.companyData.testType && ag.logistics);

      if (defaultArticleGroup) {
        initialArticleGroup = defaultArticleGroup;
      }
      this.initialValues.articleGroupId = initialArticleGroup.id;

      const articleGroupOptions: DropdownOption[] = availableArticleGroups.map((articleGroup: ArticleGroupDto) => ({
        key: articleGroup.id,
        text: t("enum.InterventionExternalCode." + articleGroup.externalCode) as string,
        value: articleGroup.id
      }));

      if (stockOrderId) {
        const stockOrder: StockOrderDto = responses[1];

        this.setState({
          stockOrder
        });

        this.initialValues.articleGroupId = stockOrder.articleGroupId;
        this.initialValues.quantity = stockOrder.quantity;
      }

      this.setState({
        articleGroups: availableArticleGroups,
        articleGroupOptions: articleGroupOptions
      });

    }).finally(() => {
      this.setState({
        pageDataLoaded: true
      });
    });
  };

  handleError = (error: any) => {
    const {t} = this.props;

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            this.setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!this.state.errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          this.setErrorMessage(t(`error.${errorCode}`));
        } else {
          this.setErrorMessage(t('error.general'));
        }
      }
    }
  };

  setErrorMessage = (errorMessage?: string) => {

    const {errorMessages} = this.state;

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);

      this.setState({
        errorMessages: errMsgs
      });
    } else {

      this.setState({
        errorMessages: []
      });
    }
  };

  handleSubmit = async (values: Partial<UpsertLightStockOrderDto>, form: FormApi) => {

    const {employeeId, articleGroups, stockOrderId} = this.state;

    const articleGroup = articleGroups.find(ag => ag.id === values.articleGroupId)!;

    const mipocoPaidStockOrder = !!articleGroup.productId;

    let upsertLightStockOrderDto = {
      quantity: values.quantity,
      articleGroupId: articleGroup.id,
      packageQuantity: articleGroup.packageQuantity,
      mipocoPaidStockOrder
    };

    try {
      const response = await saveStockOrderForEmployee(
          employeeId, upsertLightStockOrderDto, this.cancelTokenSource);

      this.setState({
        showStockOrderedSuccess: true
      });

      const paymentTransactionId = response.paymentTransactionId;
      if (response.paymentStatus === PaymentStatus.INITIALIZED && paymentTransactionId) {
        const me = this;

        this.setState({
          showDatatransLightbox: true,
          paymentStockOrderId: response.id,
          paymentTransactionId: response.paymentTransactionId
        });

      } else {
        this.setState({
          showStockOrderedSuccess: true
        });
        setTimeout(() => {
          this.setState({
            showStockOrderedSuccess: false
          });
          this.goToPreviousPage();
        }, 3500);
      }

    } catch (e) {
      this.handleError(e);
    }
  };

  handleChange = debounce((formState: FormState<any>) => {
    const {values} = formState;
    const articleGroupId = values.articleGroupId;
    this.fetchPrice(articleGroupId, values.quantity);
  }, 50);

  fetchPrice = async (articleGroupId: number | undefined, quantity: number) => {

    if (!articleGroupId) {
      this.setState({
        price: -1
      });
      return;
    }

    const {articleGroups} = this.state;
    const articleGroup = articleGroups.find(ag => ag.id === articleGroupId);
    if (!articleGroup || !articleGroup.productId) {
      this.setState({
        loadingPrice: false,
        price: -1
      });
      return;
    }

    this.setState({
      loadingPrice: true
    });

    const productPrice = await getInterventionPrice(
        articleGroup.productId, toIsoDateString(moment()), this.cancelTokenSource);

    let totalAmountPrivateTariff = 0;
    const privateTariffInvoices = productPrice.invoices.filter(inv => inv.privateTariff);
    if (privateTariffInvoices) {
      totalAmountPrivateTariff = privateTariffInvoices.reduce(
          (total, invoice) => total + invoice.amount, 0);
    }

    this.setState({
      loadingPrice: false,
      price: totalAmountPrivateTariff * quantity
    });

    // const insuranceInvoices = productPrice.invoices.filter(inv => !inv.privateTariff);
    // if (insuranceInvoices) {
    //   const totalAmountInsurance = insuranceInvoices.reduce(
    //       (total, invoice) => total + invoice.amount, 0);
    // }

  };

  goToPreviousPage = () => {
    const {companyAdmin} = this.props;
    const {employeeId} = this.state;

    if (companyAdmin && employeeId) {
      this.props.history.push("/employees/overview/test-kit-orders-list", {employeeId: employeeId});
    } else {
      this.props.history.push("/person/test-kit-orders-list");
    }
  };

  goToOrderDetailsPage = (orderId: number) => {
    this.props.history.push(`/person/test-kit-order-selfservice/${orderId}`);
  };

  render() {
    const {t} = this.props;
    const {pageDataLoaded, articleGroupOptions} = this.state;

    if (!pageDataLoaded) {
      return <></>;
    }

    return (
        <TestOrderContainer>
          <CompanyDataHeader />
          {
            articleGroupOptions && articleGroupOptions.length > 0
                ? this.renderPageContent()
                : <Message>
                  <p>{t("employee.testKitOrder.noArticleGroupsAvailable")}</p>
                </Message>
          }
        </TestOrderContainer>
    );
  }

  renderPageContent = (): JSX.Element => {

    const {t} = this.props;

    const {
      errorMessages,
      stockOrderId,
      paymentStockOrderId,
      paymentTransactionId,
      showDatatransLightbox,
      stockOrder
    } = this.state;

    const paymentExecuted = stockOrder && PaymentStatus.EXECUTED === stockOrder.paymentStatus;

    return (
        <div className="table-form-container">
          <div className="title-h1">{t("employee.testKitOrder.title")}</div>

          {errorMessages.length > 0 &&
            <div className="error">
              <StyledErrorMessage onDismiss={() => this.setErrorMessage()}>
                {errorMessages.map(err => <div key={err}>{err}</div>)}
              </StyledErrorMessage>
            </div>
          }

          <div className="message-container">
            {
                paymentExecuted &&
              <>
                <div className="success">
                  <Icon name="check circle outline" size="big" color="green" />
                  <span className="message">{t("employee.testKitOrderPaymentStatus.executed")}</span>
                </div>

                <div className="buttons">
                  <Button
                    type="button"
                    className="action-button"
                    secondary
                    onClick={this.goToPreviousPage}
                    style={{display: "inline-block"}}
                  >
                    {t("action.back")}
                  </Button>
                </div>
              </>
            }

            {
                stockOrder && stockOrder.paymentStatus === PaymentStatus.CANCELLED &&
              <div className="warning">
                <Icon name="warning sign" size="big" color="yellow" />
                <span className="message">{t("employee.testKitOrderPaymentStatus.cancelled")}</span>
              </div>
            }

            {
                stockOrder && stockOrder.paymentStatus === PaymentStatus.ERROR &&
              <div className="error">
                <Icon name="times circle outline" size="big" color="red" />
                <span className="message">{t("employee.testKitOrderPaymentStatus.error")}</span>
              </div>
            }
          </div>

          {
              showDatatransLightbox &&
            <Lightbox
              transactionId={paymentTransactionId!}
              production={!isDevMode()}
              onLoaded={() => {}}
              onOpened={() => {}}
              onCancelled={() => {
                if (stockOrderId && stockOrderId === paymentStockOrderId) {
                  getStockOrderForEmployee(stockOrderId, undefined, this.cancelTokenSource)
                  .then(response => {
                    this.setState({
                      stockOrder: response,
                      showStockOrderedSuccess: false
                    });
                  });
                } else {
                  this.goToOrderDetailsPage(paymentStockOrderId!);
                }
              }}
              onError={() => {}}
            />
          }


          {
              !paymentExecuted &&
            <>
              <div>
                {t("employee.testKitOrder.infoText")}
              </div>

              <div className="table-form-container">
                <div className="page-actions">
                  <div className="final-form">
                    {this.renderOrderForm()}
                  </div>
                </div>
              </div>
            </>
          }
        </div>
    );
  };

  renderOrderForm(): React.ReactNode {
    return (
        <FinalForm
            onSubmit={(values, form) => this.handleSubmit(values, form)}
            initialValues={this.initialValues}
            render={this.renderOrdersFrom}
            subscription={{pristine: true, submitting: true, values: true}}
        />
    );
  }

  renderOrdersFrom = ({handleSubmit, submitting}: FormRenderProps): React.ReactNode => {

    const {t} = this.props;

    const {
      articleGroupOptions,
      quantityOptions,
      loadingPrice,
      price,
      showStockOrderedSuccess
    } = this.state;

    const disabledDropdown = articleGroupOptions.length === 1;
    const showSuccessfullyOrderedMessage = showStockOrderedSuccess && price <= 0;
    const showOrderIsProcessedMessage = showStockOrderedSuccess && price > 0;

    return (
        <form onSubmit={handleSubmit}>
          <MpGrid>
            <GridRow>
              <GridColumn width={6}>
                <strong>{t("stockManagement.productSelection.dropdown.title")}</strong>
              </GridColumn>
              <GridColumn width={10}>
                <Field
                    name="articleGroupId"
                    component={RemoteSelect}
                    options={articleGroupOptions}
                    validate={required}
                    disabled={disabledDropdown}
                    fluid
                />
              </GridColumn>
            </GridRow>

            <GridRow>
              <GridColumn width={6}>
                <strong>{t("stockManagement.quantity.title")}</strong>
              </GridColumn>
              <GridColumn width={10}>
                <Field
                    name="quantity"
                    component={Select}
                    options={quantityOptions}
                    validate={required}
                    fluid
                />
              </GridColumn>
            </GridRow>
          </MpGrid>

          <div className="buttons">
            <Button
                type="submit"
                className="action-button"
                primary
                disabled={submitting || showStockOrderedSuccess || loadingPrice}
                style={{display: "inline-block", marginRight: "1.5rem"}}
            >
              {t("stockManagement.stockOrder.orderButton")}
              {price !== -1
                  ? ` (${price} CHF)`
                  : ""}
            </Button>

            <Button
                type="button"
                className="action-button"
                secondary
                disabled={submitting || showStockOrderedSuccess}
                onClick={this.goToPreviousPage}
                style={{display: "inline-block"}}
            >
              {t("action.back")}
            </Button>
          </div>

          {
              showSuccessfullyOrderedMessage &&
            <div className="action-message stock-ordered-success">
              {t("stockManagement.stockOrder.orderSendSuccess")}
            </div>
          }

          {
              showOrderIsProcessedMessage &&
            <div className="action-message stock-order-processed">
              {t("stockManagement.stockOrder.processingOrder")}
            </div>
          }

          <FormSpy subscription={{values: true}} onChange={formState => this.handleChange(formState)} />
        </form>
    );
  };

}

let TestKitOrderContainerPersonViewWithPayment = withRouterWorkaround(
    withAuthContext(
        withCompanyDataContext(
            withTranslation(["mipoco"])(
                TestKitOrderContainerPersonViewWithPaymentComponent))));

export default TestKitOrderContainerPersonViewWithPayment;
