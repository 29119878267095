import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import {CompanyDataConsumerRenderProps, withCompanyDataContext} from "component/CompanyDataContext";
import CompanyDataHeader from "component/CompanyDataHeader";
import Select, {DropdownOption, stringArrayToDropdownOptionArray} from "component/final-form/Select";
import LoaderComponent from "component/LoaderComponent";
import MpGrid from "component/MpGrid";
import StyledErrorMessage from "component/StyledErrorMessage";
import VirtualizedTable from "component/VirtualizedTable";
import Welcome from "component/Welcome";
import {FormState} from "final-form";
import createDecorator from "final-form-calculate";
import moment from "moment";
import React, {Component} from "react";
import {Field, Form as FinalForm, FormRenderProps, FormSpy} from "react-final-form";
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {Button, Container, Grid} from "semantic-ui-react";
import {
  getGuarantorCompanyOverviewData,
  getGuarantorCompanyOverviewExportUrl,
  getGuarantorCompanyOverviewInvoicingExportUrl
} from "service/companyServices";
import axios from "service/http";
import styled from "styled-components";
import {debounce} from "ts-debounce";
import {CompanySubType, CompanyType, InterventionExternalCode} from "ts-types/api.enums";
import {GuarantorCompanyOverviewDataDto, GuarantorCompanyOverviewRequest, TestTypeResultDto} from "ts-types/api.types";
import {toIsoDateString} from "util/dateUtils";
import {withRouterWorkaround} from "util/workaroundUtils";

const BoldedSpan = styled.span`
  font-weight: bold;
`;

const ContentWrapperDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  margin-bottom: 2rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
  border-top: 1px solid #0f1a52;

  .data {
    flex-basis: 60%;
    padding-right: 2rem;

    > div.data-row {
      padding: 0.5rem;

      :nth-child(even) {
        background-color: #E5E5E5;
      }
    }

    .data-row {
      display: flex;
      flex-wrap: wrap;

      .column-header {
        flex-basis: 40%;
      }

      .column-list-header {
        flex-basis: 40%;
        padding-left: 5rem;

        .list-item {
          display: list-item;
          list-style-type: disc;
        }
      }

      .column-value {
        flex-basis: 60%;
      }

      .column-value-0 {
        flex-basis: 6.5%;
      }

      .column-value-1 {
        flex-basis: 6.5%;
      }

      .column-value-2 {
        flex-basis: 6.5%;
      }

      .column-value-3 {
        flex-basis: 6.5%;
      }

      .column-value-4 {
        flex-basis: 6.5%;
      }

      .column-value-5 {
        flex-basis: 6.5%;
      }

      .column-value-6 {
        flex-basis: 6.5%;
      }
      
      .column-value-7 {
        flex-basis: 6.5%;
      }
    }

    .break-row {
      padding: 1rem 0 1rem 0;

      .break {
        flex-basis: 100%;
        border-bottom: 1px solid #0f1a52;
      }
    }
  }

  .graph {
    flex-basis: 40%;

    .test-course-graph {
      background-color: #E5E5E5;
      margin-top: 2.4rem;
      padding: 0.5rem;

      .graph-title {
        font-size: 1.2rem;
      }
    }
  }

  .graph-container {
    height: 330px;
  }

`;

const NormalText = styled.span`
  font-weight: 400;
`;

const VirtualizedTableWrapper = styled.div`
  min-height: 470px;
`;

export interface DataRow {
  header: string,
  value?: any,
  value_0?: any,
  value_1?: any,
  value_2?: any,
  value_3?: any,
  value_4?: any,
  value_5?: any,
  value_6?: any,
  value_7?: any,
  bold: boolean,
  partialBold?: string,
  bullet?: boolean,
  break?: boolean
}

interface Props extends RouteComponentProps<any>,
    AuthConsumerRenderProps,
    WithTranslation,
    CompanyDataConsumerRenderProps {

}

interface State {
  guarantorCompanyDataOverview: GuarantorCompanyOverviewDataDto,
  loadingData: boolean,
  companyTypes: Array<DropdownOption>,
  companySubTypes: Array<DropdownOption>,
  calendarWeeks: Array<DropdownOption>,
  errorMessages: Array<string>,
}

const cancelTokenSource = axios.CancelToken.source();

class Dashboard extends Component<Props, State> {

  private mounted: boolean = false;

  constructor(props: Props) {
    super(props);


    const {t} = props;

    const objectAll: DropdownOption = {
      key: -1,
      //@ts-ignore
      text: t("guarantorCompany.home.all"),
      value: -1
    };

    const companyTypeLabels: Array<string> = Object.values(CompanyType);
    const companyTypes = stringArrayToDropdownOptionArray(companyTypeLabels, props.t, "companyType");

    const companySubTypeLabels: Array<string> = Object.values(CompanySubType);
    const companySubTypes = stringArrayToDropdownOptionArray(companySubTypeLabels, props.t, "companySubType");

    const currentWeekYear = moment().isoWeekYear();

    let calendarWeeks = [];

    for (let year = currentWeekYear; year >= 2021; year--) {

      let weekNumber = moment().isoWeek();

      if (year < currentWeekYear) {
        weekNumber = moment().isoWeekYear(year).isoWeeksInISOWeekYear();
      }
      for (let i = weekNumber; i >= 1; i--) {
        const weekStart = moment().isoWeekYear(year).isoWeek(i);
        const weekStartDate = moment(weekStart).isoWeekday(1);
        const weekEndDate = moment(weekStart).isoWeekday(7);

        const weekStartDateDisplay = weekStartDate.format("DD. MMMM");
        const weekEndDateDisplay = weekEndDate.format("DD. MMMM YYYY");

        calendarWeeks.push({
          key: i,
          text: `${t("guarantorCompany.home.week")} ${i}, ${weekStartDateDisplay} - ${weekEndDateDisplay}`,
          value: toIsoDateString(weekStartDate)
        });
      }
    }

    this.state = {
      guarantorCompanyDataOverview: {
        numberOfCompanies: 0,
        totalSchools: 0,
        totalSchoolMembersWithTests: 0,
        totalSchoolPCR: 0,
        totalSchoolPCRPoolTests: 0,
        totalSchoolPCRTestsInPool: 0,
        totalSchoolPCRSingularizedTests: 0,
        totalSchoolPCRSingleTests: 0,
        totalSchoolRapid: 0,
        totalSchoolCovidCertificates: 0,
        totalSchoolTests: 0,
        totalInstitutionsWithTests: 0,
        totalEmployeesWithTests: 0,
        totalPCR: 0,
        totalPCRPoolTests: 0,
        totalPCRTestsInPool: 0,
        totalPCRSingleTests: 0,
        totalPCRSingularizedTests: 0,
        totalRapid: 0,
        totalCovidCertificates: 0,
        totalTests: 0,
        companiesByType: [],
        guaranteedCompanies: [],
        testTypeResults: [],
        countCompanyTestsDtoMap: {},
        testCourseDateFrom: "",
        testCourseDateTo: ""
      },
      loadingData: false,
      companyTypes: [objectAll, ...companyTypes],
      companySubTypes: [objectAll, ...companySubTypes],
      calendarWeeks: [objectAll, ...calendarWeeks],
      errorMessages: []
    };

  }

  componentDidMount(): void {
    this.mounted = true;

    const request: Partial<GuarantorCompanyOverviewRequest> = {
      firstDayOfWeek: toIsoDateString(moment().startOf('isoWeek'))
    };
    this.fetchGuarantorCompanyData(request);
  }

  fetchGuarantorCompanyData = async (request: Partial<GuarantorCompanyOverviewRequest>) => {

    this.setState({
      loadingData: true
    });

    const onFinally = () => {
      this.setState({
        loadingData: false
      });
    };
    getGuarantorCompanyOverviewData(request, cancelTokenSource)
    .then(response => {
      this.setState({
        guarantorCompanyDataOverview: response
      });
    })
    .finally(() => onFinally());
  };

  handleError(error: any) {
    const {t} = this.props;

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            this.setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!this.state.errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          this.setErrorMessage(t(`error.${errorCode}`));
        } else {
          this.setErrorMessage(t('error.general'));
        }
      }
    }
  };

  setErrorMessage = (errorMessage?: string) => {

    const {errorMessages} = this.state;

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);

      this.setState({
        errorMessages: errMsgs
      });
    } else {

      this.setState({
        errorMessages: []
      });
    }
  };

  handleChange = debounce(({values}: FormState<any>): void => {

    if (!this.mounted) {
      return;
    }

    let request: Partial<GuarantorCompanyOverviewRequest> = {
      companyType: values.companyType && values.companyType !== -1 ? values.companyType : undefined,
      companySubType: values.companySubType ? values.companySubType : undefined,
      firstDayOfWeek: values.firstDayOfWeek && values.firstDayOfWeek !== -1 ? values.firstDayOfWeek : undefined
    };

    this.fetchGuarantorCompanyData(request);

  }, 300);

  resetCompanySubType = createDecorator({
    field: 'companyType',
    updates: {
      companySubType: (fieldValue, allValues?: Partial<GuarantorCompanyOverviewRequest>) => {

        return !fieldValue || !allValues || (fieldValue && fieldValue !== CompanyType.SCHOOL)
            ? undefined
            : allValues.companySubType;
      }
    }
  });


  mapCompaniesDataForDisplay = (): Array<React.ReactNode> => {

    const {t} = this.props;
    const {guarantorCompanyDataOverview} = this.state;

    const companies = guarantorCompanyDataOverview.companiesByType.find(c => c.companyType === CompanyType.COMPANY);
    const administrations = guarantorCompanyDataOverview.companiesByType
    .find(c => c.companyType === CompanyType.ADMINISTRATION);

    const retirementHomes = guarantorCompanyDataOverview.companiesByType
    .find(c => c.companyType === CompanyType.RETIREMENT_HOME);

    const primarySchools = guarantorCompanyDataOverview.companiesByType
    .find(c => c.companySubType === CompanySubType.PRIMARY_SCHOOL);
    const secondarySchools = guarantorCompanyDataOverview.companiesByType
    .find(c => c.companySubType === CompanySubType.SECONDARY_SCHOOL);
    const highSchools = guarantorCompanyDataOverview.companiesByType
    .find(c => c.companySubType === CompanySubType.HIGH_SCHOOL);
    const universities = guarantorCompanyDataOverview.companiesByType
    .find(c => c.companySubType === CompanySubType.UNIVERSITY);
    const others = guarantorCompanyDataOverview.companiesByType
    .find(c => c.companySubType === CompanySubType.OTHER);

    const associations = guarantorCompanyDataOverview.companiesByType
    .find(c => c.companyType === CompanyType.ASSOCIATION);

    const gepooltePCRTests = guarantorCompanyDataOverview.testTypeResults
    .find(t => t.testType === InterventionExternalCode.PCR_SALIVA_TEST);

    const singularizedPCRTests = guarantorCompanyDataOverview.testTypeResults
        .find(t => t.testType === "PCR_SINGULARIZED");

    const singlePCRTests = guarantorCompanyDataOverview.testTypeResults
        .find(t => t.testType === "PCR_SINGLE");

    const rapidTests = guarantorCompanyDataOverview.testTypeResults
    .find(t => t.testType === InterventionExternalCode.RAPID_SALIVA_TEST);

    let rapidTestNum = 0;
    if (rapidTests !== undefined) {
      rapidTestNum = rapidTests.tests;
    }

    const pcrMappedData: Array<DataRow> = [
      {
        header: t(""),
        value_0: t("guarantorCompany.table.header.companies"),
        value_1: t("guarantorCompany.table.header.testedPersons"),
        value_2: t("guarantorCompany.table.header.pcrPools"),
        value_3: t("guarantorCompany.table.header.testsInPools"),
        value_4: t("guarantorCompany.table.header.singularizedPCRTests"),
        value_5: t("guarantorCompany.table.header.singlePcrTests"),
        value_6: t("guarantorCompany.table.header.rapidTests"),
        value_7: t("guarantorCompany.table.header.covidCertificate"),
        bold: true
      },

      {
        header: t("guarantorCompany.home.totalNum"),
        value_0: `${guarantorCompanyDataOverview.totalInstitutionsWithTests}`,
        value_1: `${guarantorCompanyDataOverview.totalEmployeesWithTests}`,
        value_2: `${guarantorCompanyDataOverview.totalPCRPoolTests}`,
        value_3: `${guarantorCompanyDataOverview.totalPCRTestsInPool}`,
        value_4: `${guarantorCompanyDataOverview.totalPCRSingularizedTests}`,
        value_5: `${guarantorCompanyDataOverview.totalPCRSingleTests}`,
        value_6: `${guarantorCompanyDataOverview.totalRapid}`,
        value_7: `${guarantorCompanyDataOverview.totalCovidCertificates}`,
        bold: true
      },
      {
        header: `- ${t("companyType.SCHOOL")}:`,
        value_0: `${guarantorCompanyDataOverview.totalSchools}`,
        value_1: `${guarantorCompanyDataOverview.totalSchoolMembersWithTests}`,
        value_2: `${guarantorCompanyDataOverview.totalSchoolPCRPoolTests}`,
        value_3: `${guarantorCompanyDataOverview.totalSchoolPCRTestsInPool}`,
        value_4: `${guarantorCompanyDataOverview.totalSchoolPCRSingularizedTests}`,
        value_5: `${guarantorCompanyDataOverview.totalSchoolPCRSingleTests}`,
        value_6: `${guarantorCompanyDataOverview.totalSchoolRapid}`,
        value_7: `${guarantorCompanyDataOverview.totalSchoolCovidCertificates}`,
        bold: true
      },
      {
        header: t("companySubType.PRIMARY_SCHOOL"),
        value_0: `${primarySchools ? primarySchools.count : 0}`,
        value_1: `${primarySchools ? primarySchools.countEmployees : 0}`,
        value_2: `${primarySchools ? primarySchools.countPCRPoolTests : 0}`,
        value_3: `${primarySchools ? primarySchools.countPCRTestsInPool : 0}`,
        value_4: `${primarySchools ? primarySchools.countPCRSingularizedTests : 0}`,
        value_5: `${primarySchools ? primarySchools.countPCRSingleTests : 0}`,
        value_6: `${primarySchools ? primarySchools.countRapidTests : 0}`,
        value_7: `${primarySchools ? primarySchools.countCovidCertificates : 0}`,
        bullet: true,
        bold: false
      },
      {
        header: t("companySubType.SECONDARY_SCHOOL"),
        value_0: `${secondarySchools ? secondarySchools.count : 0}`,
        value_1: `${secondarySchools ? secondarySchools.countEmployees : 0}`,
        value_2: `${secondarySchools ? secondarySchools.countPCRPoolTests : 0}`,
        value_3: `${secondarySchools ? secondarySchools.countPCRTestsInPool : 0}`,
        value_4: `${secondarySchools ? secondarySchools.countPCRSingularizedTests : 0}`,
        value_5: `${secondarySchools ? secondarySchools.countPCRSingleTests : 0}`,
        value_6: `${secondarySchools ? secondarySchools.countRapidTests : 0}`,
        value_7: `${secondarySchools ? secondarySchools.countCovidCertificates : 0}`,
        bullet: true,
        bold: false
      },
      {
        header: t("companySubType.HIGH_SCHOOL"),
        value_0: `${highSchools ? highSchools.count : 0}`,
        value_1: `${highSchools ? highSchools.countEmployees : 0}`,
        value_2: `${highSchools ? highSchools.countPCRPoolTests : 0}`,
        value_3: `${highSchools ? highSchools.countPCRTestsInPool : 0}`,
        value_4: `${highSchools ? highSchools.countPCRSingularizedTests : 0}`,
        value_5: `${highSchools ? highSchools.countPCRSingleTests : 0}`,
        value_6: `${highSchools ? highSchools.countRapidTests : 0}`,
        value_7: `${highSchools ? highSchools.countCovidCertificates : 0}`,
        bullet: true,
        bold: false
      },
      {
        header: t("companySubType.UNIVERSITY"),
        value_0: `${universities ? universities.count : 0}`,
        value_1: `${universities ? universities.countEmployees : 0}`,
        value_2: `${universities ? universities.countPCRPoolTests : 0}`,
        value_3: `${universities ? universities.countPCRTestsInPool : 0}`,
        value_4: `${universities ? universities.countPCRSingularizedTests : 0}`,
        value_5: `${universities ? universities.countPCRSingleTests : 0}`,
        value_6: `${universities ? universities.countRapidTests : 0}`,
        value_7: `${universities ? universities.countCovidCertificates : 0}`,
        bullet: true,
        bold: false
      },
      {
        header: t("companySubType.OTHER"),
        value_0: `${others ? others.count : 0}`,
        value_1: `${others ? others.countEmployees : 0}`,
        value_2: `${others ? others.countPCRPoolTests : 0}`,
        value_3: `${others ? others.countPCRTestsInPool : 0}`,
        value_4: `${others ? others.countPCRSingularizedTests : 0}`,
        value_5: `${others ? others.countPCRSingleTests : 0}`,
        value_6: `${others ? others.countRapidTests : 0}`,
        value_7: `${others ? others.countCovidCertificates : 0}`,
        bullet: true,
        bold: false
      },
      {
        header: `- ${t("companyType.COMPANY")}:`,
        value_0: `${companies ? companies.count : 0}`,
        value_1: `${companies ? companies.countEmployees : 0}`,
        value_2: `${companies ? companies.countPCRPoolTests : 0}`,
        value_3: `${companies ? companies.countPCRTestsInPool : 0}`,
        value_4: `${companies ? companies.countPCRSingularizedTests : 0}`,
        value_5: `${companies ? companies.countPCRSingleTests : 0}`,
        value_6: `${companies ? companies.countRapidTests : 0}`,
        value_7: `${companies ? companies.countCovidCertificates : 0}`,
        bold: true
      },
      {
        header: `- ${t("companyType.ADMINISTRATION")}:`,
        value_0: `${administrations ? administrations.count : 0}`,
        value_1: `${administrations ? administrations.countEmployees : 0}`,
        value_2: `${administrations ? administrations.countPCRPoolTests : 0}`,
        value_3: `${administrations ? administrations.countPCRTestsInPool : 0}`,
        value_4: `${administrations ? administrations.countPCRSingularizedTests : 0}`,
        value_5: `${administrations ? administrations.countPCRSingleTests : 0}`,
        value_6: `${administrations ? administrations.countRapidTests : 0}`,
        value_7: `${administrations ? administrations.countCovidCertificates : 0}`,
        bold: true
      },
      {
        header: `- ${t("companyType.RETIREMENT_HOME")}:`,
        value_0: `${retirementHomes ? retirementHomes.count : 0}`,
        value_1: `${retirementHomes ? retirementHomes.countEmployees : 0}`,
        value_2: `${retirementHomes ? retirementHomes.countPCRPoolTests : 0}`,
        value_3: `${retirementHomes ? retirementHomes.countPCRTestsInPool : 0}`,
        value_4: `${retirementHomes ? retirementHomes.countPCRSingularizedTests : 0}`,
        value_5: `${retirementHomes ? retirementHomes.countPCRSingleTests : 0}`,
        value_6: `${retirementHomes ? retirementHomes.countRapidTests : 0}`,
        value_7: `${retirementHomes ? retirementHomes.countCovidCertificates : 0}`,
        bold: true
      },
      {
        header: `- ${t("companyType.ASSOCIATION")}:`,
        value_0: `${associations ? associations.count : 0}`,
        value_1: `${associations ? associations.countEmployees : 0}`,
        value_2: `${associations ? associations.countPCRPoolTests : 0}`,
        value_3: `${associations ? associations.countPCRTestsInPool : 0}`,
        value_4: `${associations ? associations.countPCRSingularizedTests : 0}`,
        value_5: `${associations ? associations.countPCRSingleTests : 0}`,
        value_6: `${associations ? associations.countRapidTests : 0}`,
        value_7: `${associations ? associations.countCovidCertificates : 0}`,
        bold: true,
        break: true,
      },
      {
        header: t(""),
        value_0: t("guarantorCompany.table.header.testsInPools"),
        value_1: t("guarantorCompany.table.header.singularizedPCRTests"),
        value_2: t("guarantorCompany.table.header.singlePcrTests"),
        bold: true
      },
      {
        header: t("guarantorCompany.home.totalNum"),
        value_0: gepooltePCRTests ? gepooltePCRTests.tests : 0,
        value_1: singularizedPCRTests ? singularizedPCRTests.tests : 0,
        value_2: singlePCRTests ? singlePCRTests.tests : 0,
        bold: true
      },
      {
        header: `- ${t("guarantorCompany.home.testsWithResult")}:`,
        value_0: gepooltePCRTests ? gepooltePCRTests.testsWithResult : 0,
        value_1: singularizedPCRTests ? singularizedPCRTests.testsWithResult : 0,
        value_2: singlePCRTests ? singlePCRTests.testsWithResult : 0,
        bold: false
      },
      {
        header: t("guarantorCompany.home.positive"),
        value_0: (
            <React.Fragment>
              <span>
                {gepooltePCRTests ? gepooltePCRTests.testsWithResultPositive : 0}
              </span>
              &nbsp;
              <span>
                {t("dashboard.content.positivePercentage", {
                  positivityRate:
                      !gepooltePCRTests || (gepooltePCRTests.testsWithResult === 0 && gepooltePCRTests.testsWithResultPositive === 0)
                          ? Number(0).toFixed(2)
                          : ((gepooltePCRTests.testsWithResultPositive / gepooltePCRTests.testsWithResult) * 100)
                          .toFixed(2)
                })
                }
              </span>
            </React.Fragment>
        ),
        value_1: (
            <React.Fragment>
              <span>
                {singularizedPCRTests ? singularizedPCRTests.testsWithResultPositive : 0}
              </span>
              &nbsp;
              <span>
                {t("dashboard.content.positivePercentage", {
                  positivityRate:
                      !singularizedPCRTests || (singularizedPCRTests.testsWithResult === 0 && singularizedPCRTests.testsWithResultPositive === 0)
                          ? Number(0).toFixed(2)
                          : ((singularizedPCRTests.testsWithResultPositive / singularizedPCRTests.testsWithResult) * 100)
                          .toFixed(2)
                })
                }
              </span>
            </React.Fragment>
        ),
        value_2: (
            <React.Fragment>
              <span>
                {singlePCRTests ? singlePCRTests.testsWithResultPositive : 0}
              </span>
              &nbsp;
              <span>
                {t("dashboard.content.positivePercentage", {
                  positivityRate:
                      !singlePCRTests || (singlePCRTests.testsWithResult === 0 && singlePCRTests.testsWithResultPositive === 0)
                          ? Number(0).toFixed(2)
                          : ((singlePCRTests.testsWithResultPositive / singlePCRTests.testsWithResult) * 100)
                          .toFixed(2)
                })
                }
              </span>
            </React.Fragment>
        ),
        bullet: true,
        bold: false
      },
      {
        header: t("guarantorCompany.home.negative"),
        value_0: gepooltePCRTests ? gepooltePCRTests.testsWithResultNegative : 0,
        value_1: singularizedPCRTests ? singularizedPCRTests.testsWithResultNegative : 0,
        value_2: singlePCRTests ? singlePCRTests.testsWithResultNegative : 0,
        bullet: true,
        bold: false
      },
      {
        header: t("guarantorCompany.home.unclear"),
        value_0: gepooltePCRTests ? gepooltePCRTests.testsWithResultUncertain : 0,
        value_1: singularizedPCRTests ? singularizedPCRTests.testsWithResultUncertain : 0,
        value_2: singlePCRTests ? singlePCRTests.testsWithResultUncertain : 0,
        bullet: true,
        bold: false
      },
      {
        header: t("guarantorCompany.home.poolPositive"),
        value_0: gepooltePCRTests ? gepooltePCRTests.testsWithResultPoolPositive : 0,
        value_1: singularizedPCRTests ? singularizedPCRTests.testsWithResultPoolPositive : 0,
        value_2: singlePCRTests ? singlePCRTests.testsWithResultPoolPositive : 0,
        bullet: true,
        bold: false
      },
      {
        header: `- ${t("guarantorCompany.home.testsInTransit")}:`,
        value_0: gepooltePCRTests ? gepooltePCRTests.testsInTransit : 0,
        value_1: singularizedPCRTests ? singularizedPCRTests.testsInTransit : 0,
        value_2: singlePCRTests ? singlePCRTests.testsInTransit : 0,
        bold: false
      },
      {
        header: `- ${t("guarantorCompany.home.testsRegistered")}:`,
        value_0: gepooltePCRTests ? gepooltePCRTests.testsRegistered : 0,
        value_1: singularizedPCRTests ? singularizedPCRTests.testsRegistered : 0,
        value_2: singlePCRTests ? singlePCRTests.testsRegistered : 0,
        bold: false
      }
    ];

    const rapidMappedData: Array<DataRow> = [{
      header: t("guarantorCompany.table.header.rapidTests"),
      value: (
          <React.Fragment>
            <span style={{fontWeight: "bold"}}>
              {t('dashboard.content.testResultsContent',
                  {
                    testsWithResult: rapidTests ? rapidTests.tests : 0,
                    testsWithResultPositive: rapidTests ? rapidTests.testsWithResultPositive : 0
                  })
              }
            </span>
            &nbsp;
            <span style={{fontWeight: "bold"}}>
              {t("dashboard.content.positivityRate", {
                positivityRate:
                    !rapidTests || (rapidTests.tests === 0 && rapidTests.testsWithResultPositive === 0)
                        ? Number(0).toFixed(2)
                        : ((rapidTests.testsWithResultPositive / rapidTests.tests) * 100)
                        .toFixed(2)
              })
              }
            </span>
          </React.Fragment>
      ),
      bold: true
    },
      {
        header: t("guarantorCompany.home.positive"),
        value: rapidTests ? rapidTests.testsWithResultPositive : 0,
        bullet: true,
        bold: false
      },
      {
        header: t("guarantorCompany.home.negative"),
        value: rapidTests
            ? rapidTests.testsWithResultNegative
            : 0,
        bullet: true,
        bold: false
      },
      {
        header: t("guarantorCompany.home.unclear"),
        value: rapidTests ? rapidTests.testsWithResultUncertain + rapidTests.testsWithResultPoolPositive : 0,
        bullet: true,
        bold: false
      }
    ];

    let mappedData: Array<DataRow> = [];

    if (rapidTestNum > 0) {
      mappedData = pcrMappedData.concat(rapidMappedData);
    } else {
      mappedData = pcrMappedData;
    }

    return mappedData.map((data: DataRow, index: number) => {

          return (
              <React.Fragment key={index}>
                <div className="data-row">
                  {data.bullet
                      ? <div className="column-list-header">
                        <div className="list-item">
                          {data.header}
                        </div>
                      </div>
                      : <div className="column-header" style={{fontWeight: data.bold ? "bold" : "normal"}}>
                        {data.header}
                      </div>
                  }
                  {data.value !== undefined &&
                    <div
                      className="column-value"
                      style={{fontWeight: data.bold ? "bold" : "normal"}}
                    >
                      {data.value}&nbsp;
                      <NormalText>
                        {data.partialBold !== undefined && data.partialBold}
                      </NormalText>
                    </div>
                  }
                  {data.value_0 !== undefined &&
                    <div
                      className="column-value-0"
                      style={{fontWeight: data.bold ? "bold" : "normal"}}
                    >
                      {data.value_0}
                    </div>
                  }
                  {data.value_1 !== undefined &&
                    <div
                      className="column-value-1"
                      style={{fontWeight: data.bold ? "bold" : "normal"}}
                    >
                      {data.value_1}
                    </div>
                  }
                  {data.value_2 !== undefined &&
                    <div
                      className="column-value-2"
                      style={{fontWeight: data.bold ? "bold" : "normal"}}
                    >
                      {data.value_2}
                    </div>
                  }
                  {data.value_3 !== undefined &&
                    <div
                      className="column-value-3"
                      style={{fontWeight: data.bold ? "bold" : "normal"}}
                    >
                      {data.value_3}
                    </div>
                  }
                  {data.value_4 !== undefined &&
                    <div
                      className="column-value-4"
                      style={{fontWeight: data.bold ? "bold" : "normal"}}
                    >
                      {data.value_4}
                    </div>
                  }
                  {data.value_5 !== undefined &&
                    <div
                      className="column-value-5"
                      style={{fontWeight: data.bold ? "bold" : "normal"}}
                    >
                      {data.value_5}
                    </div>
                  }
                  {data.value_6 !== undefined && rapidTestNum > 0 &&
                    <div
                      className="column-value-6"
                      style={{fontWeight: data.bold ? "bold" : "normal"}}
                    >
                      {data.value_6}
                    </div>
                  }
                  {data.value_7 !== undefined &&
                  <div
                    className="column-value-7"
                    style={{fontWeight: data.bold ? "bold" : "normal"}}
                  >
                    {data.value_7}
                  </div>
                  }
                </div>
                {data.break &&
                  <div className="break-row">
                    <div className="break" />
                  </div>
                }
              </React.Fragment>
          );
        }
    );
  };

  renderSearchCompanyDataForm = (): JSX.Element => {
    return (
        <FinalForm
            onSubmit={() => {
            }}
            decorators={[this.resetCompanySubType]}
            initialValues={{
              companyType: -1,
              companySubType: -1,
              firstDayOfWeek: -1
            }}
            subscription={{pristine: true, values: true}}
            render={this.renderSearchFormContent}
        />
    );
  };

  exportGuarantorCompanyData = (values: any, language: string) => {

    this.setState({
      loadingData: true
    });

    setTimeout(() => {
      try {
        const request: Partial<GuarantorCompanyOverviewRequest> = {
          companyType: values.companyType && values.companyType !== -1 ? values.companyType : undefined,
          companySubType: values.companySubType ? values.companySubType : undefined,
          firstDayOfWeek: values.firstDayOfWeek && values.firstDayOfWeek !== -1 ? values.firstDayOfWeek : undefined,
          language: language ? language : "de"
        };
        const exportUrl = getGuarantorCompanyOverviewExportUrl(request);

        const link = document.createElement('a');
        link.href = window.location.origin + exportUrl;

        const defaultFileName = "temp_response.xlsx";

        link.setAttribute('download', defaultFileName);
        document.body.appendChild(link);
        link.click();

      } catch (e) {
        this.handleError(e.response.data);
        return;
      } finally {
        this.setState({
          loadingData: false
        });
      }
    }, 500);
  };

  exportGuarantorCompanyInvoicing = (values: any, language: string) => {

    this.setState({
      loadingData: true
    });

    setTimeout(() => {
      try {
        const request: Partial<GuarantorCompanyOverviewRequest> = {
          companyType: values.companyType && values.companyType !== -1 ? values.companyType : undefined,
          companySubType: values.companySubType ? values.companySubType : undefined,
          firstDayOfWeek: values.firstDayOfWeek && values.firstDayOfWeek !== -1 ? values.firstDayOfWeek : undefined,
          language: language ? language : "de"
        };

        const exportUrl = getGuarantorCompanyOverviewInvoicingExportUrl(request);

        const link = document.createElement('a');
        link.href = window.location.origin + exportUrl;

        const defaultFileName = "temp_response.xlsx";

        link.setAttribute('download', defaultFileName);
        document.body.appendChild(link);
        link.click();

      } catch (e) {
        this.handleError(e.response.data);
        return;
      } finally {
        this.setState({
          loadingData: false
        });
      }
    }, 500);
  };

  renderSearchFormContent = ({form, values}: FormRenderProps): React.ReactNode => {

    const {t, language} = this.props;
    const {companyTypes, companySubTypes, calendarWeeks, loadingData} = this.state;

    const disableCompanySubType = !(values.companyType && values.companyType === CompanyType.SCHOOL);

    return <div className="form-container">
      <MpGrid>
        <Grid.Row>
          <Grid.Column width={2} verticalAlign="middle">
            {t("guarantorCompany.form.companyType")}:
          </Grid.Column>
          <Grid.Column width={3}>
            <Field
                fluid
                name="companyType"
                component={Select}
                options={companyTypes}
            />
          </Grid.Column>
          <Grid.Column width={2} verticalAlign="middle">
            {t("guarantorCompany.form.companySubType")}:
          </Grid.Column>
          <Grid.Column width={3}>
            <Field
                fluid
                name="companySubType"
                component={Select}
                options={companySubTypes}
                disabled={disableCompanySubType}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={2} verticalAlign="middle">
            {t("guarantorCompany.form.calendarWeeks")}:
          </Grid.Column>
          <Grid.Column width={3}>
            <Field
                fluid
                name="firstDayOfWeek"
                component={Select}
                options={calendarWeeks}
            />
          </Grid.Column>
          <Grid.Column width={2}>
          </Grid.Column>
          <Grid.Column width={3}>
            <Button
                type="button"
                className="action-button"
                primary
                disabled={loadingData}
                onClick={() => this.exportGuarantorCompanyData(values, language)}

            >
              {t("guarantorCompany.form.export")}
            </Button>
            <Button
                type="button"
                className="action-button"
                primary
                disabled={loadingData}
                onClick={() => this.exportGuarantorCompanyInvoicing(values, language)}

            >
              {t("guarantorCompany.form.invoicing.export")}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </MpGrid>
      <FormSpy subscription={{values: true}} onChange={formState => this.handleChange(formState)} />
    </div>;
  };

  render() {

    const {companyData, location, t} = this.props;
    const {loadingData, errorMessages} = this.state;

    const locationState: any = location.state;
    const isWelcome = locationState && locationState.welcome;

    return (
        <Container fluid>
          <Welcome isWelcome={isWelcome} companyName={companyData.name} />

          <CompanyDataHeader />

          <div className="title-h1">{t("dashboard.title.overallOveriew")}</div>

          {errorMessages.length > 0 &&
            <div className="error">
              <StyledErrorMessage onDismiss={() => this.setErrorMessage()}>
                {errorMessages.map(err => <div key={err}>{err}</div>)}
              </StyledErrorMessage>
            </div>
          }

          {this.renderSearchCompanyDataForm()}

          {loadingData
              ? <LoaderComponent message={t("guarantorCompany.home.loading")} />
              : <React.Fragment>
                <ContentWrapperDiv>
                  {this.renderDataContent()}
                  {this.renderGraphContent()}
                </ContentWrapperDiv>
                <VirtualizedTableWrapper>
                  {this.renderGuaranteedCompaniesTable()}
                </VirtualizedTableWrapper>
              </React.Fragment>
          }
        </Container>
    );
  }

  renderDataContent = (): React.ReactNode => {
    return (
        <div className="data">
          {this.mapCompaniesDataForDisplay()}
        </div>
    );
  };

  renderGraphContent = (): React.ReactNode => {

    const {t} = this.props;
    const {guarantorCompanyDataOverview} = this.state;

    const testCoursePcr = guarantorCompanyDataOverview.testTypeResults
    .find(t => t.testType === InterventionExternalCode.PCR_SALIVA_TEST);

    const testCourseRapid = guarantorCompanyDataOverview.testTypeResults
    .find(t => t.testType === InterventionExternalCode.RAPID_SALIVA_TEST);

    const testCourseSingularized = guarantorCompanyDataOverview.testTypeResults
    .find(t => t.testType === "PCR_SINGULARIZED");

    const graph = (testCourse: TestTypeResultDto | undefined, messageKey: string) => (
        <>
          {testCourse && testCourse.weeklyResults && testCourse.weeklyResults.length > 0 &&
            <div className="test-course-graph">
          <span className="graph-title">
              <BoldedSpan>{messageKey}</BoldedSpan>&nbsp;
            {
              t("dashboard.graph.testCourseDateRange",
                  {
                    dateFrom: moment(guarantorCompanyDataOverview.testCourseDateFrom, "YYYY-MM-DD").format("DD. MMMM"),
                    dateTo: moment(guarantorCompanyDataOverview.testCourseDateTo, "YYYY-MM-DD").day(7).format("DD. MMMM YYYY")
                  })
            }
          </span>
              <div className="graph-container">
                <ResponsiveContainer width="99%">
                  <BarChart data={testCourse.weeklyResults}
                            margin={{
                              top: 20,
                              right: 30,
                              left: 0,
                              bottom: 0
                            }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="weekToDisplay" />
                    <YAxis label={{value: t('dashboard.graph.axis.y.label'), position: "top", dx: 70, dy: -10}} />
                    <Tooltip />
                    <Bar
                      name={t('dashboard.graph.bar.testsWithResult.label')}
                      dataKey="testsWithResult"
                      stackId="tests"
                      fill="#7687FF"
                    />
                    {testCourse.testType === InterventionExternalCode.PCR_SALIVA_TEST
                        &&
                      <Bar
                        name={t('dashboard.graph.bar.testsWithoutResult.label')}
                        dataKey="testsWithoutResult"
                        stackId="tests"
                        fill="#aeb6f2"
                      />
                    }
                    <Bar
                      name={t('dashboard.graph.bar.testsNegative.label')}
                      dataKey="testsNegative"
                      stackId="result"
                      fill="#21BA45"
                    />
                    < Bar
                      name={t('dashboard.graph.bar.testsUncertain.label')}
                      dataKey="testsUncertain"
                      stackId="result"
                      fill="#FDECA6"
                    />
                    <Bar
                      name={t('dashboard.graph.bar.testsPositive.label')}
                      dataKey="testsPositive"
                      stackId="result"
                      fill="#DB2828"
                    />
                    {testCourse.testType === InterventionExternalCode.PCR_SALIVA_TEST &&
                      <Bar
                        name={t('dashboard.graph.bar.poolPositiveTests.label')}
                        dataKey="testsPoolPositive"
                        stackId="result"
                        fill="#FFB224"
                      />
                    }
                    <Legend align="right" verticalAlign="top" height={60} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          }
        </>
    );

    const rapidOrSingularizeTestCourse = testCourseRapid !== undefined && testCourseRapid.tests > 0
        ? graph(testCourseRapid, t("guarantorCompany.graph.testCourseRapid"))
        : graph(testCourseSingularized, t("guarantorCompany.graph.testCourseSingularized"));

    return (
        <div className="graph">
          {graph(testCoursePcr, t("guarantorCompany.graph.testCoursePcr"))}
          {rapidOrSingularizeTestCourse}
        </div>
    );
  };

  renderGuaranteedCompaniesTable = (): JSX.Element => {

    const {t} = this.props;
    const {guarantorCompanyDataOverview} = this.state;
    const guaranteedCompanies = guarantorCompanyDataOverview.guaranteedCompanies;

    return (
        <VirtualizedTable
            rowCount={guaranteedCompanies.length}
            rowGetter={this.guaranteedCompaniesRowGetter}
            rowRenderer={this.guaranteedCompaniesRowRenderer}
            columns={[
              {
                width: 100,
                label: "#",
                dataKey: "index"
              },
              {
                width: 300,
                label: (t("guarantorCompany.guaranteedCompanies.table.description")),
                dataKey: "description"
              },
              {
                width: 500,
                label: (t("guarantorCompany.guaranteedCompanies.table.administrator")),
                dataKey: "adminEmails"
              },
              {
                width: 200,
                label: (t("guarantorCompany.guaranteedCompanies.table.type")),
                dataKey: "companyType",
                cellRenderer: this.companyTypeCellRenderer
              },
              {
                width: 200,
                label: (t("guarantorCompany.guaranteedCompanies.table.subType")),
                dataKey: "companySubType",
                cellRenderer: this.companySubTypeCellRenderer
              },
              {
                width: 200,
                label: (t("guarantorCompany.guaranteedCompanies.table.city")),
                dataKey: "city"
              },
              {
                width: 200,
                label: (t("guarantorCompany.guaranteedCompanies.table.street")),
                dataKey: "street"
              },
              {
                width: 200,
                label: (t("guarantorCompany.guaranteedCompanies.table.numberOfParticipants")),
                dataKey: "numberOfEmployees"
              },
              {
                width: 200,
                label: (t("guarantorCompany.table.header.testsInPools")),
                dataKey: "id",
                cellRenderer: this.companyTestsInPoolCellRendered
              },
              {
                width: 200,
                label: (t("guarantorCompany.table.header.singularizedPCRTests")),
                dataKey: "id",
                cellRenderer: this.companySinglarizedPcrTestsCellRendered
              },
              {
                width: 200,
                label: (t("guarantorCompany.table.header.singlePcrTests")),
                dataKey: "id",
                cellRenderer: this.companySinglePcrTestsCellRendered
              },
              {
                width: 200,
                label: (t("guarantorCompany.table.header.rapidTests")),
                dataKey: "id",
                cellRenderer: this.companyRapidTestsCellRendered
              },
              {
                width: 200,
                label: (t("guarantorCompany.table.header.covidCertificate")),
                dataKey: "id",
                cellRenderer: this.companyCovidCertificatesCellRendered
              }
            ]}
        />
    );
  };

  companyTypeCellRenderer = ({cellData}: any) => {
    const {t} = this.props;
    if (cellData && cellData.length > 0) {
      return t(`companyType.${cellData}`);
    }
    return "";
  };

  companySubTypeCellRenderer = ({cellData}: any) => {
    const {t} = this.props;
    if (cellData && cellData.length > 0) {
      return t(`companySubType.${cellData}`);
    }
    return "";
  };

  companyTestsInPoolCellRendered = ({cellData}: any) => {

    const {guarantorCompanyDataOverview} = this.state;
    const countCompanyTestsDtoMap = guarantorCompanyDataOverview.countCompanyTestsDtoMap;

    if (countCompanyTestsDtoMap[cellData] && countCompanyTestsDtoMap[cellData].countPCRTestsInPool) {
      return countCompanyTestsDtoMap[cellData].countPCRTestsInPool;
    } else {
      return 0;
    }
  };

  companySinglarizedPcrTestsCellRendered = ({cellData}: any) => {
    const {guarantorCompanyDataOverview} = this.state;
    const countCompanyTestsDtoMap = guarantorCompanyDataOverview.countCompanyTestsDtoMap;

    if (countCompanyTestsDtoMap[cellData] && countCompanyTestsDtoMap[cellData].countPCRSingularizedTests) {
      return countCompanyTestsDtoMap[cellData].countPCRSingularizedTests;
    } else {
      return 0;
    }
  };

  companySinglePcrTestsCellRendered = ({cellData}: any) => {
    const {guarantorCompanyDataOverview} = this.state;
    const countCompanyTestsDtoMap = guarantorCompanyDataOverview.countCompanyTestsDtoMap;

    if (countCompanyTestsDtoMap[cellData] && countCompanyTestsDtoMap[cellData].countPCRSingleTests) {
      return countCompanyTestsDtoMap[cellData].countPCRSingleTests;
    } else {
      return 0;
    }
  };

  companyRapidTestsCellRendered = ({cellData}: any) => {
    const {guarantorCompanyDataOverview} = this.state;
    const countCompanyTestsDtoMap = guarantorCompanyDataOverview.countCompanyTestsDtoMap;

    if (countCompanyTestsDtoMap[cellData] && countCompanyTestsDtoMap[cellData].countRapidTests) {
      return countCompanyTestsDtoMap[cellData].countRapidTests;
    } else {
      return 0;
    }
  };

  companyCovidCertificatesCellRendered = ({cellData}: any) => {
    const {guarantorCompanyDataOverview} = this.state;
    const countCompanyTestsDtoMap = guarantorCompanyDataOverview.countCompanyTestsDtoMap;

    if (countCompanyTestsDtoMap[cellData] && countCompanyTestsDtoMap[cellData].countCovidCertificates) {
      return countCompanyTestsDtoMap[cellData].countCovidCertificates;
    } else {
      return 0;
    }
  };

  guaranteedCompaniesRowGetter = ({index}: any) => {
    const {guarantorCompanyDataOverview} = this.state;
    const guaranteedCompanies = guarantorCompanyDataOverview.guaranteedCompanies;

    Object.assign(guaranteedCompanies[index], {index: index + 1});

    return guaranteedCompanies[index];
  };

  guaranteedCompaniesRowRenderer = ({className, columns, index, key, style}: any) => {
    const a11yProps = {'aria-rowindex': index + 1};

    return (
        <div
            {...a11yProps}
            className={className}
            key={key}
            role="row"
            style={style}
        >
          {columns}
        </div>
    );
  };

}


let DashboardWrapper = withRouterWorkaround(
    withAuthContext(
        withCompanyDataContext(
            withTranslation(["mipoco"])(
                Dashboard))));

export default DashboardWrapper;