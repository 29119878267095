import {CancelTokenSource} from "axios";
import axios from "service/http";
import {InterventionInvoicePriceDto} from "ts-types/api.types";

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const apiUrl = `${baseUrl}`;


export const getInterventionPrice = (
    productId: string, date: string, source: CancelTokenSource): Promise<InterventionInvoicePriceDto> => {

  const formData = new FormData();
  formData.append("productId", productId);
  formData.append("date", date);

  return axios
  .post(`${apiUrl}/intervention-price`, formData, {cancelToken: source.token})
  .then((response) => response.data);
};
