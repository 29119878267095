import {CancelTokenSource} from "axios";
import axios from "service/http";
import {ContractDocumentsDto, UpsertContractConfirmationsDto} from "ts-types/api.types";

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const apiUrl = `${baseUrl}/contract`;

export const getContactDocuments = (
    cancelSourceToken: CancelTokenSource): Promise<ContractDocumentsDto> => {

  return axios
  .get(`${apiUrl}/data`, {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};

export const saveCompanyContractConfirmation = (
    request: Partial<UpsertContractConfirmationsDto>,
    cancelSourceToken: CancelTokenSource): Promise<string> => {

  return axios
  .put(`${apiUrl}/company`, request, {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};

export const saveEmployeeContractConfirmation = (
    request: Partial<UpsertContractConfirmationsDto>,
    cancelSourceToken: CancelTokenSource): Promise<string> => {

  return axios
  .put(`${apiUrl}/employee`, request, {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};
