import {CancelTokenSource} from "axios";
import axios from "service/http";
import {StockOrderDto, UpsertLightStockOrderDto} from "ts-types/api.types";

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const apiUrl = `${baseUrl}/stock-orders`;

export const saveStockOrder = (
    upsertLightStockOrderDto: Partial<UpsertLightStockOrderDto>,
    cancelSourceToken: CancelTokenSource): Promise<StockOrderDto> => {

  return axios
  .post(apiUrl, upsertLightStockOrderDto, {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};

export const getStockOrderForEmployee = (
    orderId: number,
    employeeId: number | undefined,
    cancelSourceToken: CancelTokenSource): Promise<StockOrderDto> => {

  const url = `${apiUrl}/order/${orderId}`;

  const params: {[key: string] : string} = {};
  if (employeeId) {
    params.employeeId = "" + employeeId;
  }

  return axios
  .get(url, {params, cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};

export const saveStockOrderForEmployee = (
    employeeId: number | undefined,
    upsertLightStockOrderDto: Partial<UpsertLightStockOrderDto>,
    cancelSourceToken: CancelTokenSource): Promise<StockOrderDto> => {

  const url = employeeId ? `${apiUrl}/employee/${employeeId}` : `${apiUrl}/employee`;

  return axios
  .post(url, upsertLightStockOrderDto, {cancelToken: cancelSourceToken.token})
  .then(response => response.data);
};
