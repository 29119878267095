import React, {Component} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {ContainerPersonOnboardViews} from "route/person-onboard-container/ContainerPersonOnboardView";
import styled from "styled-components";

const ViewContainerDiv = styled.div`
  margin: 0.5rem 0.525rem;
  display: flex;
  flex-direction: column;
  font-size: 1.275rem;

  @media (max-width: 768px) {
    margin-left: 0;
    margin-right: 0;
  }

  .header-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 3rem;

    @media (max-width: 768px) {
      margin-bottom: 1.3rem;
    }

    .view-title {
      color: #7687FF;
      font-weight: bold;
      font-size: 1.8rem;
      margin-bottom: 0;
      align-self: center;
    }

    .footsteps-icon {
      margin-left: auto;

      @media (max-width: 714px) {
        margin-left: unset;
        margin-top: 2rem;
      }

      .step-text {
        vertical-align: top;
        font-weight: bold;
      }

      svg {
        margin-left: 1rem;
      }
    }
  }

  .login-button {
    margin-bottom: 1.5rem;
  }
`;

interface Props extends WithTranslation {
  titleKey: string;
  activeView: ContainerPersonOnboardViews;
}

class ContainerPersonOnboardContainer extends Component<Props> {

  render(): React.ReactNode {

    const {titleKey, t, children} = this.props;

    return <ViewContainerDiv className="login-view">
      <div className="header-content">
        <h2 className="view-title">
          {t(titleKey)}
        </h2>
      </div>

      {children}
    </ViewContainerDiv>;
  }
}

export default withTranslation(["login"])(ContainerPersonOnboardContainer);
