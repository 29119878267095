import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import {CompanyDataConsumerRenderProps, withCompanyDataContext} from "component/CompanyDataContext";
import {DataRow} from "component/Dashboard";
import React, {Component} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {RouterProps} from "react-router";
import styled from 'styled-components';
import {EmployeeAccessTokenType} from "ts-types/api.enums";
import {withRouterWorkaround} from "util/workaroundUtils";

const ContentWrapperDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 1.4rem;

  .container & {
    @media (max-width: 768px) {
      margin-right: -1rem;
      margin-left: -1rem;
    }
  }

  .data {
    flex-basis: 60%;
    padding-right: 2rem;

    > div {
      padding: 0.5rem;

      :nth-child(odd) {
        background-color: #E5E5E5;
      }
    }

    .data-row {
      display: flex;
      flex-wrap: wrap;

      .column-header {
        flex-basis: 40%;
      }

      .column-value {
        flex-basis: 60%;
      }
    }
  }

  .company-logo-container {
    flex-basis: 40%;
    //background-color: #E5E5E5;
    padding: 0.5rem;
    position: relative;
    font-weight: 700;
    text-align: center;

    .logo, img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    img {
      max-height: 100px;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 767px) {
    .data {
      flex-basis: 100%;
      padding-right: 0;

      .data-row {
        flex-wrap: nowrap;
      }
    }

    .company-logo-container {
      flex-basis: 100%;
      min-height: 2.78rem;
      margin-top: 0.75rem;
    }
  }
`;

interface Props extends RouterProps, AuthConsumerRenderProps, CompanyDataConsumerRenderProps, WithTranslation {
}

interface State {

}

class CompanyDataHeader extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  mapCompanyDataForDisplay = (): Array<React.ReactNode> => {

    const {companyData, isPersonAccessType, t} = this.props;

    const currentUser = this.props.currentUser!;

    const accountType = currentUser.accountType;
    const personAccessType = isPersonAccessType();

    const messageKey = personAccessType ? "employee" : "administrator";

    const personEmail = currentUser.username != null ? `, ${currentUser.username}` : "";

    const name = personAccessType
        ? `${currentUser.firstName} ${currentUser.lastName}${personEmail}`
        : companyData.administrator;

    let mappedData: DataRow[] = [];

    if (!(EmployeeAccessTokenType.PERSON_SELF_ONBOARDED === accountType) && !currentUser.selfOnboardedPerson) {

      const companyName = companyData.id === 957 ? currentUser.companyName : currentUser.companyNameAndAddress;

      mappedData = [{
        header: t("dashboard.content.institution"),
        value: companyName,
        bold: true
      },
        {
          header: t(`dashboard.content.${messageKey}`),
          value: name,
          bold: true
        }
      ];
    } else {
      mappedData = [
        {
          header: t(`dashboard.content.${messageKey}`),
          value: name,
          bold: true
        }
      ];
    }


    return mappedData.map((data: DataRow, index: number) => (
        <div className="data-row" key={index}>
          <div className="column-header">{data.header}:</div>
          <div
              className="column-value"
              style={{fontWeight: data.bold ? "bold" : "normal"}}
          >
            {data.value}
          </div>
        </div>
    ));
  };

  renderCompanyLogo = (): React.ReactNode => {

    const {companyLogoUrl} = this.props;

    const currentUser = this.props.currentUser!;
    const accountType = currentUser.accountType;

    if (EmployeeAccessTokenType.PERSON_SELF_ONBOARDED === accountType
        && currentUser.selfOnboardedPerson) {
      return <></>;
    }

    return (
        <div className="company-logo-container">
          {companyLogoUrl
              ? <img alt="Company logo" src={companyLogoUrl} height="90%" />
              : <div></div>
          }
        </div>
    );
  };

  render(): React.ReactNode {

    return (
        <ContentWrapperDiv>
          <div className="data">
            {this.mapCompanyDataForDisplay()}
          </div>
          {this.renderCompanyLogo()}
        </ContentWrapperDiv>
    );
  }
}

export default withRouterWorkaround(withAuthContext(withCompanyDataContext(withTranslation(["mipoco"])(CompanyDataHeader))));
