import {AuthConsumerRenderProps, withAuthContext} from "auth/AuthContext";
import axios, {CancelTokenSource} from "axios";
import CompanyDataHeader from "component/CompanyDataHeader";
import DeleteActionButton from "component/DeleteActionButton";
import HeaderMessage from "component/HeaderMessage";
import LoaderComponent from "component/LoaderComponent";
import StyledErrorMessage from "component/StyledErrorMessage";
import VirtualizedTable from "component/VirtualizedTable";
import React, {ChangeEvent, Component, createRef} from 'react';
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router";
import {Button, Icon, Input, InputOnChangeData} from "semantic-ui-react";
import {addTestToPool, getPoolTestKits, removeTestFromPool} from "service/testKitServices";
import styled from "styled-components";
import {PoolTestKitOverviewProjection} from "ts-types/api.types";
import {isKeyCheck} from "util/keyUtils";
import {withRouterWorkaround} from "util/workaroundUtils";

const TestRegistrationDiv = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  .header-message-text {
    font-size: 1.5rem;
  }

  .search-form {
    padding-left: 0.75rem;
    margin-bottom: 1rem;

    label {
      margin-right: 1rem;
    }

    .ui.input {
      min-width: 20rem;
    }

    button {
      margin-left: 1rem;
    }
  }

  .results-table {
    flex: 1 1 auto;

    .row-actions {
      i.icon,
      i.icons {
        color: #768aff;
      }
    }
  }

  .icon-button {
    padding: 2px !important;
    background: transparent !important;
  }

  .page-actions {
    justify-self: start;
    margin-top: 0.75rem;

    .actions-left {
      float: left;
    }

    .actions-right {
      float: right;
    }
  }

  .error {
    margin-bottom: 1rem;
  }
`;

interface Props extends RouteComponentProps<any>, AuthConsumerRenderProps, WithTranslation {
}

interface State {
  testKitCode: string,
  poolTestId: number,
  poolTestKitCode: string,
  testKits: PoolTestKitOverviewProjection[],
  testKitsLoaded: boolean,
  errorMessages: Array<string>,
  cancelTokenSource: CancelTokenSource;
}

class PoolTestRegistration extends Component<Props, State> {

  testKitCodeInputRef = createRef<Input>();

  constructor(props: Props) {
    super(props);

    const cancelTokenSource = axios.CancelToken.source();

    const state: any = props.location.state!;

    this.state = {
      testKitCode: "",
      poolTestId: state.poolTestId,
      poolTestKitCode: state.poolTestKitCode,
      testKits: [],
      testKitsLoaded: true,
      errorMessages: [],
      cancelTokenSource
    };

    setTimeout(() => {
      this.fetchTestKits();
    }, 5);
  }

  fetchTestKits = async () => {
    const {poolTestId, cancelTokenSource} = this.state;

    this.clearErrors();

    this.setState({
      testKitsLoaded: false
    });

    try {
      const searchResults = await getPoolTestKits(poolTestId, cancelTokenSource);
      this.setState({
        testKits: searchResults
      });
    } catch (ex) {
      this.handleError(ex.response.data);
    } finally {
      this.setState({
        testKitsLoaded: true
      });
    }
  };

  handleError = (exception: any) => {
    const {t} = this.props;

    const error = exception;
    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
          "COVID_POOL_TEST_CHANGE_NOT_ALLOWED",
          "COVID_TEST_CHANGE_NOT_ALLOWED",
          "COVID_TEST_ALREADY_IN_ANOTHER_POOL"
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            this.setErrorMessage(t(`poolTestKitEdit.error.${violation.errorCode}`));
          }
        });
      }

      if (!this.state.errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          this.setErrorMessage(t(`error.${errorCode}`));
        } else {
          this.setErrorMessage(t('error.general'));
        }
      }
    }
  };

  setErrorMessage = (errorMessage?: string) => {

    const {errorMessages} = this.state;

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);

      this.setState({
        errorMessages: errMsgs
      });

    } else {
      this.clearErrors();
    }
  };

  clearErrors = () => {
    this.setState({
      errorMessages: []
    });
  };

  setTestKitCode = (evt: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    this.setState({
      testKitCode: data.value
    });
  };

  onTestKitCodeKeyDown = (event: any) => {
    event.persist();
    const isKey: any = isKeyCheck(event);

    if (isKey.enter) {
      this.addTestKitCodeToPool();
    }
  };

  addTestKitCodeToPool = async () => {
    const {testKitCode, poolTestId, cancelTokenSource} = this.state;

    this.clearErrors();

    try {
      await addTestToPool(poolTestId, testKitCode, cancelTokenSource);
      this.setState({
        testKitCode: ""
      });
      this.testKitCodeInputRef.current?.focus();
      this.fetchTestKits();
    } catch (e) {
      this.testKitCodeInputRef.current?.focus();
      this.testKitCodeInputRef.current?.select();
      this.handleError(e.response.data);
    }
  };

  removeTestKitCodeFromPool = async (testKitCode: string) => {
    const {poolTestId, cancelTokenSource} = this.state;

    this.clearErrors();

    try {
      await removeTestFromPool(poolTestId, testKitCode, cancelTokenSource);
      this.setState({
        testKitCode: ""
      });
      this.fetchTestKits();
    } catch (e) {
      this.handleError(e.response.data);
    }
  };

  render(): React.ReactNode {

    const {t} = this.props;
    const {testKitCode, errorMessages, testKitsLoaded, poolTestKitCode} = this.state;

    const state: any = this.props.location.state;
    const registeredTestkitCode = (state && state.registeredTestkitCode) ? state.registeredTestkitCode : undefined;

    let headerMessageText = "";
    let headerMessageParts: Array<string> = [];

    if (registeredTestkitCode) {
      headerMessageText = t(
          "pooledTestKitEdit.registrationSuccess", {code: registeredTestkitCode});
      headerMessageParts = headerMessageText.split("###");
    }

    return <TestRegistrationDiv className="test-registration">

      {
        registeredTestkitCode &&
        <HeaderMessage visible>
          <div className="header-message-text">
            {headerMessageParts[0]}<b>{headerMessageParts[1]}</b>{headerMessageParts[2]}
          </div>
        </HeaderMessage>
      }

      <CompanyDataHeader />

      <div className="title-h1">{t("poolTestKitEdit.title", {code: poolTestKitCode})}</div>

      {errorMessages.length > 0 &&
      <div className="error">
        <StyledErrorMessage onDismiss={() => this.clearErrors()}>
          {errorMessages.map(err => <div key={err}>{err}</div>)}
        </StyledErrorMessage>
      </div>
      }

      <div className="results-table">
        {!testKitsLoaded
            ? <LoaderComponent message={t("employeeDashboard.table.loading")} />
            : this.renderTestKitsTable()
        }
      </div>

      <div className="page-actions">
        {/*<div className="actions-left">
        </div>*/}

        <div className="actions-right">
          <div className="search-form">
            <label>{t("poolTestKitEdit.addTest.testKitCode")}</label>
            <Input
                ref={this.testKitCodeInputRef}
                placeholder=""
                value={testKitCode}
                onChange={this.setTestKitCode}
                onKeyDown={(e: any) => this.onTestKitCodeKeyDown(e)}
            />

            <Button
                type="button"
                className="action-button"
                primary
                onClick={this.addTestKitCodeToPool}
            >
              {t("poolTestKitEdit.addTest.addAction")}
            </Button>

            <Button
                type="button"
                className="action-button"
                color={"grey"}
                onClick={(evt) => this.props.history.push("/pool-testkit-registration")}
            >
              {t("action.back")}
            </Button>
          </div>
        </div>
      </div>

    </TestRegistrationDiv>;
  }

  renderTestKitsTable = (): JSX.Element => {

    const {t} = this.props;
    const {testKits} = this.state;

    return (
        <VirtualizedTable
            rowCount={testKits.length}
            rowGetter={this.testKitsRowGetter}
            rowRenderer={this.testKitsRowRenderer}
            columns={[
              {
                width: 200,
                label: t("poolTestKitEdit.tableHeader.employee"),
                dataKey: "testKitId",
                flexGrow: 1,
                cellRenderer: this.employeeCellRenderer
              },
              {
                width: 200,
                label: (t("poolTestKitEdit.tableHeader.testKitNum")),
                flexGrow: 2,
                dataKey: "testKitCode"
              },
              {
                width: 120,
                label: (t("employee.actions")),
                dataKey: "poolTestId",
                cellRenderer: this.actionCellRenderer
              }
            ]}
        />

    );
  };

  testKitsRowGetter = ({index}: any) => {
    const {testKits} = this.state;
    Object.assign(testKits[index], {index: index + 1});

    return testKits[index];
  };

  testKitsRowRenderer = ({className, columns, index, key, style}: any) => {
    const a11yProps = {'aria-rowindex': index + 1};

    // const {testKitRegistrations} = this.state;
    // const registration = testKitRegistrations[index];

    let rowStyle = {...style};

    return (
        <div
            {...a11yProps}
            className={className}
            key={key}
            role="row"
            style={rowStyle}
        >
          {columns}
        </div>
    );
  };

  employeeCellRenderer = (data: any) => {
    const {rowData} = data;
    return <div>
      {`${rowData.employeeFirstName} ${rowData.employeeLastName}`}
    </div>;
  };

  actionCellRenderer = ({rowData}: any) => {
    const {t} = this.props;

    return <div className="row-actions">
      <DeleteActionButton
          popupMessage={
            t("testKitRegistration.action.deleteConfirmMessage",
                {
                  code: rowData.covidTestCode,
                  employee: `${rowData.employeeFirstName} ${rowData.employeeLastName}`
                })
          }
          hoverMessage={t("testKitRegistration.action.deleteHoverMessage")}
          onConfirm={() => this.removeTestKitCodeFromPool(rowData.testKitCode)}
          trigger={<Icon name={'trash alternate outline'} />}
      />
    </div>;
  };

}

export default withRouterWorkaround(
    withAuthContext(
        withTranslation(["mipoco"])(
            PoolTestRegistration)));
