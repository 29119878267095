// Even though this is deprecated the @zxing/browser doesn't work as expected
import {BrowserBarcodeReader} from "@zxing/library";
import {DropdownOption} from "component/final-form/Select";
import React from "react";
import {useTranslation} from "react-i18next";
import {Dropdown} from "semantic-ui-react";

interface BarcodeReaderProps {
    width: number;
    height: number;
    cameraScanActive: boolean;

    onCodeRead(code: string): void;
}

const BarcodeReader = ({width, height, onCodeRead, cameraScanActive}: BarcodeReaderProps) => {

    const {t} = useTranslation('login');

    const codeReaderRef = React.useRef<BrowserBarcodeReader>();
    const videoRef = React.useRef<HTMLVideoElement>(null);

    const [videoDeviceAvailable, setVideoDeviceAvailable] = React.useState(true);
    const [error, setError] = React.useState<string | undefined>();
    const [videoDevices, setVideoDevices] = React.useState<MediaDeviceInfo[]>([]);
    const [videoDeviceOptions, setVideoDeviceOptions] = React.useState<DropdownOption[]>([]);
    const [selectedDeviceId, setSelectedDeviceId] = React.useState<string>('');

    const createAndSetDeviceOptions = (devices: any[]) => {
        const options: DropdownOption[] = devices.map(device => ({
           key: device.deviceId,
           value: device.deviceId,
           text: device.label
        }));
        setVideoDeviceOptions(options);
    };

    React.useEffect(() => {
        codeReaderRef.current = new BrowserBarcodeReader();

        const getVideoDevices = async () => {
            const codeReader = codeReaderRef.current;
            if (codeReader && codeReader.isMediaDevicesSuported) {
                try {
                    // needed to ask permissions
                    await window.navigator.mediaDevices.getUserMedia({video: true});

                    let videoDevices = await codeReader.listVideoInputDevices();
                    videoDevices = videoDevices.filter(videoDevice => !!videoDevice.deviceId);
                    if (videoDevices.length >= 2) {
                        const deviceId = videoDevices[1].deviceId;
                        setSelectedDeviceId(deviceId);
                        setVideoDevices(videoDevices);
                        createAndSetDeviceOptions(videoDevices);
                    } else if (videoDevices.length >= 1) {
                        const deviceId = videoDevices[0].deviceId;
                        setSelectedDeviceId(deviceId);
                        setVideoDevices(videoDevices);
                        createAndSetDeviceOptions(videoDevices);
                    } else {
                        setVideoDeviceAvailable(false);
                    }
                } catch (error) {
                    setVideoDeviceAvailable(false);
                }
            } else {
                setVideoDeviceAvailable(false);
            }
        };

        getVideoDevices();
    }, []);

    React.useEffect(() => {
        if (cameraScanActive && videoRef.current && selectedDeviceId) {
            codeReaderRef.current?.decodeFromVideoDevice(selectedDeviceId, videoRef.current, (result, err) => {
                if (result) {
                    const text = result.getText();
                    onCodeRead(text);
                } else {
                    setError(err?.message);
                }
            });
        } else if (!cameraScanActive) {
            codeReaderRef.current?.reset();
        }
    }, [selectedDeviceId, cameraScanActive, onCodeRead])

    const handleVideoDeviceChange = React.useCallback((newDeviceId: string) => {
        setSelectedDeviceId(newDeviceId);
    }, []);

    if (!videoDeviceAvailable) {
        return (
            <>
                <span>{t('video.device.not.available')}</span>
                <input
                    type="text"
                    placeholder={t('input.barcode.placeholder')}
                    onChange={e => onCodeRead(e.target.value)}
                />
            </>
        );
    }

    return (
        <>
            <video
                ref={videoRef}
                autoPlay
                playsInline
                // width={`${width}px`}
                // height={`${height}px`}
            />

            {!!videoDevices.length && (
                <Dropdown
                    className="device-select"
                    selection
                    options={videoDeviceOptions}
                    value={selectedDeviceId}
                    onChange={(event, data) => handleVideoDeviceChange(data.value as string)}
                />
            )}

            {error && <p>Error: {error}</p>}
        </>
    )
}

export default BarcodeReader;